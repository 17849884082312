import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KbaQuestionsAndAnswersQueryVariables = Types.Exact<{
  kbaId: Types.Scalars['String'];
}>;


export type KbaQuestionsAndAnswersQuery = { __typename?: 'Query', getKBAQuestionAnswers: { __typename?: 'KbaEntity', id: string, KBAQuestions?: Array<{ __typename?: 'KbaQuestionEntity', id?: string | null, index?: number | null, type?: string | null, text?: string | null, kbaAnswers?: Array<{ __typename?: 'KbaAnswerEntity', id?: string | null, index?: number | null, text?: string | null, KBAQuestionId?: string | null }> | null }> | null } };


export const KbaQuestionsAndAnswersDocument = gql`
    query kbaQuestionsAndAnswers($kbaId: String!) {
  getKBAQuestionAnswers(kbaId: $kbaId) {
    id
    KBAQuestions {
      id
      index
      type
      text
      kbaAnswers {
        id
        index
        text
        KBAQuestionId
      }
    }
  }
}
    `;

/**
 * __useKbaQuestionsAndAnswersQuery__
 *
 * To run a query within a React component, call `useKbaQuestionsAndAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKbaQuestionsAndAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKbaQuestionsAndAnswersQuery({
 *   variables: {
 *      kbaId: // value for 'kbaId'
 *   },
 * });
 */
export function useKbaQuestionsAndAnswersQuery(baseOptions: Apollo.QueryHookOptions<KbaQuestionsAndAnswersQuery, KbaQuestionsAndAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KbaQuestionsAndAnswersQuery, KbaQuestionsAndAnswersQueryVariables>(KbaQuestionsAndAnswersDocument, options);
      }
export function useKbaQuestionsAndAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KbaQuestionsAndAnswersQuery, KbaQuestionsAndAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KbaQuestionsAndAnswersQuery, KbaQuestionsAndAnswersQueryVariables>(KbaQuestionsAndAnswersDocument, options);
        }
export type KbaQuestionsAndAnswersQueryHookResult = ReturnType<typeof useKbaQuestionsAndAnswersQuery>;
export type KbaQuestionsAndAnswersLazyQueryHookResult = ReturnType<typeof useKbaQuestionsAndAnswersLazyQuery>;
export type KbaQuestionsAndAnswersQueryResult = Apollo.QueryResult<KbaQuestionsAndAnswersQuery, KbaQuestionsAndAnswersQueryVariables>;
export const namedOperations = {
  Query: {
    kbaQuestionsAndAnswers: 'kbaQuestionsAndAnswers'
  }
}