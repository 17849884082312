import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import moment from 'moment';
import { message as messageAlert } from 'antd';
// import API from '../../api';
import * as config from '../../api/config';
import { getFullName } from '../../utils/getFullName';
// import { getChannel } from '../../redux/actions/channel';
import { useGetChannelMessagesLazyQuery, useGetUserChannelsQuery } from '../../graphql/queries/messaging';
import { useUpdateMessagesMutation } from '../../graphql/mutations/messaging';
import reduxStore from '../../redux/store/store';

let g_pusher = null;
const getPusher = (accessToken) => {
  if (!accessToken) {
    return undefined;
  }

  if (g_pusher) {
    return g_pusher;
  }

  g_pusher = new Pusher(config.pusherKey, {
    cluster: 'us2',
    authEndpoint: `${config.serverUrl}/pusher`,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  return g_pusher;
};

const useMultiChannels = (userId, currentChannelId, visible, setCurrentChannelId, dispatch) => {
  const [allChannels, setAllChannels] = useState([]);
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState({}); // { channelId: [messages] }
  const [newMessage, setNewMessage] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);
  const [isChannelsThere, setChannelFetchedStatus] = useState(null);
  const [firmList, setFirmList] = useState([]);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);

  const accessToken = reduxStore.store.getState()?.session?.graphSession?.accessToken;
  const pusher = getPusher(accessToken);

  const [getChannelMessages] = useGetChannelMessagesLazyQuery();

  useGetUserChannelsQuery({
    onCompleted: (data) => {
      const chs = (data?.userChannels ?? []).map((x) => {
        const channel = {
          id: x.id,
          ChannelMembers: x.ChannelMembers.map((cm) => ({
            userId: cm.userId,
            Users: {
              UserInformation: cm.Users?.UserInformation
            }
          })),
        }
        return channel;
      })
      setAllChannels(chs);
      const ourMessages = (data?.userChannels ?? []).reduce((acc, channel) => {
        acc[channel.id] = channel.Messages.map((m) => ({
          id: m.id,
          userId: m.userId,
          channelId: m.channelId,
          readDate: m.readDate,
          attachment: m.attachment,
          text: m.text,
          isSms: m.isSms,
          createAt: m.createAt,
          updateAt: m.updateAt,
        }));
        return acc;
      }, {});
      setMessages(ourMessages);
    }
  })

  const addMessages = (id, messagesToAdd) => {
    const updatedChannel = channels.find((ch) => ch.id === id);
    if (updatedChannel) {
      if (currentChannelId === id && visible) {
        messages[id].push(
          ...messagesToAdd.map((msg) => ({
            ...msg,
            readDate: new Date(),
          })),
        );
      } else {
        messages[id].push(...messagesToAdd);
      }
    }
    const filterChannels = channels.filter((ch) => ch.id !== id);
    setChannels([updatedChannel, ...filterChannels]);
    setNewMessage(undefined);
  };

  const getMoreMessages = async (channelId, take, skip) => {
    setLoadingMoreMessages(true);
    const { data } = await getChannelMessages({
      fetchPolicy: 'network-only',
      variables: {
        channelId,
        take: take || 10,
        skip,
      },
      onError: (err) => {
        messageAlert.error(err.message);
      },
    });
    const fetchedMessages = data?.getChannelMessages;
    addMessages(
      channelId,
      fetchedMessages,
    );
    setLoadingMoreMessages(false);
  };

  const [setMessagesRead] = useUpdateMessagesMutation();

  const readHistoryNotCatch = async (channel, setMessagesReadFunc) => {
    // API.put(`messages/read/${channelName}`).then(() => {});
    const messagesRead = messages[channel.id]
      .map((msg) => { 
        if (msg.userId !== userId && !msg.readDate) return { id: msg.id, readDate: new Date() };
        return null;
      })
      .filter((msg) => msg);
  
    if (messagesRead.length) {
      return setMessagesReadFunc({
        variables: {
          updateMessagesInput: messagesRead,
        }
      });
    }
    return [];
  };  

  const readHistory = async (idChannel) => {
    const channel = channels.find((ch) => ch.id === idChannel);
    const filterChannels = channels.filter((ch) => ch.id !== idChannel);
    /* messages[channel.id].forEach((msg) => {
      // eslint-disable-next-line no-param-reassign
      if (msg.userId !== userId) msg.readDate = new Date();
    }); */
    setChannels([channel, ...filterChannels]);
    await readHistoryNotCatch(channel, setMessagesRead, userId);
  };
  
  const onMessage = (id, message) => {
    setNewMessage({ id, message });
  };

  useEffect(() => {
    const channel = pusher.channel(`presence-${userId}`);
    if (!channel) {
      const newChannel = pusher.subscribe(`presence-${userId}`);
      newChannel.bind('call', (infoCall) => {
        setIncomingCall(infoCall.name);
      });
      newChannel.bind('chat', (message) => {
        onMessage(userId, message);
      });
    }

    setChannels(allChannels);
    setChannelFetchedStatus(isChannelsThere === null ? !!allChannels.length : !isChannelsThere);
  }, [allChannels]);

  useEffect(() => {
    const addMessage = ({ message }) => {
      const updatedChannel = channels.find((ch) => ch.id === message.channelId);
      if (updatedChannel) {
        if (currentChannelId === message.channelId && visible) {
          messages[message.channelId].push({
            ...message,
            readDate: new Date(),
          });
          // readHistoryNotCatch(updatedChannel.id, userId);
        } else {
          messages[message.channelId].push(message);
        }
      }
      const filterChannels = channels.filter((ch) => ch.id !== message.channelId);
      setChannels([updatedChannel, ...filterChannels]);
      setNewMessage(undefined);
    };

    // const addStatus = ({ id, localStatus }) => {
    //   const updatedChannel = channels.find((ch) => ch?.id === id);
    //   if (updatedChannel) {
    //     updatedChannel.status = localStatus;
    //   }
    //   const filterChannels = channels.filter((ch) => ch?.id !== id);
    //   setChannels([updatedChannel, ...filterChannels]);
    // };

    // // if (channels.length <= 0) {
    // //   connectChannels();
    // // }  
    // if (status) {
    //   addStatus(status);
    // }
    if (newMessage) {
      addMessage(newMessage);
    }
  }, [newMessage, status]);

  useEffect(() => {
    (async () => {
      if (isChannelsThere === null) {
        return;
      }  
      // const { data: result } = await firmListFetcher(userId);
      /* const firmHasChannels = new Set();
      channels?.forEach((e) => {
        firmHasChannels.add(e?.ChannelMembers[0]?.Users?.id);
        firmHasChannels.add(e?.ChannelMembers[1]?.Users?.id);
      }); */
      const allowedChannels = channels; /* .filter(
        (e) => {
          const id = e.ChannelMembers[0].userId !== userId ? e.ChannelMembers[0].userId
            : e.ChannelMembers[1].userId;
          return result.indexOf(id) !== -1;
        },
      ); */
      setFirmList(
        allowedChannels.map(
          (e) => {
            const firmDetails = (e.ChannelMembers[0].userId !== userId ? e.ChannelMembers[0]
              : e.ChannelMembers[1]);
            return {
              channelId: e.id,
              channelName: e.id,
              avatar: firmDetails.Users?.UserInformation?.profilePhoto,
              name: getFullName(firmDetails.Users?.UserInformation),
            };
          },
        ),
      );
      /* const firmWithOutChannel = result.filter((e) => !firmHasChannels.has(e));
      await Promise.all(firmWithOutChannel.map((firmId) => API.post(`${config.serverUrl}/channels/uniqueChecker`, {
        members: [userId, firmId],
      })));

      if (firmWithOutChannel.length) await connectChannels(); */
      const allowedChannelId = allowedChannels.at(0)?.id;
      setCurrentChannelId(allowedChannelId);
    })();
    // eslint-disable-next-line
  }, [isChannelsThere]);

  useEffect(() => {
    if (!channels.length || !channels[0]?.id || !firmList.length) {
      return;
    }
    setFirmList((prev) => prev.map((e) => {
      const channel = channels.find((c) => c.id === e.channelId);
      e.online = channel.online;
      e.unreadMsgCount = messages[channel.id].filter(
        (ev) => !ev.readDate && userId !== ev.userId,
      ).length;
      e.lastMessage = messages[channel.id].length > 0
        ? moment(
          messages[channel.id][messages[channel.id].length - 1].updatedAt,
        ).format('MMM DD, YYYY')
        : '';
      return e;
    }));
    // eslint-disable-next-line
  }, [channels]);

  return [
    channels,
    messages,
    newMessage ? newMessage.message.id : '',
    readHistory,
    incomingCall,
    setIncomingCall,
    firmList,
    getMoreMessages,
    loadingMoreMessages,
  ];
};

export default useMultiChannels;
