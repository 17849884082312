import React, { useCallback } from 'react';
import {
  Input, Form, Col, Select, Radio,
} from 'antd';
import PhoneInputField from '../../../../Controls/PhoneInputField';
import DatePicker from '../../../../atoms/DatePicker';
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const EditInterviewQuestion = ({
  type,
  options,
  required,
  questionId,
  questionName,
  currentValue,
  onAnswerChange,
}) => {
  // console.log('*** EditInterviewQuestion ***');

  const onAnswerChangeHandler = useCallback((event) => {
    if (type === 'SSN') {
      let ssn = event.target.value.replace(/[^0-9-]/g, '');
      if (ssn.startsWith('-')) {
        ssn = ssn.slice(1);
      }
      if (ssn.indexOf('--') !== -1) {
        ssn = ssn.slice(0, ssn.indexOf('--')) + ssn.slice(ssn.indexOf('--') + 1);
      }
      const onlyDashes = ssn.replace(/[0-9]/g, '');
      if (onlyDashes.length > 2) {
        ssn = ssn.slice(0, ssn.lastIndexOf('-')) + ssn.slice(ssn.lastIndexOf('-') + 1);
      }
      const onlyNums = ssn.replace(/-/g, '');
      if (onlyNums.length > 9 || (onlyNums.length === 9 && ssn[ssn.length - 1] === '-')) {
        ssn = ssn.slice(0, -1);
      }
      return onAnswerChange(questionId, ssn);
    }
    if (type === 'FLOAT') {
      const value = event.target.value.replace(/[^0-9.]/g, '');
      return onAnswerChange(questionId, value);
    }
    return onAnswerChange(questionId, event.target.value);
  }, [questionId]);

  const onAnswerChangeHandlerAntd = (value) => {
    onAnswerChange(questionId, value);
  };

  const getInputType = () => {
    switch (type) {
      case 'TEXT':
      case 'EMAIL':
      case 'ENCRYPTED_TEXT':
      case 'FLOAT':
      case 'SSN':
        return (
          <Input onChange={onAnswerChangeHandler} />
        );
      case 'DROPDOWN':
        return (
          <Select onChange={onAnswerChangeHandlerAntd}>
            {
              options.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))
            }
          </Select>
        );
      case 'RADIO':
        return (
          <Radio.Group onChange={onAnswerChangeHandler}>
            {
              options.map(({ value, label }) => (
                <Radio value={value}>{label}</Radio>
              ))
            }
          </Radio.Group>
        );
      case 'DATE':
        return (
          <DatePicker style={{ width: '100%' }} onChange={onAnswerChangeHandlerAntd} />
        );
      case 'PHONE':
        return (
          <PhoneInputField
            onChange={(value) => onAnswerChange(questionName, value)}
            phoneNumber={currentValue}
          />
        );
      case 'TEXT_AREA':
        return (
          <TextArea onChange={onAnswerChangeHandler} />
        );
      default:
        return false;
    }
  };

  const rules = [
    {
      required,
      message: `'${questionName}' is required`,
    },
    ...(
      type === 'EMAIL' ? [{ type: 'email', message: `${questionName} is not a valid email` }] : []
    ),
    ...(
      type === 'SSN' ? [{ pattern: /^(\d-?){8}\d$/, message: `${questionName} is not a valid SSN` }] : []
    ),
  ]

  return (
    <Col sm={10} md={6} lg={20}>
      <Item
        label={questionName}
        name={questionId}
        rules={rules}
      >
        {getInputType()}
      </Item>
    </Col>
  );
};

export default EditInterviewQuestion;