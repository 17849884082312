import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import API from '../../../api';
import MessagesListAdmin from '../MessagesListAdmin';
import useChannel from '../../hooks/useChannel';
import ChatInputAdmin from '../ChatInputAdmin';
import styles from './style.module.css';

const OnCallChat = ({
  channelName, visible, onClose, userId,
}) => {
  const {
    channel,
    newMessage: idLastMessage,
    getInfo: userProInfo,
  } = useChannel(userId, channelName);
  const [mobile, mobileSet] = useState(null);

  const onSendMessage = async (val, attachment) => {
    const message = {
      text: val,
    };
    if (attachment) message.attachment = attachment;
    if (val || attachment) {
      await API.post('messages', {
        message,
        connection: {
          channelId: channel.id,
          userId,
        },
      });
    }
  };

  const isMobile = () => {
    if (window.innerWidth < 390) {
      mobileSet(true);
    } else {
      mobileSet(false);
    }
  };

  useEffect(() => {
    if (mobile === null) isMobile();
    window.addEventListener('resize', isMobile);
    return () => window.removeEventListener('resize', isMobile);
  }, [isMobile]);

  return (
    <div>
      <Drawer
        bodyStyle={{
          padding: 0,
          paddingRight: 20,
          paddingLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        width={mobile ? '100%' : 390}
        title="Chat"
        placement="right"
        closable
        onClose={onClose}
        visible={visible}
        maskStyle={{
          backgroundColor: 'transparent',
        }}
      >
        {channel && (
          <div className={styles.containerMessages}>
            <MessagesListAdmin
              userId={userId}
              data={channel.Messages}
              style={{ height: '100%' }}
              idLastMessage={idLastMessage}
            />
          </div>
        )}
        {userProInfo && (
          <ChatInputAdmin
            style={{ position: 'absolute', bottom: '0', width: '100%' }}
            userId={userId}
            onSendMessage={onSendMessage}
            clientData={userProInfo.clientData}
          />
        )}
      </Drawer>
    </div>
  );
};
OnCallChat.propTypes = {
  channelName: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};
export default OnCallChat;
