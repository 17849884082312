import React from 'react';

const Email = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <g fill="#3D80F3" fillRule="nonzero">
      <path d="M17.263.032H2.405C1.078.032 0 1.152 0 2.528v10.543c0 1.377 1.078 2.497 2.405 2.497h14.854c1.327 0 2.405-1.12 2.405-2.497V2.532c.004-1.377-1.074-2.5-2.4-2.5zm1.306 13.04c0 .747-.586 1.355-1.306 1.355H2.405c-.72 0-1.306-.608-1.306-1.356V2.532c0-.747.585-1.356 1.306-1.356h14.854c.72 0 1.306.609 1.306 1.356v10.54h.004z" />
      <path d="M12.401 7.669l4.81-4.478a.589.589 0 0 0 .04-.807.538.538 0 0 0-.777-.042l-6.632 6.18-1.294-1.2c-.004-.004-.008-.008-.008-.012a.823.823 0 0 0-.09-.08L3.187 2.337a.535.535 0 0 0-.777.046.585.585 0 0 0 .044.807L7.32 7.707l-4.846 4.71a.588.588 0 0 0-.025.806c.11.119.257.182.403.182a.54.54 0 0 0 .374-.152l4.92-4.777L9.48 9.713a.537.537 0 0 0 .732-.004l1.371-1.276 4.891 4.824a.535.535 0 0 0 .777-.02.588.588 0 0 0-.02-.808L12.4 7.67z" />
    </g>
  </svg>
);

export default Email;
