import { useState } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import classes from './style.module.scss';
import Translation from '../../../atoms/Translation';
import './twoFactor.css';

const TwoFA = (props) => {
  const {
    onChangeVerificationCode,
    onToggle,
    session,
    secretOTP,
    codeQR,
    loadingQR,
  } = props;
  const { graphSession } = session;
  const { secretOTP: initialSecretKey } = graphSession || {};
  const [otpValue, changeOtp] = useState(!!initialSecretKey);

  const ToggleOtp = async () => {
    changeOtp((prevValue) => !prevValue);
    onToggle(!otpValue);
  };
  const copyToClipboard = () => navigator.clipboard.writeText(secretOTP);

  return (
    <div className="card p-4 container" style={{ textAlign: 'center' }}>
      <h2>
        <Translation text="settings.twoFactorAuthentication" />
      </h2>
      <hr style={{ margin: 0 }} />
      <div>
        <div>
          <br />
          <ol style={{ padding: '0px' }}>
            <li>
              <div className="row">
                <div className="col-9">
                  <Translation text="settings.twofa.enable" />
                </div>
                <div className="col">
                  <Switch onChange={ToggleOtp} checked={otpValue} loading={loadingQR} />
                </div>
              </div>
            </li>
            { !loadingQR && (
              <>
                {
                  otpValue && codeQR ? (
                    <>
                      <li>
                        <div style={{ paddingTop: '15px' }}>
                          <Translation text="settings.twofa.scan" />
                        </div>
                        <div
                          style={{ textAlign: 'center' }}
                        >
                          <div
                            className="border p-2 rounded-lg"
                            style={{
                              width: '180px',
                              backgroundColor: 'rgba(0, 0, 0, 0.05)',
                              display: 'inline-block',
                            }}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: codeQR }}
                          />
                        </div>
                        <div className="my-3">
                          <span>
                            <Translation text="settings.twofa.getKey1" />
                            {' '}
                          </span>
                          <button
                            onClick={copyToClipboard}
                            type="button"
                            className="btn btn-outline-dark btn-sm"
                          >
                            <Translation text="settings.twofa.getKey2" />
                          </button>
                          <span>
                            {' '}
                            <Translation text="settings.twofa.getKey3" />
                          </span>
                        </div>
                      </li>
                      <li>
                        <p>
                          <Translation text="settings.twofa.enterCode" />
                        </p>
                        <div className="input-group input-group-sm">
                          <input
                            data-cy="codeInput"
                            className="form-control"
                            type="text"
                            onChange={(e) => onChangeVerificationCode(e.target.value)}
                          />
                        </div>
                      </li>
                    </>
                  ) : null
                }
              </>
            )}
          </ol>
        </div>
      </div>
    </div>
  );
};

TwoFA.defaultProps = {
  onChangeVerificationCode: () => {},
  onToggle: () => {},
};

TwoFA.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  // QR: PropTypes.shape().isRequired,
  // generateOTP: PropTypes.func.isRequired,
  // saveChanges: PropTypes.func.isRequired,
  // msgError: PropTypes.string,
  // classError: PropTypes.string,
  // loading: PropTypes.bool.isRequired,
  // setLoading: PropTypes.func.isRequired,
  // msgSuccess: PropTypes.string,
  // cantSave: PropTypes.bool,
  onChangeVerificationCode: PropTypes.func,
  onToggle: PropTypes.func,
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(TwoFA);
