export const appContainer = {
  display: 'block',
  minWidth: '300px',
  margin: '0 auto',
  '@media (min-width: 640px)': {
    padding: '0 10px',
  },
  '@media (min-width: 768px)': {
    padding: '0 20px',
  },
  '@media (min-width: 1024px)': {
    padding: '0 40px',
    maxWidth: '1220px',
  },
};

export const appInner = {
  margin: 0,
  padding: '35px 20px',
  width: '100%',
  '@media (min-width: 640px)': {
    padding: '35px 40px',
  },
  '@media (min-width: 768px)': {
    padding: '40px 54px',
  },
};

export const appCard = {
  ...appInner,
  borderRadius: '3px',
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
};

// const breakpoints = {
//     sm: 400,
//     md: 640,
//     lg: 768,
//     xl: 1024,
//     xxl: 1200
// };
