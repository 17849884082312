import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import VideoCall from '../../organisms/VideoCall';
// import API from '../../../api';
import { MS_PER_MINUTE } from '../../helpers/time';
import { getName, getAvatar } from '../../helpers/userInformation';
import CallLobby from '../../molecules/CallLobby';
import callErrors from './callErrors';
import { useUserMeetingQuery } from "../../../graphql/queries/messaging";

const Call = ({ userId, meetingId }) => {
  const [audioPermission, setAudioPermission] = useState(false);
  const [videoPermission, setVideoPermission] = useState(false);
  const [branding, setBranding] = useState(null);
  const [audio, setAudio] = useState(false);
  const [video, setVideo] = useState(false);
  const [streamAudio, setStreamAudio] = useState(null);
  const [streamVideo, setStreamVideo] = useState(null);
  const [join, setJoin] = useState(false);
  const [callError, setCallError] = useState(callErrors.initialError);

  const { data: meeting, loading: loadingMeeting } = useUserMeetingQuery({
    variables: {
      id: meetingId,
    }
  });

  const { t: translation } = useTranslation();

  const leave = () => {
    setJoin(false);
  };

  const joinCall = () => {
    setJoin(true);
  };

  const updateVideoPermission = (value) => {
    setVideoPermission(value);
  };
  const updateAudioPermission = (value) => {
    setAudioPermission(value);
  };

  const verifyAccess = (event) => {
    if (!event) return true;
    const { startDate, endDate, EventTypes } = event;
    const start = new Date(
      new Date(startDate).getTime() - EventTypes.beforeBuffer * MS_PER_MINUTE,
    );
    const end = new Date(endDate).getTime() + EventTypes.afterBuffer * MS_PER_MINUTE;
    if (moment(new Date()).isBefore(start)) {
      setCallError({
        ...callErrors.earlierAccess,
        description: translation('videocall.call.callScheduledFor', {
          date: moment(start).format('MMM DD, YYYY'),
          timeH: moment(start).format('HH'),
          timeM: moment(start).format('mm'),
        }),
      });
      return false;
    }
    if (moment(new Date()).isAfter(end)) {
      const months = moment(new Date()).diff(moment(end), 'months');
      if (months >= 1) setCallError(callErrors.callExpired);
      else setCallError(callErrors.laterAccess);
      return false;
    }
    return true;
  };

  /* const getBranding = async (preparerId) => {
    const brandingData = await API.get(`whitelabel/branding/${preparerId}`).catch((err) => err);
    if (brandingData.data && brandingData.data.branding) {
      setBranding(brandingData.data.branding);
    } else {
      setBranding({
        customBranding: {
          logoS3Key: 'original',
        },
        firmName: '',
        Users: {
          PreparerDetails: {
            firstName: '',
            lastName: '',
          },
        },
      });
    }
  }; */

  useEffect(() => {
    if (userId && meeting?.UserMeeting.id) {
      const userIsInvited = meeting.UserMeeting.MeetingAttendees.some(
        (attendee) => attendee.userId === userId,
      );
      if (!userIsInvited) {
        setCallError(callErrors.userNotInvited);
        return;
      }
      if (meeting.UserMeeting.Events && meeting.UserMeeting.Events.status === 'CANCELED') {
        setCallError(callErrors.callCancelled);
        return;
      }
      if (verifyAccess(meeting.UserMeeting.Events)) {
        if (
          !callError.error
          || callError.name === 'noMic'
          || callError.name === 'noCam'
        ) {
          if (!audioPermission) setCallError(callErrors.noMic);
          else if (!videoPermission) setCallError(callErrors.noCam);
          const user = meeting.UserMeeting.MeetingAttendees.find(
            (member) => member.userId !== userId,
          );
          const userName = `${user.firstName} ${user.lastName}`;
          const avatarImage = user.profilePhoto;
          const CleanMeeting = {
            ...callErrors.cleanMeeting,
            title: `${translation('videocall.call.meetingWith')} ${userName}`,
            userName,
            avatarImage,
          };

          if (audioPermission && videoPermission) {
            setCallError(meeting.UserMeeting.Events ? callErrors.cleanEvent : CleanMeeting);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [meeting, audioPermission, videoPermission]);

  if (!userId) {
    setCallError(callErrors.mustLogin); 
  }

  if (loadingMeeting) {
    return <></>;
  }

  return join ? (
    <>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </MetaTags>
      <VideoCall
        meeting={meeting?.UserMeeting}
        chatChannel={meeting?.UserMeeting ? meeting.UserMeeting.id : ''}
        branding={branding}
        userId={userId}
        permission={{ audio: audioPermission, video: videoPermission }}
        initialConfig={{ audio, video }}
        leaveCall={leave}
        members={meeting?.UserMeeting ? meeting.UserMeeting.MeetingAttendees : []}
      />
    </>
  ) : (
    <>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </MetaTags>
      <CallLobby
        data={meeting?.UserMeeting}
        branding={branding}
        permission={{
          audio: {
            get: audioPermission,
            set: updateAudioPermission,
          },
          video: {
            get: videoPermission,
            set: updateVideoPermission,
          },
        }}
        config={{
          audio: {
            get: audio,
            set: setAudio,
          },
          streamAudio: {
            get: streamAudio,
            set: setStreamAudio,
          },
          streamVideo: {
            get: streamVideo,
            set: setStreamVideo,
          },
          video: {
            get: video,
            set: setVideo,
          },
        }}
        onClickJoin={joinCall}
        callError={callError}
      />
    </>
  );
};

Call.propTypes = {
  userId: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
};

export default Call;
