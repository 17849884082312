export const GetTaskRank = (task) => {
  let taskRank = 0;
  switch (task.taskType) {
    case 'INTERVIEW':
      taskRank = 10;
      break;
    case 'DOCUMENTS':
      taskRank = 5;
      break;
    case 'PAYMENTS':
      taskRank = 0;
      break;
    default:
      break;
  }

  return taskRank;
};

export const TaskSort = (taskA, taskB) => {
  const taskTypeRankA = GetTaskRank(taskA);
  const taskTypeRankB = GetTaskRank(taskB);

  return taskTypeRankB - taskTypeRankA;
};

export const formatProjects = (clientTaxYearStatuses) => clientTaxYearStatuses.map((item) => {
  const {
    id,
    taxYear,
    taxYearStatus,
    TaxJobTypes,
    Reviews,
    Users_ClientTaxYearStatuses_preparedByIdToUsers: PreparedByUser,
    Tasks,
    ProgressStatuses,
  } = item;

  const tasks = Tasks.sort(TaskSort);

  return {
    id,
    taxYear,
    taxYearJobNamePair: `${taxYear} ${TaxJobTypes.jobName}`,
    taxJobTypeId: TaxJobTypes.id,
    taxYearStatus,
    progressStatus: ProgressStatuses,
    tasks,
    review: Reviews,
    preparedByUser: PreparedByUser?.PreparerDetails
      ? {
        id: PreparedByUser.id,
        inviteLink: PreparedByUser.PreparerDetails.inviteLink,
        email: PreparedByUser.email,
        firstName: PreparedByUser.PreparerDetails.firstName,
        lastName: PreparedByUser.PreparerDetails.lastName,
        personalPhone: PreparedByUser.PreparerDetails.personalPhone,
        profilePicture: PreparedByUser.PreparerDetails.Files.length
          ? PreparedByUser.PreparerDetails.Files[0].s3Key
          : null,
      }
      : null,
  };
});
