import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

const TextFieldModal = ({
  field,
  onOk,
  onCancel,
}) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription(field?.description ?? '');
  }, [field]);

  return (
    <Modal
      visible={!!field}
      title={field?.title}
      onOk={() => onOk(description)}
      onCancel={() => onCancel()}
      okType="primary"
      okButtonProps={{
        disabled: !description.trim().length,
      }}
    >
      <Input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Modal>
  );
}

export default TextFieldModal;
