import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Divider } from "antd";

import Badge from "./Badge";
import Button from "./Button/Button";
import Dropdown from "./Dropdown";
import ContactInfo from "./ContactInfo";
import Referrals from "./Referrals";

import { getFullName } from "../utils/getFullName";
import { grey } from "../styles/colors";
import { appContainer, appCard } from "../styles/layout";
import { Lato, heading } from "../styles/fonts";

import Translation from "./atoms/Translation";

import { TaskType, TaxYearStatus } from '../graphql';
import { useGetTasksByTaxYearInterviewIdQuery } from "../graphql/queries/tasks";

const ProjectCard = (props) => {
  const {
    project, client, channelId, disableTaxarooCalendar,
  } = props;

  // use graphql to fetch tasks
  const { data: tasksData, loading: loadingTasks, error: tasksError } = useGetTasksByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId: project.id,
    },
  });
  const tasks = tasksData?.tasksByTaxYearInterviewId ?? [];
    
  const interviews = tasks
    && tasks.filter(
      (task) => task.taskType === TaskType.Interview && !task.completedAt && !task.deletedAt
    ).length;
  const invoices = tasks
    && tasks.filter(
      (task) => task.taskType === TaskType.Payments && !task.completedAt && !task.deletedAt
    ).length;
  const documents = tasks
    && tasks.filter(
      (task) => (
        [
          TaskType.Signatures,
          TaskType.Documents,
          TaskType.RequestDocuments,
          TaskType.ApproveDocuments,
        ].includes(task.taskType)
      )
      && !task.completedAt && !task.deletedAt  
    ).length;
  let tempAllCount = interviews > 0 ? interviews : 0;
  if (invoices > 0) {
    tempAllCount += invoices;
  }
  if (documents > 0) {
    tempAllCount += documents;
  }

  const openCount = {
    interviews,
    invoices,
    documents,
  };
  const allCount = tempAllCount;

  const openedTasks = tasks.filter(({ completedAt }) => completedAt === null);
  const completedTasks = tasks.filter(({ completedAt }) => !!completedAt);
  const {
    ProgressStatuses,
    Interviews,
    PreparedUser,
    TaxYear,
    Review,
  } = project;
  const {
    year,
    Entity,
  } = TaxYear;
  const { name } = Interviews;
  const { FirmAccount } = Entity.UserFirmAccount[0];

  const statusType = ProgressStatuses?.underlyingStatus === TaxYearStatus.Completed ? "success" : "progress";
  const badgeText = ProgressStatuses?.underlyingStatus === TaxYearStatus.Completed ? "project.complete" : "project.inProgress";
  const taxYearJobNamePair = `${year} ${name}`;
  const preparedByUser = PreparedUser ? {
    ...PreparedUser,
    ...PreparedUser.UserInformation,
  } : null;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={css(styles.container)}>
      <div className={css(styles.card)}>
        <div className={css(styles.header)}>
          <div className={css(styles.titleHolder)}>
            <span className={css(styles.title)}>{taxYearJobNamePair}</span>
            <Badge type={statusType}>
              <Translation text={badgeText} />
            </Badge>
          </div>
          {allCount > 0 && (
            <div className={css(styles.taskCounter)}>
              {allCount} 
              {' '}
              <Translation text="project.openTasks" />
            </div>
          )}
        </div>
        <div className={css(styles.main)}>
          <div className={css(styles.columnLeft)}>
            <div className={css(styles.btnBlock)}>
              <div className={css(styles.third)}>
                <Link
                  to={`/interview/${project.id}`}
                  className={css(styles.btnHolder)}
                >
                  {openCount.interviews > 0 && (
                    <Badge badgeStyles={counterStyles} type="todo">
                      !
                    </Badge>
                  )}
                  <Button
                    label={<Translation text="project.interview" />}
                    primary
                    fullwidth
                  />
                </Link>
              </div>
              <div className={css(styles.third)}>
                <Link
                  to={`/documents/${project.id}`}
                  className={css(styles.btnHolder)}
                >
                  {openCount.documents > 0 && (
                    <Badge badgeStyles={counterStyles} type="todo">
                      !
                    </Badge>
                  )}
                  <Button
                    label={<Translation text="project.documents" />}
                    primary
                    fullwidth
                  />
                </Link>
              </div>
              <div className={css(styles.third)}>
                <Link
                  to={`/payments/${project.id}`}
                  className={css(styles.btnHolder)}
                >
                  {openCount.invoices > 0 && (
                    <Badge badgeStyles={counterStyles} type="todo">
                      !
                    </Badge>
                  )}

                  <Button
                    label={<Translation text="project.invoices" />}
                    primary
                    fullwidth
                  />
                </Link>
              </div>
            </div>
            <div>
              <Dropdown open items={openedTasks} projectId={project.id} />
              <Dropdown completed items={completedTasks} projectId={project.id} />
            </div>
          </div>
          <div className={css(styles.columnRight)}>
            {preparedByUser?.firstName && (
              <h3 className={css(styles.blockTitle)}>
                <Translation text="contactinfo.title" />
              </h3>
            )}
            <ContactInfo
              avatar={preparedByUser && preparedByUser.profilePhoto}
              name={preparedByUser?.firstName ? getFullName(preparedByUser) : null}
              company={FirmAccount?.name?.trim() === getFullName(preparedByUser)?.trim() ? '' : FirmAccount.name}
              phone={preparedByUser && preparedByUser.personalPhone?.replace("+1", "")}
              email={preparedByUser && preparedByUser.email}
              review={Review}
              projectId={project.id}
              status={ProgressStatuses?.underlyingStatus}
              preparedByUserId={preparedByUser && preparedByUser.id}
              client={client}
              channelId={channelId}
              disableTaxarooCalendar={disableTaxarooCalendar}
            />
            {/* Referrals */}
            {preparedByUser?.firstName && (
              <Divider />
            )}
            {(preparedByUser?.firstName || FirmAccount.name) && (
              <h3 className={css(styles.blockTitle)}>
                <Translation text="referrals.title" />
                <span> 
                  {' '}
                  {preparedByUser?.firstName ? getFullName(preparedByUser) : FirmAccount.name}
                </span>
              </h3>
            )}
            <Referrals
              firmName={FirmAccount.name}
              firmId={FirmAccount.id}
              firmUniqueLink={FirmAccount.uniqueLink}
              firmOwnerId={FirmAccount.ownerId}
              preparedByUser={preparedByUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
}


const counterStyles = {
  position: "absolute",
  top: "-26px",
  right: "-5px",
  zIndex: 1,
};

const styles = StyleSheet.create({
  container: {
    ...appContainer,
    marginBottom: "15px",
    "@media (min-width: 640px)": {
      marginBottom: "20px",
    },
  },
  card: appCard,
  header: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: "10px",
    marginBottom: "30px",
    minHeight: "70px",
    ":after": {
      position: "absolute",
      bottom: 0,
      left: "-60px",
      right: "-60px",
      content: '""',
      display: "block",
      borderBottom: `1px solid ${grey["200"]}`,
    },
  },
  titleHolder: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  title: {
    marginRight: "34px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "24px",
    letterSpacing: "0.8px",
    color: grey["800"],
  },
  taskCounter: {
    padding: "4px 0 10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "15px",
    fontWeight: "bold",
    letterSpacing: "0.6px",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  columnLeft: {
    "@media (min-width: 768px)": {
      flexBasis: "50%",
    },
    "@media (min-width: 1024px)": {
      flexBasis: "525px",
    },
  },
  columnRight: {
    "@media (min-width: 768px)": {
      paddingLeft: "40px",
      flexBasis: "50%",
    },
    "@media (min-width: 1024px)": {
      paddingLeft: "54px",
      flexBasis: "calc(100% - 470px)",
    },
  },
  btnBlock: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  btnHolder: {
    position: "relative",
  },
  third: {
    flexBasis: "calc(33.33% - 9px)",
    "@media (min-width: 768px)": {
      flexBasis: "calc(33.33% - 20px)",
    },
  },
  blockTitle: {
    ...heading.h3,
    paddingTop: "4px",
    marginBottom: "30px",
    marginTop: 0,
  },
});

export default ProjectCard;
