/* eslint-disable react/jsx-props-no-spreading */
import {
  useCallback, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
 message, Modal, Tooltip 
} from 'antd';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { completeTask } from "../../../../redux/actions/projects";
import { Icon, IconWithHover } from '../../../Icon/Icon';
import Loader from '../../../Loader/Loader';
import { toggleRequestedDoc } from '../../../../redux/actions/documents';
import { humanFileSize } from '../../../../utils/fileSize';
import { utcToCustomTime } from '../../../../utils/utcToCustomTime';
import Translation from '../../../atoms/Translation';
import DragAndDrop from '../../../atoms/DragAndDrop';
import SelectTaskModal from './SelectTaskModal';
import styles from './ClientDocuments.module.scss';
import axios from 'axios';
import { TaskType } from '../../../../graphql';
import { useUpdateTaskMutation } from '../../../../graphql/mutations/tasks';
import {
  useTaxpayerDeleteFileMutation,
  useUploadTaxpayerFileMutation,
  useUploadTaxpayerFilePresigedUrlMutation,
} from '../../../../graphql/mutations/files';
import FileNameClickableOpen from './FileNameClickableOpen';


const { confirm } = Modal;

const msg = {
  init: <Translation text="documents.clientDocuments.msgUpload" />,
  loading: <Translation text="documents.clientDocuments.msgLoading" />,
  notFormat: <Translation text="documents.clientDocuments.notFormat" />,
};

const isAllowedFileType = (type) => [
  'application/msword', // .doc
  'application/pdf', // .pdf
  'application/rtf', // .rtf
  'application/vnd.ms-excel', // .xls
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.oasis.opendocument.text', // .odt
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/xml', // .xml
].includes(type)
|| [
  'image/', // .bmp, .gif, .jpeg, .png, .tiff, .webp
  'text/', // .csv, .html, .txt
].some(
  (prefix) => type.startsWith(prefix),
);

const ClientDocument = ({
  dispatch,
  taskId,
  tasks,
  projectId, // taxYearInterviewId
  files,
  userId,
  lastName,
  firmAccountId,
  accessToken,
  getSignedFile,
  loadingFiles,
  refetchFiles,
}) => {
  const [requestedDocs, setRequestedDocs] = useState();
  const [uploadMsg, setUploadMsg] = useState(msg.init);
  const [loading, setLoading] = useState(false);
  const [openDocReqTasks, setOpenDocReqTasks] = useState([]);
  const [openInterviewDocTasks, setOpenInterviewDocTasks] = useState([]);
  const [showSelectTaskModal, setShowSelectTaskModal] = useState(false);
  const [numFiles, setNumFiles] = useState(null);
  const inputFile = useRef(null);
  const { t: translation } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const [updateTask] = useUpdateTaskMutation();
  const [taxpayerDeleteFile] = useTaxpayerDeleteFileMutation();
  const [uploadTaxpayerFile] = useUploadTaxpayerFileMutation();
  const [uploadTaxpayerFilePresignedUrl] = useUploadTaxpayerFilePresigedUrlMutation();

  useEffect(() => {
    const oReqDocTasks = tasks && tasks.filter((task) => !task.completedAt && task.taskType === TaskType.RequestDocuments);
    setOpenDocReqTasks(oReqDocTasks);
    const oInterviewDocTasks = tasks && tasks.filter((task) => !task.completedAt && task.taskType === TaskType.Documents);
    setOpenInterviewDocTasks(oInterviewDocTasks);
  }, [tasks]);

  const saveFile = async (fileToUpload, taxYearInterviewId) => {
    try {
      const url = await uploadTaxpayerFilePresignedUrl({
        variables: {
          taxYearInterviewId,
          fileName: fileToUpload.name,
          /*
            Caveat: The MIME type is detected from the file extension and can be fooled or spoofed.
            One can rename a .jpg to a .png and the MIME type will be be reported as image/png.
            But this would be enough for legacy code ;)
          */
          contentType: fileToUpload.type,
        }
      });

      if (url.data?.uploadTaxpayerFilePresigedUrl) {
        const { signedUrl, url: newFileName } = url.data.uploadTaxpayerFilePresigedUrl;
        const fileUrl = new URL(signedUrl);
        const buffer = Buffer.from(await fileToUpload.arrayBuffer());
  
        await axios.put(
          fileUrl.toString(),
          buffer,
          {
            headers: {
              'Content-Type': fileToUpload.type,
            },
          },
        );

        await uploadTaxpayerFile({
          variables: {
            uploadTaxpayerFileInput: {
              taxYearInterviewId,
              fileName: newFileName ?? fileToUpload.name,
              size: fileToUpload.size,
            }
          }
        });
      }
    } catch (error) {
      message.error(error.message, 10);
      throw new Error(error);
    }
  };

  const onDrop = useCallback((callBackFiles) => {
    // check if files are allowed
    if (!callBackFiles.every((x) => isAllowedFileType(x.type))) {
      message.error(translation('documents.clientDocument.notAllowedType'));
      return;
    }

    setLoading(true);
    const now = new Date();
    if (callBackFiles[0]?.name && callBackFiles.length === 1) {
      setUploadMsg(msg.loading);
      setNumFiles(callBackFiles.length);

      saveFile(callBackFiles[0], projectId)
      .then(() => {
        // need to update the list of files
        refetchFiles();
        message.success(`${translation('documents.clientDocuments.uploadSuccess')}`);
        setUploadMsg(msg.init);
        // if open document request tasks & no current taskId
        if (taskId) {
          // close out this particurar task
          updateTask({
            variables: {
              updateTaskInput: {
                id: taskId,
                completedAt: now,
              }
            },
            refetchQueries: ['GetTasksByTaxYearInterviewId'],
            awaitRefetchQueries: true,
          });
          // dispatch to update the list of tasks
          dispatch(completeTask(projectId, [taskId]))
        }
        if (!taskId && openDocReqTasks.length > 0) {
          // open modal to ask about specific tasks
          setShowSelectTaskModal(true);
        }
        if (openInterviewDocTasks.length > 0) {
          // close out the Upload Files interview task
          const taskIds = openInterviewDocTasks.map((item) => item.id);
          // call function to update tasks
          for (const t of taskIds) {
            updateTask({
              variables: {
                updateTaskInput: {
                  id: t,
                  completedAt: now,
                }
              }
            }) 
          }
        }
      });
    } else if (callBackFiles.length > 1 && callBackFiles.every((f) => f.name)) {
      setUploadMsg(msg.loading);
      setNumFiles(callBackFiles.length);

      callBackFiles.forEach((f) => {
        if (f.name) {
          saveFile(f, projectId)
          .then(() => {
            // need to update the list of files
            refetchFiles();
            setUploadMsg(msg.init);
            if (taskId) {
              // close out this particular task
              updateTask({
                variables: {
                  updateTaskInput: {
                    id: taskId,
                    completedAt: now,
                  }
                }
              });
            }
            // if open document request tasks & no current taskId
            if (!taskId && openDocReqTasks.length > 0) {
              // open modal to ask about specific tasks
              setShowSelectTaskModal(true);
            }
            if (openInterviewDocTasks.length > 0) {
              // close out the Upload Fiiles interview task
              const taskIds = openInterviewDocTasks.map((item) => item.id);
              // call function to update tasks
              for (const t of taskIds) {
                updateTask({
                  variables: {
                    updateTaskInput: {
                      id: t,
                      completedAt: now,
                    }
                  }
                }) 
              }
            }
          });
        }
      });
    } else {
      setUploadMsg(msg.notFormat);
    }
    setLoading(false);
    inputFile.current.value = null;
  }, [tasks, taskId, openDocReqTasks, openInterviewDocTasks]);


  const onRequestedDocClick = (docIndex) => {
    const newDocs = requestedDocs.slice();
    newDocs[docIndex].completedAt = new Date();

    setRequestedDocs({
      requestedDocs: newDocs,
    });
    dispatch(
      toggleRequestedDoc(newDocs[docIndex].id, newDocs[docIndex].completedAt),
    );
  };

  const onDeleteFile = async (e, id) => {
    e.stopPropagation();
    confirm({
      title: translation('documents.clientDocuments.areYouSure'),
      content: translation('documents.clientDocuments.deleteMessage'),
      cancelText: translation('documents.clientDocuments.cancel'),
      okText: translation('documents.clientDocuments.delete'),
      okType: "danger",
      async onOk() {
        // delete file in the db
        try {
          await taxpayerDeleteFile({
            variables: {
              id,
            }
          });
          message.success(`${translation('documents.clientDocuments.deleteSuccess')}`);
          refetchFiles();
        } catch (error) {
          console.log(error);
          message.error(error);
        }
      },
    });
  };

  const uploadFile = (event) => {
    event.preventDefault();
    console.log(`uploadFile - event.target.files: ${JSON.stringify(event.target.files)}`);
    onDrop([...event.target?.files]);
  };

  const openLink = async (key) => {
    try {
      const result = await getSignedFile({
        variables: {
          s3Key: key,
        },
      });
      const url = result?.data?.SignedFileByS3Key;
      window.open(url, '_blank');
    } catch (e) {
      console.log('caught error... ');
      console.warn(e);
    }
  };

  const tableFiles = [
    ...files,
    ...(!tasks ? [] : tasks
      .filter((task) => task.taskType === TaskType.RequestDocuments && !task.completedAt && !task.deletedAt)
      .map((task) => ({
        id: task.id,
        name: task.RequestedFiles?.fileName,
        createAt: task.createAt,
      }))
    ),
  ];

  return (
    <div>
      <div className={styles.btnBlock} />
      <div className={styles.contentBlock}>
        {requestedDocs?.length > 0 && (
          <div className={styles.requestedBlock}>
            <div className={styles.h3}>Requested Documents</div>
            <div className={styles.requestedList}>
              {requestedDocs.map((d, i, arr) => (
                <div
                  key={d.id}
                  className={`${styles.listRow} ${
                    i === arr.length - 1 && styles.lastListRow
                  }`}
                >
                  <div className={styles.checkIcon}>
                    <Icon
                      onClick={() => onRequestedDocClick(i)}
                      check={d.completedAt ? 'square' : 'squareBlank'}
                    />
                  </div>

                  <div className={styles.text}>{d.documentName}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <DragAndDrop onDrop={onDrop} className={styles.dropBoxWrap} click={false}>
          <div className={styles.table}>
            <div className={styles.header}>
              <div className={`${styles.headerCol} ${styles.headerColFirst}`}>
                <Translation text="documents.clientDocuments.name" />
              </div>
              <div className={`${styles.headerCol} ${styles.headerColSecond}`}>
                <Translation text="documents.clientDocuments.fileSize" />
              </div>
              <div className={`${styles.headerCol} ${styles.headerColThird}`}>
                <Translation text="documents.clientDocuments.date" />
              </div>
              <div className={`${styles.headerCol} ${styles.headerColLast}`} />
            </div>
            {loadingFiles && (
              <div className={styles.loaderHolder}>
                <Loader />
              </div>
            )}
            {!loadingFiles && files?.length === 0 && (
            <div className={styles.loaderHolder}>
              <span className={styles.text}>
                <Translation text="documents.clientDocuments.notUploaded" />
              </span>
            </div>
            )}
            {!!tableFiles?.length
              && tableFiles.map((d) => (
                <div key={d.id} className={styles.row}>
                  <div className={`${styles.cell} ${styles.cellFirst}`}>
                    {
                      d.s3Key
                        ? (
                          <FileNameClickableOpen
                            id={d.id}
                            s3Key={d.s3Key}
                            name={d.name}
                            openLink={openLink}
                          />
                        )
                        : (
                          <div style={{ fontWeight: 'normal', color: 'black' }}>
                            {
                              `${translation('documents.clientDocuments.pendingFileRequest', {
                                  fileName: d.name || translation('documents.clientDocuments.fileNameIsEmpty')
                              })}`
                            }    
                          </div>  
                        )
                    }
                  </div>
                  <div className={`${styles.cell} ${styles.cellSecond}`}>
                    {d.s3Key ? humanFileSize(d.size) : ''}
                  </div>
                  <div className={`${styles.cell} ${styles.cellThird}`}>
                    {utcToCustomTime(d.createAt, false)}
                  </div>
                  <div className={`${styles.cell} ${styles.cellLast}`}>
                    {
                      d.s3Key
                      ? (
                        <Tooltip title={translation('documents.clientDocuments.view')}>
                          <button
                            type="button"
                            className={styles.links}
                            onClick={() => openLink(d.s3Key)}
                          >
                            <IconWithHover icon="eye" hovered={false} />
                          </button>
                        </Tooltip>  
                      )
                      : (
                        <div />
                      )
                    }

                    {
                      d.s3Key
                        ? (
                          <Tooltip title={translation('documents.clientDocuments.delete')}>
                            <button
                              type="button"
                              className={styles.links}
                              onClick={(e) => onDeleteFile(e, d.id)}
                            >
                              <IconWithHover
                                icon="trash"
                                // type="button"
                                hovered={false}
                              />
                            </button>  
                          </Tooltip>
                        )
                        : (
                          <div />
                        )
                    }
                  </div>
                </div>
              ))}
            {loading ? <Icon type="upload" progress /> : (
              <label
                htmlFor="file"
                className={styles.rowUpload}
              >
                <input
                  id="file"
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  onChange={uploadFile}
                  ref={inputFile}
                />
                <div className={styles.dropBox}>
                  <Icon type="upload" />
                  <span>{uploadMsg}</span>

                </div>
              </label>
            )}
          </div>
        </DragAndDrop>
      </div>
      {showSelectTaskModal && openDocReqTasks && (
        <SelectTaskModal
          setShowSelectTaskModal={setShowSelectTaskModal}
          openDocReqTasks={openDocReqTasks}
          numDocsUploaded={numFiles || 2}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};

ClientDocument.defaultProps = {
  taskId: undefined,
};

ClientDocument.propTypes = {
  dispatch: PropTypes.func.isRequired,
  taskId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
};

export default ClientDocument;
