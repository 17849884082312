/* eslint-disable */
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";

import Payment from "./Payment";
import InvoiceView from "./InvoiceView";
import Loader from "../Loader/Loader";
import Badge from "../Badge";
import Button from "../Button/Button";
import {
  viewInvoice,
  resetInvoices,
  showPayment,
} from "../../redux/actions/invoices";
import withOverlay from "../../utils/withOverlay";
import { Lato, text } from "../../styles/fonts";
import { color, grey } from "../../styles/colors";
import { utcToCustomTimeAndDate } from "../../utils/utcToCustomTime";

import Translation from "../atoms/Translation";
import { useGetPaymentsByTaxYearInterviewIdQuery } from "../../graphql/queries/payments";
import { message } from "antd";
import { isMobileView } from "../../utils/screen";

const InvoiceCard = ({ onSelect, onOpenPayment, ...restProps }) => {
  const { completedAt, status, requestAt, totalDollarAmount, deletedAt } = restProps;
  // console.log('status: ', status);

  const renderStatus = () => {
    if (deletedAt) {
      return (
        <div className={css(card.statusCanceled)}>
          <Translation text={"invoices.canceled"} />
        </div>
      );
    }
    if (completedAt) {
      return (
        <div className={css(card.statusPaid)}>
          <Translation text={"invoices.paid"} />
        </div>
      );
    } else if (status === "processing") {
      return (
        <div className={css(card.statusRequested)}>
          <Translation text={"invoices.processing"} />
        </div>
      );
    } else {
      return (
        <div className={css(card.statusRequested)}>
          <Translation text={"invoices.requested"} />
        </div>
      );
    }
  }

  const t = completedAt ? completedAt : requestAt;
  const { date, time } = utcToCustomTimeAndDate(t); 

  const payButton = !completedAt && status !== 'processing' && !deletedAt ? (
    <Button
      label={<Translation text={"invoices.payInvoice"} />}
      blue
      onClick={() => onOpenPayment(restProps)}
      style={{ minWidth: 150, marginRight: 5 }}
    />
  ) : (
    <div
      style={{ minWidth: 150 }}
    />
  );

  const viewButton = (
    <div className={css(card.icon)}>
      <div
        className={css(card.viewButton)}
        onClick={() => onSelect(restProps)}
      >
        <Translation text={"invoices.view"} />
      </div>        
    </div>
  );

  let badgeColor = 'orange';
  if (completedAt) {
    badgeColor = 'green';
  } else if (deletedAt) {
    badgeColor = 'red';
  }

  return (
    <div className={css(card.holder)}>
      <div className={css(card.amount)}>
        <Badge
          color={badgeColor}
          type="amount"
          badgeStyles={{
            height: "42px",
            padding: isMobileView() ? '5px 10px' : '6px 30px 4px',
            borderRadius: "24px",
            width: '9em',
          }}
          labelStyles={{
            ...textRegular,
            height: "32px",
            lineHeight: "32px",
            "@media (max-width: 550px)": { fontSize: "11px" },
          }}
        >
          ${totalDollarAmount.toFixed(2)}
        </Badge>
      </div>

      {isMobileView() && (
        <div className={css(card.title)}>
          {renderStatus()}
          <div className={css(card.date)}>
            {date}
          </div>
          <div className={css(card.date)}>
            {time}
          </div>
        </div>
      )}

      {!isMobileView() && (
        <>
          <div className={css(card.title)}>
            {renderStatus()}
          </div>
          <div className={css(card.title)}>
            <div className={css(card.date)}>
              {date}
            </div>
            <div className={css(card.date)}>
              {time}
            </div>
          </div>
        </>  
      )}
      
      {isMobileView() && (
        <div>
          {viewButton}
          {payButton}
        </div>  
      )}

      {!isMobileView() && (
        <>
          {payButton}
          {viewButton}
        </>  
      )}
    </div>
  );
};

const Invoices = (props) => {

  // graphql
  const { data: paymentsData, loading: loadingPayments } = useGetPaymentsByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId: props.projectId,
      includeDeleted: true,
    },
    onError: (error) => {
      message.error(error.message);
    }
  });
  const payments = paymentsData?.paymentsByTaxYearInterviewId;

  const hasUnpaidInvoices = () => {
    return payments.some((payment) => !payment.completedAt);
  }

  useEffect(() => {
    // nothing to do here

    return () => {
      props.dispatch(resetInvoices());
    }
  }, []);

  useEffect(() => {
    if (props.close) {
      onCancel();
    }
  }, [props.close]);

  const selectInvoice = (payment) => {
    props.dispatch(viewInvoice(payment));
  }

  const onOpenPayment = (payment) => {
    props.dispatch(showPayment(payment));
  }

  const onCancel = () => {
    props.history.push("/");
  }
  
  const { activeInvoice, show, dispatch } = props;

  {loadingPayments && <Loader />}

  if (show === "view") {
    return (
      <InvoiceView
        dispatch={dispatch}
        invoice={activeInvoice}
        projects={props.allProjects}
        setProjectsFromChild={props.setProjectsFromChild}
      />
    );
  } else if (show === "payment") {
    if (payments) {
      return <Payment {...props} invoices={payments} />
    } else {
      return <Loader />
    }
  }

  return (
    <div className={css(styles.listHolder)}>
      {(!payments || (!loadingPayments && payments.length === 0)) && (
        <div className={css(styles.text)}>
          <Translation text={"invoices.notAvailable"} />
        </div>
      )}

      {loadingPayments && <Loader />}

      {payments &&
        payments.length > 0 &&
        payments.map((payment, i) => (
          <InvoiceCard
            {...payment}
            key={payment.id}
            onSelect={selectInvoice}
            onOpenPayment={onOpenPayment}
          />
        ))}
      {payments && payments.length > 0 && (
        <div className={css(styles.btnBlock)}>
          <Button
            label={<Translation text={"invoices.close"} />}
            secondary
            onClick={onCancel}
            style={{ minWidth: "155px" }}
          />
        </div>
      )}
    </div>
  );
}

const textRegular = {
  fontFamily: [Lato, "sans-serif"],
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0.6px",
};

const screenSize = {
  smartphone: "@media (max-width: 550px)",
};

const card = StyleSheet.create({
  viewButton: {
    cursor: "pointer",
  },
  amount: {
    width: "160px",
    paddingRight: "10px",
    ...textRegular,
    marginLeft: isMobileView() ? 5 : 10,
  },
  date: {
    ...textRegular,
    marginLeft: isMobileView() ? 0 : 10,
    fontWeight: "normal",
    color: "#808080",
    [screenSize.smartphone]: {
      fontSize: "12px",
    },
  },
  holder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "80px",
    marginBottom: "3px",
    padding: "10px 10px 10px 10px",
    borderRadius: "8px",
    backgroundColor: grey["300"],
    [screenSize.smartphone]: {
      width: "100%",
      height: "67px",
      padding: 0,
      borderRadius: 0,
    },
  },
  icon: {
    display: "flex",
    flexDirection: isMobileView() ? 'column' : 'row',
    alignItems: "center",
    justifyContent: "space-between",
    // width: "94px",
    // background: 'red',
    paddingLeft: isMobileView() ? 0 : 10,
    paddingRight: "10px",
    ...textRegular,
    color: grey["800"],
  },
  title: {
    width: isMobileView() ? '30%' : 'calc(100% - 220px)',
    ...textRegular,
    color: grey["800"],
  },
  statusPaid: {
    color: color.green,
    [screenSize.smartphone]: {
      fontSize: "12px",
    },
  },
  statusRequested: {
    color: grey["800"],
    [screenSize.smartphone]: {
      fontSize: "12px",
    },
  },
  statusCanceled: {
    color: color.lightRed,
    [screenSize.smartphone]: {
      fontSize: "12px",
    },
  },
});

const styles = StyleSheet.create({
  text: { ...text.regular, textAlign: "center" },
  listHolder: {
    position: "relative",
    minHeight: "300px",
  },
  btnBlock: {
    width: "100%",
    marginTop: "36px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "@media (max-width: 550px)": {
      justifyContent: "center",
    },
  },
});

const mapStateToProps = (state, ownProps) => {
  const {
    match: { params },
    history,
  } = ownProps;
  const { activeProject, projects } = state.projects;
  const { invoices, show, activeInvoice } = state.invoices;

  if (!params) {
    history.push("/");
  }

  const shouldGetInvoices = params.projectId !== activeProject || !invoices;

  return {
    activeInvoice,
    invoices: shouldGetInvoices ? null : invoices,
    show,
    projectId: params.projectId,
    allProjects: projects,
  };
};

export default withOverlay(
  withRouter(connect(mapStateToProps)(Invoices)),
  false
);