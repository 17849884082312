import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientChannelMutationVariables = Types.Exact<{
  preparerId: Types.Scalars['String'];
}>;


export type ClientChannelMutation = { __typename?: 'Mutation', ClientChannel?: { __typename?: 'ChannelEntity', id: string, description?: string | null } | null };

export type PreparerEventTypesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type PreparerEventTypesMutation = { __typename?: 'Mutation', PreparerEventTypes: Array<{ __typename?: 'PreparerEventTypeEntity', id: string, name: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, EventTypeAvailability?: Array<{ __typename?: 'PreparerEventTypeAvailabilityEntity', id: string, day: string, EventTypeAvailabilityBracket?: Array<{ __typename?: 'PreparerEventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> | null }> | null }> };

export type PreparerAvailabilityMutationVariables = Types.Exact<{
  input: Types.PreparerAvailabilityInput;
}>;


export type PreparerAvailabilityMutation = { __typename?: 'Mutation', PreparerAvailability: { __typename?: 'PreparerAvailabilityEntity', eventsInDay: number, brackets: Array<{ __typename?: 'PreparerEventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> } };

export type CreateEventMutationVariables = Types.Exact<{
  input: Types.CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, updateAt: any } };

export type CancelEventMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CancelEventMutation = { __typename?: 'Mutation', cancelEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType } };


export const ClientChannelDocument = gql`
    mutation ClientChannel($preparerId: String!) {
  ClientChannel(preparerId: $preparerId) {
    id
    description
  }
}
    `;
export type ClientChannelMutationFn = Apollo.MutationFunction<ClientChannelMutation, ClientChannelMutationVariables>;

/**
 * __useClientChannelMutation__
 *
 * To run a mutation, you first call `useClientChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientChannelMutation, { data, loading, error }] = useClientChannelMutation({
 *   variables: {
 *      preparerId: // value for 'preparerId'
 *   },
 * });
 */
export function useClientChannelMutation(baseOptions?: Apollo.MutationHookOptions<ClientChannelMutation, ClientChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClientChannelMutation, ClientChannelMutationVariables>(ClientChannelDocument, options);
      }
export type ClientChannelMutationHookResult = ReturnType<typeof useClientChannelMutation>;
export type ClientChannelMutationResult = Apollo.MutationResult<ClientChannelMutation>;
export type ClientChannelMutationOptions = Apollo.BaseMutationOptions<ClientChannelMutation, ClientChannelMutationVariables>;
export const PreparerEventTypesDocument = gql`
    mutation PreparerEventTypes($id: String!) {
  PreparerEventTypes(preparerId: $id) {
    id
    name
    duration
    maxEvents
    minNotice
    beforeBuffer
    afterBuffer
    EventTypeAvailability {
      id
      day
      EventTypeAvailabilityBracket {
        id
        startHour
        startMinute
        endHour
        endMinute
      }
    }
  }
}
    `;
export type PreparerEventTypesMutationFn = Apollo.MutationFunction<PreparerEventTypesMutation, PreparerEventTypesMutationVariables>;

/**
 * __usePreparerEventTypesMutation__
 *
 * To run a mutation, you first call `usePreparerEventTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreparerEventTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preparerEventTypesMutation, { data, loading, error }] = usePreparerEventTypesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreparerEventTypesMutation(baseOptions?: Apollo.MutationHookOptions<PreparerEventTypesMutation, PreparerEventTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreparerEventTypesMutation, PreparerEventTypesMutationVariables>(PreparerEventTypesDocument, options);
      }
export type PreparerEventTypesMutationHookResult = ReturnType<typeof usePreparerEventTypesMutation>;
export type PreparerEventTypesMutationResult = Apollo.MutationResult<PreparerEventTypesMutation>;
export type PreparerEventTypesMutationOptions = Apollo.BaseMutationOptions<PreparerEventTypesMutation, PreparerEventTypesMutationVariables>;
export const PreparerAvailabilityDocument = gql`
    mutation PreparerAvailability($input: PreparerAvailabilityInput!) {
  PreparerAvailability(preparerAvailabilityInput: $input) {
    eventsInDay
    brackets {
      id
      startHour
      startMinute
      endHour
      endMinute
    }
  }
}
    `;
export type PreparerAvailabilityMutationFn = Apollo.MutationFunction<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>;

/**
 * __usePreparerAvailabilityMutation__
 *
 * To run a mutation, you first call `usePreparerAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreparerAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preparerAvailabilityMutation, { data, loading, error }] = usePreparerAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreparerAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>(PreparerAvailabilityDocument, options);
      }
export type PreparerAvailabilityMutationHookResult = ReturnType<typeof usePreparerAvailabilityMutation>;
export type PreparerAvailabilityMutationResult = Apollo.MutationResult<PreparerAvailabilityMutation>;
export type PreparerAvailabilityMutationOptions = Apollo.BaseMutationOptions<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(createEventInput: $input) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    updateAt
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CancelEventDocument = gql`
    mutation cancelEvent($id: String!) {
  cancelEvent(id: $id) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
  }
}
    `;
export type CancelEventMutationFn = Apollo.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, options);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = Apollo.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = Apollo.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const namedOperations = {
  Mutation: {
    ClientChannel: 'ClientChannel',
    PreparerEventTypes: 'PreparerEventTypes',
    PreparerAvailability: 'PreparerAvailability',
    createEvent: 'createEvent',
    cancelEvent: 'cancelEvent'
  }
}