const {
  REACT_APP_STRIPE_API_PUBLISHABLE_KEY,
  REACT_APP_SERVER,
  REACT_APP_NEST_SERVER,
  REACT_APP_PUSHER_KEY,
  REACT_APP_AGORA_APP_ID,
  REACT_APP_WEBSITE,
} = process.env;

export const agoraID = REACT_APP_AGORA_APP_ID;
export const pusherKey = REACT_APP_PUSHER_KEY;
export const websiteUrl = REACT_APP_WEBSITE;
export const serverUrl = REACT_APP_NEST_SERVER || 'http://localhost:3000';
export const getBaseUrl = () => REACT_APP_SERVER || 'http://localhost:3001';
export const getNestUrl = () => REACT_APP_NEST_SERVER || 'http://localhost:3000';
export const getStripeKey = () => REACT_APP_STRIPE_API_PUBLISHABLE_KEY;
