import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  loginInput: Types.LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', Login: { __typename?: 'SessionEntity', accessToken?: string | null, headToURL?: string | null, selectedFirmAccount?: string | null, RelatedFirms?: Array<{ __typename?: 'UserFirmAccountEntity', userId?: string | null, entityId?: string | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null } | null, Users?: { __typename?: 'UserEntity', UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null }> | null, UserFirmAccount?: { __typename?: 'UserFirmAccountEntity', userId?: string | null, entityId?: string | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null } | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', languageId?: string | null, email?: string | null, uniqueLink?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, firstName: string, lastName: string, birthday?: any | null, ssn?: string | null, profilePhoto?: string | null } | null, Languages?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null } | null } | null, Users?: { __typename?: 'UserEntity', languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, firstName: string, lastName: string, birthday?: any | null, ssn?: string | null, profilePhoto?: string | null } | null, Languages?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null } | null } };


export const LoginDocument = gql`
    mutation Login($loginInput: LoginInput!) {
  Login(loginInput: $loginInput) {
    accessToken
    headToURL
    selectedFirmAccount
    RelatedFirms {
      userId
      entityId
      FirmAccount {
        id
        name
      }
      Users {
        UserInformation {
          firstName
          lastName
        }
      }
      Roles {
        id
        description
      }
    }
    UserFirmAccount {
      userId
      entityId
      FirmAccount {
        id
        name
      }
      Entity {
        Users {
          UserInformation {
            id
            userId
            firstName
            lastName
            birthday
            ssn
            profilePhoto
          }
          Languages {
            id
            lang
            description
          }
          languageId
          email
          uniqueLink
        }
      }
      Users {
        UserInformation {
          id
          userId
          firstName
          lastName
          birthday
          ssn
          profilePhoto
        }
        Languages {
          id
          lang
          description
        }
        languageId
      }
      Roles {
        id
        description
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const namedOperations = {
  Mutation: {
    Login: 'Login'
  }
}