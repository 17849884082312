import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
    <path fill={fill} fillRule="evenodd" d="M5.852 3.946L1.98.229C1.86.11 1.742.11 1.623.229L.955.897c-.119.133-.126.252-.022.356l4.741 4.54a.24.24 0 0 0 .178.067.24.24 0 0 0 .178-.067l4.741-4.54c.119-.119.119-.238 0-.356l-.69-.668c-.119-.119-.237-.119-.356 0L5.852 3.946z" />
  </svg>
);

Arrow.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default Arrow;
