import { getUpcomingAppointments as getUpcomingAppointmentsApi } from '../../api/appointments';

export const SET_UPCOMING_APPOINTMENTS = 'SET_UPCOMING_APPOINTMENTS';

export const getUpcomingAppointments = (preparerId, date) => async (dispatch) => {
  const upcomingAppointments = await getUpcomingAppointmentsApi(preparerId, date);
  if (upcomingAppointments.length) {
    dispatch({
      type: SET_UPCOMING_APPOINTMENTS,
      payload: upcomingAppointments,
    });
  }
};
