/* eslint-disable */
import React from 'react';

const withHover = (WrappedComponent) => class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  render() {
    return (
      <span
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <WrappedComponent {...this.props} hovered={this.state.isHovered} />
      </span>
    );
  }
};

export default withHover;
