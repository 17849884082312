import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelfUserMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
}>;


export type UpdateSelfUserMutation = { __typename?: 'Mutation', updateSelfUser: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, type: Types.PhoneType, value: string }> | null } | null } };

export type ValidateOtpMutationVariables = Types.Exact<{
  validateOtp: Types.ValidateOtpInput;
}>;


export type ValidateOtpMutation = { __typename?: 'Mutation', validateOTP: { __typename?: 'ValidateOtpEntity', secretOTP?: string | null } };

export type CreateClientMutationVariables = Types.Exact<{
  createClientInput: Types.CreateClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', CreateClient: { __typename?: 'UserEntity', id: string, email?: string | null } };

export type UpdateUserPasswordMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  oldPassword: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: { __typename?: 'UserEntity', id: string, email?: string | null } };

export type DeleteTwoStepAuthFromUserMutationVariables = Types.Exact<{
  validateOtp: Types.ValidateOtpInput;
}>;


export type DeleteTwoStepAuthFromUserMutation = { __typename?: 'Mutation', deleteTwoStepAuthFromUser: { __typename?: 'UserSuccessEntity', success: boolean } };

export type UpdateUserInformationMutationVariables = Types.Exact<{
  updateUserInformationInput: Types.UpdateUserInformationInput;
}>;


export type UpdateUserInformationMutation = { __typename?: 'Mutation', UpdateUserInformation: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, type: Types.PhoneType, value: string }> | null } };

export type GenerateClientOtpMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type GenerateClientOtpMutation = { __typename?: 'Mutation', generateClientOTP: { __typename?: 'generateOtpEntity', codeQR: string, key: string } };


export const UpdateSelfUserDocument = gql`
    mutation UpdateSelfUser($updateUserInput: UpdateUserInput!) {
  updateSelfUser(updateUserInput: $updateUserInput) {
    id
    email
    UserInformation {
      id
      firstName
      lastName
      Phones {
        id
        type
        value
      }
    }
  }
}
    `;
export type UpdateSelfUserMutationFn = Apollo.MutationFunction<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>;

/**
 * __useUpdateSelfUserMutation__
 *
 * To run a mutation, you first call `useUpdateSelfUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfUserMutation, { data, loading, error }] = useUpdateSelfUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateSelfUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>(UpdateSelfUserDocument, options);
      }
export type UpdateSelfUserMutationHookResult = ReturnType<typeof useUpdateSelfUserMutation>;
export type UpdateSelfUserMutationResult = Apollo.MutationResult<UpdateSelfUserMutation>;
export type UpdateSelfUserMutationOptions = Apollo.BaseMutationOptions<UpdateSelfUserMutation, UpdateSelfUserMutationVariables>;
export const ValidateOtpDocument = gql`
    mutation ValidateOTP($validateOtp: ValidateOtpInput!) {
  validateOTP(validateOtp: $validateOtp) {
    secretOTP
  }
}
    `;
export type ValidateOtpMutationFn = Apollo.MutationFunction<ValidateOtpMutation, ValidateOtpMutationVariables>;

/**
 * __useValidateOtpMutation__
 *
 * To run a mutation, you first call `useValidateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOtpMutation, { data, loading, error }] = useValidateOtpMutation({
 *   variables: {
 *      validateOtp: // value for 'validateOtp'
 *   },
 * });
 */
export function useValidateOtpMutation(baseOptions?: Apollo.MutationHookOptions<ValidateOtpMutation, ValidateOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateOtpMutation, ValidateOtpMutationVariables>(ValidateOtpDocument, options);
      }
export type ValidateOtpMutationHookResult = ReturnType<typeof useValidateOtpMutation>;
export type ValidateOtpMutationResult = Apollo.MutationResult<ValidateOtpMutation>;
export type ValidateOtpMutationOptions = Apollo.BaseMutationOptions<ValidateOtpMutation, ValidateOtpMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($createClientInput: CreateClientInput!) {
  CreateClient(createClientInput: $createClientInput) {
    id
    email
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      createClientInput: // value for 'createClientInput'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($userId: String!, $oldPassword: String!, $newPassword: String!) {
  updateUserPassword(
    userId: $userId
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    id
    email
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const DeleteTwoStepAuthFromUserDocument = gql`
    mutation DeleteTwoStepAuthFromUser($validateOtp: ValidateOtpInput!) {
  deleteTwoStepAuthFromUser(validateOtp: $validateOtp) {
    success
  }
}
    `;
export type DeleteTwoStepAuthFromUserMutationFn = Apollo.MutationFunction<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>;

/**
 * __useDeleteTwoStepAuthFromUserMutation__
 *
 * To run a mutation, you first call `useDeleteTwoStepAuthFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTwoStepAuthFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTwoStepAuthFromUserMutation, { data, loading, error }] = useDeleteTwoStepAuthFromUserMutation({
 *   variables: {
 *      validateOtp: // value for 'validateOtp'
 *   },
 * });
 */
export function useDeleteTwoStepAuthFromUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>(DeleteTwoStepAuthFromUserDocument, options);
      }
export type DeleteTwoStepAuthFromUserMutationHookResult = ReturnType<typeof useDeleteTwoStepAuthFromUserMutation>;
export type DeleteTwoStepAuthFromUserMutationResult = Apollo.MutationResult<DeleteTwoStepAuthFromUserMutation>;
export type DeleteTwoStepAuthFromUserMutationOptions = Apollo.BaseMutationOptions<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>;
export const UpdateUserInformationDocument = gql`
    mutation UpdateUserInformation($updateUserInformationInput: UpdateUserInformationInput!) {
  UpdateUserInformation(updateUserInformationInput: $updateUserInformationInput) {
    id
    firstName
    lastName
    Phones {
      id
      type
      value
    }
  }
}
    `;
export type UpdateUserInformationMutationFn = Apollo.MutationFunction<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;

/**
 * __useUpdateUserInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInformationMutation, { data, loading, error }] = useUpdateUserInformationMutation({
 *   variables: {
 *      updateUserInformationInput: // value for 'updateUserInformationInput'
 *   },
 * });
 */
export function useUpdateUserInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>(UpdateUserInformationDocument, options);
      }
export type UpdateUserInformationMutationHookResult = ReturnType<typeof useUpdateUserInformationMutation>;
export type UpdateUserInformationMutationResult = Apollo.MutationResult<UpdateUserInformationMutation>;
export type UpdateUserInformationMutationOptions = Apollo.BaseMutationOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;
export const GenerateClientOtpDocument = gql`
    mutation generateClientOTP($email: String!) {
  generateClientOTP(email: $email) {
    codeQR
    key
  }
}
    `;
export type GenerateClientOtpMutationFn = Apollo.MutationFunction<GenerateClientOtpMutation, GenerateClientOtpMutationVariables>;

/**
 * __useGenerateClientOtpMutation__
 *
 * To run a mutation, you first call `useGenerateClientOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateClientOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateClientOtpMutation, { data, loading, error }] = useGenerateClientOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateClientOtpMutation(baseOptions?: Apollo.MutationHookOptions<GenerateClientOtpMutation, GenerateClientOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateClientOtpMutation, GenerateClientOtpMutationVariables>(GenerateClientOtpDocument, options);
      }
export type GenerateClientOtpMutationHookResult = ReturnType<typeof useGenerateClientOtpMutation>;
export type GenerateClientOtpMutationResult = Apollo.MutationResult<GenerateClientOtpMutation>;
export type GenerateClientOtpMutationOptions = Apollo.BaseMutationOptions<GenerateClientOtpMutation, GenerateClientOtpMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdateSelfUser: 'UpdateSelfUser',
    ValidateOTP: 'ValidateOTP',
    CreateClient: 'CreateClient',
    UpdateUserPassword: 'UpdateUserPassword',
    DeleteTwoStepAuthFromUser: 'DeleteTwoStepAuthFromUser',
    UpdateUserInformation: 'UpdateUserInformation',
    generateClientOTP: 'generateClientOTP'
  }
}