/* eslint-disable */
import React from "react";
import { css, StyleSheet } from "aphrodite";
import Loader from "../Loader/Loader";
import { table } from "../../styles/tables";
import { text } from "../../styles/fonts";
import { utcToCustomTime } from "../../utils/utcToCustomTime";
import { getBaseUrl } from "../../api/config";
import { completeTask } from "../../redux/actions/projects";
import { grey } from "../../styles/colors";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import BootstrapButton from "react-bootstrap/Button";
import { getDocuments } from "../../redux/actions/documents";
import Translation from "../atoms/Translation";
import { Badges } from './components/';

class FirmDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isButtonHovered: false,
    };
    this.baseUrl = getBaseUrl(); //.split().splice(0, -1).join();
  }

  onClickToView = (type, d, uniqueId = null) => {
    switch (type) {
      case "original":
        this.openLink(d.s3Key);
        break;
      case "approver":
        // find the first fileRequest where the fileApproval is not completed

        // console.log(`d.CompiledFile: ${JSON.stringify(d.CompiledFile)}`);
        const ourFileRequest = d.CompiledFile?.filter((cfr) => cfr.FileApprovals?.at(0)?.completedAt === null)[0];
        // console.log('ourFileRequest:', ourFileRequest);
        uniqueId && window.open(`${window.location.origin}/signatures/kba/${ourFileRequest?.FileApprovals.at(0)?.id}`);
        break;
      case "download":
        this.openLink(d.s3Key);
        break;
      default:
        console.log("Some unexpected action");
    }
  };

  sortDocApprovals = (arr) => {
    if (!arr || !arr.length) return null;
    const { email } = this.props;
    if (!email) return null;
    const onlyNotSigned = arr.filter((item) => !item.completedAt);
    if (arr.indexOf(email) < 1) {
      return onlyNotSigned;
    }
    let final =
      [email] +
      onlyNotSigned.slice(0, arr.indexOf(email)) +
      onlyNotSigned.slice(arr.indexOf(email));
    return final;
  };

  signatureListenerHandler = (event) => {
    const { projectId, dispatch } = this.props;
    const { type, taskId, ifLast } = event.data;
    if (type === "allIsSigned") {
      taskId && ifLast
        ? dispatch(completeTask(projectId, [taskId]))
        : dispatch(getDocuments(projectId));
    }
  };

  componentDidMount() {
    window.addEventListener("message", this.signatureListenerHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.signatureListenerHandler);
  }

  openLink = async (key) => {
    try {
      const result = await this.props.getSignedFile({
        variables: {
          s3Key: key,
        },
      });
      const url = result?.data?.SignedFileByS3Key;
      // console.log('url: ', url);
      window.open(url, '_blank');
    } catch (e) {
      console.log('caught error... ');
      console.warn(e);
    }
  };

  isEKBAIDScanFailed(docApprovalRequests)  {

    if(!docApprovalRequests || docApprovalRequests.constructor.name !== 'Array' || docApprovalRequests.length === 0)  {
      return false;
    }

    const failedIDScans = docApprovalRequests.filter((docApprovalRequest) => {
      const kbaFields = docApprovalRequest.kbaFields;
      const numRequestedIdScans = kbaFields && kbaFields.hasOwnProperty('numRequestedIdScans') ? kbaFields.numRequestedIdScans: 0;
      return kbaFields && docApprovalRequest.kbaEnabled && kbaFields.hasOwnProperty('kbaType') && kbaFields.kbaType === 'enhanced' &&
      !kbaFields.isIdScanPending && !kbaFields.isIdScanPassed && numRequestedIdScans >= 2;
    })

    return failedIDScans.length > 0;

  }

  isEKBAQuizFailed(docApprovalRequests)  {

    if(!docApprovalRequests || docApprovalRequests.constructor.name !== 'Array' || docApprovalRequests.length === 0)  {
      return false;
    }

    const failedScans = docApprovalRequests.filter((docApprovalRequest) => {
      const kbaFields = docApprovalRequest.kbaFields;
      const numKbaAttempts = kbaFields && kbaFields.hasOwnProperty('numKbaAttempts') ? kbaFields.numKbaAttempts : 0;
      return kbaFields && docApprovalRequest.kbaEnabled && kbaFields.hasOwnProperty('kbaType') && kbaFields.kbaType === 'enhanced' &&
      kbaFields.hasOwnProperty('isIdScanPassed') && kbaFields.hasOwnProperty('isValidated') && 
      kbaFields.isIdScanPassed && !kbaFields.isValidated && numKbaAttempts >= 2;
    })

    return failedScans.length > 0;

  }

  render() {
    const { files, projectId } = this.props;
    const shouldBeDocsBlocked =
      !!files &&
      !!files.length &&
      files.some((f) => f.paymentLocked);
    return (
      <div>
        {/* <div className={css(styles.btnBlock)}>
                    <span onMouseEnter={() => this.setState({ isButtonHovered: true })} onMouseLeave={() => this.setState({ isButtonHovered: false })} className={css(styles.btnHolder)}>
                        <Button
                            secondary
                            icon="download"
                            label="Download All"
                            isHovered={isButtonHovered}
                            style={{ padding: '10px 20px' }} />
                    </span>
                </div> */}

        {/* Warning Message if some invoices are not paid */}
        {shouldBeDocsBlocked && (
          <div className={css(styles.text, styles.unPaid)}>
            You have an outstanding invoice needs to be paid before you&apos;re able
            to access certain documents below. Please click on the &quot;Invoices&quot;
            button to pay or follow <a href={`/payments/${projectId}`}>this link</a>.
          </div>
        )}
        <div className={css(styles.table)}>
          <div className={css(styles.header)}>
            <div className={css(styles.headerCol, styles.headerColFirst)}>
              <Translation text="documents.firmDocuments.status" />
            </div>
            <div className={css(styles.headerCol, styles.headerColSecond)}>
              <div className={css(styles.hideAtSmall)}><Translation text="documents.firmDocuments.description" /></div>
              <div className={css(styles.showAtSmall)}><Translation  text="documents.clientDocuments.name" /></div>
            </div>
            <div className={css(styles.headerCol, styles.headerColThird)}>
              <Translation text="documents.firmDocuments.requestedTime" />
            </div>
            <div className={css(styles.headerCol, styles.headerColLast)}>
              <Translation text="documents.firmDocuments.viewOriginal" />
            </div>
          </div>
          {files && files === "loading" && ( // todo: files will never equal "loading"
            <div className={css(styles.rowHolder)}>
              <Loader />
            </div>
          )}
          {files &&
            files.length === 0 && (
              <div className={css(styles.rowHolder)}>
                <span className={css(styles.text)}>
                  <Translation text="documents.firmDocuments.notUploaded" />
                </span>
              </div>
            )}

          {files &&
            files.length > 0 &&
            files.map((d, i, arr) => {
              const fileApprovalRequests = [];
              for (const compiledFileRequest of d.CompiledFile) {
                for (const fileApproval of compiledFileRequest.FileApprovals) {
                  fileApprovalRequests.push({ ...fileApproval, file: d });
                }
              }
              for (const signedFileRequest of d.SignedFile) {
                for (const fileApproval of signedFileRequest.FileApprovals) {
                  fileApprovalRequests.push({ ...fileApproval, file: d });
                }
              }
              // remove duplicates by id
              fileApprovalRequests.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              
              const signedFile = d.CompiledFile?.[0]?.SignedFile;

              const isComplete = !!d.signaturesComplete || !!fileApprovalRequests?.every((x) => !!x.completedAt);
              const docUniqueId =
                fileApprovalRequests &&
                fileApprovalRequests.length &&
                fileApprovalRequests[0].id; // todo: was .uniqueId ... ?
              const sortedDocAppRequest = this.sortDocApprovals(
                fileApprovalRequests
              );
              const isEkbaQuizFailedValue = this.isEKBAQuizFailed(d.DocApprovalRequests);
              const isEKBAIDScanFailedValue = this.isEKBAIDScanFailed(d.DocApprovalRequests);
              return (
                <div
                  key={String(i)}
                  className={css(
                    styles.row,
                    i === arr.length - 1 && styles.rowLast
                  )}
                >
                  <div className={css(styles.cell, styles.cellFirst)}>
                    <Badges isComplete={isComplete} isEkbaQuizFailed={isEkbaQuizFailedValue} isEKBAIDScanFailed={isEKBAIDScanFailedValue} />
                  </div>
                  <div className={css(styles.cell, styles.cellSecond)}>
                    {shouldBeDocsBlocked
                    ? (
                      d.name
                    )
                    : (
                      <button
                        className={css(styles.links)}
                        onClick={ () => this.openLink(d.s3Key, !!signedFile)}
                      >
                        {d.name}
                      </button>
                    )}
                  </div>
                  <div className={css(styles.cell, styles.cellThird)}>
                    {utcToCustomTime(d.createAt, false)}
                  </div>
                  {sortedDocAppRequest && !shouldBeDocsBlocked && (
                    <div className={css(styles.cell, styles.cellLast)}>
                      <Dropdown
                        as={ButtonGroup}
                        alignRight
                        size="sm"
                        className={css(styles.dropHolder)}
                      >
                        <BootstrapButton
                          style={{ maxWidth: 100 }}
                          variant="secondary"
                          onClick={() =>
                            (!signedFile &&
                            !!sortedDocAppRequest.length)
                              ? this.onClickToView('approver', d, docUniqueId)
                              : this.onClickToView("download", signedFile)
                          }
                          className={css(styles.dropDown)}
                        >
                          {!signedFile &&
                          !!sortedDocAppRequest.length ? (
                            <Translation
                              text="documents.firmDocuments.approverName"
                              variables={{
                                approverName:
                                  sortedDocAppRequest[0].name,
                              }}
                            />
                          ) : (
                            <Translation text="documents.firmDocuments.finalCopy" />
                          )}
                        </BootstrapButton>

                        <Dropdown.Toggle
                          split
                          variant="secondary"
                          id="dropdown-split-basic"
                          className={css(styles.toggleBtn)}
                        />

                        <Dropdown.Menu>
                          {!!sortedDocAppRequest.length &&
                            sortedDocAppRequest.length > 1 &&
                            sortedDocAppRequest.map((a, index) => {
                              if (index === 0) return null;
                              return (
                                <Dropdown.Item
                                  className={css(styles.dropItem)}
                                  onClick={() =>
                                    this.onClickToView(
                                      "approver",
                                      d,
                                      a.id
                                    )
                                  }
                                  hred={`#/action-${index}`}
                                  key={a.id}
                                >
                                  <Translation
                                    text="documents.firmDocuments.approverName"
                                    variables={{ approverName: a.name }}
                                  />
                                </Dropdown.Item>
                              );
                            })}
                          <Dropdown.Item
                            className={css(styles.dropItem)}
                            hred={`#/action-1`}
                            onClick={() => this.onClickToView("original", d)}
                          >
                            <Translation text="documents.firmDocuments.viewOriginal" />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const width = {
  first: "140px",
  second: "calc(100% - (140px + 180px + 150px - 20px))", // 20px for padding
  third: "180px",
  last: "150px",
};

const xsWidth = {
  first: "50px",
  second: "calc(100% - (50px + 80px + 80px - 20px))", // 20px for padding
  third: "80px",
  last: "80px",
};

const smallWidth = {
  first: "80px",
  second: "calc(100% - (80px + 100px + 120px - 20px))", // 20px for padding
  third: "100px",
  last: "120px",
};

const mediumWidth = {
  first: "60px",
  second: "calc(100% - (60px + 120px + 120px - 20px))", // 20px for padding
  third: "120px",
  last: "120px",
};

// const screenSizes = {
//     max1024: '@media (max-width: 1024px)',
//     tablets: '@media (max-width: 700px)',
//     smartphones: '@media (max-width: 420px)'
// }

const styles = StyleSheet.create({
  toggleBtn: {
    backgroundColor: grey["300"],
    border: `1px solid ${grey["400"]}`,
    color: grey["700"],
  },
  dropDown: {
    backgroundColor: "white",
    ...text.regular,
    fontSize: "11px",
    border: `1px solid ${grey["400"]}`,
  },
  dropItem: {
    ...text.regular,
  },
  unPaid: {
    backgroundColor: "#d0e9f5",
    textAlign: "center",
    borderRadius: "3px",
    padding: "12px 6px",
    width: "calc(100% + 40px)",
    margin: "20px -20px",
    color: "#3d708e",
    "@media (max-width: 640px)": { width: "100%", margin: "20px 0" },
  },
  links: {
    textDecoration: "none",
    cursor: "pointer",
    border: "none",
    background: "none",
    ":visited": {
      textDecoration: "none",
      ...text.regular,
    },
    ":hover": {
      color: "#3d80f3",
    },
    textAlign: 'left',
    padding: 0,
  },
  text: { ...text.regular },
  btnBlock: {
    padding: "30px 0 10px",
    textAlign: "right",
  },
  btnHolder: {
    display: "none",
    "@media (min-width: 640px)": { display: "inline-block" },
  },
  table: {
    width: "calc(100% + 40px)",
    margin: "20px -20px 0 -20px",
    overflowX: "hidden",
    "@media (max-width: 640px)": { width: "100%", margin: '20px 0 0 0' },
  },
  header: { ...table.header, alignItems: "center" },
  headerCol: {
    ...table.headerCol,
    lineHeight: "1.2",
    breakWord: "word-break",
  },
  headerColFirst: {
    ...table.headerColFirst,
    width: mediumWidth.first,
    padding: "16px 10px",
    "@media (min-width: 400px)": {
      width: mediumWidth.first,
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.first,
      marginLeft: 0,
    },
    "@media (min-width: 768px)": {
      width: width.first,
      padding: "24px 10px 22px 10px",
    },
  },
  headerColSecond: {
    width: xsWidth.second,
    padding: "16px 5px",
    "@media (min-width: 400px)": {
      width: smallWidth.second,
      padding: "16px 10px",
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.second,
      padding: "16px 10px",
    },
    "@media (min-width: 768px)": {
      width: width.second,
      padding: "16px 10px",
    },
  },
  headerColThird: {
    width: xsWidth.third,
    padding: "16px 0px",
    "@media (min-width: 400px)": {
      width: smallWidth.third,
      padding: "16px 20px 16px 0px",
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.third,
      padding: "16px 10px",
    },
    "@media (min-width: 768px)": {
      width: width.third,
      padding: "16px 10px",
    },
  },
  headerColLast: {
    ...table.headerColLast,
    width: smallWidth.last,
    marginRight: "-14px",
    padding: "16px 5px",
    "@media (min-width: 400px)": {
      width: smallWidth.last,
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.last,
      marginRight: 0,
    },
    "@media (min-width: 768px)": {
      width: width.last,
    },
  },
  row: { ...table.row },
  rowLast: { ...table.rowLast },
  cell: { ...table.cell, width: width.third },
  cellFirst: {
    ...table.cellFirst,
    width: mediumWidth.first,
    padding: "16px 10px",
    "@media (min-width: 400px)": {
      width: mediumWidth.first,
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.first,
      marginLeft: 0,
    },
    "@media (min-width: 768px)": {
      width: width.first,
    },
  },
  cellSecond: {
    // ...table.cellSecond,
    width: xsWidth.second,
    padding: "16px 5px",
    "@media (min-width: 400px)": {
      width: smallWidth.second,
      padding: "16px 10px",
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.second,
      padding: "16px 10px",
    },
    "@media (min-width: 768px)": {
      width: width.second,
      padding: "16px 10px",
    },
  },
  cellThird: {
    padding: "16px 10px",
    width: xsWidth.third,
    "@media (min-width: 400px)": {
      width: smallWidth.third,
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.third,
    },
    "@media (min-width: 768px)": {
      width: width.third,
    },
  },
  cellLast: {
    ...table.cellLast,
    width: smallWidth.last,
    padding: "16px 5px",
    textAlign: "left",
    "@media (min-width: 400px)": {
      width: smallWidth.last,
    },
    "@media (min-width: 640px)": {
      width: mediumWidth.last,
      marginRight: 0,
    },
    "@media (min-width: 768px)": {
      width: width.last,
    },
  },
  rowHolder: {
    ...table.rowLast,
    position: "relative",
    minHeight: "74px",
    justifyContent: "center",
  },
  hideAtSmall: {
    display: 'none',
    "@media (min-width: 450px)": {
      display: 'block',
    },
  },
  showAtSmall: {
    display: 'block',
    "@media (min-width: 450px)": {
      display: 'none',
    },
  }
});

export default FirmDocuments;