import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import {
  Alert, Button, Card, Form, Input,
} from 'antd';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { setSession } from '../redux/actions/session';
import './login.css';
import updateFavicon from '../helpers/updateFavicon';
import { useLoginMutation } from '../graphql/mutations/login';
import { useGetCustomDomainByNameLazyQuery } from '../graphql/queries/customDomain';
import { trackSignIn } from '../components/helpers/woopra';
import { constructGraphSession } from '../components/PrivateRoute';

const { Item } = Form;
const { Password } = Input;

const Login = ({
  dispatch, branding, history
}) => {
  const [needCode, setNeedCode] = useState(false);
  const [generalErrorMsg, setGeneralErrorMsg] = useState('');
  // const [loading, setLoading] = useState(false);
  const [customLayout, setCustomLayout] = useState(null);

  // fetch CustomLayout by custom domain
  const [fetchCustomLayout, { data: customLayoutData }] = useGetCustomDomainByNameLazyQuery();

  const { origin } = window.location;
  useEffect(() => {
    if (origin && process.env.REACT_APP_TAXAROO_CLIENT_URL) {
      if (origin !== process.env.REACT_APP_TAXAROO_CLIENT_URL) {
        // fetch the CustomLayout
        const theDomain = origin.replace('https://', '').replace('http://', '');
        fetchCustomLayout({
          variables: { name: theDomain },
          onCompleted: (d) => {
            setCustomLayout({
              ...d?.CustomDomainByName?.FirmAccount?.CustomLayout,
              uniqueLink: d?.CustomDomainByName?.FirmAccount?.uniqueLink,
              customDomainId: d?.CustomDomainByName?.id,
            } || null)
          },
          onError: () => {
            setCustomLayout(null);
          }
        });
      }
    }
  }, [origin]);

  const [login, { data, loading, error }] = useLoginMutation();

  useEffect(() => {
    const errorMessage = error?.message
    if (errorMessage === 'GoogleAuthenticatorCodeMissed') {
      setNeedCode(true)
    }

    const graphqlError = error?.graphQLErrors?.at(0)
    const graphqlErrorName = graphqlError?.name
    let errorDescription;
    if (errorMessage === 'Not Found') {
      errorDescription = 'Username/email not registered';
    } else if (errorMessage === 'Unauthorized') {
      errorDescription = 'Incorrect password';
    } else if (errorMessage === 'Account Login Suspended') {
      errorDescription = 'Your account has been suspended due to 5 incorrect login attempts. A password reset link has been sent to your email. Please update your password and then log in again.';
    } else if (errorMessage === 'GoogleAuthenticatorCodeMissed') {
      errorDescription = ''; // not an error
    } else if (errorMessage === 'Error code 2001') {
      errorDescription = 'Your tax firm is not currently active. Please contact them directly. Thank you.';
    } else if (graphqlErrorName === 'GoogleAuthenticatorCodeNotValid') {
      errorDescription = 'Incorrect code';
    } else {
      errorDescription = errorMessage
    }
    setGeneralErrorMsg(errorDescription);
  }, [error]);

  useEffect(() => {
    // dispatch(setSession(null));
    if (data) {
      // next I'm going to dispatch the new session data
      const graphSession = constructGraphSession(data.Login);
      dispatch(setSession(graphSession));
      trackSignIn();

      const fromUrl = history?.location?.state?.from?.pathname;
      if (fromUrl) history.replace(fromUrl);
      else history.replace('/');
    }
  }, [data, dispatch]);

  const location = useLocation();
  const message = location?.state?.message;

  const formik = useFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().required().typeError(),
      code: yup.number().typeError('Incorrect code'),
    }),
    initialValues: {
      email: '',
      password: '',
      code: '',
    },
    onSubmit: async (val) => {
      try {
        await login({
          variables: {
            loginInput: {
              email: val.email,
              password: val.password,
              code: val.code,
              // selectedFirmAccount: '1', // todo: ask for firm account if member of multiple firms
            },
          },
        });
      } catch (e) {
        console.log('caught error... ');
        console.warn(e);
      }
      // setLoading(true);

      // const values = val;
      // if (!needCode) delete values.code;
      // const fromUrl = history?.location?.state?.from?.pathname || '';

      // try {
      //   const { data } = await API({
      //     method: 'POST',
      //     url: needCode ? '/login/2FA' : '/login',
      //     data: values,
      //   });
      //   console.log('data: ', data);
      //   const { accessToken, user } = data;
      //   setLoading(false);
      //   if (!fromUrl.includes('videocall') && user.isTaxPro) {
      //     confirm({
      //       title: 'This is a Tax preparer account',
      //       icon: <ExclamationCircleOutlined />,
      //       content: 'you will be redirected to the appropriate page.',
      //       onOk() {
      //         window.location.replace(`${getBaseUrl()}/login`);
      //       },
      //     });
      //   } else {
      //     dispatch(setSession(user, accessToken));
      //   }
      // } catch ({ response }) {
      //   setLoading(false);
      //   if (response?.status === 417) {
      //     setGeneralErrorMsg(response.data.message);
      //   }
      //   setLoading(false);
      //   if (response.data.needCodeOTP) {
      //     setNeedCode(response.data.needCodeOTP);
      //   } else setGeneralErrorMsg('Incorrect password');
      // }
    },
  });

  const {
    touched, values, errors, handleSubmit, handleChange,
  } = formik;

  useEffect(() => {
    if (customLayout?.logo) updateFavicon(customLayout?.logo);
  }, [customLayout?.logo]);

  // todo: do we need this?
  // useEffect(() => {
  //   // temporal fix unless old user clear old session cookies
  //   const deleteOldSessionCookies = async () => {
  //     try {
  //       await LegacyAPI({
  //         method: 'GET',
  //         headers: {
  //           'x-requested-with': 'XMLHttpRequest',
  //         },
  //         url: `${getBaseUrl()}/logout?isOldClientSession=true`,
  //       });
  //     } catch (err) {
  //       // console.log('error when log out', err);
  //     }
  //   };
  //   deleteOldSessionCookies();
  // });

  return (
    <div
      className="login-container"
      style={{ flexDirection: 'column', backgroundColor: '#ffffff', color: '#000000' }}
    >
      {message && (
      <Alert
        style={{
          maxWidth: 250,
          marginBottom: 12,
        }}
        message={message}
        type="info"
            // showIcon
        closable
      />
      )}
      <Card className="login-card">
        {!!customLayout?.logo && (
          <img alt="Logo" className="login-logo" src={customLayout?.logo} />
        )}
        {!!generalErrorMsg && (
          <Alert
            style={{
              maxWidth: 250,
              marginBottom: 12,
            }}
            message={`${generalErrorMsg}`}
            type="error"
            showIcon
          />
        )}
        <Form layout="vertical" name="basic" onFinish={handleSubmit}>
          <Item
            label="Username"
            validateStatus={touched.email && errors.email && 'error'}
            help={
              touched.email
              && errors.email && <Alert message={errors.email} type="error" />
            }
          >
            <Input
              name="email"
              onChange={(e) => {
                setGeneralErrorMsg('');
                handleChange(e);
              }}
              value={values.email}
            />
          </Item>
          <Item
            label="Password"
            validateStatus={touched.password && errors.password && 'error'}
            help={
              touched.password
              && errors.password && (
                <Alert message={errors.password} type="error" />
              )
            }
          >
            <Password
              name="password"
              onChange={(e) => {
                setGeneralErrorMsg('');
                handleChange(e);
              }}
              value={values.password}
            />
          </Item>
          {needCode && (
            <Item
              label="Code"
              validateStatus={touched.code && errors.code && 'error'}
              help={
                touched.code
                && errors.code && <Alert message={errors.code} type="error" />
              }
            >
              <Input
                name="code"
                onChange={(e) => {
                  setGeneralErrorMsg('');
                  handleChange(e);
                }}
                value={values.code}
              />
            </Item>
          )}
          <Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Login
            </Button>
          </Item>
          <Item style={{ textAlign: 'center' }}>
            {values.email === ''
              ? (
                <a
                  href={
                    branding?.id
                      ? `/forgot-password/${branding.id}`
                      : '/forgot-password'
                  }
                >
                  Forgot password?
                </a>
              )
              : (
                <a
                  href={
                    branding?.id
                      ? `/forgot-password/${branding.id}?email=${encodeURIComponent(values.email)}`
                      : `/forgot-password?email=${encodeURIComponent(values.email)}`
                  }
                >
                  Forgot password?
                </a>
              )}
            {!!customLayout?.customDomainId && (
            <p>
              New to Portal?
              <a href={`/invite/${customLayout.uniqueLink}`}>
                {' '}
                Create Account
              </a>
            </p>
            )}
          </Item>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ profile: { branding }, session }) => ({
  branding,
  session,
});

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  branding: PropTypes.shape({
    customBranding: PropTypes.shape({
      logoS3Key: PropTypes.string,
    }),
    id: PropTypes.number,
  }).isRequired,
  session: PropTypes.shape({
    user: PropTypes.shape({}),
    accessToken: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Login));
