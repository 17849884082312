import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { css } from "aphrodite";
import { useTranslation } from 'react-i18next';

import Button from "../Button/Button";
import { Icon } from "../Icon/Icon";
import withOverlay from "../../utils/withOverlay";
import styles from './AddReviewStyles';

import Translation from "../atoms/Translation";
import { useCreateReviewMutation, useUpdateReviewMutation } from "../../graphql/mutations/reviews";
import { message } from "antd";
import { useGetReviewByTaxYearInterviewIdQuery } from "../../graphql/queries/reviews";

const maxReviewLength = 512;

const AddReview = (props) => {
  const {
    close,
    history,
    match: { params },
  } = props;
  const { projectId } = params;
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { t: translation } = useTranslation();

  // graphql
  // fetch
  const { data: { ReviewByTaxYearInterviewId: review } = {}, loading: loadingReview } = useGetReviewByTaxYearInterviewIdQuery({
    variables: { taxYearInterviewId: projectId },
    onError: (error) => {
      if (!error.message.includes("1001")) { // 1001 is the error code for no review found
        message.error(error?.message)
      }
    },
  });
  // create
  const [createReview, { loading: loadingCreate }] = useCreateReviewMutation({
    onError: (error) => { message.error(error.message) },
    onCompleted: () => {
      message.success(`${translation('Your review successfully created')}`);
      history.push("/");
    },
    refetchQueries: ["GetJobsByClientId"],
  });
  // update
  const [updateReview, { loading: loadingUpdate }] = useUpdateReviewMutation({
    onError: (error) => { message.error(error.message) },
    onCompleted: () => {
      message.success(`${translation('Your review successfully updated')}`);
      history.push("/");
    },
  });

  // componentDidMount
  useEffect(() => {
    if (review?.reviewContent) {
      setComment(review.reviewContent);
    }
    if (review?.reviewValue) {
      setRating(review.reviewValue);
    }
  }, [review]);

  useEffect(() => {
    if (close) {
      history.push("/");
    }
  }, [close]);

  const changeComment = (e) => {
    if (comment.length <= maxReviewLength) {
      setComment(e.target.value);
    }
  }

  const handleHover = (type, i) => {
    setHoveredIndex(type === "on" ? i : null);
  }

  const onSubmit = () => {
    if (review) { // // update review
      updateReview({
        variables: {
          updateReviewInput: {
            id: review.id,
            reviewContent: comment,
            reviewValue: rating,
          },
        },
      });
    } else { // create review
      // eslint-disable-next-line no-lonely-if
      if (rating) {
        createReview({
          variables: {
            createReviewInput: {
              reviewValue: rating,
              reviewContent: comment,
              taxYearInterviewId: projectId,
            }
          },
        });
      } else {
        message.error("A rating is required");
      }
    } // end else create review
  }
  return (
    <div>
      <div className={css(styles.starsBlock)}>
        <span className={css(styles.blockTitle)}>
          <Translation text="contactinfo.yourReview" />
        </span>
        <div>
          {[0, 1, 2, 3, 4].map((icon, i) => (
            <span
              key={`icon-${icon}`}
              onMouseEnter={() => handleHover("on", i)}
              onMouseLeave={() => handleHover()}
            >
              <Icon
                star={
                  (rating && rating > i)
                  || (hoveredIndex && hoveredIndex >= i)
                    ? "filled"
                    : true
                }
                interactive
                onClick={() => setRating(i + 1)}
              />
            </span>
          ))}
        </div>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="comments" className={css(styles.label)}>
          <Translation text="modal.addReview.comments" />
        </label>
        <textarea
          id="comments"
          className={css(styles.textArea)}
          placeholder="..."
          value={comment}
          onChange={changeComment}
        />
        <div className={css(styles.infoBlock)}>
          <Translation text="modal.addReview.charactersRemaining" />
          {`:${maxReviewLength - comment.length}`}
        </div>
      </div>
      <div className={css(styles.btnHolder)}>
        <Button
          blue
          label={<Translation text={review ? "modal.addReview.updateReview" : "modal.addReview.submitReview"} />}
          onClick={onSubmit}
          loading={loadingCreate || loadingUpdate}
        />
      </div>
    </div>
  );
};

export default withOverlay(withRouter((AddReview)));
