import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInterviewNamesByFirmAccountIdQueryVariables = Types.Exact<{
  filterLiveInterviews?: Types.InputMaybe<Types.Scalars['Boolean']>;
  firmAccountId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetInterviewNamesByFirmAccountIdQuery = { __typename?: 'Query', interviewNamesByFirmAccountId: Array<{ __typename?: 'InterviewEntity', id: string, name: string, isLive: boolean }> };

export type GetInterviewNamesQueryVariables = Types.Exact<{
  filterLiveInterviews?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetInterviewNamesQuery = { __typename?: 'Query', interviewNames: Array<{ __typename?: 'InterviewEntity', id: string, name: string, isLive: boolean, createAt: any, updateAt: any, isTemplate: boolean, firmAccountId: string }> };


export const GetInterviewNamesByFirmAccountIdDocument = gql`
    query GetInterviewNamesByFirmAccountId($filterLiveInterviews: Boolean, $firmAccountId: String) {
  interviewNamesByFirmAccountId(
    filterLiveInterviews: $filterLiveInterviews
    firmAccountId: $firmAccountId
  ) {
    id
    name
    isLive
  }
}
    `;

/**
 * __useGetInterviewNamesByFirmAccountIdQuery__
 *
 * To run a query within a React component, call `useGetInterviewNamesByFirmAccountIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewNamesByFirmAccountIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewNamesByFirmAccountIdQuery({
 *   variables: {
 *      filterLiveInterviews: // value for 'filterLiveInterviews'
 *      firmAccountId: // value for 'firmAccountId'
 *   },
 * });
 */
export function useGetInterviewNamesByFirmAccountIdQuery(baseOptions?: Apollo.QueryHookOptions<GetInterviewNamesByFirmAccountIdQuery, GetInterviewNamesByFirmAccountIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterviewNamesByFirmAccountIdQuery, GetInterviewNamesByFirmAccountIdQueryVariables>(GetInterviewNamesByFirmAccountIdDocument, options);
      }
export function useGetInterviewNamesByFirmAccountIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewNamesByFirmAccountIdQuery, GetInterviewNamesByFirmAccountIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterviewNamesByFirmAccountIdQuery, GetInterviewNamesByFirmAccountIdQueryVariables>(GetInterviewNamesByFirmAccountIdDocument, options);
        }
export type GetInterviewNamesByFirmAccountIdQueryHookResult = ReturnType<typeof useGetInterviewNamesByFirmAccountIdQuery>;
export type GetInterviewNamesByFirmAccountIdLazyQueryHookResult = ReturnType<typeof useGetInterviewNamesByFirmAccountIdLazyQuery>;
export type GetInterviewNamesByFirmAccountIdQueryResult = Apollo.QueryResult<GetInterviewNamesByFirmAccountIdQuery, GetInterviewNamesByFirmAccountIdQueryVariables>;
export const GetInterviewNamesDocument = gql`
    query GetInterviewNames($filterLiveInterviews: Boolean) {
  interviewNames(filterLiveInterviews: $filterLiveInterviews) {
    id
    name
    isLive
    createAt
    updateAt
    isTemplate
    firmAccountId
  }
}
    `;

/**
 * __useGetInterviewNamesQuery__
 *
 * To run a query within a React component, call `useGetInterviewNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewNamesQuery({
 *   variables: {
 *      filterLiveInterviews: // value for 'filterLiveInterviews'
 *   },
 * });
 */
export function useGetInterviewNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetInterviewNamesQuery, GetInterviewNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterviewNamesQuery, GetInterviewNamesQueryVariables>(GetInterviewNamesDocument, options);
      }
export function useGetInterviewNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewNamesQuery, GetInterviewNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterviewNamesQuery, GetInterviewNamesQueryVariables>(GetInterviewNamesDocument, options);
        }
export type GetInterviewNamesQueryHookResult = ReturnType<typeof useGetInterviewNamesQuery>;
export type GetInterviewNamesLazyQueryHookResult = ReturnType<typeof useGetInterviewNamesLazyQuery>;
export type GetInterviewNamesQueryResult = Apollo.QueryResult<GetInterviewNamesQuery, GetInterviewNamesQueryVariables>;
export const namedOperations = {
  Query: {
    GetInterviewNamesByFirmAccountId: 'GetInterviewNamesByFirmAccountId',
    GetInterviewNames: 'GetInterviewNames'
  }
}