import {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { useGetPlanAccessLevelQuery } from '../../../graphql/queries/stripeInformation';

// enum for all the features that can be upgraded
export const Features = {
  signatures: 'signatures',
  approvals: 'approvals',
  followUps: 'followUps',
  aiMessaging: 'aiMessaging',
  mappedDrive: 'mappedDrive',
  entityInterviews: 'entityInterviews',
  reviews: 'reviews',
  payments: 'payments',
  appointments: 'appointments',
  aiResearch: 'aiResearch',
  export: 'export',
  videoCalls: 'videoCalls',
  birthdayEmails: 'birthdayEmails',
  pixels: 'pixels',
  teamMembers: 'teamMembers',
}

export const liteFeatures = [
  Features.appointments,
  Features.videoCalls,
  Features.payments,
  Features.pixels,
  Features.birthdayEmails,
];

const PlanAccessContext = createContext({
  accessObject: {},
});

export const PlanAccessProvider = ({ children }) => {
  const [accessObject, setAccessObject] = useState(null);

  const { data: planAccessData, loading: loadingPlanType } = useGetPlanAccessLevelQuery();
  const currentPlanAccess = planAccessData?.getPlanAccessLevel?.planType;

  const getAccess = (theFeature) => {
    if (currentPlanAccess === 'FREE') { // If on free plan or paused they are on freemium
      return false;
    }
    if (currentPlanAccess === 'LITE') { // If on lite plan they have access to all lite features
      return liteFeatures.includes(theFeature);
    }
    return true; // must be on pro plan
  };

  useEffect(() => {
    if (!loadingPlanType) {
      const accessResults = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const f of Object.values(Features)) {
        accessResults[f] = getAccess(f);
      }
      setAccessObject(accessResults);
    }
  }, [currentPlanAccess, loadingPlanType]);

  // Memoize the context value
  const contextValue = useMemo(() => ({
    accessObject,
  }), [currentPlanAccess, accessObject]);

  return (
    <PlanAccessContext.Provider value={contextValue}>
      {children}
    </PlanAccessContext.Provider>
  );
};

export const usePlanAccess = () => useContext(PlanAccessContext);
