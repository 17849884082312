import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import API from '../../../api';
import 'antd/dist/antd.min.css';
import Translation from '../Translation';
import statuses from './status';
import { utcToCustomTime } from '../../../utils/utcToCustomTime';
import { getTimeZone } from '../../../helpers/time';
import styles from './style.module.scss';

const Meeting = ({ meetingId }) => {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const getMeeting = async () => {
      const getEvent = await API.get(`events/meeting/${meetingId}`);
      const appointment = getEvent.data && getEvent.data.length ? getEvent.data[0] : '';
      setEvent(appointment);
    };
    if (!event) {
      getMeeting();
    }
  }, [event, meetingId]);

  if (!event) return <></>;

  return (
    <div>
      {!!event && (
      <div className={styles.appointment} key={event.id}>
        {event.title && <h3>{event.title}</h3>}
        <p className={styles.key}>
          <span className={styles.keyTitle}>
            <Translation text="upcomingAppointments.status" />
            :
            {' '}
          </span>
          <span className={styles.value}>
            <Translation text={statuses[event.status]} />
          </span>
        </p>
        { event.type
          && (
          <p className={styles.key}>
            <span className={styles.keyTitle}>
              <Translation text="upcomingAppointments.type" />
              :
              {' '}
            </span>
            <span className={styles.value}>
              <Translation text={`upcomingAppointments.type.${event.type}`} />
            </span>
          </p>
          )}
        <p className={styles.key}>
          <span className={styles.keyTitle}>
            <Translation text="upcomingAppointments.starts" />
            :
            {' '}
          </span>
          <span className={styles.value}>
            {utcToCustomTime(event.startDate, false)}
            {' '}
            (
            {getTimeZone(event.startDate).short}
            )
          </span>
        </p>
        <p className={styles.key}>
          <span className={styles.keyTitle}>
            <Translation text="upcomingAppointments.ends" />
            :
            {' '}
          </span>
          <span className={styles.value}>
            {utcToCustomTime(event.endDate, false)}
            {' '}
            (
            {getTimeZone(event.endDate).short}
            )
          </span>
        </p>
        {event.notes && (
        <p className={styles.key}>
          <span className={styles.keyTitle}>
            <Translation text="upcomingAppointments.notes" />
            :
            {' '}
          </span>
          <span className={styles.value}>{event.notes}</span>
        </p>
        )}
        {(!event.type || event.type === 'VIDEO') && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={styles.join}
            href={`/videocall/${event.Meetings.id}`}
          >
            <Translation text="upcomingAppointments.join" />
          </a>
        )}
      </div>
      )}
    </div>
  );
};

Meeting.propTypes = {
  meetingId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default Meeting;
