import React from 'react';
import { Select, message } from 'antd';

const { Option } = Select;

const {
  success, error, warning, info,
} = message;

const getOptions = (array) => array.map(({ value, label }) => (
  <Option value={value} key={value}>
    {label}
  </Option>
));

const Message = (content, type) => {
  const style = { marginTop: '5vh' };
  const typeLower = `${type}`.toLowerCase();
  switch (typeLower) {
    case 'success':
      return success({ content, style });
    case 'error':
      return error({ content, style });
    case 'warning ':
      return warning({ content, style });
    default:
      return info({ content, style });
  }
};

export { Message, getOptions };
