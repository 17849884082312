const getName = (obj) => {
  if (!obj) return '';
  let firstName = '';
  let lastName = '';
  if (obj?.Users && obj?.Users?.PreparerDetails) {
    firstName = obj.Users.PreparerDetails.firstName;
    lastName = obj.Users.PreparerDetails.lastName;
  } else if (obj?.Users?.Clients_Clients_clientUserIdToUsers) {
    firstName = obj.Users.Clients_Clients_clientUserIdToUsers.firstName;
    lastName = obj.Users.Clients_Clients_clientUserIdToUsers.lastName;
  }
  return `${firstName} ${lastName}`;
};

const getOccupation = (obj) => {
  if (!obj) return '';
  let occupation = '';
  const client = obj.Users.Clients_Clients_clientUserIdToUsers;
  if (client.ClientInterviews.length > 0) {
    const ClientInterview = client.ClientInterviews[0];
    if (ClientInterview.interviewForm) {
      occupation = ClientInterview.interviewForm.taxpayer_occupation;
    }
  }
  return occupation;
};

const getPhone = (obj) => {
  if (!obj) return '';
  let phone = '';
  if (obj.Users && obj.Users.PreparerDetails) {
    phone = obj.Users.PreparerDetails.personalPhone;
  } else if (obj.Users.Clients_Clients_clientUserIdToUsers) {
    phone = obj.Users.Clients_Clients_clientUserIdToUsers.phone;
  }
  return phone;
};

const getMobile = (obj) => {
  if (!obj) return '';
  let phoneMobile = '';
  if (obj.Users && obj.Users.PreparerDetails) {
    phoneMobile = obj.Users.PreparerDetails.personalPhone;
  } else if (obj.Users.Clients_Clients_clientUserIdToUsers) {
    phoneMobile = obj.Users.Clients_Clients_clientUserIdToUsers.phoneMobile;
  }
  return phoneMobile;
};

const getWork = (obj) => {
  if (!obj) return '';
  let phoneWork = '';
  if (obj.Users && obj.Users.PreparerDetails) {
    phoneWork = obj.Users.PreparerDetails.personalPhone;
  } else if (obj.Users.Clients_Clients_clientUserIdToUsers) {
    phoneWork = obj.Users.Clients_Clients_clientUserIdToUsers.phoneWork;
  }
  return phoneWork;
};

const getAvatar = (obj) => {
  if (!obj) return '';
  let avatar = '';
  if (
    obj.Users.PreparerDetails
    && obj.Users
    && obj.Users.PreparerDetails
    && obj.Users.PreparerDetails.Files
    && obj.Users.PreparerDetails.Files.length > 0
  ) {
    avatar = obj.Users.PreparerDetails.Files[0].s3Key;
  }
  return avatar;
};

export {
  getName, getOccupation, getPhone, getMobile, getWork, getAvatar,
};
