import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import MessageAdmin from '../../atoms/MessageAdmin';
import { getName } from '../../helpers/userInformation';
import MissedCall from '../../atoms/MissedCall';
import 'antd/dist/antd.min.css';

const MessagesList = ({
  data, userId, style, idLastMessage,
}) => {
  const { t: translation } = useTranslation();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  useEffect(() => {
    scrollToBottom();
  }, [idLastMessage, data]);

  return (
    <div className={styles.container} style={style}>
      <List
        size="small"
        header={<div />}
        footer={<div ref={messagesEndRef} />}
        bordered={false}
        dataSource={data}
        renderItem={(item, index) => (item.isMissedCall ? (
          <MissedCall
            key={index}
            createdAt={new Date(item.createdAt)}
            text={translation('chat.messagesList.missedCall')}
            readDate={item.readDate !== null}
            isAdmin
          />
        ) : (
          <>
            {item?.Users ? (
              <MessageAdmin
                key={index}
                createdAt={new Date(item.createdAt)}
                mine={userId === item.userId}
                message={item.text}
                last={data.length === index + 1}
                name={getName(item)}
                attachment={item.attachment}
              />
            ) : <></>}
          </>
        ))}
      />
    </div>
  );
};

MessagesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  userId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  idLastMessage: PropTypes.number.isRequired,
};

export default MessagesList;
