import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
 Row, Card, Form, Input, Button, message, Col, Select, Spin, Popconfirm, Tooltip
} from 'antd';
import Translation from '../../../atoms/Translation';
import { useUpdateUserInformationMutation } from '../../../../graphql/mutations/settings';
import { useTranslation } from 'react-i18next';
import { updateFieldSession } from '../../../../redux/actions/session';
import { usePhonesQuery, PhonesDocument } from '../../../../graphql/queries/settings';
import { DeleteOutlined } from '@ant-design/icons';
import { PhoneType } from '../../../../graphql';
import PhoneInputField from '../../../Controls/PhoneInputField';
import { isValidPhoneNumber } from 'react-phone-number-input'

const ClientInfo = (props) => {
  const { dispatch, session } = props;
  const { graphSession } = session;
  const {
    userInformationId, firstName, lastName, email, 
  } = graphSession;

  const { t: translation } = useTranslation();
  const [userInfoForm] = Form.useForm();
  const [formPhones, setFormPhones] = useState([]);
  const [firstNameHelp, setFirstNameHelp] = useState('');
  const [lastNameHelp, setLastNameHelp] = useState('');
  const [invalidPhoneId, setInvalidPhoneId] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const phoneTypeOptions = Object.values(PhoneType).map((pt) => ({
    value: pt,
    label: pt,
  }));
  const { data: phonesData, loading: loadingPhones, error: phonesError } = usePhonesQuery({
    variables: {
      userInformationId,
    }
  });
  const [updateUserInformation, { loading: loadingUpdateUserInfo }] = useUpdateUserInformationMutation({
    onError: (err) => message.error(err.message),
    refetchQueries: [{ query: PhonesDocument, variables: { userInformationId }}],
    awaitRefetchQueries: true,
  });

  const getPhones2Update = (formValues) => formPhones
    .map((phone) => {
      if (formValues[phone.id] && formValues[`${phone.id}_type`]
          && phone.id.indexOf('phone_') === -1) {
        return {
          id: phone.id,
          type: formValues[`${phone.id}_type`],
          value: formValues[phone.id],
        }
      }
      return undefined;
    })
    .filter((phone) => phone?.id);

  const getPhones2Create = (formValues, includeEmptyValues = false) => formPhones
    .map((phone) => {
      if (includeEmptyValues && phone.id.indexOf('phone_') === 0) {
        return {
          id: phone.id,
          type: formValues[`${phone.id}_type`],
          value: formValues[phone.id],
        }
      }

      if (formValues[phone.id] && formValues[`${phone.id}_type`]
          && phone.id.indexOf('phone_') === 0) {
        return {
          id: phone.id,
          type: formValues[`${phone.id}_type`],
          value: formValues[phone.id],
        }
      }

      return undefined;
    })
    .filter((phone) => phone?.id);

  const getPhones2Delete = (formValues) => phonesData?.Phones
    .map((phone) => {
      if (formValues[phone.id] === undefined && formValues[`${phone.id}_type`] === undefined
          && phone.id.indexOf('phone_') === -1) {
        return {
          id: phone.id,
          type: phone.type,
          value: phone.value,
          deleted: true,
        }
      }
      return undefined;
    })
    .filter((phone) => phone?.id);

  const formFieldChanged = () => {
    let isValid = true;

    const formValues = userInfoForm.getFieldsValue(true);

    const firstNameOk = formValues.formFirstName?.length > 0;
    setFirstNameHelp(firstNameOk ? '' : 'First name should not be empty');
    if (!firstNameOk) {
      isValid = false;
    }

    const lastNameOk = formValues.formLastName?.length > 0;
    setLastNameHelp(lastNameOk ? '' : 'Last name should not be empty');
    if (!lastNameOk) {
      isValid = false;
    }

    const phones2Update = getPhones2Update(formValues);
    const phones2Create = getPhones2Create(formValues);
    const phones2Delete = getPhones2Delete(formValues);
    if (formPhones.length !== phones2Update.length + phones2Create.length
      && phones2Delete.length === 0
    ) {
      isValid = false;
    }

    const invalidPhones = phones2Update
      .concat(phones2Create)
      .filter((phone) => phone.value === '' || !isValidPhoneNumber(phone.value));
    setInvalidPhoneId(invalidPhones.length > 0 ? invalidPhones[0].id : '');
    if (invalidPhones.length) {
      isValid = false;
    }

    setSubmitDisabled(!isValid);
  };

  const updatePhonesValues = (phones) => {
    const phonesUpdate = Object.assign(
      {},
      ...phones.map((phone) => ({
        [phone.id]: phone.value,
        [`${phone.id}_type`]: phone.type,
      })),
    );

    userInfoForm.setFieldsValue(phonesUpdate);
  };

  const handleSaveInfo = async () => {
    const formValues = userInfoForm.getFieldsValue(true);
    const { formFirstName, formLastName } = formValues;
    const phones2Update = getPhones2Update(formValues);
    const phones2Create = getPhones2Create(formValues)
      .map((ph) => ({
        type: ph.type,
        value: ph.value,
      }));
    const phones2Delete = getPhones2Delete(formValues);

    const result = await updateUserInformation({
      variables: {
        updateUserInformationInput: {
          id: userInformationId,
          firstName: formFirstName,
          lastName: formLastName,
          Phones: [].concat(phones2Update).concat(phones2Create).concat(phones2Delete),
        }
      }
    });
    
    if (result.data?.UpdateUserInformation?.id) {
      dispatch(updateFieldSession('firstName', result.data.UpdateUserInformation.firstName));
      dispatch(updateFieldSession('lastName', result.data.UpdateUserInformation.lastName));
      setSubmitDisabled(true);
      message.success("Update success");
    } else {
      message.error("Update error");
    }
  };

  const removePhoneFromList = (phoneId) => {
    setFormPhones(formPhones.filter((fp) => fp.id !== phoneId));
    userInfoForm.setFieldsValue({
      [phoneId]: undefined,
      [`${phoneId}_type`]: undefined,
    });
    formFieldChanged();
  };

  useEffect(() => {
    if (phonesData && phonesData?.Phones) {
      setFormPhones(phonesData.Phones);
      updatePhonesValues(phonesData.Phones);
    }
  }, [phonesData?.Phones]);

  useEffect(() => {
    if (phonesError) {
      message.error(phonesError.message);
    }
  }, [phonesError]);

  return (
    <Row align="middle" justify="center">
      <Card
        className="container"
        style={{ marginTop: '25px', border: '1px solid rgba(0, 0, 0, 0.125)' }}
      >
        <h2>
          <Translation text="contactinfo.title" />
        </h2>
        <hr style={{ margin: 0, paddingBottom: '20px' }} />
        <Form
          layout="vertical"
          initialValues={{
            formFirstName: firstName,
            formLastName: lastName,
          }}
          form={userInfoForm}
          style={{ width: '100%' }}
          onFieldsChange={formFieldChanged}
        >
          <Form.Item
            name="formFirstName"
            label={<Translation text="InviteForm.firstName" />}
            validateStatus={firstNameHelp === '' ? 'success' : 'error'}
            help={firstNameHelp}
          >
            <Input
              size="large"
              placeholder={translation('InviteForm.firstName')}
              rules={[{ required: true }]}
            />
          </Form.Item>

          <Form.Item
            name="formLastName"
            label={<Translation text="InviteForm.lastName" />}
            validateStatus={lastNameHelp === '' ? 'success' : 'error'}
            help={lastNameHelp}
          >
            <Input
              size="large"
              placeholder={translation('InviteForm.lastName')}
              rules={[{ required: true }]}
            />
          </Form.Item>

          <Form.Item label={<Translation text="InviteForm.email" />}>
            <Tooltip title="In order to change your email, please contact customer support">
              <span>
                <Input
                  value={email}
                  size="large"
                  disabled
                />
              </span>
            </Tooltip>
          </Form.Item>

          {loadingPhones
            ? <Spin size="large" />
            : formPhones.map((phone) => (
              <Row align="middle" justify="space-between" key={`${phone.id}_row`}>
                <Col style={{ width: "60%" }}>
                  <Form.Item
                    name={phone.id}
                    label="Phone Number"
                    validateStatus={phone.id === invalidPhoneId ? 'error' : 'success'}
                    help={phone.id === invalidPhoneId ? 'Invalid phone number' : ''}
                  >
                    <PhoneInputField 
                      size="large"
                      type="tel"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col style={{ width: "25%" }}>
                  <Form.Item
                    name={`${phone.id}_type`}
                    label={<Translation text="upcomingAppointments.type" />}
                  >
                    <Select
                      options={phoneTypeOptions}
                    />
                  </Form.Item>
                </Col>
                
                <Col>
                  {phone.id.indexOf('phone_') === 0 // newly created phones
                  ? (
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removePhoneFromList(phone.id)}
                    />
                    )
                  : (
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={() => removePhoneFromList(phone.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        danger
                      />  
                    </Popconfirm>
                    )}
                </Col>
              </Row>
          ))}

          <Form.Item>
            <Button
              type="link"
              onClick={() => {
                const newValue = {
                  id: `phone_${new Date().getTime()}`,
                  type: PhoneType.Mobile,
                  value: '',
                };
                const formValues = userInfoForm.getFieldsValue(true);
                const phones2Update = getPhones2Update(formValues);
                const phones2Create = getPhones2Create(formValues, true);
                const newPhonesArray = [].concat(phones2Update).concat(phones2Create).concat([newValue]);
                setFormPhones(newPhonesArray);
                updatePhonesValues(newPhonesArray);
              }}
              disabled={formPhones.length > 5}
            >
              ADD PHONE
            </Button>
          </Form.Item>
          
          <Button
            disabled={submitDisabled}
            type="primary"
            size="large" 
            loading={loadingUpdateUserInfo}
            style={{ borderRadius: '5px' }}
            onClick={handleSaveInfo}
          >
            <Translation text="settings.message.save" />
          </Button>
        </Form>
      </Card>
    </Row>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(ClientInfo);
