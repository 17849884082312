import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Progress,
  Upload,
  message as antdMessage,
} from 'antd';
import 'antd/dist/antd.min.css';
import {
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import OutsideAlerter from '../../helpers/OutSiderAlerter';
import { S3Policy, saveFile } from '../../helpers/s3Upload';
import { formatProjects } from '../../../helpers/Projects';
import { setProjects as setProjectsAction } from '../../../redux/actions/projects';
import styles from './style.module.css';
import './style.css';

const ChatInputAdmin = ({
  onSendMessage, style, projects, client, dispatch,
}) => {
  const [val, setValue] = useState('');
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [openChat, setOpenChat] = useState(true);

  const { t: translation, i18n } = useTranslation();

  useEffect(() => {
    if (client) {
      let newPhone = client.phoneMobile
        ? client.phoneMobile.replace(/[^\w\s]/gi, '').replace(' ', '')
        : null;
      newPhone = newPhone
        || (client.phone
          ? client.phone.replace(/[^\w\s]/gi, '').replace(' ', '')
          : null);
      newPhone = newPhone
        || (client.phoneWork
          ? client.phoneWork.replace(/[^\w\s]/gi, '').replace(' ', '')
          : null);
      setPhone(newPhone);

      if (!projects || projects.length === 0) {
        const clientProjects = formatProjects(
          client.ClientTaxYearStatuses,
        );
        dispatch(setProjectsAction(clientProjects));
      }/* else {
        dispatch(getProjects());
      } */
    }
  }, [client]);

  const uploadPros = {
    name: 'file',
    onChange(inf) {
      const info = inf;
      if (info.file.status === 'uploading') {
        if (!uploadingStatus) setUploadingStatus(true);
        if (info.event) setUploadPercent(info.event.percent);
      }
      if (info.file.status === 'done') {
        setUploadingStatus(false);
        setUploadPercent(0);
        info.file.s3key = info.file.originFileObj.s3key;
        onSendMessage('', JSON.stringify(info.file));
        antdMessage.success({
          content: `${info.file.name} ${translation(
            'chat.chatInput.fileUploaded',
          )}`,
          duration: 3,
          className: 'customAlert-messageSuccess',
          style: {
            marginTop: '38px',
            textAlign: 'center',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-35%)',
          },
        });
      } else if (info.file.status === 'error') {
        antdMessage.error(
          `${info.file.name} ${translation('chat.chatInput.fileNotUploaded')}`,
        );
      }
    },
  };

  const sendMessage = async () => {
    if (openChat) {
      onSendMessage(val);
    } else {
      onSendMessage(`${message}~#*msg~#*${phone || ''}`);
    }
    setValue('');
  };

  const chat = (open) => {
    if (open === 1) {
      setOpenChat(false);
    } else {
      setOpenChat(true);
    }
  };

  const uploadFile = async (options, projectId) => {
    const config = {
      folder: 'client',
      clientUniqueId: client.uniqueId,
      clientId: client.id,
      taxYearStatusId: projectId, // projectId
      appendName: true,
    };
    const s3Pkg = await S3Policy(options.file, config, '/service/S3/sign');
    const bucketName = s3Pkg.s3Bucket;
    const action = `//${bucketName}.s3.amazonaws.com/`;
    await saveFile(s3Pkg, action, options);
  };

  const sendMessageSms = async () => {
    setValue(message);
    sendMessage();
    setMessage('');
  };

  const { name, onChange } = uploadPros;

  const projectsMenu = () => (
    <Menu>
      <Menu.Item style={{ fontWeight: 'bolder' }} disabled key={0}>
        {translation('chat.chatInput.selectProject')}
      </Menu.Item>
      {projects?.map((p) => (
        <Menu.Item key={p.id}>
          <Upload
            name={name}
            onChange={onChange}
            showUploadList={false}
            disabled={uploadingStatus}
            multiple={false}
            customRequest={(options) => uploadFile(options, p.id)}
          >
            {p.name}
          </Upload>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={style} className={styles.container}>
      {emojiVisible && (
        <OutsideAlerter onOutside={() => setEmojiVisible(false)}>
          <Picker
            set="apple"
            showPreview={false}
            showSkinTones={false}
            style={{
              width: '100%',
              maxHeight: 320,
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
            sheetSize={32}
            onSelect={(emoji) => {
              if (openChat) setValue(`${val} ${emoji.native} `);
              else setValue(`${val} `);
              if (openChat) setMessage(`${message} `);
              else setMessage(`${message} ${emoji.native} `);
            }}
            i18n={i18n.getDataByLanguage(i18n.language).emojiMart}
          />
        </OutsideAlerter>
      )}

      <div className={styles.containerInput}>
        {openChat ? (
          <Dropdown
            overlay={projectsMenu()}
            trigger={['click']}
            placement="topLeft"
          >
            <Button
              className={`${styles.clip} ant-dropdown-link`}
              type="default"
              shape="circle"
              size="medium"
              onClick={(e) => e.preventDefault()}
            >
              {!uploadingStatus && <PaperClipOutlined />}
              {uploadingStatus && (
                <Progress
                  type="circle"
                  percent={uploadPercent}
                  showInfo={false}
                  width={30}
                  style={{ marginRight: 8 }}
                />
              )}
            </Button>
          </Dropdown>
        ) : (
          <Dropdown overlay={projectsMenu()} trigger={[]} placement="topLeft">
            <Button
              className={`${styles.clipsms} ant-dropdown-link`}
              type="default"
              shape="circle"
              size="medium"
              onClick={(e) => e.preventDefault()}
            >
              {!uploadingStatus && <PaperClipOutlined />}
              {uploadingStatus && (
                <Progress
                  type="circle"
                  percent={uploadPercent}
                  showInfo={false}
                  width={30}
                  style={{ marginRight: 8 }}
                />
              )}
            </Button>
          </Dropdown>
        )}

        <div className="dropup">
          <button className="dropbtn" type="button">
            <i className="fa fa-comments" aria-hidden="true" />
          </button>
          <div className="dropupcontent">
            {phone === null ? (
              ''
            ) : (
              <div
                role="button"
                onClick={() => chat(1)}
                onKeyPress={() => {}}
                tabIndex="0"
              >
                <p>SMS</p>
              </div>
            )}
            <div
              role="button"
              onClick={() => chat(0)}
              onKeyPress={() => {}}
              tabIndex="0"
            >
              <p>Email / Chat</p>
            </div>
          </div>
        </div>

        {openChat ? (
          <Input
            className={styles.input}
            placeholder="Send Message via Email / Chat"
            value={val}
            onPressEnter={sendMessage}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <Input
            className={styles.input}
            placeholder="Send Message via SMS"
            value={message}
            onPressEnter={sendMessageSms}
            onChange={(e) => setMessage(e.target.value)}
          />
        )}
        <Button
          className={styles.clip}
          type="default"
          shape="circle"
          icon={<SmileOutlined />}
          size="medium"
          onClick={() => setEmojiVisible(!emojiVisible)}
        />
        {openChat ? (
          <Button
            className={styles.send}
            type="default"
            shape="circle"
            icon={<SendOutlined />}
            size="medium"
            onClick={() => sendMessage()}
          />
        ) : (
          <Button
            className={styles.send}
            type="default"
            shape="circle"
            icon={<SendOutlined />}
            size="medium"
            onClick={() => sendMessageSms()}
          />
        )}
      </div>
    </div>
  );
};

ChatInputAdmin.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  style: PropTypes.objectOf(PropTypes.string).isRequired,
  client: PropTypes.shape().isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  client: state.session.user.Clients_Clients_clientUserIdToUsers,
  projects: state.projects.projects,
  dispatch: state.dispatch,
});

export default connect(mapStateToProps)(ChatInputAdmin);
