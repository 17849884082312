import React from "react";
import { css, StyleSheet } from "aphrodite";
import { Icon } from "../../Icon/Icon";
import { grey } from "../../../styles/colors";
import { Lato } from "../../../styles/fonts";
import PropTypes from "prop-types";

const cardStyles = StyleSheet.create({
  holder: {
    display: 'flex',
    minHeight: '70px',
    cursor: 'pointer',
    marginBottom: '3px',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '25px 40px',
    backgroundColor: grey['300'],
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
    color: grey['800'],
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    fontFamily: [Lato, 'sans-serif'],
  }
});

const SectionStatusCard = ({
  title, status, onSelect, id
}) => (
  <div className={css(cardStyles.holder)} onClick={() => onSelect(id)}>
    <div className={css(cardStyles.title)}>
      {title}
    </div>
    <div className={css(cardStyles.icon)}>
      <Icon type={status ? 'check' : 'warn'} />
    </div>
  </div>
);

SectionStatusCard.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SectionStatusCard;
