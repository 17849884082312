import axios from 'axios';
import storage from 'redux-persist/lib/storage';
import LegacyAPI from './api';
import { serverUrl } from './config';

const getToken = () => {
  try {
    const token = localStorage.getItem('accessToken');
    return typeof token === 'string' ? `Bearer ${token}` : '';
  } catch (e) {
    return '';
  }
};

const instance = axios.create({
  baseURL: serverUrl,
});

instance.interceptors.request.use((config) => {
  const token = getToken();
  if (!token) return config;
  return { ...config, headers: { ...config.headers, Authorization: token } };
});

instance.interceptors.response.use((response) => response, (error) => {
  if (!error?.response?.status) return Promise.reject(error);
  if (error.response.status === 401) {
    storage.removeItem('persist:root');
    storage.removeItem('lang');
    window.location.href = `${window.location.origin}/login`;
    return {
      data: {
        status: 'ERROR',
        error: 'Unauthorized',
      },
    };
  }
  return Promise.reject(error);
});

export { LegacyAPI };

export default instance;
