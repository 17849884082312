import { combineReducers } from 'redux';
import documents from './documents';
import interview from './interview';
import invoices from './invoices';
import projects from './projects';
import profile from './profile';
import languages from './languages';
import appointments from './appointments';
import session from './session';
import channels from './channels';

export default combineReducers({
  documents,
  interview,
  invoices,
  projects,
  profile,
  languages,
  appointments,
  session,
  channels,
});
