import { color, grey } from './colors';

export const Lato = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: "url('./fonts/Lato-Regular.ttf') format('ttf')",
};

export const LatoBold = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: "url('./fonts/Lato-Bold.ttf') format('ttf')",
};

export const LatoLight = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'light',
  src: "url('./fonts/Lato-Light.ttf') format('ttf')",
};

export const heading = {
  h3: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    textTransform: 'uppercase',
    color: grey['800'],
  },
  modalHeading: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#000',
    '@media (min-width: 640px)': {
      fontSize: '30px',
    },
  },
  table: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    textTransform: 'uppercase',
  },
};

export const text = {
  regular: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.2px',
    color: '#000',
  },
  small: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.2px',
  },
  error: {
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '11px',
    fontWeight: 'normal',
    lineHeight: '1.2',
    letterSpacing: '0.2px',
    color: color.red,
  },
};
