import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPlanAccessLevelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPlanAccessLevelQuery = { __typename?: 'Query', getPlanAccessLevel: { __typename?: 'StripePlanTypeEntity', planType?: Types.StripePlanType | null } };


export const GetPlanAccessLevelDocument = gql`
    query GetPlanAccessLevel {
  getPlanAccessLevel {
    planType
  }
}
    `;

/**
 * __useGetPlanAccessLevelQuery__
 *
 * To run a query within a React component, call `useGetPlanAccessLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanAccessLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanAccessLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanAccessLevelQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>(GetPlanAccessLevelDocument, options);
      }
export function useGetPlanAccessLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>(GetPlanAccessLevelDocument, options);
        }
export type GetPlanAccessLevelQueryHookResult = ReturnType<typeof useGetPlanAccessLevelQuery>;
export type GetPlanAccessLevelLazyQueryHookResult = ReturnType<typeof useGetPlanAccessLevelLazyQuery>;
export type GetPlanAccessLevelQueryResult = Apollo.QueryResult<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>;
export const namedOperations = {
  Query: {
    GetPlanAccessLevel: 'GetPlanAccessLevel'
  }
}