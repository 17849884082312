import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateClientFromInviteLinkMutationVariables = Types.Exact<{
  createClientFromInviteLinkInput: Types.CreateClientFromInviteLinkInput;
}>;


export type CreateClientFromInviteLinkMutation = { __typename?: 'Mutation', CreateClientFromInviteLink: { __typename?: 'UserEntity', id: string } };

export type UpdateClientFromInviteLinkMutationVariables = Types.Exact<{
  updateClientFromInviteLinkInput: Types.UpdateClientFromInviteLinkInput;
}>;


export type UpdateClientFromInviteLinkMutation = { __typename?: 'Mutation', UpdateClientFromInviteLink: { __typename?: 'ActivateClientEntity', message: string } };

export type UpdateUserUniqueLinkMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
}>;


export type UpdateUserUniqueLinkMutation = { __typename?: 'Mutation', updateUserUniqueLink: { __typename?: 'UserEntity', id: string, uniqueLink?: string | null } };


export const CreateClientFromInviteLinkDocument = gql`
    mutation CreateClientFromInviteLink($createClientFromInviteLinkInput: CreateClientFromInviteLinkInput!) {
  CreateClientFromInviteLink(
    createClientFromInviteLinkInput: $createClientFromInviteLinkInput
  ) {
    id
  }
}
    `;
export type CreateClientFromInviteLinkMutationFn = Apollo.MutationFunction<CreateClientFromInviteLinkMutation, CreateClientFromInviteLinkMutationVariables>;

/**
 * __useCreateClientFromInviteLinkMutation__
 *
 * To run a mutation, you first call `useCreateClientFromInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientFromInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientFromInviteLinkMutation, { data, loading, error }] = useCreateClientFromInviteLinkMutation({
 *   variables: {
 *      createClientFromInviteLinkInput: // value for 'createClientFromInviteLinkInput'
 *   },
 * });
 */
export function useCreateClientFromInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientFromInviteLinkMutation, CreateClientFromInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientFromInviteLinkMutation, CreateClientFromInviteLinkMutationVariables>(CreateClientFromInviteLinkDocument, options);
      }
export type CreateClientFromInviteLinkMutationHookResult = ReturnType<typeof useCreateClientFromInviteLinkMutation>;
export type CreateClientFromInviteLinkMutationResult = Apollo.MutationResult<CreateClientFromInviteLinkMutation>;
export type CreateClientFromInviteLinkMutationOptions = Apollo.BaseMutationOptions<CreateClientFromInviteLinkMutation, CreateClientFromInviteLinkMutationVariables>;
export const UpdateClientFromInviteLinkDocument = gql`
    mutation UpdateClientFromInviteLink($updateClientFromInviteLinkInput: UpdateClientFromInviteLinkInput!) {
  UpdateClientFromInviteLink(
    updateClientFromInviteLinkInput: $updateClientFromInviteLinkInput
  ) {
    message
  }
}
    `;
export type UpdateClientFromInviteLinkMutationFn = Apollo.MutationFunction<UpdateClientFromInviteLinkMutation, UpdateClientFromInviteLinkMutationVariables>;

/**
 * __useUpdateClientFromInviteLinkMutation__
 *
 * To run a mutation, you first call `useUpdateClientFromInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientFromInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientFromInviteLinkMutation, { data, loading, error }] = useUpdateClientFromInviteLinkMutation({
 *   variables: {
 *      updateClientFromInviteLinkInput: // value for 'updateClientFromInviteLinkInput'
 *   },
 * });
 */
export function useUpdateClientFromInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientFromInviteLinkMutation, UpdateClientFromInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientFromInviteLinkMutation, UpdateClientFromInviteLinkMutationVariables>(UpdateClientFromInviteLinkDocument, options);
      }
export type UpdateClientFromInviteLinkMutationHookResult = ReturnType<typeof useUpdateClientFromInviteLinkMutation>;
export type UpdateClientFromInviteLinkMutationResult = Apollo.MutationResult<UpdateClientFromInviteLinkMutation>;
export type UpdateClientFromInviteLinkMutationOptions = Apollo.BaseMutationOptions<UpdateClientFromInviteLinkMutation, UpdateClientFromInviteLinkMutationVariables>;
export const UpdateUserUniqueLinkDocument = gql`
    mutation UpdateUserUniqueLink($updateUserInput: UpdateUserInput!) {
  updateUserUniqueLink(updateUserInput: $updateUserInput) {
    id
    uniqueLink
  }
}
    `;
export type UpdateUserUniqueLinkMutationFn = Apollo.MutationFunction<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>;

/**
 * __useUpdateUserUniqueLinkMutation__
 *
 * To run a mutation, you first call `useUpdateUserUniqueLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserUniqueLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserUniqueLinkMutation, { data, loading, error }] = useUpdateUserUniqueLinkMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserUniqueLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>(UpdateUserUniqueLinkDocument, options);
      }
export type UpdateUserUniqueLinkMutationHookResult = ReturnType<typeof useUpdateUserUniqueLinkMutation>;
export type UpdateUserUniqueLinkMutationResult = Apollo.MutationResult<UpdateUserUniqueLinkMutation>;
export type UpdateUserUniqueLinkMutationOptions = Apollo.BaseMutationOptions<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreateClientFromInviteLink: 'CreateClientFromInviteLink',
    UpdateClientFromInviteLink: 'UpdateClientFromInviteLink',
    UpdateUserUniqueLink: 'UpdateUserUniqueLink'
  }
}