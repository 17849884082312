/* eslint-disable */
import { useEffect } from 'react';
// import { LegacyAPI } from './api/';
// import { getBaseUrl } from './api/config';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './containers/Dashboard';
import Login from './containers/Login';
import Call from './containers/Call';
import InviteContainer from './containers/Invite';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import { useServiceWorker } from './useServiceWorker';
import PreviousUser from './components/atoms/PreviousUser';
import Signatures from './components/Signatures/Signatures';

const App = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  useEffect(() => {
    // temporal log out to delete old logged clients (cookies)
    // async function deleteOldSessionCookies() {
    //   try {
    //     await LegacyAPI({
    //       method: "GET",
    //       headers: {
    //         "x-requested-with": "XMLHttpRequest",
    //       },
    //       url: `${getBaseUrl()}/logout?isOldClientSession=true`,
    //     });
    //   } catch (error) {
    //     console.log('error when log out', error);
    //   }
    // }
    // deleteOldSessionCookies();

    if (isUpdateAvailable) updateAssets();
  }, [isUpdateAvailable]);
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/invite/activate">
            <InviteContainer />
          </Route>
          <Route exact path="/invite/:firmId/:preparerId">
            <InviteContainer />
          </Route>
          <Route exact path="/invite/:firmId">
            <InviteContainer />
          </Route>
          <Route
            exact
            path="/activate-client-account/:uniqueId"
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/invite/activate',
                  state: { from: props.location, params: props.match.params },
                }}
              />
            )}
          />
          <Route exact path="/forgot-password/">
            <ForgotPassword />
          </Route>
          <Route exact path="/forgot-password/:firmId">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset-password/:id">
            <ResetPassword />
          </Route>
          <Route path="/signatures/kba/:fileRequestId" >
            <Signatures />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/previousUser">
            <PreviousUser />
          </Route>
          <PrivateRoute path="/videocall/:meetingName" component={Call} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
