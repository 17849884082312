import React from 'react';
import { Empty, ConfigProvider } from 'antd';

const CustomizeRenderEmpty = (component, description, imageLinkForSvg) => (
  <ConfigProvider
    renderEmpty={() => (
      <Empty image={imageLinkForSvg} description={<span>{description}</span>} />
    )}
  >
    {component}
  </ConfigProvider>
);
export default CustomizeRenderEmpty;
