import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Input,
  Select,
  message,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  LeftOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  HourglassOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  CloseOutlined,
  PhoneOutlined,
  HomeOutlined,
  DesktopOutlined,
  CoffeeOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import API from '../../../api';
import { createDate, parseHoursRange } from '../../helpers/time';
import { Message } from '../../helpers/forms';
import styles from './style.module.css';
import './style.css';
import { getTimeZone } from '../../../helpers/time';
// import { getUpcomingAppointments } from '../../../redux/actions/appointments';
import {
  usePreparerEventTypesMutation,
  usePreparerAvailabilityMutation,
  useCreateEventMutation,
} from "../../../graphql/mutations/appointments";
import { MyEventsDocument } from "../../../graphql/queries/appointments";

const { Option } = Select;
const { TextArea } = Input;

const getIcon = (type) => {
  switch (type) {
    case 'VIDEO':
      return <DesktopOutlined />;
    case 'CALL':
      return <PhoneOutlined />;
    case 'HOME':
      return <HomeOutlined />;
    case 'OFFICE':
      return <CoffeeOutlined />;
    default:
      return <DesktopOutlined />;
  }
};

const renderIcon = (saved) => {
  if (saved === undefined) return <LoadingOutlined style={{ fontSize: 60 }} />;
  if (saved) return <CheckCircleOutlined className={styles.success} />;
  return <CloseCircleOutlined className={styles.error} />;
};

const ScheduleCall = ({
  evenTypesUser,
  organizerId,
  userId,
  closeSchedule,
  // channelName,
  organizerName,
  onSendMessage,
  dispatch,
}) => {
  const [step, setStep] = useState(1);
  const [scheduled, setScheduled] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [notes, setNotes] = useState('');
  const [eventType, setEventType] = useState();
  const [day, setDay] = useState(moment());
  const [brackets, setBrackets] = useState([]);
  const [hours, setHours] = useState('');
  const [saved, setSaved] = useState();
  const [type, setType] = useState();
  const { t: translation } = useTranslation();

  const updateNotes = ({ target: { value } }) => {
    setNotes(value);
  };

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };

  const selectEventType = (et) => {
    setEventType(et);
    nextStep();
  };

  const [preparerAvailability, { loading: loadingPreparerAvailability }] = usePreparerAvailabilityMutation({
    onError: (err) => message.error(err.message),
  });

  const selectDay = async (value) => {
    const momentDay = moment(value);
    setDay(momentDay);
    nextStep();
    const availability = eventType.EventTypeAvailability.find(
      (ETA) => ETA.day === momentDay.format('dddd').toUpperCase(),
    );

    const availabilityResult = await preparerAvailability({
      variables: {
        input: {
          availabilityBrackets:
            availability.EventTypeAvailabilityBracket.map((bracket) => ({...bracket, __typename: undefined })),
          day: momentDay.format("YYYY-MM-DD"),
          today: moment().toDate(),
          minNotice: eventType.minNotice,
          beforeBuffer: eventType.beforeBuffer,
          afterBuffer: eventType.afterBuffer,
          timezone: getTimeZone().large,
          preparerId: userId,
        }
      }
    });

    const Brackets = availabilityResult?.data.PreparerAvailability.brackets.sort(
      (a, b) => {
        const aName = Number(`${a.startHour}${a.startMinute === 0 ? '00' : a.startMinute}`);
        const bName = Number(`${b.startHour}${b.startMinute === 0 ? '00' : b.startMinute}`);
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      }
    );
    const EventsInDay = availabilityResult?.data.PreparerAvailability.eventsInDay;

    if (
      Brackets.length > 0
      && (!eventType.maxEvents || eventType.maxEvents > EventsInDay)
    ) {
      setBrackets(Brackets);
    } else {
      setStep((st) => st - 1);
      Message(translation('scheduleCall.error'), 'error');
    }
  };

  const selectHours = (value) => {
    setHours(value);
    nextStep();
  };

  const selectType = (et) => {
    setType(et);
    nextStep();
  };

  const getDate = (bracket) => {
    const start = new Date().setHours(bracket.startHour, bracket.startMinute);
    const end = new Date().setHours(bracket.endHour, bracket.endMinute);
    return `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
  };

  const disabledDate = ({ date }) => {
    const actualDate = moment().format();
    const currentDate = moment(date);

    if (
      currentDate.isBefore(actualDate)
      && !currentDate.isSame(actualDate, 'day')
    ) return true;
    return (
      eventType.EventTypeAvailability.findIndex(
        (ETAvailability) => ETAvailability.day === currentDate.format('dddd').toUpperCase()
          && ETAvailability.EventTypeAvailabilityBracket.length > 0,
      ) < 0
    );
  };

  const [createEventMutation] = useCreateEventMutation({
    onError: (err) => message.error(err.message),
    refetchQueries: [{ query: MyEventsDocument }],
    awaitRefetchQueries: true,
  });

  const returnEvent = async () => {
    const hoursDetails = parseHoursRange(hours);
    const { startHour, endHour } = hoursDetails;
    const startDate = createDate(day, startHour.hours, startHour.minutes);
    const endDate = createDate(day, endHour.hours, endHour.minutes);

    setScheduled(true);

    const saveResult = await createEventMutation({
      variables: {
        input: {
          title: organizerName,
          type,
          eventTypeId: eventType.id,
          clientId: userId,
          startDate,
          endDate,
          notes,
        }
      }
    });

    if (saveResult.data) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  };

  const [preparerEventTypesMutation, { loading: loadingPreparerEventTypes}] = usePreparerEventTypesMutation({
    onError: (err) => message.error(err.message),
  });

  useEffect(() => {
    const loadEventType = async () => {
      const eventTypesResult = await preparerEventTypesMutation({
        variables: {
          id: evenTypesUser,
        }
      })
      
      setEventTypes(eventTypesResult.data?.PreparerEventTypes);
    };

    if (evenTypesUser && organizerId) loadEventType();
  }, [evenTypesUser]);

  const renderStep = () => {
    const timezone = getTimeZone();

    switch (step) {
      case 1:
        return (
          <div className={styles.containerStep1}>
            <p className={styles.title}>
              {translation('scheduleCall.scheduleCall')}
            </p>
            {loadingPreparerEventTypes
            ? <Spin size="large" />
            : eventTypes.map((et) => (
              <Button key={et.id} onClick={() => selectEventType(et)}>
                {et.name}
              </Button>
            ))}
            {/* if there are no event types, display message */}
            {!loadingPreparerEventTypes && eventTypes.length === 0 && (
              <p className={styles.noEventTypes}>
                {translation('scheduleCall.noEventTypes')}
              </p>
            )}
          </div>
        );
      case 2:
        return (
          <div className={styles.containerStep4}>
            <p className={styles.title}>{eventType.name}</p>
            <Select
              value={type}
              placeholder="Meeting Type"
              style={{ width: '85%', marginTop: 10 }}
              onChange={selectType}
            >
              <Option value="VIDEO">Video</Option>
              <Option value="CALL">Phone Call</Option>
              <Option value="OFFICE">Office</Option>
            </Select>
          </div>
        );
      case 3:
        return (
          <div className={styles.containerStep2}>
            <p className={styles.title}>{eventType.name}</p>
            <TextArea
              onChange={updateNotes}
              placeholder={translation('scheduleCall.notes')}
              className={styles.textArea}
              rows={4}
            />
            {/* <p
              className={notes ? styles.skipDisabled : styles.skip}
              onClick={notes ? null : nextStep}
            >
              {translation('scheduleCall.skipNotes')}
            </p> */}
            <Button onClick={nextStep}>
              {translation('scheduleCall.next')}
            </Button>
          </div>
        );
      case 4:
        return (
          <div className={styles.containerStep3}>
            <p className={styles.title}>{eventType.name}</p>
            <Calendar
              value={new Date()}
              onChange={selectDay}
              tileDisabled={disabledDate}
            />
          </div>
        );
      case 5:
        return (
          <div className={styles.containerStep4}>
            <p className={styles.title}>{eventType.name}</p>
            <div>
              <div className={styles.contLabel}>
                {getIcon(type)}
                <p style={{ textTransform: 'capitalize', paddingLeft: '10px' }}>{type.toLocaleLowerCase()}</p>
              </div>
              <div className={styles.contLabel}>
                <CalendarOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.date')}: ${day.format(
                    'ddd MMM DD, YYYY',
                  )}`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <ClockCircleOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.duration')}: ${eventType.duration
                  } min`}
                </p>
              </div>
            </div>
            <Select
              showSearch
              style={{ width: '85%', marginTop: 10 }}
              placeholder={translation('scheduleCall.selectOption')}
              optionFilterProp="children"
              onChange={selectHours}
              loading={loadingPreparerAvailability}
            >
              {brackets.map((opt) => (
                <Option key={opt.toString()} value={getDate(opt)}>
                  {`${getDate(opt)} ${timezone.short}`}
                </Option>
              ))}
            </Select>
          </div>
        );
      case 6:
        return (
          <div className={styles.containerStep5}>
            <p className={styles.title}>{eventType.name}</p>
            <div>
              <div className={styles.contLabel}>
                {getIcon(type)}
                <p style={{ textTransform: 'capitalize', paddingLeft: '10px' }}>{type.toLocaleLowerCase()}</p>
              </div>
              <div className={styles.contLabel}>
                <CalendarOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.date')}: ${day.format(
                    'ddd MMM DD, YYYY',
                  )}`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <ClockCircleOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.duration')}: ${eventType.duration
                  } min`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <HourglassOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${hours} ${timezone.short}`}
                </p>
              </div>
            </div>
            {!scheduled ? (
              <Button onClick={returnEvent}>
                {translation('scheduleCall.scheduleCall')}
              </Button>
            ) : (
              <>
                {renderIcon(saved)}
                <p className={styles.successtxt}>
                  {translation('scheduleCall.callScheduled')}
                </p>
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  if (!organizerId || !userId || !evenTypesUser) return <div />;

  return (
    <div className={styles.container}>
      <LeftOutlined
        className={styles.left}
        onClick={() => (step === 1 ? closeSchedule() : prevStep())}
      />
      <CloseOutlined className={styles.right} onClick={closeSchedule} />
      {renderStep()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

ScheduleCall.propTypes = {
  evenTypesUser: PropTypes.number.isRequired,
  organizerId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  closeSchedule: PropTypes.func.isRequired,
  // channelName: PropTypes.string.isRequired,
  organizerName: PropTypes.string.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(ScheduleCall));
