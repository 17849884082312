/* eslint-disable */
import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Button from "../Button/Button";
import { color, grey } from "../../styles/colors";
import { Lato } from "../../styles/fonts";

import Translation from "../atoms/Translation";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { isMobileView } from "../../utils/screen";

const PageTitle = ({ customBranding = null }) => {
  const { t: translation } = useTranslation();

  return (
    <div className={css(styles.pageTitle)}>
      <div className={css(styles.inner)}>
        <span className={css(styles.type)}>
          <Link to="/">
            <Translation text={"header.yourProjects"} />
          </Link>
        </span>
        <span className={css(styles.action)}>
          <Link to="/add-project">
            <Button
              create
              label={<Translation text={"header.createProject"} />}
              style={{ paddingRight: "0" }}
            />
            <Tooltip
              title={translation('header.createProject.tooltip')}
            >
              <InfoCircleOutlined
                style={{
                  fontSize: 12,
                  position: 'relative',
                  top: '-1em',
                  cursor: 'pointer',
                  left: isMobileView() ? '1em' : undefined,
                }}
              />
            </Tooltip>
          </Link>
        </span>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    display: "block",
    minWidth: "300px",
    margin: "0 auto",
    maxWidth: "1220px",
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    margin: 0,
    marginTop: "35px",
    padding: "0px 20px 16px",
    width: "100%",
    "@media (min-width: 640px)": {
      padding: "0px 20px 5px",
    },
    "@media (min-width: 1024px)": {
      padding: "0px 40px 5px",
    },
  },
  type: {
    display: "inline-block",
    position: "relative",
    marginBottom: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "24px",
    color: grey["800"],
    "@media (min-width: 640px)": {
      marginBottom: 0,
      marginRight: "60px",
    },
    ":after": {
      position: "absolute",
      content: '""',
      display: "block",
      top: "-12px",
      left: 0,
      width: "50px",
      height: "3px",
      backgroundColor: color.green,
    },
  },
});

export default PageTitle;