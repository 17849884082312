import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Page from '../components/Layout/Page';
import Header from '../components/Layout/Header';
import ForgotPassword from '../components/Pages/ForgotPassword';
import { useGetCustomDomainByNameLazyQuery } from '../graphql/queries/customDomain';

const ForgotPasswordContainer = () => {

  const [customLayout, setCustomLayout] = useState(null);

  // fetch CustomLayout by custom domain
  const [fetchCustomLayout, { data: customLayoutData }] = useGetCustomDomainByNameLazyQuery();

  const { origin } = window.location;
  useEffect(() => {
    if (origin && process.env.REACT_APP_TAXAROO_CLIENT_URL) {
      if (origin !== process.env.REACT_APP_TAXAROO_CLIENT_URL) {
        // fetch the CustomLayout
        const theDomain = origin.replace('https://', '').replace('http://', '');
        fetchCustomLayout({
          variables: { name: theDomain },
          onCompleted: (d) => {
            setCustomLayout(d?.CustomDomainByName?.FirmAccount?.CustomLayout || null)
          },
          onError: () => {
            setCustomLayout(null);
          }
        });
      }
    }
  }, [origin]);

  return (
    <Page customStyles={null}>
      <Header data={null} customLogo={customLayout?.logo} customStyles={customLayout} />
      <ForgotPassword />
    </Page>
  );
};

const mapStateToProps = ({ profile: { branding } }) => ({
  branding,
});

ForgotPasswordContainer.defaultProps = {};

ForgotPasswordContainer.propTypes = {};

export default connect(mapStateToProps)(ForgotPasswordContainer);
