/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { StyleSheet, css } from "aphrodite";
import { Row, Col, Grid, message } from 'antd'
import Translation from "./atoms/Translation";

import { Icon } from "./Icon/Icon";
import Button from "./Button/Button";
import { grey } from "../styles/colors";
import { Lato } from "../styles/fonts";
import { getFileUrl } from "../utils/getFileUrl";
import { TaxYearStatus } from '../graphql';
import { Features, usePlanAccess } from "./atoms/PlanAccessProvider";

const mapStateToProps = (state) => ({
  channels: state.channels, // todo: get channels from graphql
});

const ContactInfo = (props) => {
  const [name, setName] = useState(props.name ? props.name : null);
  const [phone, setPhone] = useState(props.phone ? props.phone : null);
  const [email, setEmail] = useState(props.email ? props.email : null);
  const [projectId, setProjectId] = useState(props.projectId ? props.projectId : null);
  const [reviewText, setReviewText] = useState(props.review ? "contactinfo.changeReview" : "contactinfo.leaveReview")
  const [status, setStatus] = useState(props.status ? props.status : null);
  const [firm, setFirm] = useState(props.firm ? props.firm : { isHappyTax: false });
  const [company, setCompany] = useState(props.company ? props.company : null);
  const [avatarURL, setAvatarURL] = useState(null);

  const { review, channelId } = props

  const { accessObject } = usePlanAccess();

  const getAvatarURL = async () => {
    const avatar = props.avatar ? props.avatar : null;
    const fileUrl = await getFileUrl(avatar);
    setAvatarURL(fileUrl)
  }

  useEffect(() => {
    getAvatarURL()
  }, []);

  return (
    <div className={css(styles.infoCard)}>
      <Row>
        {firm && avatarURL && (
          <div className={css(styles.avatarHolder)}>
            <img
              src={avatarURL}
              alt=""
              className={css(styles.avatar)}
            />
          </div>
        )}
        <Col className={css(avatarURL && styles.withAvatar)}>
          {/* {firm && firm.isHappyTax && (
            <div className={css(styles.avatarHolder)}>
              <img src={happyLogo} alt="" className={css(styles.avatar)} />
            </div>
          )} */}
          {firm && !firm.isHappyTax && name && (
            <span className={css(styles.name)}>{name}</span>
          )}
          {/* {firm && firm.isHappyTax && (
            <span className={css(styles.name)}>Happy Tax</span>
          )} */}
          {name && firm && company && (
            <div className={css(styles.company)}>{company}</div>
          )}

          {status === TaxYearStatus.Completed && review && (
            <div className={css(styles.rateHolder)}>
              <span className={css(styles.text)}>
                <Translation text={"contactinfo.yourReview"} />
              </span>
              {[0, 1, 2, 3, 4].map((icon, i) => (
                <Icon key={i} star={review.reviewValue > i ? "filled" : true} />
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Row>
        {avatarURL && <Col  className={css(styles.fakeColWithAvatar)} />}
        <Col className={css(styles.info, avatarURL && ( styles.nameWithAvatar, styles.infoWithAvatar ) )}>
          {firm && phone && (
            <span className={css(styles.phone)}>
              <Icon phone />
              <a href={`tel:${phone}`} className={css(styles.infoText)}>
                {phone}
              </a>
            </span>
          )}

          {email && firm && (
            <span className={css(styles.email)}>
              <Icon email />
              <a href={`mailto:${email}`} className={css(styles.infoText)}>
                {email}
              </a>
            </span>
          )}

          {firm && channelId && !props.disableTaxarooCalendar && accessObject?.[Features.appointments] && (
            <span className={css(styles.appointment)}>
              <Icon
                calendarOutlined
                onClick={() => {
                  props.history.push(`/schedule/appointment/${channelId}`);
                }}
              />
              <Link to={`/schedule/appointment/${channelId}`}>
                <span className={css(styles.infoText)}>
                  <Translation text={"contactinfo.scheduleAnAppointment"} />
                </span>
              </Link>
            </span>
          )}

          {/* {firm && firm.isHappyTax && (
            <span className={css(styles.email)}>
              <span className={css(styles.infoText)}>
                <Translation text={"contactinfo.taxQuestions"} />:
                <a href="mailto:tax@happytaxonline.com">
                  tax@happytaxonline.com
                </a>
              </span>
            </span>
          )} */}

          {/* {firm && firm.isHappyTax && firm.referredByUserEmail && (
            <span className={css(styles.email)}>
              <span className={css(styles.infoText)}>
                <Translation text={"contactinfo.yourRepresentative"} />:
                <a href={`mailto:${firm.referredByUserEmail}`}>
                  {firm.referredByUserEmail}
                </a>
              </span>
            </span>
          )} */}

          {status === TaxYearStatus.Completed && accessObject?.[Features.reviews] && (
            <Link to={`/add-review/${projectId}`}>
              <Button label={<Translation text={reviewText} />} text />
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );
}

const styles = StyleSheet.create({
  fakeColWithAvatar: {
    width: "100px",
    '@media only screen and (max-width: 1100px)': {
      width: "0px",
    },
    '@media only screen and (max-width: 767px)': {
      width: "100px",
    },
    '@media only screen and (max-width: 400px)': {
      width: "0px",
    },
  },
  infoCard: {
    // display: "flex",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  infoWithAvatar: {
    marginTop: "-10px",
    '@media only screen and (max-width: 1100px)': {
      marginTop: "15px",
    },
    '@media only screen and (max-width: 767px)': {
      marginTop: "-10px",
    },
    '@media only screen and (max-width: 400px)': {
      marginTop: "15px",
    },
  },
  withAvatar: {
    flexBasis: "calc(100% - 100px)",
  },
  nameWithAvatar: {
    flexBasis: "calc(100% - 100px)",
    '@media only screen and (max-width: 1100px)': {
      flexBasis: "100%",
    },
    '@media only screen and (max-width: 767px)': {
      flexBasis: "calc(100% - 100px)",
    },
    '@media only screen and (max-width: 400px)': {
      flexBasis: "100%",
    },
  },
  avatarHolder: {
    width: "76px",
    height: "76px",
    marginRight: "24px",
    borderRadius: "50%",
    backgroundColor: grey["100"],
    overflow: "hidden",
  },
  avatar: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  name: {
    marginBottom: "5px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.6px",
    color: grey["800"],
  },
  company: {
    marginBottom: "10px",
    marginTop: "5px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.6px",
    color: grey["800"],
  },
  phone: {
    marginBottom: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "16px",
    letterSpacing: "0.6px",
    color: grey["800"],
  },
  appointment: {
    marginBottom: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "16px",
    letterSpacing: "0.6px",
    color: grey["800"],
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
  },
  appointmentCalendar: {
    marginLeft: "11px",
  },
  email: {
    marginBottom: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "16px",
    letterSpacing: "0.6px",
    color: grey["800"],
  },
  infoText: {
    marginLeft: "16px",
    textDecoration: "none",
    wordBreak: "break-all",
    color: grey["800"],
    "@media (min-width: 640px)": {
      marginLeft: "20px",
    },
  },
  rateHolder: {
    marginBottom: "10px",
  },
  text: {
    display: "inline-block",
    marginRight: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "12px",
    letterSpacing: "0.4px",
    opacity: "0.8",
    color: grey["800"],
  },
});

export default withRouter(connect(mapStateToProps)(ContactInfo));