import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import reduxStore from '../redux/store/store';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';
import * as config from '../api/config';
import store from '../redux/store/store';

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const link = createHttpLink({
  uri,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
  },
  fetch,
});

const authLink = setContext((_, { headers }) => {
  // const { accessToken } = store.getState().session;
  // console.log('authLink:', reduxStore.store.getState());
  let accessToken = null;
  if (reduxStore.store.getState()?.session?.graphSession) {
    accessToken = reduxStore.store.getState()?.session?.graphSession?.accessToken;
  }
  // console.log('accessToken::', accessToken);

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const logoutLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length) {
    /*
      * IMPORTANT: I am using any, because the typing of the variableGraphQLErrors did not detect
      * the status property, which is the one we need to handle the user's logout.
    */
    const error: any = graphQLErrors[0];

    if (error.status === 401) {
      // check if the token has expired or not
      const accessToken = store.store.getState()?.session?.graphSession?.accessToken;
      if (accessToken) {
        axios.get(`${config.serverUrl}/account/isTokenExpired?token=${accessToken}`)
          .then((res) => {
            if (res.data) {
              localStorage.clear();
              window.location.reload();
            }
          })
          .catch((err) => {
            localStorage.clear();
            window.location.reload();
          });
      }
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // link: authLink.concat(link).concat(uploadLink),
  link: from([authLink, logoutLink, link]),
});

export default client;
