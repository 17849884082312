import { FieldType } from '../../../../graphql';
import './field.css'
import DateField from './DateField';
import SignatureField from './SignatureField';
import TextField from './TextField';

const Field = ({
  signatureField,
  fileWidth,
  fileHeight,
  complete,
  signature,
  title,
  description,
  handleFieldClick,
  pendingSubmit,
  completedAt,
  transformation,
  isMobileView,
}) => {
  const {
    fieldTopPercentage,
    fieldLeftPercentage,
    fieldWidthPercentage,
    fieldHeightPercentage,
    fieldType,
    id,
  } = signatureField;
  let top = (fieldTopPercentage / 100) * (fileHeight);
  let left = (fieldLeftPercentage / 100) * (fileWidth);
  let height = (fieldHeightPercentage / 100) * fileHeight;
  let width = (fieldWidthPercentage / 100) * fileWidth;

  // if mobile, apply transformations
  if (isMobileView) {
    left *= transformation;
    top *= transformation;
    height *= transformation;
    width *= transformation;
  }

  if (fieldType === FieldType.Date) {
    return (
      <DateField
        id={id}
        top={top}
        left={left}
        height={height}
        width={width}
        handleFieldClick={handleFieldClick}
        complete={complete}
        pendingSubmit={pendingSubmit}
        completedAt={completedAt}
        transformation={transformation}
        isMobileView={isMobileView}
      />
    );
  }

  if (fieldType === FieldType.Signature) {
    return (
      <SignatureField
        id={id}
        top={top}
        left={left}
        height={height}
        width={width}
        handleFieldClick={handleFieldClick}
        complete={complete}
        signature={signature}
        pendingSubmit={pendingSubmit}
        transformation={transformation}
        isMobileView={isMobileView}
      />
    );  
  }

  return (
    <TextField
      id={id}
      top={top}
      left={left}
      height={height}
      width={width}
      handleFieldClick={handleFieldClick}
      complete={complete}
      title={title}
      description={description}
      pendingSubmit={pendingSubmit}
      transformation={transformation}
      isMobileView={isMobileView}
    />
  );
}

export default Field;
