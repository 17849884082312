import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReferralsByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type GetReferralsByUserIdQuery = { __typename?: 'Query', referralsByUserId: Array<{ __typename?: 'UserEntity', id: string, referredById?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } | null }> };


export const GetReferralsByUserIdDocument = gql`
    query GetReferralsByUserId($userId: String!) {
  referralsByUserId(userId: $userId) {
    id
    referredById
    UserInformation {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetReferralsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetReferralsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReferralsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>(GetReferralsByUserIdDocument, options);
      }
export function useGetReferralsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>(GetReferralsByUserIdDocument, options);
        }
export type GetReferralsByUserIdQueryHookResult = ReturnType<typeof useGetReferralsByUserIdQuery>;
export type GetReferralsByUserIdLazyQueryHookResult = ReturnType<typeof useGetReferralsByUserIdLazyQuery>;
export type GetReferralsByUserIdQueryResult = Apollo.QueryResult<GetReferralsByUserIdQuery, GetReferralsByUserIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetReferralsByUserId: 'GetReferralsByUserId'
  }
}