import PropTypes from 'prop-types';
import SettingsLayout from '../Layout';

const SettingsContainer = ({ userId, dispatch }) => {
  if (!userId) return null;

  return (
    <SettingsLayout
      dispatch={dispatch}
      userId={userId}
    />
  );
};

SettingsContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SettingsContainer;
