/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Button from "./Button/Button";
import { Icon } from "./Icon/Icon";
import { color } from "../styles/colors";
import { heading } from "../styles/fonts";
import Translation from "./atoms/Translation";
import i18n from 'i18next';
import "./css/dropdown.css"

import { TaskType } from '../graphql';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: !!props.open,
      isHovered: false,
    };
  }

  onMove(props) {
    // console.log('props', props);
    const { actionType, taskId, fileApprovalId } = props;
    const { completed, projectId, history } = this.props;

    if (actionType === TaskType.Signatures || actionType === TaskType.ApproveDocuments) {
      history.push(`/signatures/kba/${fileApprovalId}`);
    } else if (actionType === TaskType.RequestDocuments) {
      history.push(`/${TaskType.Documents.toLowerCase()}/${projectId}${!completed ? `?taskId=${taskId}` : ''}`);
    } else {
      history.push(`/${actionType.toLowerCase()}/${projectId}${!completed ? `?taskId=${taskId}` : ''}`);
    }
  }

  render() {
    const { open, items } = this.props;
    const { isOpened, isHovered } = this.state;
    const dropdownTitle = open ? "project.yourOpenTasks" : "project.completedTasks";

    const onClick = (item) => {
      this.onMove.call(
        this,
        item.actionType
          ? { actionType: item.actionType, taskId: item.id, fileApprovalId: item?.FileApproval?.id }
          : { actionType: item.taskType, taskId: item.id, fileApprovalId: item?.FileApproval?.id }
      );
    };

    return (
      <div>
        {items && items.length > 0 && (
          <div
            className={css(styles.header)}
            onClick={() => this.setState({ isOpened: !isOpened })}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <span className={css(styles.headerText)}>
              <Translation text={dropdownTitle} />
            </span>
            <Icon arrow down={!isOpened} hovered={isHovered} />
          </div>
        )}
        <div className={css(styles.drop, isOpened && styles.open) + " custom-scroll"}
             style={{height: this.props.items.length > 3 ? "283px" : this.props.items.length * 72 +'px'}}
        >
          {items &&
            items.length > 0 &&
            items.map((item) => {
              const title = item.RequestedFiles || item.FileApproval
                ? `${i18n.t('taskType.' + item.taskType)}: ${item?.RequestedFiles?.fileName || item?.FileApproval?.FileRequests?.title || i18n.t('documents.clientDocuments.fileNameIsEmpty')}`
                : i18n.t('taskType.' + item.taskType);

              return (
                <Button
                  label={<Translation text={title} />}
                  card
                  activeTask={!!open}
                  key={item.id}
                  style={{ marginBottom: "8px" }}
                  onClick={() => onClick(item)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    margin: "16px 0",
    padding: "4px 0",
    cursor: "pointer",
    transition: "0.2s ease",
    ":hover span": {
      color: color.blue,
    },
  },
  headerText: {
    ...heading.h3,
    display: "inline-block",
    marginRight: "14px",
  },
  drop: {
    maxHeight: 0,
    overflowY: "auto",
    overflowX: "hidden",
    transition: "0.4 ease-in-out",
    scrollBarWidth: "none"
  },
  open: {
    maxHeight: "800px",
    margin: "-7px",
    padding: "7px",
    transition: "0.4 ease-in-out",
  },
});

export default withRouter(Dropdown);