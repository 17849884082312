import React, { useState } from 'react';
import axios from 'axios';
import { saveAs as saveFileFromUrl } from 'file-saver';
import PropTypes from 'prop-types';
import { Button, Progress } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.min.css';

const DownloadFile = ({ getUrl, url, name }) => {
  const { t: translation } = useTranslation();
  const [downloadState, setDownloadState] = useState(false);
  const [percentState, setPercentState] = useState(0);
  const [error, setError] = useState(null);

  const download = async () => {
    const urlF = url || await getUrl();
    setDownloadState(true);
    axios.get(urlF, {
      responseType: 'blob',
      onDownloadProgress(e) {
        setPercentState(Math.ceil((e.loaded / e.total) * 100));
      },
    })
      .then((response) => {
        const file = new File([response.data], name);
        saveFileFromUrl(file, name);
        setDownloadState(false);
        setPercentState(0);
        setError(null);
      })
      .catch((err) => {
        if (err) {
          setError(translation('chat.downloadFile.error'));
        }
      });
  };
  return (
    <>
      {downloadState && !error && (
        <div>
          <Progress percent={percentState} size="small" />
        </div>
      )}
      {!downloadState && !error && (
        <Button type="default" icon={<DownloadOutlined />} size="middle" onClick={download} />
      )}
      {error && (
        <p>{error}</p>
      )}
    </>
  );
};

DownloadFile.defaultProps = {
  getUrl: () => {},
};

DownloadFile.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getUrl: PropTypes.func,
};

export default DownloadFile;
