import { Form } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const { useForm } = Form;

const useEditSection = (section, taxYearInterviewId) => {
  const form = useForm();
  const projects = useSelector((state) => state.projects.projects);
  const getJob = () => projects?.find((x) => x.id === taxYearInterviewId) ?? {};

  const currentStoredAnswers = useMemo(() => {
    const job = getJob();
    return job.Answers ?? [];
  }, [getJob().Answers?.length]);

  const processQuestions = (inputSection) => {
    let answers = inputSection.Questions?.reduce((acc, question) => {
      let answer = currentStoredAnswers.find(({ questionId }) => questionId === question.id)?.description ?? '';
      if (question.type === 'DATE') {
        const date = moment(answer);
        answer = date.isValid() ? date : '';
      }
      return {...acc, [question.id]: answer};
    }, {}) ?? {};

    const subsections = inputSection.SubSections ?? [];
    if (subsections.length > 0) {
      subsections.forEach((subsection) => {
        answers = {...answers, ...processQuestions(subsection)};
      });
    }

    return answers;
  };

  const getInitialValues = () => [section].reduce(
    (acc, currSection) => ({...acc, ...processQuestions(currSection)}), {}
  );

  return {
    getJob,
    getInitialValues,
    currentStoredAnswers,
    sectionForm: form[0] ?? {},
  };
};

export default useEditSection;