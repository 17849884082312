import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, List } from 'antd';
import UserCall from '../UserCall';

const UserCallList = ({ data, visible, onClose }) => (
  <div>
    <Drawer
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      width={350}
      title="Call Members"
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
    >
      <List
        size="small"
        header={<div />}
        footer={<div />}
        bordered={false}
        dataSource={data}
        renderItem={(item) => (
          <UserCall name={item.name} avatar={item.avatar} />
        )}
      />
    </Drawer>
  </div>
);
UserCallList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default UserCallList;
