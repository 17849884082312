import { useState } from 'react';
import {
  Modal,
  Checkbox,
  Col,
  Row,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Translation from '../../../atoms/Translation';
import { updateTasks } from '../../../../api/tasks';
import { getProjects } from '../../../../redux/actions/projects';
import { useUpdateTaskMutation } from '../../../../graphql/mutations/tasks';

const SelectTaskModal = (props) => {
  const {
    setShowSelectTaskModal,
    openDocReqTasks,
    numDocsUploaded,
    dispatch,
  } = props;
  const { t: translation } = useTranslation();
  const [selectedBoxs, setSelectedBoxs] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);

  const [updateTask] = useUpdateTaskMutation();

  // eslint-disable-next-line arrow-body-style
  const updateTaskFunction = async (taskId, completedAtDate) => {
    return updateTask({
      variables: {
        updateTaskInput: {
          id: taskId,
          completedAt: completedAtDate,
        }
      }
    })
  }

  const handleCancel = () => {
    setShowSelectTaskModal(false);
  };

  const onChange = (e, taskId) => {
    const tmpBoxes = selectedBoxs;
    const index = tmpBoxes.indexOf(taskId);
    if (!e.target.checked) { // if an uncheck
      // remove the task from the list of checked
      tmpBoxes.splice(index, 1);
      setSelectedBoxs([...tmpBoxes]);
      return;
    }
    if (index > -1) { // if task is already selected (unselect)
      tmpBoxes.splice(index, 1);
      setSelectedBoxs([...tmpBoxes]);
    } else if (tmpBoxes.length < numDocsUploaded) { // There is room to select more (select)
      setSelectedBoxs([...tmpBoxes, taskId]);
    } else { // There is no more room (unselect, and select)
      tmpBoxes.splice(0, 1);
      tmpBoxes.push(taskId);
      setSelectedBoxs([...tmpBoxes]);
    }
  };

  const handleSave = async () => {
    setLoadingSave(true);
    const tempBoxes = selectedBoxs;
    const otherIndex = tempBoxes.indexOf('other');
    if (otherIndex > -1) {
      tempBoxes.splice(otherIndex, 1);
    }
    if (tempBoxes.length < 1) { // 'other' must have been only box checked
      // message.success(`${translation('settings.message.success')}`, 5);
      setLoadingSave(false);
      setShowSelectTaskModal(false);
      return;
    }
    // use api to update
    const now = new Date();
    const promises = [];
    for (const taskId of tempBoxes) {
      promises.push(updateTaskFunction(taskId, now));
    }
    try {
      await Promise.all(promises);
      message.success(`${translation('settings.message.success')}`);
    } catch (error) {
      console.log('error:', error);
      message.error(error);
    } finally {
      setLoadingSave(false);
      setShowSelectTaskModal(false);
    }
  };

  return (
    <Modal
      title={`${translation('documents.clientDocuments.whichDocTitle')}`}
      cancelButtonProps={{ hidden: true }}
      okText={`${translation('words.save')}`}
      okButtonProps={{ disabled: selectedBoxs.length <= 0, loading: loadingSave }}
      onOk={handleSave}
      onCancel={handleCancel}
      visible
      closable
      maskClosable={false}
    >
      <p><Translation text="documents.clientDocuments.whichDocUploaded" /></p>
      <Col>
        {openDocReqTasks.map((item) => {
          const taskTitle = item.RequestedFiles
          ? item?.RequestedFiles?.fileName
          : item.taskType;
          const index = selectedBoxs.indexOf(item.id);
          return (
            <Row key={item.id}>
              <Checkbox
                onChange={(e) => onChange(e, item.id)}
                checked={index > -1}
              >
                {taskTitle}
              </Checkbox>
            </Row>
          );
        })}
        <Row>
          <Checkbox
            onChange={(e) => onChange(e, 'other')}
            checked={selectedBoxs.indexOf('other') > -1}
          >
            {translation('words.other')}
          </Checkbox>
        </Row>
      </Col>
    </Modal>
  );
};

SelectTaskModal.propTypes = {
  setShowSelectTaskModal: PropTypes.func.isRequired,
  openDocReqTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  numDocsUploaded: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SelectTaskModal;
