import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import datePickerES from 'date-fns/locale/es';
import en from './en';
import es from './es';

registerLocale('es', datePickerES);

const lang = localStorage.getItem('lang') || 'en';

if (lang !== 'en') {
  setDefaultLocale(lang);
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      es,
    },
    fallbackLng: ['en', 'es'],
    lng: lang,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
