/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { useHistory } from "react-router-dom";
import { clearSession } from '../../redux/actions/session';
import { Icon } from "../Icon/Icon";
import { color, grey } from "../../styles/colors";
import { LatoBold } from "../../styles/fonts";
import Translation from "../atoms/Translation";
import { useGetCustomLayoutsQuery } from "../../graphql/queries/settings";
import { trackSignOut, setWoopraIdentityFromStorage } from "../helpers/woopra";

const Menu = connect(null)(({ items, dispatch, menuOpened, setMenuOpened, menuRef }) => {
  // todo: fix this null client thing
  let client = null;
  const history = useHistory();
  const previousUserUrl = localStorage.getItem('previousUser');
  const [listening, setListening] = useState(false);

  // this is to close the menu when the user clicks outside the menu
  function listenForOutsideClick(listening, setListening, menuRef, setMenuOpened) {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef.current?.contains(evt.target)) return;
          setMenuOpened(false);
        });
      });
    }
  }
  useEffect(listenForOutsideClick(
    listening,
    setListening,
    menuRef,
    setMenuOpened,
  ));

  const logout = async (e) => {
    trackSignOut();
    e.preventDefault();
    await dispatch(clearSession());
    history.replace({ pathname: "/login" });
  };

  return (
    <ul className={css(menu.menuList, menuOpened && menu.menuListOpen)}>
      {items &&
        items.map((item, index) => (
          <li key={`${index}`}>
            <Link
              data-cy={`link-${item.label}`}
              to={item.link}
              className={css(menu.menuItem)}
            >
              {<Translation text={item.label} />}
            </Link>
          </li>
        ))}
      <a onClick={logout} className={css(menu.menuItem)}>
        <Translation text="header.logout" />
      </a>
      {/* todo: we need this originalUser varaible */}
      {((client && client.originalUser) || (previousUserUrl)) && (
        <a
          href="/previousUser"
          className={css(menu.menuItem)}
        >
          <Translation text="header.previousUser" />
        </a>
      )}
    </ul>
  );
});


const Header = (props) => {
  const { logo, isTaxPro, isUserLoggedIn, session, user, customStyles } = props;
  const firstName = user?.firstName ?? session?.graphSession?.firstName;
  const lastName = user?.lastName ?? session?.graphSession?.lastName;
  const accessToken = session?.graphSession?.accessToken;
  const { data: customLayoutData, loading: loadingCustomLayoutQuery } = useGetCustomLayoutsQuery();
  const { CustomLayout } = customLayoutData || {};
  const {
    headerBackgroundColor,
    headerFontColor,
  } = CustomLayout || customStyles || {};

  const [menuOpened, setMenuOpened] = useState(false);

  if (accessToken) {
    setWoopraIdentityFromStorage();
  }

  const menuRef = useRef(null);

  let logoLinkDest = "/";

  const changeFavicon = (src) => {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = src;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const menuItems = isTaxPro ? [] : [{ label: "header.settings", link: "/settings" }];

  logo && changeFavicon(logo);

  const headerCustom = StyleSheet.create({
    custom: {
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
      backgroundColor: headerBackgroundColor,
      color: headerFontColor,
    },
  });
  const titleStyles = StyleSheet.create({
    title: {
      display: "none",
      marginLeft: "10px",
      fontFamily: [LatoBold, "sans-serif"],
      fontWeight: "bold",
      fontSize: "16px",
      color: headerFontColor || grey["800"],
      "@media (min-width: 400px)": {
        display: "inline-block",
      },
    },
  });

  return (
    <div
      className={css(
        headerBackgroundColor || headerFontColor
          ? headerCustom.custom
          : styles.header
      )}
    >
      <div className={css(styles.container)}>
        <ul className={css(styles.headerHolder)}>
          <li className={css(styles.logoHolder)}>
            {logo &&
              (isTaxPro ? (
                <img
                  src={logo}
                  alt="Site Logo"
                  className={css(styles.img)}
                  style={{ width: "auto" }}
                />
              ) : (
                <Link to={logoLinkDest} style={{ width: '100%' }}>
                  <img
                    src={logo}
                    alt="Site Logo"
                    className={css(styles.img)}
                    style={{ width: "auto" }}
                  />
                </Link>
              ))}
          </li>
          {isUserLoggedIn ? (
            <li
              data-cy="userMenuHeader"
              className={css(styles.menuHolder)}
              onClick={() => setMenuOpened(!menuOpened)}
              ref={menuRef}
            >
              <Icon user />
              <span className={css(titleStyles.title)}>
                {(firstName || lastName) ? `${firstName} ${lastName}` : '...'}
              </span>
              <Icon
                arrow
                down={!menuOpened}
                hovered
                style={{ marginLeft: "10px" }}
              />
              <Menu
                items={menuItems}
                menuOpened={menuOpened}
                setMenuOpened={setMenuOpened}
                menuRef={menuRef}
              />
            </li>
          ) : (
            <Link className={css(titleStyles.title)} to="/login"><Translation text={'header.signIn'} /></Link>
          )}
        </ul>
      </div>
    </div>
  );
}


Header.defaultProps = {
  isTaxPro: false,
};

Header.propTypes = {
  data: PropTypes.object,
  isTaxPro: PropTypes.bool,
  dispatch: PropTypes.func,
  customLogo: PropTypes.string
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: color.white,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
  },
  headertest: {
    backgroundColor: "blue",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
  },
  container: {
    display: "block",
    minWidth: "300px",
    margin: "0 auto",
    maxWidth: "1220px",
  },
  headerHolder: {
    display: "flex",
    flexDirection: "row",
    height: "inherit",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 0,
    padding: "0px 20px",
    width: "100%",
    "@media (min-width: 1024px)": {
      padding: "0px 40px",
    },
    li: {
      display: "flex",
      alignItems: "center",
    },
  },
  logoHolder: {
    width: "200px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 640px)": {
      height: "70px",
      width: "160px",
    },
  },
  img: {
    maxHeight: "80px",
    maxWidth: "200px",
    "@media (max-width: 640px)": {
      maxHeight: "70px",
      maxWidth: "160px",
    },
  },
  title: {
    display: "none",
    marginLeft: "10px",
    fontFamily: [LatoBold, "sans-serif"],
    fontWeight: "bold",
    fontSize: "16px",
    color: grey["800"],
    "@media (min-width: 400px)": {
      display: "inline-block",
    },
  },
  menuHolder: { position: "relative", cursor: "pointer" },
});

const menu = StyleSheet.create({
  menuList: {
    display: "none",
    position: "absolute",
    top: "32px",
    right: 0,
    width: "100%",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
    zIndex: 2,
  },
  menuListOpen: {
    display: "block",
    width: "201px",
  },
  menuItem: {
    display: "inline-block",
    width: "100%",
    fontFamily: "Lato, sans-serif",
    padding: "14px 20px",
    fontSize: "16px",
    textDecoration: "none",
    color: grey["700"],
    backgroundColor: "#fff",
    borderBottom: `1px solid ${grey["300"]}`,
    transition: "0.2s ease",
    ":hover": {
      backgroundColor: grey["300"],
      color: grey["800"],
    },
  },
});

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(Header);
