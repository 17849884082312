import React from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Tooltip } from 'antd';
// import Icon from '@ant-design/icons';
// import { useTranslation } from 'react-i18next';
import AudioMuted from '../../../assets/image/mic-off.png';
import Audio from '../../../assets/image/mic-on.png';
import VideoMuted from '../../../assets/image/camera-off.png';
import Video from '../../../assets/image/camera-on.png';
import ShareOff from '../../../assets/image/sharescreen-off.png';
import Share from '../../../assets/image/sharescreen-on.png';
import Chat from '../../../assets/image/chat-on.png';
import Phone from '../../../assets/image/end-call.png';
// import { Record, StopRecord } from './Icons';
// import useTimer from '../../hooks/useTimer';
import styles from './style.module.css';

const CallMenu = ({
  count,
  onClickChat,
  microphone,
  camera,
  screen,
  onClickEndCall,
  // recording,
  // isTaxPro,
  remoteSharingScreen,
}) => {
  // const [time, active, stop] = useTimer(false);
  // const { t: translation } = useTranslation();

  const RequestMic = () => {
    microphone.onClick();
  };

  const RequestVideo = () => {
    camera.onClick();
  };

  const RequestScreenShare = () => {
    screen.onClick();
  };

  // useEffect(() => {
  //   if (recording.sid) active(true);
  //   else stop();
  //   // eslint-disable-next-line
  // }, [recording.sid]);

  return (
    <div>
      <div className={styles.container}>
        <Tooltip
          placement="top"
          title={`${microphone.status ? 'Mute' : 'Unmute'}`}
        >
          <Button
            className={`${styles.btn} ${microphone.status && styles.btnActive}`}
            type={microphone.status && 'primary'}
            disabled={microphone.disabled}
            icon={
              microphone.status ? (
                <img alt="Audio on" src={Audio} />
              ) : (
                <img alt="Audio off" src={AudioMuted} />
              )
            }
            onClick={RequestMic}
          />
        </Tooltip>
        <Tooltip
          placement="top"
          title={`${camera.status ? 'Turn Off Camera' : 'Turn On Camera'}`}
        >
          <Button
            className={`${styles.btn} ${camera.status && styles.btnActive}`}
            type={camera.status && 'primary'}
            disabled={screen.status || camera.disabled}
            icon={
              camera.status ? (
                <img alt="Video on" src={Video} />
              ) : (
                <img alt="Video off" src={VideoMuted} />
              )
            }
            onClick={RequestVideo}
          />
        </Tooltip>
        {remoteSharingScreen ? (
          <Tooltip
            placement="top"
            title={`${
              !screen.status
                ? 'Someone Else Is Sharing Screen'
                : 'Stop Streaming'
            }`}
          >
            <Button
              className={`${styles.btn} ${styles.btnDisabled}`}
              type="primary"
              icon={<img alt="Share Screen" src={Share} />}
              disabled
            />
          </Tooltip>
        ) : (
          <Tooltip
            placement="top"
            title={`${!screen.status ? 'Share Your Screen' : 'Stop Streaming'}`}
          >
            <Button
              className={`${styles.btn} ${!screen.status && styles.btnActive}`}
              type={screen.status && 'primary'}
              icon={
                !screen.status ? (
                  <img alt="Share Screen on" src={Share} />
                ) : (
                  <img alt="Share Screen off" src={ShareOff} />
                )
              }
              onClick={RequestScreenShare}
            />
          </Tooltip>
        )}
        {/*
        <Tooltip placement="top" title="Open Chat">
          <Badge count={count} className={styles.badge}>
            <Button
              className={`${styles.btn} ${styles.btnActive}`}
              icon={<img alt="Chat" src={Chat} />}
              onClick={onClickChat}
            />
          </Badge>
        </Tooltip>
        */}
        <Tooltip placement="top" title="End Call">
          <Button
            className={styles.btnEnd}
            type="primary"
            onClick={onClickEndCall}
            icon={<img alt="End Call" src={Phone} />}
          />
        </Tooltip>
        {/* {isTaxPro && (
          <div className={styles.recordingContainer}>
            {!recording.sid ? (
              <Button
                className={styles.btnRecording}
                type="primary"
                icon={<Icon component={Record} />}
                onClick={recording.start}
              />
            ) : (
              <Button
                className={styles.btnRecording}
                type="primary"
                icon={<Icon component={StopRecord} />}
                onClick={recording.stop}
              />
            )}
            <p className={styles.textRecording}>
              {!recording.sid
                ? `${translation('videocall.callMenu.recordSession')}`
                : `${translation('videocall.callMenu.stopRecording')}  ${time}`}
            </p>
          </div>
        )} */}
      </div>
      <div className={styles.containerMobile}>
        <Button
          className={`${styles.btn} ${microphone.status && styles.btnActive}`}
          type={microphone.status && 'primary'}
          disabled={microphone.disabled}
          icon={
            microphone.status ? (
              <img alt="Audio on" src={Audio} />
            ) : (
              <img alt="Audio off" src={AudioMuted} />
            )
          }
          onClick={RequestMic}
        />
        <Button
          className={`${styles.btn} ${camera.status && styles.btnActive}`}
          type={camera.status && 'primary'}
          disabled={screen.status || camera.disabled}
          icon={
            camera.status ? (
              <img alt="Video on" src={Video} />
            ) : (
              <img alt="Video off" src={VideoMuted} />
            )
          }
          onClick={RequestVideo}
        />
        <Badge count={count} className={styles.badge}>
          <Button
            className={`${styles.btn} ${styles.btnActive}`}
            icon={<img alt="Chat" src={Chat} />}
            onClick={onClickChat}
          />
        </Badge>

        <div
          className={styles.btnEnd}
          type="primary"
          onClick={onClickEndCall}
          shape="circle"
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <img alt="End Call" src={Phone} />
        </div>
      </div>
    </div>
  );
};

CallMenu.defaultProps = {
  count: null,
};

CallMenu.propTypes = {
  onClickChat: PropTypes.func.isRequired,
  microphone: PropTypes.shape({
    status: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
  camera: PropTypes.shape({
    status: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
  screen: PropTypes.shape({
    status: PropTypes.bool,
    onClick: PropTypes.func,
  }).isRequired,
  onClickEndCall: PropTypes.func.isRequired,
  count: PropTypes.number,
  // recording: PropTypes.shape().isRequired,
  // isTaxPro: PropTypes.bool.isRequired,
  remoteSharingScreen: PropTypes.bool.isRequired,
};

export default CallMenu;
