/* eslint-disable */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { color as baseColor } from '../styles/colors';
import { Lato } from '../styles/fonts';
import { Icon } from './Icon/Icon';

const Badge = ({
  type, children, color, labelStyles = {}, badgeStyles = {},
}) => {
  const styles = StyleSheet.create({
    amount: { padding: '5px 30px' },
    badge: {
      // display: 'inline-block',
      height: '28px',
      lineHeight: '1.5',
      padding: '5px 12px',
      fontFamily: [Lato, 'sans-serif'],
      fontWeight: 'bold',
      fontSize: '13px',
      letterSpacing: '0.4px',
      color: baseColor.white,
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...badgeStyles,
    },
    orange: {
      backgroundColor: baseColor.orange,
    },
    todo: {
      backgroundColor: '#ff7e00',
    },
    green: {
      backgroundColor: baseColor.green,
    },
    red: {
      backgroundColor: baseColor.lightRed,
    },
    label: {
      ...labelStyles,
      // '@media screen and (max-width: 640px)': {
      //   display: 'none',
      // },
      // '@media screen and (max-width: 400px)': {
      //   fontSize: '9px',
      // }
    },
  });

  return (
    <div className={css(
      styles.badge,
      type === 'amount' && styles.amount,
      (type === 'progress' || color === 'orange') && styles.orange,
      (type === 'todo') && styles.todo,
      (type === 'success' || color === 'green') && styles.green,
      (color === 'red') && styles.red,
    )}
    >
      { type && <Icon progress={type === 'progress'} success={type === 'success'} />}
      <span className={css(styles.label)}>{ children }</span>
    </div>
  );
};

export default Badge;