import { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  message,
  Alert,
  Spin,
} from 'antd';
import { withRouter } from "react-router-dom";
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { VariableSizeList as List } from "react-window";
import { asyncMap } from "@wojtekmaj/async-array-utils";
import Field from './components/Field';
import { useGetFileApprovalQuery, useGetSignatureFieldsByFileApprovalIdLazyQuery, useGetSignedFileByFileIdLazyQuery } from '../../graphql/queries/files';
import { FieldType, KbaType } from '../../graphql';
import { useFinishedSigningMutation, useMarkFileApprovedMutation } from '../../graphql/mutations/files';
import SignCanvasModal from './components/Field/SignatureField/SignCanvasModal';
import FreeKBA from './components/KBA/FreeKBA';
import EnhancedKBA from './components/KBA/EnhancedKBA';
import { getScreenHeight, isMobileView } from '../../utils/screen';
import TextFieldModal from './components/Field/TextField/TextFieldModal';
import { TaskType } from '../../graphql';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.0.379/build/pdf.worker.min.mjs';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   // 'pdf.worker.js',
//   import.meta.url,
// ).toString();

// const pdfFile = 'https://raw.githubusercontent.com/wojtekmaj/react-pdf/main/sample/parcel2/sample.pdf';
// const compiledFile = pdfFile;
// const compiledFile = 'https://test-api-graphql-bucket.s3.amazonaws.com/clients/K/fs.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVS7FB4XQSIFRRI56%2F20220526%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220526T154716Z&X-Amz-Expires=10&X-Amz-Signature=b3014ce3ae5cd18ad5ad8903c90763d6135268f3e320197a457657d0565f9e44&X-Amz-SignedHeaders=host&response-content-disposition=inline%3B%20filename%3D%22fs.pdf%22';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

const Signatures = ({ match }) => {
  const fileApprovalId = match?.params?.fileRequestId;
  const [compiledFile, setCompiledFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [fileWidth, setFileWidth] = useState(null);
  const [fileHeight, setFileHeight] = useState(null);
  const [mySignatureFields, setMySignatureFields] = useState([]);
  const [otherSignatureFields, setOtherSignatureFields] = useState([]);
  const [completedFields, setCompletedFields] = useState([]); // [ fieldId, fieldId ]
  const [allFieldsCompleted, setAllFieldsCompleted] = useState(false);
  const [myFieldsCompleted, setMyFieldsCompleted] = useState(false);
  const [isSignModalVisible, setIsSignModalVisible] = useState(false);
  const [textFieldModalField, setTextFieldModalField] = useState(null);
  const [signature, setSignature] = useState(null);
  const [otherNotCompletedFileApprovals, setOtherNotCompletedFileApprovals] = useState([]);
  const [currentSignatureFieldIdInModal, setCurrentSignatureFieldIdInModal] = useState(null);
  const [expired, setExpired] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [pageViewports, setPageViewports] = useState(null);

  const signatureCanvasRef = useRef(null);
  const screenHeight = getScreenHeight();

  // get the file approval
  const [getSignedFile] = useGetSignedFileByFileIdLazyQuery();
  const [GetSignatureFieldsByFileApprovalId] = useGetSignatureFieldsByFileApprovalIdLazyQuery();
  const [callFinishedSigning, { loading: loadingFinishedSigning }] = useFinishedSigningMutation();
  const [markFileApproved, { loading: loadingmarkFileApproved }] = useMarkFileApprovedMutation();
  const { data: fileApprovalData, loading: loadingFileApproval, error: fileApprovalError } = useGetFileApprovalQuery({
    variables: {
      id: fileApprovalId,
    },
  });

  const { fileApproval } = fileApprovalData || {};
  const { FileRequests, KBA } = fileApproval || {};
  const {
    CompiledFile, signedFileId, FileApprovals: fileApprovals, TaxYearInterview
  } = FileRequests || {};
  const { name, id: fileId } = CompiledFile || {};

  const markFieldAsCompleted = (signatureFieldId) => {
    setCompletedFields((oldArray) => [...oldArray, signatureFieldId]);
  };

  // get and categorize the signature fields
  const getAllTheSignatureFields = async () => {
    const promises = [];
    /* eslint-disable no-await-in-loop */
    for (const fa of fileApprovals) {
      promises.push(await GetSignatureFieldsByFileApprovalId({
        variables: {
          fileApprovalId: fa.id,
        },
      }));
    }
    /* eslint-enable no-await-in-loop */
    const results = await Promise.all(promises);
    for (const result of results) {
      const fileApprovalSignatureFields = result?.data?.getSignatureFieldsByFileApprovalId;
      if (fileApprovalSignatureFields) {
        for (const field of fileApprovalSignatureFields) {
          if (field.completedAt) {
            markFieldAsCompleted(field.id);
          }
          if (field.fileApprovalId === fileApprovalId) {
            setMySignatureFields((theArray) => [...theArray, field]);
          } else {
            setOtherSignatureFields((theArray) => [...theArray, field]);
          }
        }
      }
    }
  }

  useEffect(() => {
    setExpired((new Date().getTime() - new Date(fileApproval?.expiresAt).getTime()) > 0);
  }, [fileApproval?.expiresAt]);

  // get the signature fields
  useEffect(() => {
    if (fileApprovals) {
      if (fileApprovals.length > 0) {
        getAllTheSignatureFields();
      }
    }
  }, [fileApprovals]);

  // get the signed file
  useEffect(() => {
    if (fileId) {
      getSignedFile({
        variables: {
          fileId,
        },
      }).then((res) => {
        setCompiledFile(res?.data?.SignedFileByFileId);
      }).catch((err) => {
        console.log('err:', err);
      });
    }
  }, [fileId]);

  // enable and disable submit button
  useEffect(() => {
    const myNotCompletedFields = mySignatureFields.filter((field) => !completedFields.includes(field.id));
    const othersNotCompletedFields = otherSignatureFields.filter((field) => !completedFields.includes(field.id));
    if (myNotCompletedFields.length === 0) { // If all my fields are completed
      setMyFieldsCompleted(true);
      if (othersNotCompletedFields.length === 0) { // If all my fields and all other's fields are also completed
        setAllFieldsCompleted(true);
      } else {
        setAllFieldsCompleted(false);
      }
    } else { // I still have fields to complete
      setMyFieldsCompleted(false);
      setAllFieldsCompleted(false);
    }
  }, [completedFields, mySignatureFields, otherSignatureFields]);

  // find not completed fileApprovals
  useEffect(() => {
    if (fileApprovals) {
      setOtherNotCompletedFileApprovals(fileApprovals.filter((fa) => !fa.completedAt));
    }
  }, [fileApprovals]);

  const handleFieldClick = (fieldType, id) => {
    if (fieldType === FieldType.Signature) { // && !signature
      setIsSignModalVisible(true);
      setCurrentSignatureFieldIdInModal(id);
    } else if (fieldType === FieldType.Date) {
      markFieldAsCompleted(id);
    } else if (fieldType === FieldType.Text) {
      const field = mySignatureFields.find((x) => x.id === id);
      setTextFieldModalField(field);
    }
  }

  const onSignatureDrawComplete = () => {
    if (signatureCanvasRef.current.isEmpty()) {
      // remove this field from being complete
      setCompletedFields((oldArray) => oldArray.filter((v) => v !== currentSignatureFieldIdInModal));
      setIsSignModalVisible(false);
    } else {
      const canvas = signatureCanvasRef.current.getCanvas();
      setSignature(canvas.toDataURL('image/png'));
      markFieldAsCompleted(currentSignatureFieldIdInModal);
      setIsSignModalVisible(false);
    }
  };

  const onTextFieldModalComplete = (description) => {
    const id = textFieldModalField?.id;
    markFieldAsCompleted(id);
    setMySignatureFields((oldValue) => {
      const otherFields = oldValue.filter((x) => x.id !== id);
      const field = oldValue.find((x) => x.id === id);
      return [
        ...otherFields, {
          ...field,
          description,
        },
      ];
    });
  };

  const handleClear = () => {
    signatureCanvasRef.current.clear();
    setSignature(null);
  }

  const handleCancel = () => {
    if (signatureCanvasRef.current.isEmpty()) {
      setCompletedFields((oldArray) => oldArray.filter((v) => v !== currentSignatureFieldIdInModal));
    }
    setIsSignModalVisible(false);
  }

  const getSignedPDF = async () => {
    getSignedFile({
      variables: {
        fileId: signedFileId,
      },
    }).then((res) => {
      window.open(res?.data?.SignedFileByFileId, "_blank");
    }).catch((err) => {
      console.log('err:', err);
    });
  }

  // for file approvals
  const getApprovedPDF = async () => {
    getSignedFile({
      variables: {
        fileId: CompiledFile.id,
      },
    }).then((res) => {
      window.open(res?.data?.SignedFileByFileId, "_blank");
    }).catch((err) => {
      console.log('err:', err);
    });
  }

  const sendSignatures = async () => {
    try {
      await callFinishedSigning({
        variables: {
          finishedSigningInput: {
            fileApprovalId,
            signatureImageData: signature,
            TextFields: mySignatureFields
              .filter((x) => x.fieldType === FieldType.Text)
              .map((x) => ({ id: x.id, description: x.description })),
          },
        },
      });
      message.success('Success.');
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      console.log('error:', error);
      message.error(error.toString());
    }
  };
  
  const approveDocument = async () => {
    try {
      const result = await markFileApproved({
        variables: {
          id: fileApprovalId,
        },
      });
      message.success('Success.');
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      console.log('error:', error);
      message.error(error.toString());
    }
  };

  async function onDocumentLoadSuccess(pdf) {
    const firstPage = await pdf.getPage(1);
    const width = firstPage.view[2];
    const height = firstPage.view[3];
    setNumPages(pdf.numPages);
    setFileWidth(width);
    setFileHeight(height);
    // for virtualized list
    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) => pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 })));

      setPageViewports(nextPageViewports);
    })();
  }

  const onLoadError = (error) => {
    console.log('loading error:', error);
  };

  const unValidatedKBA = KBA && KBA.filter((k) => !k.isValidated && k.type !== KbaType.Without)[0];
  if (unValidatedKBA?.type === KbaType.Basic) {
    return (
      <FreeKBA
        kbaId={unValidatedKBA.id}
        numKbaFailedAttempts={unValidatedKBA.numKbaFailedAttempts}
      />
    );
  }

  if (unValidatedKBA?.type === KbaType.Enhanced) {
    return (
      <EnhancedKBA
        kbaId={unValidatedKBA.id}
        numKbaFailedAttempts={unValidatedKBA.numKbaFailedAttempts}
        numFailedIdScans={unValidatedKBA.numFailedIdScans}
        phoneValue={unValidatedKBA.KBAFields[0].value}
      />
    );
  }

  if (loadingFileApproval || !compiledFile) {
    return (
      <Row justify="center" align="middle" style={{ height: '90vh' }}>
        <Spin size="large" />
      </Row>
    )
  }

  const notPaidInvoices = TaxYearInterview.Tasks?.filter(
    (task) => task.taskType === TaskType.Payments && !task.completedAt && !task.deletedAt
  ).length || 0;

  if (notPaidInvoices > 0) {
    return (
      <Row justify="center">
        <Alert
          message={(
            <span>
              You have an outstanding invoice needs to be paid before you&apos;re able to access this document.
              Please follow
              {' '}
              <a href={`/payments/${TaxYearInterview?.id}`}>this link</a>
              {' '}
              to pay.
            </span>
          )}
          type="info"
          showIcon
          style={{ margin: 10, marginBottom: 15 }}
        />  
      </Row>
    );
  }

  // if there are no signatureFields than we know it's just a doc approval
  const justAFileApproval = mySignatureFields.length === 0 && otherSignatureFields.length === 0;

  const screenWidth = window.screen.width;


  const ListRow = ({ index, style }) => {
    const onPageRenderSuccess = (page) => {
      console.log(`Page ${page.pageNumber} rendered`);
    }
  
    return (
      <div
        key={`page_${index + 1}`}
        id={`page_${index + 1}`}
        className="page-wrapper"
        data-page={index + 1}
        style={{
          display: 'block',
          border: '1px solid #d6d6d6',
          marginTop: 10,
          // transform: isMobileView() ? `scale(${screenWidth / 500})` : undefined,
          // transform: isMobileView() ? `scale(${screenWidth / fileWidth})` : undefined,
          // left: isMobileView() ? (screenWidth - fileWidth) / 2 : undefined,
          // top: isMobileView() ? -50 : undefined,
          ...style,
        }}
      >
        {mySignatureFields.map((signatureField) => {
          if (signatureField.page === index + 1) {
            return (
              <Field
                key={signatureField.id}
                page={index + 1}
                signatureField={signatureField}
                fileWidth={fileWidth}
                fileHeight={fileHeight}
                handleFieldClick={handleFieldClick}
                complete={signatureField.completedAt ? true : completedFields.includes(signatureField.id)}
                pendingSubmit={!signatureField.completedAt}
                signature={signatureField.signatureImage ? signatureField.signatureImage : signature}
                signatureCanvasRef={signatureCanvasRef}
                title={signatureField.title}
                description={signatureField.description}
                completedAt={signatureField.completedAt}
                transformation={isMobileView() ? screenWidth / fileWidth : 1}
                isMobileView={isMobileView()}
              />
            );
          }
          return null;
        })}
        {otherSignatureFields.map((signatureField) => {
          if (signatureField.page === index + 1 && signatureField.completedAt) {
            return (
              <Field
                key={signatureField.id}
                signatureField={signatureField}
                fileWidth={fileWidth}
                fileHeight={fileHeight}
                handleFieldClick={handleFieldClick}
                complete={signatureField.completedAt ? true : completedFields.includes(signatureField.id)}
                pendingSubmit={!signatureField.completedAt}
                signature={signatureField.signatureImage ? signatureField.signatureImage : signature}
                signatureCanvasRef={signatureCanvasRef}
                title={signatureField.title}
                description={signatureField.description}
                completedAt={signatureField.completedAt}
                transformation={isMobileView() ? screenWidth / fileWidth : 1}
              />
            );
          }
          return null;
        })}
        <Page
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={isMobileView() ? screenWidth / fileWidth : undefined}
          // width={fileWidth}
          // pageIndex={index}
          // onRenderSuccess={onPageRenderSuccess}
        />
      </div>
    );
  }

  const getPageHeight = (pageIndex) => {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = fileWidth / pageViewport.width;
    const actualHeight = pageViewport.height * scale;
    // if mobile accomidate for scale
    if (isMobileView()) {
      return actualHeight * (screenWidth / fileWidth);
    }

    return actualHeight;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobileView() ? undefined : 'center',
        marginTop: 10,
        paddingBottom: 40,
        margin: 'auto',
      }}
    >
      {!fileApproval.completedAt && expired && ( // if expired
        <Alert
          message="This signature request has expired."
          description="Please ask your preparer to send another request."
          type="error"
          showIcon
          style={{ margin: 10, marginBottom: 15 }}
        />
      )}
      {fileApproval.completedAt && otherNotCompletedFileApprovals?.length > 0 && !expired && (
        <Alert
          message="Document still pending signatures"
          description={`${otherNotCompletedFileApprovals.map((v) => v.name).join(', ')} still needs to sign.`}
          type="info"
          showIcon
          style={{ margin: 10, marginBottom: 15 }}
        />
      )}
      {(justAFileApproval && !fileApproval.completedAt) && !expired && (
        <Alert
          message="Your tax preparer has asked you to review this document and approve it."
          type="info"
          showIcon
          style={{ margin: 10, marginBottom: 15 }}
        />
      )}
      {/* If signature(s) requested */}
      {(!justAFileApproval && !fileApproval.completedAt) && (
        <Alert
          message="To ensure smooth processing, please complete all fields in this form. Incomplete information may prevent us from proceeding."
          type="info"
          showIcon
          style={{ margin: 10, marginBottom: 15 }}
        />
      )}
      <Document
        file={compiledFile}
        onLoadSuccess={(p) => onDocumentLoadSuccess(p)}
        option={options}
        onLoadError={(p) => onLoadError(p)}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{
            backgroundColor: '#f0f2f5',
            border: '1px solid #d6d6d6',
            borderRadius: 5,
            padding: 10,
            position: 'sticky',
            top: 5,
            zIndex: 999,
          }}
        >
          <Col>
            <div
              style={{ fontSize: isMobileView() ? '0.7rem' : '1rem' }}
            >
              {name}
            </div>
          </Col>
          <Col>
            {/* If justAFileApproval */}
            {(justAFileApproval && !fileApproval.completedAt) && (
              <Button
                type="primary"
                disabled={!myFieldsCompleted || expired}
                loading={loadingmarkFileApproved}
                onClick={approveDocument}
              >
                I Approve
              </Button>
            )}
            {(justAFileApproval && fileApproval.completedAt) && (
              <Button
                type="primary"
                disabled={!allFieldsCompleted}
                onClick={getApprovedPDF}
              >
                View PDF
              </Button>
            )}
            {/* If signature(s) requested */}
            {(!justAFileApproval && !fileApproval.completedAt) && (
              <Button
                type="primary"
                disabled={!myFieldsCompleted || expired}
                loading={loadingFinishedSigning}
                onClick={sendSignatures}
              >
                Submit
              </Button>
            )}
            {(!justAFileApproval && fileApproval.completedAt) && (
              <Button
                type="primary"
                disabled={!allFieldsCompleted}
                onClick={getSignedPDF}
              >
                View Signed PDF
              </Button>
            )}
          </Col>
        </Row>
        {/* virtualized */}
        {pageViewports ? (
          <List
            width={fileWidth > screenWidth ? screenWidth : fileWidth + 2}
            height={screenHeight - 20}
            estimatedItemSize={fileHeight}
            itemCount={numPages}
            itemSize={getPageHeight}
          >
            {ListRow}
          </List>
        ) : null}
        {/* {Array.from(new Array(numPages), (_el, index) => (
          <div
            key={`page_${index + 1}`}
            id={`page_${index + 1}`}
            className="page-wrapper"
            data-page={index + 1}
            style={{
              position: isMobileView() ? 'absolute' : 'relative',
              display: 'block',
              border: '1px solid #d6d6d6',
              marginTop: 10,
              transform: isMobileView() ? `scale(${screenWidth / fileWidth})` : undefined,
              left: isMobileView() ? (screenWidth - fileWidth) / 2 : undefined,
              top: isMobileView() ? -50 : undefined,
            }}
          >
            {mySignatureFields.map((signatureField) => {
              if (signatureField.page === index + 1) {
                return (
                  <Field
                    key={signatureField.id}
                    page={index + 1}
                    signatureField={signatureField}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                    handleFieldClick={handleFieldClick}
                    complete={signatureField.completedAt ? true : completedFields.includes(signatureField.id)}
                    pendingSubmit={!signatureField.completedAt}
                    signature={signatureField.signatureImage ? signatureField.signatureImage : signature}
                    signatureCanvasRef={signatureCanvasRef}
                    title={signatureField.title}
                    description={signatureField.description}
                  />
                );
              }
              return null;
            })}
            {otherSignatureFields.map((signatureField) => {
              if (signatureField.page === index + 1 && signatureField.completedAt) {
                return (
                  <Field
                    key={signatureField.id}
                    signatureField={signatureField}
                    fileWidth={fileWidth}
                    fileHeight={fileHeight}
                    handleFieldClick={handleFieldClick}
                    complete={signatureField.completedAt ? true : completedFields.includes(signatureField.id)}
                    pendingSubmit={!signatureField.completedAt}
                    signature={signatureField.signatureImage ? signatureField.signatureImage : signature}
                    signatureCanvasRef={signatureCanvasRef}
                    title={signatureField.title}
                    description={signatureField.description}
                  />
                );
              }
              return null;
            })}
            <Page
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
            />
          </div>
        ))} */}
      </Document>

      <SignCanvasModal
        isModalVisible={isSignModalVisible}
        signatureCanvasRef={signatureCanvasRef}
        onClear={() => handleClear()}
        onCancel={() => handleCancel()}
        onSignatureDrawComplete={onSignatureDrawComplete}
      />

      <TextFieldModal
        field={textFieldModalField}
        onOk={(description) => {
          onTextFieldModalComplete(description);
          setTextFieldModalField(null);
        }}
        onCancel={() => {
          setTextFieldModalField(null);
        }}
      />
    </div>
  );
}

export default withRouter((Signatures))