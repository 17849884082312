/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Form, Collapse, Button, message, Alert, Row
} from "antd";
import { connect } from 'react-redux';
import { css, StyleSheet } from "aphrodite";
import { useDispatch } from "react-redux";
import { v4 as uuid4 } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { getTitle } from "..";
import useEditSection from "../../../hooks/useEditSection";
import EditInterviewQuestion from "./EditInterviewQuestion";
import { completeInterviewTask, saveProjectAnswers } from "../../../../redux/actions/projects";
import { useSaveAnswersMutation } from "../../../../graphql/mutations/taxYearInterviews";
import { useUpdateUserInformationMutation } from "../../../../graphql/mutations/settings";
import Translation from "../../../atoms/Translation";
import { useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation } from '../../../../graphql/mutations/taxYearInterviews';
import { GetTaxYearInterviewSectionsDocument } from '../../../../graphql/queries/taxYearInterviews';

const { Panel } = Collapse;

const buttonStyle = StyleSheet.create({
  marginRight: {
    marginRight: 12
  },
  marginBottom: {
    marginTop: 24
  },
});

const processQuestion = (inputSection, form, isDependent, wipeDependentAnswers) => {
  const questions = (inputSection.Questions ?? []).map(({
    id, type, required, Options, Translations
  }) => {
    const questionTitle = getTitle(Translations);
    const optionsToRender = [...(Options ?? [])].map(({ id: optionId, Translations: optionTranslations }) => ({
      value: optionId,
      label: getTitle(optionTranslations)
    }));

    const onAnswerChange = (questionId, answer) => {
      form.setFieldsValue({ [questionId]: answer });
    };

    return (
      <EditInterviewQuestion
        key={uuid4()}
        type={type}
        questionId={id}
        currentValue=""
        required={required}
        options={optionsToRender}
        questionName={questionTitle}
        onAnswerChange={onAnswerChange}
      />
    );
  });

  const subsections = (inputSection.SubSections ?? []).map((subsection) => (
    <Subsection
      key={subsection.id}
      form={form}
      subsection={subsection}
      isDependent={isDependent}
      wipeDependentAnswers={wipeDependentAnswers}
    />
  ));

  return [...questions, ...subsections];
};

const EditSection = ({
  section,
  jobId: taxYearInterviewId,
  interview,
  session,
  allAnswers,
  taxYearInterviewSections,
  goToNextSection,
  handleDeleteSection,
}) => {
  const { graphSession } = session;
  const {
    userInformationId,
  } = graphSession;
  const dispatch = useDispatch();
  const { parentId } = section;
  const [saveAnswers, { loading: saveAnswersLoading }] = useSaveAnswersMutation();
  const [updateUserInformation] = useUpdateUserInformationMutation();
  const {
    sectionForm, getJob, getInitialValues
  } = useEditSection(section, taxYearInterviewId);

  const [setTaxInterviewSectionPrePopulatedConfirmedValue, { loading: sectionConfirmLoading }] = useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation({
    onError: (err) => message.error(err.message),
    refetchQueries: [{ query: GetTaxYearInterviewSectionsDocument, variables: { taxYearInterviewId } }],
  });

  const extractQuestion = (inputSection) => {
    let questions = [...inputSection.Questions];

    const subsections = inputSection.SubSections ?? [];
    if (subsections.length > 0) {
      subsections.forEach((subsection) => {
        questions = [...questions, ...extractQuestion(subsection)]
      });
    }

    return questions;
  }

  const questionIds = section.Questions.map((q) => q.id);
  const hasPrepopulated = allAnswers.some((a) => questionIds.some((qId) => qId === a.questionId) && a.isPrePopulated);

  const onSaveClick = (values, notGoForward = false) => {
    const arrayValues = Object.keys(values)
      .filter((key) => typeof values[key] !== "undefined")
      .map((key) => ({
        [key]: values[key]
      }));

    const allQuestions = [section].reduce((prev, curr) => [...prev, ...extractQuestion(curr)], []);
    const answers = arrayValues.map((value) => {
      const question = allQuestions.find(({ id: questionId }) => Object.keys(value ?? {}).find((key) => key === questionId));

      return {
        taxYearInterviewId,
        questionId: question.id,
        description: value[question?.id]
      }
    });

    // if updating birthday on individual tax return, also update the user's userInformation
    const dobQuestion = allQuestions.filter((q) => q.Translations.filter(({ description }) => description === 'Taxpayer Date of Birth').length > 0)[0];
    const isBasicPersonalInformationSection = section.Translations.filter(({ description }) => description === 'Basic Personal Information').length > 0;
    if (dobQuestion && isBasicPersonalInformationSection && interview?.name === 'Individual') { // only if the interview is an individual tax return
      const dobAnswer = answers.filter((a) => a.questionId === dobQuestion.id)[0].description;
      updateUserInformation({
        variables: {
          updateUserInformationInput: {
            id: userInformationId,
            birthday: dobAnswer,
          },
        },
      });
    }

    saveAnswers({
      variables: {
        saveAnswerInput: {
          answers,
          taxYearInterviewId,
          sectionId: section.id,
        }
      }
    }).then((result) => {
      if (result.errors) {
        message.error(result.errors?.join(','));
        return;
      }

      result.data.saveAnswer.answers.forEach((answer) => {
        dispatch(saveProjectAnswers(taxYearInterviewId, answer));
      });

      if (result.data.saveAnswer.sectionIsComplete && getJob()?.Tasks.find(({ taskType, completedAt }) => taskType === "INTERVIEW" && completedAt === null)) {
        dispatch(completeInterviewTask(taxYearInterviewId));
      }

      message.success('The answers in this section has been successfully saved.');

      if (!notGoForward) {
        goToNextSection();
      }
    }).catch((err) => {
      message.error(err.message);
    });
  };

  const markSectionPrepopulatedConfirmed = async () => {
    await setTaxInterviewSectionPrePopulatedConfirmedValue({
      variables: {
        taxYearInterviewId,
        sectionId: section.id,
        value: true,
      }
    })
  };

  const wipeDependentAnswers = (id) => {
    const question2Remove = section.SubSections.find((ss) => ss.id === id)?.Questions?.map((q) => q.id);
    sectionForm.setFieldsValue(question2Remove.reduce((a, v) => ({ ...a, [v]: ""}), {}));
    onSaveClick(sectionForm.getFieldsValue(true), true);
    handleDeleteSection(id);
  };

  return (
    <>
      {hasPrepopulated && !taxYearInterviewSections?.find((ss) => ss.sectionId === section.id)?.prePopulatedConfirmed && (
        <Alert
          showIcon
          type="info"
          message={<Translation text="interview.sectionHasPrepopulated" />}
          description={(
            <Button
              key="confirmationBtn"
              type="primary"
              onClick={() => markSectionPrepopulatedConfirmed()}
              loading={sectionConfirmLoading}
            >
              <Translation text="interview.confirmBtnLabel" />
            </Button>
          )}
        />
      )}
      <Form
        key={section?.id}
        layout="vertical"
        form={sectionForm}
        onFinish={onSaveClick}
        name={section?.id ?? ''}
        initialValues={getInitialValues()}
      >
        {
          processQuestion(section, sectionForm, section.specialType === "DEPENDENT_INFORMATION", wipeDependentAnswers)
        }
        <Row justify="end">
          {!parentId && (
            <Button
              htmlType="submit"
              loading={saveAnswersLoading}
              className={css(buttonStyle.marginBottom)}
              type="primary"
            >
              Save and Continue
            </Button>
          )}
        </Row>
      </Form>
    </>
  );
};

const Subsection = (props) => {
  const {
    form, subsection, isDependent, wipeDependentAnswers,
  } = props;
  const [conditionalsStatus, setConditionalsStatus] = React.useState([]);

  const getExtra = (id) => {
    const handleDeleteClick = (e) => {
      e.stopPropagation();
      wipeDependentAnswers(id);
    };

    return (
      <div>
        <Button
          onClick={(e) => handleDeleteClick(e)}
          type="danger"
          size="small"
        >
          <Translation text="interview.dependentSection.delete" />
        </Button>
      </div>
    );
  };

  const getSection = () => (
    <Collapse key={subsection.id}>
      <Panel
        header={getTitle(subsection.Translations)}
        extra={isDependent ? getExtra(subsection.id) : undefined}
      >
        {processQuestion(subsection, form)}
      </Panel>
    </Collapse>
  );

  const getContent = () => {
    const { Conditionals } = subsection;

    if (Conditionals?.length > 0) {
      return (
        <>
          {subsection.Conditionals.map((conditional, index) => {
            const handleConditionalsMeetsChange = (meets) => {
              setConditionalsStatus((prev) => {
                const copy = [...prev];
                copy[index] = meets;
                return copy;
              })
            };

            return (
              <Conditional
                form={form}
                key={conditional.id}
                conditional={conditional}
                onChange={handleConditionalsMeetsChange}
              />
            )
          })}
          {conditionalsStatus.some((status) => status) && (
            getSection()
          )}
        </>
      );
    }

    return getSection();
  };

  return (
    <>
      {getContent(subsection, form)}
    </>
  );
};

/**
 * * Abstracted this component to be able to use useWatch hook for each conditional
 * */
const Conditional = (props) => {
  const {
    form,
    onChange,
    conditional,
  } = props;

  const onChangeRef = React.useRef();
  onChangeRef.current = onChange;
  const answer = Form.useWatch(conditional.Options.questionId, form);


  const meets = answer === conditional.optionId;

  React.useEffect(() => {
    if (answer) {
      onChangeRef.current?.(meets);
    }
  }, [meets])

  return null;
};

EditSection.propTypes = {
  jobId: PropTypes.string.isRequired,
  // TODO: CREATE Section Entity PropTypes with PropTypes.objectOf and PropTypes.object.shape as paramet
  section: PropTypes.object.isRequired
};


const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(EditSection);
