/* eslint-disable */
import {
  actionType,
  SHOW_SECTION,
  GOTO_NEXT_SECTION,
  CHANGE_SECTION,
  SET_CURRENT_SECTION_VALUES,
  CLEAR_CURRENT_SECTION_VALUES,
} from '../actions/interview';

const initialState = {
  interview: {
    clientValues: {
      dependents: [],
    },
  },
  section: null,
  isListShown: true,
  errors: null,
  currentSectionValues: null,
  isSectionUploading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  let nextSectionIndex;
  switch (type) {
    case actionType.isSectionUploading:
      return {
        ...state,
        isSectionUploading: payload,
      };
    case SET_CURRENT_SECTION_VALUES:
      return {
        ...state,
        currentSectionValues: {
          ...state.currentSectionValues,
          [payload.fieldName]: payload.value,
        },
      };
    case CLEAR_CURRENT_SECTION_VALUES:
      return { ...state, currentSectionValues: {} };
    case actionType.set:
      if (payload === 'loading') {
        return {
          ...state,
          interview: payload,
          section: payload,
        };
      }
      return {
        ...state,
        interview: payload,
      };
    case actionType.deleteLastDependent:
      // prevention to check if to delete the fields only or the state should be cleared
      if (!state.interview.clientValues.dependents || payload > state.interview.clientValues.dependents.length) return state;
      return {
        ...state,
        interview: {
          ...state.interview,
          clientValues: {
            ...state.interview.clientValues,
            dependents: [...state.interview.clientValues.dependents.slice(0, -1)],
          },
        },
      };
    case actionType.setSection:
      return {
        ...state,
        section: payload,
        errors: null,
      };
    case SHOW_SECTION:
      return {
        ...state,
        isListShown: !payload,
      };
    case GOTO_NEXT_SECTION:
      if (state.interview.config) {
        state.interview.config.forEach((section, i, arr) => {
          if (section.fieldName === payload && i < arr.length - 1) {
            nextSectionIndex = i + 1;
            return true;
          }
        });
      }

      if (nextSectionIndex) {
        return {
          ...state,
          section: state.interview.config[nextSectionIndex],
          isSectionUploading: false,
        };
      }
      return {
        ...state,
        isListShown: true,
        isSectionUploading: false,
      };
    case actionType.updateErrors:
      return {
        ...state,
        errors: payload,
      };
    case CHANGE_SECTION:
      // converting the value to a number
      if (payload.fieldName === 'dependent_annual_income') {
        payload.value = isNaN(Number(payload.value)) ? 0 : Number(payload.value);
      }
      // if the section is dependent info - first, get the new object we will place instead of the previous one
      if (payload.ifDependent) {
        let objToReplace = {};
        if (state.interview && state.interview.clientValues && state.interview.clientValues.dependents && state.interview.clientValues.dependents.length) {
          // if object exists - replace only the necessary field
          objToReplace = {
            ...state.interview.clientValues.dependents[payload.dependentIndex],
            [payload.fieldName]: payload.value,
          };
        } else {
          // if the form was emply - create new object
          objToReplace = { [payload.fieldName]: payload.value };
        }
        let newDependentsArray = [];
        if (state.interview.clientValues && state.interview.clientValues.dependents && state.interview.clientValues.dependents.length) {
          // if the data already existed, just replace the neccessary array item
          newDependentsArray = state.interview.clientValues.dependents.slice(0, payload.dependentIndex).concat(objToReplace).concat(state.interview.clientValues.dependents.slice(payload.dependentIndex + 1));
        } else {
          newDependentsArray[payload.dependentIndex] = objToReplace;
        }
        return {
          ...state,
          interview: {
            ...state.interview,
            clientValues: {
              ...state.interview.clientValues,
              dependents: [...newDependentsArray],
            },
          },
        };
      }
      return {
        ...state,
        interview: {
          ...state.interview,
          clientValues: {
            ...state.interview.clientValues,
            [payload.fieldName]: payload.value,
          },
        },
      };

    default:
      return state;
  }
};
