import { useState, useEffect } from 'react';
import { message } from 'antd';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { css, StyleSheet } from 'aphrodite';
import { connect } from 'react-redux';
import Translation from '../atoms/Translation';
import Button from '../Button/Button';

const styles = StyleSheet.create({
  btnBlock: { textAlign: 'right', marginTop: '20px', marginBottom: '20px' },
});

const CheckoutForm = (props) => {
  const { session } = props;
  const { firstName, lastName, email } = session?.graphSession;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element.on('ready', () => {
        // give it 300 milliseconds to render
        setTimeout(() => {
          setPaymentElementReady(true);
        }, 300);
      })
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/?paymentSuccess=true`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      // setErrorMessage(error.message);
      message.error(error.message);
    } else {
      // setErrorMessage("An unexpected error occurred.");
      message.error("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        name: (firstName && lastName) ? `${firstName} ${lastName}` : '',
        email: email ?? '',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      {paymentElementReady && (
        <div className={css(styles.btnBlock)}>
          <Button
            label={<Translation text="invoices.paymentForm.payNow" />}
            onClick={handleSubmit}
            blue
            style={{ minWidth: '155px' }}
            loading={isLoading}
            disabled={isLoading || !stripe || !elements}
            icon="lock"
          />
        </div>
      )}
      {/* Show error message to your customers */}
      {/* {errorMessage && <Alert message={errorMessage} type="error" />} */}
    </form>
  )
};

// redux
const mapStateToProps = (state) => ({
  session: state.session,
  user: state.user,
});

export default connect(mapStateToProps)(CheckoutForm);
