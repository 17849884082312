import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendChatMessageMutationVariables = Types.Exact<{
  createMessageInput: Types.CreateMessageInput;
}>;


export type SendChatMessageMutation = { __typename?: 'Mutation', createMessage: { __typename?: 'MessageEntity', id: string, userId: string, channelId: string, text?: string | null, isSms: boolean, createAt: any, updateAt: any } };

export type UpdateMessagesMutationVariables = Types.Exact<{
  updateMessagesInput: Array<Types.UpdateMessageInput> | Types.UpdateMessageInput;
}>;


export type UpdateMessagesMutation = { __typename?: 'Mutation', updateMessages: Array<{ __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, readDate?: any | null, createAt: any, updateAt: any, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null }> };

export type CreateMessageWithFileMutationVariables = Types.Exact<{
  createMessageWithFileInput: Types.CreateMessageWithFileInput;
}>;


export type CreateMessageWithFileMutation = { __typename?: 'Mutation', createMessageWithFile: { __typename?: 'FileMessageEntity', id: string, name: string, size: number, s3Key: string, folder: string, taxYearInterviewId: string, deleteAt?: any | null, uploadedBy: string, deletedBy?: string | null } };

export type AgoraAccessTokenMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  channelId: Types.Scalars['String'];
}>;


export type AgoraAccessTokenMutation = { __typename?: 'Mutation', AgoraAccessToken: string };


export const SendChatMessageDocument = gql`
    mutation sendChatMessage($createMessageInput: CreateMessageInput!) {
  createMessage(createMessageInput: $createMessageInput) {
    id
    userId
    channelId
    text
    isSms
    createAt
    updateAt
  }
}
    `;
export type SendChatMessageMutationFn = Apollo.MutationFunction<SendChatMessageMutation, SendChatMessageMutationVariables>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      createMessageInput: // value for 'createMessageInput'
 *   },
 * });
 */
export function useSendChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendChatMessageMutation, SendChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(SendChatMessageDocument, options);
      }
export type SendChatMessageMutationHookResult = ReturnType<typeof useSendChatMessageMutation>;
export type SendChatMessageMutationResult = Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const UpdateMessagesDocument = gql`
    mutation UpdateMessages($updateMessagesInput: [UpdateMessageInput!]!) {
  updateMessages(updateMessagesInput: $updateMessagesInput) {
    id
    text
    userId
    readDate
    createAt
    updateAt
    channelId
    attachment
    isSms
    Users {
      id
      email
      createAt
      updateAt
      languageId
      UserInformation {
        id
        userId
        createAt
        updateAt
        birthday
        lastName
        firstName
      }
    }
  }
}
    `;
export type UpdateMessagesMutationFn = Apollo.MutationFunction<UpdateMessagesMutation, UpdateMessagesMutationVariables>;

/**
 * __useUpdateMessagesMutation__
 *
 * To run a mutation, you first call `useUpdateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessagesMutation, { data, loading, error }] = useUpdateMessagesMutation({
 *   variables: {
 *      updateMessagesInput: // value for 'updateMessagesInput'
 *   },
 * });
 */
export function useUpdateMessagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessagesMutation, UpdateMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessagesMutation, UpdateMessagesMutationVariables>(UpdateMessagesDocument, options);
      }
export type UpdateMessagesMutationHookResult = ReturnType<typeof useUpdateMessagesMutation>;
export type UpdateMessagesMutationResult = Apollo.MutationResult<UpdateMessagesMutation>;
export type UpdateMessagesMutationOptions = Apollo.BaseMutationOptions<UpdateMessagesMutation, UpdateMessagesMutationVariables>;
export const CreateMessageWithFileDocument = gql`
    mutation CreateMessageWithFile($createMessageWithFileInput: CreateMessageWithFileInput!) {
  createMessageWithFile(createMessageWithFileInput: $createMessageWithFileInput) {
    id
    name
    size
    s3Key
    folder
    taxYearInterviewId
    deleteAt
    uploadedBy
    deletedBy
  }
}
    `;
export type CreateMessageWithFileMutationFn = Apollo.MutationFunction<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>;

/**
 * __useCreateMessageWithFileMutation__
 *
 * To run a mutation, you first call `useCreateMessageWithFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageWithFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageWithFileMutation, { data, loading, error }] = useCreateMessageWithFileMutation({
 *   variables: {
 *      createMessageWithFileInput: // value for 'createMessageWithFileInput'
 *   },
 * });
 */
export function useCreateMessageWithFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>(CreateMessageWithFileDocument, options);
      }
export type CreateMessageWithFileMutationHookResult = ReturnType<typeof useCreateMessageWithFileMutation>;
export type CreateMessageWithFileMutationResult = Apollo.MutationResult<CreateMessageWithFileMutation>;
export type CreateMessageWithFileMutationOptions = Apollo.BaseMutationOptions<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>;
export const AgoraAccessTokenDocument = gql`
    mutation AgoraAccessToken($userId: String!, $channelId: String!) {
  AgoraAccessToken(userId: $userId, channelId: $channelId)
}
    `;
export type AgoraAccessTokenMutationFn = Apollo.MutationFunction<AgoraAccessTokenMutation, AgoraAccessTokenMutationVariables>;

/**
 * __useAgoraAccessTokenMutation__
 *
 * To run a mutation, you first call `useAgoraAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgoraAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agoraAccessTokenMutation, { data, loading, error }] = useAgoraAccessTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useAgoraAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<AgoraAccessTokenMutation, AgoraAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgoraAccessTokenMutation, AgoraAccessTokenMutationVariables>(AgoraAccessTokenDocument, options);
      }
export type AgoraAccessTokenMutationHookResult = ReturnType<typeof useAgoraAccessTokenMutation>;
export type AgoraAccessTokenMutationResult = Apollo.MutationResult<AgoraAccessTokenMutation>;
export type AgoraAccessTokenMutationOptions = Apollo.BaseMutationOptions<AgoraAccessTokenMutation, AgoraAccessTokenMutationVariables>;
export const namedOperations = {
  Mutation: {
    sendChatMessage: 'sendChatMessage',
    UpdateMessages: 'UpdateMessages',
    CreateMessageWithFile: 'CreateMessageWithFile',
    AgoraAccessToken: 'AgoraAccessToken'
  }
}