/* eslint-disable */
import {
  SET_PROJECTS,
  SET_PROJECTS_LOADING, 
  SET_ACTIVE_PROJECT,
  COMPLETE_TASK, 
  SET_PROJECT_TASKS, 
  SET_PROJECT_ANSWER, 
  UPDATE_PROJECT_ANSWER,
  SET_INTERVIEW_SECTION_DEPENDENT,
  REMOVE_INTERVIEW_SECTION_DEPENDENT,
  SAVE_PROJECT_ANSWERS,
  COMPLETE_INTERVIEW_TASK,
} from '../actions/projects';

const initialState = {
  projects: null,
  activeProject: null,
  loading: true,
};

const findProjectIndex = (allProjects, searchId) => {
  let projectIndex;
  allProjects.forEach((project, index) => {
    if (`${project.id}` === `${searchId}`) { // makes sure to compare same types (string)
      projectIndex = index;
    }
  });
  return projectIndex;
};

const updateTaskCompletedDate = (currentProject, tasksToUpdate) => {
  currentProject.Tasks.forEach((task) => {
    if (tasksToUpdate.some((taskId) => +taskId === task.id)) {
      task.completedAt = task.completedAt || new Date();
    }
  });
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  // const updatingProjectIndex = findProjectIndex(state.projects, payload.projectId);
  // const currentProject = { ...state.projects[updatingProjectIndex] };
  // const newProjects = updatingProjectIndex === 0 ? [currentProject, ...state.projects.slice(1)] : state.projects.slice(0, updatingProjectIndex).concat(currentProject).concat(state.projects.slice(updatingProjectIndex + 1));

  switch (type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case SET_PROJECTS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_PROJECT_TASKS: {
      const updatingProjectIndex = findProjectIndex(state.projects, payload.projectId);
      const currentProject = { ...state.projects[updatingProjectIndex] };

      currentProject.tasks = payload.tasks;
      const newProjects = updatingProjectIndex === 0 ? [currentProject, ...state.projects.slice(1)] : state.projects.slice(0, updatingProjectIndex).concat(currentProject).concat(state.projects.slice(updatingProjectIndex + 1));
      return {
        ...state,
        projects: newProjects,
      };
    }
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: payload,
      };
    case COMPLETE_TASK: {
      const completedProjectIndex = findProjectIndex(state.projects, payload.projectId);
      const currentProject = { ...state.projects[completedProjectIndex] };
      updateTaskCompletedDate(currentProject, payload.taskIds);
      const newProjects = completedProjectIndex === 0 ? [currentProject, ...state.projects.slice(1)] : state.projects.slice(0, completedProjectIndex).concat(currentProject).concat(state.projects.slice(completedProjectIndex + 1));
      return {
        ...state,
        projects: newProjects,
      };
    }
    case SAVE_PROJECT_ANSWERS:
      return {
        ...state,
        projects: [...state.projects].map((project) => {
          const { projectId, answer: answerInput } = action.payload;

          if (project.id === projectId) {
            return {
              ...project,
              Answers: [...project.Answers, ...(project.Answers.find(({id}) => answerInput.id === id) === undefined ? [answerInput] : [])].map((answer) => {
                if (answer.id === answerInput.id) {
                  return answerInput;
                }

                return answer;
              }),
            }
          }
          return project;
        })
      }
    case SET_PROJECT_ANSWER:
      return {
        ...state,
        projects: [...state.projects].map((project) => {
          const { id, newAnswer } = action.payload;

          if (project.id === id) {

            return {
              ...project,
              Answers: [...project.Answers, newAnswer],
            }
          }

          return project;
        }),
      }
      case UPDATE_PROJECT_ANSWER:
        return {
          ...state,
          projects: [...state.projects].map((project) => {
            const { projectId, updatedAnswer } = action.payload;
  
            if (project.id === projectId) {
  
              return {
                ...project,
                Answers: [...project.Answers].map((answer) => {
                  if (answer.id === updatedAnswer.id) {
                    return updatedAnswer;
                  }

                  return answer;
                }),
              }
            }
  
            return project;
          }),
        }
      case SET_INTERVIEW_SECTION_DEPENDENT:
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === action.payload.projectId) {
              return {
                ...project,
                Interviews: {
                  ...project.Interviews,
                  Sections: project.Interviews.Sections.map((section) => {
                    if (section.id === action.payload.newDependentSection.parentId) {
                      return {
                        ...section,
                        SubSections: [...(section.SubSections ?? []), action.payload.newDependentSection]
                      };
                    }

                    return section;
                  }),
                },
              };
            }


            return project
          }),
        }
        case REMOVE_INTERVIEW_SECTION_DEPENDENT:
          return {
            ...state,
            projects: state.projects.map((project) => {
              if (project.id === action.payload.projectId) {
                return {
                  ...project,
                  Interviews: {
                    ...project.Interviews,
                    Sections: project.Interviews.Sections.map((section) => {
                      if (section.id === action.payload.dependentSection.parentId) {
                        return {
                          ...section,
                          SubSections: [...(section.SubSections ?? [])].filter(({ id }) => id !== action.payload.dependentSection.id),
                        };
                      }
  
                      return section;
                    }),
                  },
                };
              }
  
  
              return project
            }),
          }
        case COMPLETE_INTERVIEW_TASK:
          return {
            ...state,
            projects: [...state.projects].map((project) => {
              const { projectId } = action.payload;

              if (project.id === projectId) {
                return {
                  ...project,
                  Tasks: [...(project.Tasks ?? [])].map((task) => {
                    if (task.taskType === "INTERVIEW") {
                      return {
                        ...task,
                        completedAt: new Date(),
                      }
                    }

                    return task;
                  })
                };
              }

              return project;
            }),
          }
    default:
      return state;
  }
};
