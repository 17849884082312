import mustLoginImage from '../../../assets/image/Must-login.png';
import roomNotAvailableImage from '../../../assets/image/Room-not-available-yet.png';
import callEndedImage from '../../../assets/image/Call-ended.png';
import callCancelledImage from '../../../assets/image/Call-cancelled.png';
import notInvitedImage from '../../../assets/image/User-not-invited.png';
import noCamImage from '../../../assets/image/No-cam.png';
import noMicImage from '../../../assets/image/No-mic.png';
import callExpiredImage from '../../../assets/image/Call-expired.png';

const callErrors = {
  initialError: {
    name: 'initialError',
    error: false,
    title: '',
    description: '',
    image: '',
    joinCall: false,
  },
  cleanEvent: {
    name: 'cleanEvent',
    error: false,
    title: '',
    description: '',
    image: '',
    joinCall: true,
  },
  cleanMeeting: {
    name: 'cleanMeeting',
    error: true,
    title: '',
    description: 'call.callErrors.description.cleanMeeting',
    image: '',
    joinCall: true,
    avatar: true,
  },
  userNotInvited: {
    name: 'userNotInvited',
    error: true,
    title: 'call.callErrors.title.userNotInvited',
    description: 'call.callErrors.description.userNotInvited',
    imageURL: notInvitedImage,
    joinCall: false,
  },
  mustLogin: {
    name: 'mustLogin',
    error: true,
    title: 'call.callErrors.title.mustLogin',
    description: 'call.callErrors.description.mustLogin',
    imageURL: mustLoginImage,
    joinCall: false,
  },
  earlierAccess: {
    name: 'earlierAccess',
    error: true,
    title: 'call.callErrors.title.earlierAccess',
    description: '',
    imageURL: roomNotAvailableImage,
    joinCall: false,
  },
  laterAccess: {
    name: 'laterAccess',
    error: true,
    title: 'call.callErrors.title.laterAccess',
    description: 'call.callErrors.description.laterAccess',
    imageURL: callEndedImage,
    joinCall: false,
  },
  callCancelled: {
    name: 'callCancelled',
    error: true,
    title: 'call.callErrors.title.callCancelled',
    description: 'call.callErrors.description.callCancelled',
    imageURL: callCancelledImage,
    joinCall: false,
  },
  noCam: {
    name: 'noCam',
    error: true,
    title: 'call.callErrors.title.noCam',
    description: 'call.callErrors.description.noCam',
    imageURL: noCamImage,
    joinCall: true,
  },
  noMic: {
    name: 'noMic',
    error: true,
    title: 'call.callErrors.title.noMic',
    description: 'call.callErrors.description.noMic',
    imageURL: noMicImage,
    joinCall: true,
  },
  callExpired: {
    name: 'callExpired',
    error: true,
    title: 'call.callErrors.title.callExpired',
    description: 'call.callErrors.description.callExpired',
    imageURL: callExpiredImage,
    joinCall: false,
  },
};

export default callErrors;
