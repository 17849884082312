/* eslint-disable */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { setSession } from '../redux/actions/session';
import { useLoginAsMutation } from '../graphql/mutations/clients';
import { message, Spin } from 'antd';
import { useHistory } from "react-router-dom";

export const constructGraphSession = (data) => ({
  accessToken: data.accessToken,
  id: data.UserFirmAccount.Entity?.Users.UserInformation.id
    ?? data.UserFirmAccount.Users?.UserInformation.id,
  userId: data.UserFirmAccount.Entity?.Users.UserInformation.userId
    ?? data.UserFirmAccount.Users?.UserInformation.userId,
  userInformationId: data.UserFirmAccount.Entity?.Users.UserInformation.id
    ?? data.UserFirmAccount.Users?.UserInformation.id,
  firstName: data.UserFirmAccount.Entity?.Users.UserInformation.firstName
    ?? data.UserFirmAccount.Users?.UserInformation.firstName,
  lastName: data.UserFirmAccount.Entity?.Users.UserInformation.lastName
    ?? data.UserFirmAccount.Users?.UserInformation.lastName,
  birthday: data.UserFirmAccount.Entity?.Users.UserInformation.birthday
    ?? data.UserFirmAccount.Users?.UserInformation.birthday,
  ssn: data.UserFirmAccount.Entity?.Users.UserInformation.ssn
    ?? data.UserFirmAccount.Users?.UserInformation.ssn,
  profilePhoto: data.UserFirmAccount.Entity?.Users.UserInformation.profilePhoto
    ?? data.UserFirmAccount.Users?.UserInformation.profilePhoto,
  firmAccountId: data.UserFirmAccount.FirmAccount.id,
  language: data.UserFirmAccount.Entity?.Users?.Languages
    ?? data.UserFirmAccount.Users?.Languages,
  email: data.UserFirmAccount.Entity?.Users.email
    ?? data.UserFirmAccount.Users?.email,
  secretOTP: data.UserFirmAccount.Entity?.Users.secretOTP
    ?? data.UserFirmAccount.Users?.secretOTP,
  entityId: data.UserFirmAccount.entityId || null,
  userRole: data.UserFirmAccount.Roles.description,
  uniqueLink: data.UserFirmAccount.Entity?.Users?.uniqueLink
});

const PrivateRoute = ({ component: Component, session, dispatch, ...res }) => {
  const query =  new URLSearchParams(useLocation().search);
  const userIdParam = query.get('userId');
  const tokenParam = query.get('token');
  const history = useHistory();

  const [loginAs] = useLoginAsMutation({
    onError: (err) => message.error(`Login as client error: ${err.message}`, 5),
  });

  const handleLoginAs = async () => {
    const result = await loginAs({
      variables: {
        token: tokenParam,
        userId: userIdParam,
      }
    });

    const graphSession = constructGraphSession(result.data.LoginAs);
    dispatch(setSession(graphSession));

    if (location.pathname.indexOf('videocall') !== -1) {
      history.replace(location.pathname);
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    if (tokenParam && userIdParam) {
      handleLoginAs();
    }
  }, []);

  if (tokenParam && userIdParam) {
    return <Spin />;
  }

  return (
    <Route
      {...res}
      render={({ location }) => (session.graphSession
        ? (
            <Component session={session.user} />
          )
        : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
      )}
    />
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(PrivateRoute);
