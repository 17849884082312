import React from 'react';
import { Tooltip } from 'antd';

const toolTipWrapper = (Component, isShow, title) => (isShow
  ? (
    <Tooltip title={title}>
      {Component}
    </Tooltip>
  )
  : Component);

export default toolTipWrapper;
