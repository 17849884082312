/* eslint-disable */
import API from '../api';

export const getFileUrl = async (name, isFirm) => {
  if (isFirm) {
    return `/${name.slice(1)}`;
  }

  if (!name) return '';

  try {
    const { data } = await API.get(`/files/view/${name}`);
    return data.url;
  } catch (error) {
    return '';
  }
};
