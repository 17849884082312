import Alert from 'react-s-alert';
import API from '../../api';
import { setActiveProject, refreshTaskList } from './projects';

export const CLEAR_INTERVIEW = 'CLEAR_INTERVIEW';
export const SHOW_SECTION = 'SHOW_SECTION';
export const GOTO_NEXT_SECTION = 'GOTO_NEXT_SECTION';
export const CHANGE_SECTION = 'CHANGE_SECTION';
export const SET_CURRENT_SECTION_VALUES = 'SET_CURRENT_SECTION_VALUES';
export const CLEAR_CURRENT_SECTION_VALUES = 'CLEAR_CURRENT_SECTION_VALUES';

export const actionType = {
  set: 'SET_INTERVIEW',
  setSection: 'SET_SECTION',
  updateErrors: 'UPDATE_ERRORS',
  deleteLastDependent: 'DELETE_LAST_DEPENDENT',
  isSectionUploading: 'isSectionUploading',
};

export const setInterview = (interview) => ({
  type: actionType.set,
  payload: interview,
});

export const getInterview = (projectId) => (dispatch) => {
  dispatch(setActiveProject(projectId));
  dispatch(setInterview('loading'));
  return API.get(`/interviews/clientTaxYearStatuses/${projectId}`)
    .then((response) => dispatch(setInterview(response.data)));
};

export const setInterviewSection = (section) => ({
  type: actionType.setSection,
  payload: section,
});

export const setCurrentSectionValues = (fieldName, value) => ({
  type: SET_CURRENT_SECTION_VALUES,
  payload: { fieldName, value },
});

export const clearCurrentSectionValues = () => ({
  type: CLEAR_CURRENT_SECTION_VALUES,
});

export const clearLastDependent = (dependentsDisplayedLength) => ({
  type: actionType.deleteLastDependent,
  payload: dependentsDisplayedLength,
});

export const updateErrors = (data) => ({
  type: actionType.updateErrors,
  payload: data,
});

export const isSectionUploading = (isUploading) => ({
  type: actionType.isSectionUploading,
  payload: isUploading,
});

export const changeSectionField = (
  ifDependent = false,
  dependentIndex = null,
  fieldName,
  value,
) => ({
  type: CHANGE_SECTION,
  payload: {
    fieldName, value, ifDependent, dependentIndex,
  },
});

export const showNextInterviewSection = (activeSection) => ({
  type: GOTO_NEXT_SECTION,
  payload: activeSection,
});

export const saveInterviewSection = (
  translation,
  projectId,
  section,
  values,
  final = false,
) => (dispatch) => {
  dispatch(isSectionUploading(true));

  if (final) {
    return API.post(`client-interviews/projects/${projectId}/interview/`).then((response) => {
      if (response.data.success) {
        Alert.success(translation(response.data.message));
        dispatch(getInterview(projectId));
        dispatch(isSectionUploading(false));
        dispatch(refreshTaskList(projectId));
      }
      dispatch(isSectionUploading(false));
    }).catch(() => {
      // dispatch(updateErrors(error.response.data));
      dispatch(isSectionUploading(false));
    });
  }
  return API.put(`client-interviews/projects/${projectId}/interview/${section.fieldName}?taxJobTypeId=${section.TaxJobTypeId}`, values).then((response) => {
    if (response.data.success) {
      Alert.success(translation(response.data.message));
      dispatch(getInterview(projectId)).then(() => {
        dispatch(clearCurrentSectionValues());
        dispatch(showNextInterviewSection(section.fieldName));
        dispatch(isSectionUploading(false));
        dispatch(refreshTaskList(projectId));
      });
    }
    if (response?.data?.errors) {
      dispatch(updateErrors(response.data));
    }
    dispatch(isSectionUploading(false));
  }).catch(() => {
    dispatch(isSectionUploading(false));
  });
};

export const showInterviewSection = (bool) => ({
  type: SHOW_SECTION,
  payload: bool,
});
