import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, Input, Row, Col, Tooltip,
} from 'antd';
import useWebShare from 'react-use-web-share';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import classes from './style.module.scss';
import Translation from '../atoms/Translation';
// import ImportContacts from './ImportContacts';

const ShareButton = ({
  children, url, firmName, clientFirstName,
}) => {
  ShareButton.propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string.isRequired,
    firmName: PropTypes.string.isRequired,
    clientFirstName: PropTypes.string.isRequired,
  };
  const defaultShareMessage = `Hey there,

I've had a great experience working with this provider and highly recommend them!

You can learn more and get started here:
${url}

All the best,
${clientFirstName}${'\n'}

`;

  const separator = 'Again, the url is: ';

  const [showShareModal, setShowShareModal] = useState(false);
  const [shareValue, setShareValue] = useState(defaultShareMessage);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  // const { loading, isSupported, share } = useWebShare();
  const { isSupported, share } = useWebShare();

  const handleShare = () => {
    if (isSupported) {
      share({
        text: defaultShareMessage,
        url,
      });
    } else {
      setShowShareModal(true);
    }
  };

  const copyMessage = () => {
    navigator.clipboard.writeText(shareValue);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 777);
  };

  return (
    <>
      <Button
        type="ghost"
        onClick={handleShare}
      >
        {children}
      </Button>
      <Modal
        visible={showShareModal}
        footer={null}
        title={<Translation text="referrals.share" />}
        onCancel={() => setShowShareModal(false)}
        style={{ fontFamily: 'Lato, sans-serif'}}
      >
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            style={{ cursor: 'text', color: 'black' }}
            value={shareValue}
            onChange={(v) => setShareValue(v.target.value)}
            autoSize={{ minRows: 10, maxRows: 20 }}
          />
          <div className={classes.copiedOverlay} style={showCopiedMessage ? { height: '33px', opacity: 1 } : {}}>
            <p>
              <Translation text="referrals.copied" />
            </p>
          </div>
        </div>
        <Row justify="end" gutter={[10, 10]} style={{ marginTop: '10px' }}>
          {/* <Col>
            <ImportContacts
              defaultShareMessage={defaultShareMessage}
              clientFirstName={clientFirstName}
            />
          </Col> */}
          <Col>
            <Button
              onClick={() => copyMessage()}
            >
              <Translation text="referrals.copyMessage" />
            </Button>
          </Col>
          <Col>
            <EmailShareButton url={url} body={shareValue} subject={`Invitation to ${firmName}`} separator={separator}>
              <div className="ant-btn ant-btn-primary" type="primary">
                <Translation text="referrals.emailMessage" />
              </div>
            </EmailShareButton>
          </Col>
        </Row>
        <Row justify="space-between" style={{ width: 150, marginTop: 10 }}>
          <Tooltip title="Facebook">
            <FacebookShareButton url={url} quote={shareValue}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </Tooltip>
          <Tooltip title="Messenger">
            <FacebookMessengerShareButton url={url} appId="202641201496445">
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          </Tooltip>
          <Tooltip title="Twitter">
            <TwitterShareButton url={url} title={`${shareValue} ${separator}`}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </Tooltip>
          <Tooltip title="What's App">
            <WhatsappShareButton url={url} title={shareValue} separator={separator}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </Tooltip>
        </Row>
      </Modal>
    </>
  );
};

export default ShareButton;
