import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPullToRefresh from 'react-pull-to-refresh';
import styles from './style.module.css';
import Message from '../../atoms/Message';
import MissedCall from '../../atoms/MissedCall';
import 'antd/dist/antd.min.css';

const MessagesList = ({
 userId, data, idLastMessage, getMoreMessages, loadingMoreMessages, channelId,
}) => {
  const { t: translation } = useTranslation();
  const messagesEndRef = useRef(null);
  const messagesListContainerRef = useRef(null);
  
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const getInitialMessagesAndScroll = async () => {
    await getMoreMessages(channelId, 10, data.length);
    scrollToBottom();
  };

  useEffect(() => {
    getInitialMessagesAndScroll();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [idLastMessage]);

  const loadMoreData = async () => {
    if (loadingMoreMessages) {
      return;
    }
    // Record the current scroll position and the height of the messages container before loading new messages
    const currentScrollPosition = messagesListContainerRef.current?.scrollTop ?? 0;
    const currentContainerHeight = messagesListContainerRef.current?.scrollHeight ?? 0;

    await getMoreMessages(channelId, 10, data.length);

    // After new messages have been loaded and rendered, adjust the scroll position
    requestAnimationFrame(() => {
      const newContainerHeight = messagesListContainerRef.current?.scrollHeight ?? 0;
      const heightDifference = newContainerHeight - currentContainerHeight;
      if (messagesListContainerRef.current) {
        messagesListContainerRef.current.scrollTop = currentScrollPosition + heightDifference;
      }
    });
  };

  return (
    <div className={styles.container} ref={messagesListContainerRef}>
      <ReactPullToRefresh
        loading={loadingMoreMessages}
        onRefresh={loadMoreData}
        style={{ textAlign: 'center' }}
        // icon={<Spin />}
      >
        {loadingMoreMessages
          ? (
            <Spin />
          )
          : (
            <Button
              className={styles.loadMoreMessagesBanner}
              onClick={loadMoreData}
            >
              <span>
                Load more messages
              </span>
            </Button>
          )}
        {data.map((item, index) => (
          <Message
            key={`message-${item.id}`}
            createdAt={new Date(item.createAt)}
            mine={userId === item.userId}
            message={item.text}
            last={data.length === index + 1}
            attachment={item.attachment}
            isSms={item.isSms}
          />
          ))}
        <div ref={messagesEndRef} />
      </ReactPullToRefresh>
    </div>
  );
};

MessagesList.defaultProps = {
  idLastMessage: undefined,
};

MessagesList.propTypes = {
  userId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  idLastMessage: PropTypes.string,
};

export default MessagesList;
