/* eslint-disable */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Skeleton, Divider } from 'antd';

import { grey } from '../styles/colors';
import { appContainer, appCard } from '../styles/layout';
import { Lato, heading } from '../styles/fonts';
import Button from './Button/Button';

const styles = StyleSheet.create({
  container: {
    ...appContainer,
    marginBottom: '15px',
    '@media (min-width: 640px)': {
      marginBottom: '20px',
    },
  },
  card: appCard,
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '10px',
    marginBottom: '30px',
    minHeight: '70px',
    ':after': {
      position: 'absolute',
      bottom: 0,
      left: '-60px',
      right: '-60px',
      content: '',
      display: 'block',
      borderBottom: `1px solid ${grey['200']}`,
    },
  },
  titleHolder: {
    display: 'block',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  title: {
    marginRight: '34px',
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '24px',
    letterSpacing: '0.8px',
    color: grey['800'],
  },
  taskCounter: {
    padding: '4px 0 10px',
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '15px',
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    color: grey['500'],
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  columnLeft: {
    '@media (min-width: 768px)': {
      flexBasis: '50%',
    },
    '@media (min-width: 1024px)': {
      flexBasis: '525px',
    },
  },
  columnRight: {
    '@media (min-width: 768px)': {
      paddingLeft: '40px',
      flexBasis: '50%',
    },
    '@media (min-width: 1024px)': {
      paddingLeft: '54px',
      flexBasis: 'calc(100% - 470px)',
    },
  },
  btnBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  btnHolder: {
    position: 'relative',
  },
  third: {
    flexBasis: 'calc(33.33% - 9px)',
    '@media (min-width: 768px)': {
      flexBasis: 'calc(33.33% - 20px)',
    },
  },
  blockTitle: {
    ...heading.h3,
    paddingTop: '4px',
    marginBottom: '30px',
    marginTop: 0,
  },
});

const ProjectCardLoading = () => (
  <div className={css(styles.container)}>
    <div className={css(styles.card)}>
      <div className={css(styles.header)}>
        <div className={css(styles.titleHolder)}>
          <span className={css(styles.title)}>
            <Skeleton
              active
              paragraph={{ rows: 1 }}
            />
          </span>
        </div>
      </div>
      <div className={css(styles.main)}>
        <div className={css(styles.columnLeft)}>
          <div className={css(styles.btnBlock)}>
            <div className={css(styles.third)}>
              <Button
                fullwidth
                primary
              />
            </div>
            <div className={css(styles.third)}>
              <Button
                fullwidth
                primary
              />
            </div>
            <div className={css(styles.third)}>
              <Button
                fullwidth
                primary
              />
            </div>
          </div>
          <Skeleton
            active
            size="large"
            paragraph={{ rows: 2 }}
          />
        </div>
        <div>
          {/* dropdowns */}
          <Skeleton
            active
            paragraph={{ rows: 4 }}
          />
        </div>
        <div className={css(styles.columnRight)}>
          <Skeleton
            active
            size="large"
            paragraph={{ rows: 1 }}
          />
          <Divider />
          <Skeleton
            active
            size="large"
            paragraph={{ rows: 2 }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ProjectCardLoading;
