/* eslint-disable */
import { StyleSheet, css } from "aphrodite";

const Page = ({ children, customStyles }) => {
  const custom = StyleSheet.create({
    page: {
      minHeight: "100vh",
      height: "100%",
    },
  });
  return (
    <div
      className={css(styles.page)}
    >
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    minHeight: "100vh",
    height: "100%",
  },
});

export default Page;