import PropTypes from 'prop-types';
import { Badge, Grid } from 'antd';
import { CheckCircleOutlined, RedoOutlined, WarningOutlined } from '@ant-design/icons';
import { css, StyleSheet } from 'aphrodite';
import Translation from '../../../atoms/Translation';
import { isMobileView } from '../../../../utils/screen';

const { useBreakpoint } = Grid;

const styles = StyleSheet.create({
  badgeStyles: {
    padding: isMobileView() ? '6px 15px' : '6px 10px',
    borderRadius: '21px',
    minWidth: isMobileView() ? undefined : '8.5em',
    color: 'white',
  },
  badgeSent: {
    background: '#fdcb5c',
  },
  badgeComplete: {
    background: '#81ddb1',
  },
  badgeFail: {
    background: '#ff6666',
  },
});

const Badges = ({ isComplete, isEkbaQuizFailed, isEKBAIDScanFailed }) => {
  const screens = useBreakpoint();

  if (isEkbaQuizFailed || isEKBAIDScanFailed) {
    return (
      <Badge
        className={css(styles.badgeStyles, styles.badgeFail)}
        count={(
          <>
            <WarningOutlined style={{ display: 'inline-grid', marginRight: screens.md ? '6px' : 0 }} />
            {screens.md && <Translation text="Failed" />}
          </>
        )}
      />
    );
  }

  if (isComplete) {
    return (
      <Badge
        className={css(styles.badgeStyles, styles.badgeComplete)}
        count={(
          <>
            <CheckCircleOutlined style={{ display: 'inline-grid', marginRight: screens.md ? '6px' : 0 }} />
            {screens.md && <Translation text="documents.firmDocuments.complete" />}
          </>
        )}
      />
    );
  }

  return (
    <Badge
      className={css(styles.badgeStyles, styles.badgeSent)}
      count={(
        <>
          <RedoOutlined style={{ display: 'inline-grid', marginRight: screens.md ? '6px' : 0 }} />
          {screens.md && <Translation text="documents.firmDocuments.sent" />}
        </>
      )}
    />
  );
};

Badges.propTypes = {
  isComplete: PropTypes.bool.isRequired,
  isEkbaQuizFailed: PropTypes.bool.isRequired,
  isEKBAIDScanFailed: PropTypes.bool.isRequired,
};

export default Badges;
