import API from '../../api';

export const SET_CHANNELS = 'SET_CHANNELS';

export const getChannel = () => async (dispatch) => {
  const { data } = await API.get('/channels/user');
  dispatch({
    type: SET_CHANNELS,
    payload: data,
  });
  return data;
};

export const createChannel = async (members) => API.post('/channels', { members });
