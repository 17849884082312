import axios from 'axios';
import API from '../../api';
import api from '../../api/api';
import { completeTask } from './projects';

export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const REMOVE_CLIENT_FILE = 'REMOVE_CLIENT_FILE';

export const documentsLoading = () => ({
  type: LOAD_DOCUMENTS,
});

export const setDocuments = (documents) => ({
  type: SET_DOCUMENTS,
  payload: documents,
});

export const getDocuments = (projectId) => (dispatch) => {
  dispatch(documentsLoading());
  return API
    .get(`client-tax-year-statuses/docs/${projectId}`)
    .then((response) => dispatch(setDocuments(response.data)));
};

export const clearDocuments = () => ({
  type: CLEAR_DOCUMENTS,
});

export const toggleRequestedDoc = (docId, completedAt) => () => {
  const requestData = {
    completedAt,
  };
  return api
    .put(`api/v1/requestedDocs/${docId}`, requestData)
    .then();
};

export const storeFileOnAWS = (fil, fileCon, projectId, taskId) => (
  dispatch,
) => {
  const file = fil;
  const fileConfig = fileCon;
  const bucketName = fileConfig.s3Bucket;
  const data = new FormData();

  // TODO set s3Bucket in url
  delete fileConfig.s3Bucket;

  file.custom_status = 'ready';
  file.postData = fileConfig;
  file.s3 = fileConfig.key;
  // file.taxYearStatusId = taxYearStatusId;

  file.finalName = fileConfig.finalName ? fileConfig.finalName : file.name;

  // let finalName = file.name;
  delete file.postData.finalName;

  // if(file.postData.finalName) {
  //     finalName = file.postData.finalName;
  //     delete file.postData.finalName;
  // }

  // eslint-disable-next-line
  for (const key in file.postData) {
    data.append(key, file.postData[key]);
  }

  data.append(
    'x-amz-meta-original-file-name',
    fileConfig.finalName ? fileConfig.finalName : file.name,
  );
  data.append('Content-length', file.size);
  data.append('Content-type', file.type);
  data.append('x-amz-server-side-encryption', 'AES256');
  data.append('x-ignore-filenameupdate', '');
  data.append('acl', 'private');
  data.append('file', file);

  const keyIfSuccess = { keyName: file.postData.key, taskId };

  return axios({
    method: 'POST',
    baseURL: `https://${bucketName}.s3.amazonaws.com/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
    .then(() => API
      .post('files/recordFile', keyIfSuccess)
      .then(() => {
        dispatch(getDocuments(projectId));
        if (taskId) return dispatch(completeTask(projectId, [taskId]));
        return null;
      }))
    .catch(() => {
      // console.error(error);
    });
};

export const removeClientFile = (fileId) => ({
  type: REMOVE_CLIENT_FILE,
  payload: fileId,
});

// export const saveFile = (projectId, file, fileConfig, taskId) => (dispatch) => API
//   .post(`files/sign/${projectId}`, fileConfig)
//   .then((response) => dispatch(storeFileOnAWS(file, response.data, projectId, taskId)));

export const deleteFile = (fileId) => (dispatch) => API.delete(`/files/${fileId}`).then((response) => {
  if (response.data.success) {
    dispatch(removeClientFile(fileId));
  }
});
