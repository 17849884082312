import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Image,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FieldType } from '../../../../../graphql';
import { useGetSignedSignatureBySignatureFieldIdLazyQuery } from '../../../../../graphql/queries/signatureFields';

const SignatureField = ({
  id,
  top,
  left,
  height,
  width,
  complete,
  signature,
  handleFieldClick,
  pendingSubmit,
  transformation,
  isMobileView,
}) => {
  const [getSignedImage] = useGetSignedSignatureBySignatureFieldIdLazyQuery();
  const [signatureURL, setSignatureURL] = useState(null);
  
  useEffect(() => {
    if (!pendingSubmit) {
      // get signed signature s3 url
      getSignedImage({
        variables: {
          signatureFieldId: id,
        },
      }).then((res) => {
        setSignatureURL(res?.data?.getSignedSignatureBySignatureFieldId);
      }).catch((err) => {
        console.log('err:', err);
      });
    }
  }, [signature]);

  return (
    <div
      className={`field ${complete && "completed-field"}`}
      style={{
        top,
        left,
        height,
        width,
        cursor: pendingSubmit ? 'pointer' : 'default',
        display: isMobileView ? 'inline-flex' : 'block',
      }}
      onClick={() => {
        if (pendingSubmit) {
          handleFieldClick(FieldType.Signature, id);
        }
      }}
    >
      <Row
        justify="center"
        gutter={10}
        style={{ transform: `scale(${transformation})` }}
      >
        <Col span={6}>
          {pendingSubmit && (
            <div style={{ textAlign: 'center' }}>
              <CloseOutlined color="#595959" />
            </div>
          )}
          
        </Col>
        <Col span={18}>
          <p style={{ textDecoration: 'underline', color: '#595959', margin: 0 }}>
            {complete
              ? (
                <Image
                  src={signatureURL || signature}
                  alt="signature"
                  preview={false}
                  height={height}
                />
              )
              : 'Signature'}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default SignatureField;
