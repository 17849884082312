import React from 'react';

const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16">
    <g fill="#3D80F3" fillRule="nonzero">
      <path d="M6.443 8.667h.111c1.014-.017 1.835-.36 2.441-1.016 1.334-1.446 1.112-3.924 1.088-4.16C9.996 1.715 9.123.866 8.403.47 7.866.173 7.24.013 6.54 0H6.48c-.384 0-1.14.06-1.863.456-.728.397-1.614 1.246-1.7 3.035-.025.236-.247 2.714 1.087 4.16.602.656 1.423.999 2.438 1.016zM3.842 3.574c0-.01.003-.02.003-.027C3.96 1.16 5.722.903 6.477.903h.042c.935.02 2.525.386 2.632 2.644 0 .01 0 .02.004.027.003.023.246 2.288-.856 3.48-.436.474-1.018.707-1.783.713H6.48c-.762-.006-1.347-.24-1.78-.712-1.098-1.186-.863-3.461-.86-3.481z" />
      <path d="M13 12.682v-.01c0-.025-.004-.051-.004-.08-.019-.64-.06-2.135-1.434-2.612l-.031-.01c-1.428-.372-2.615-1.211-2.627-1.22a.421.421 0 0 0-.595.106.441.441 0 0 0 .104.607c.054.039 1.314.933 2.89 1.346.737.268.82 1.072.842 1.809 0 .029 0 .055.003.08.003.291-.016.74-.067.998-.512.297-2.522 1.324-5.58 1.324-3.044 0-5.066-1.03-5.582-1.327a5.66 5.66 0 0 1-.067-.998c0-.026.003-.051.003-.08.023-.737.105-1.54.842-1.809 1.576-.413 2.836-1.31 2.89-1.346a.441.441 0 0 0 .104-.607.421.421 0 0 0-.595-.107c-.012.01-1.193.85-2.627 1.22a.393.393 0 0 0-.031.01C.064 10.457.023 11.953.004 12.59c0 .029 0 .055-.003.08v.01c-.003.168-.006 1.03.161 1.463a.413.413 0 0 0 .165.203c.095.065 2.37 1.544 6.178 1.544 3.807 0 6.083-1.482 6.178-1.544a.43.43 0 0 0 .164-.203c.158-.43.155-1.292.152-1.46z" />
    </g>
  </svg>
);

export default User;
