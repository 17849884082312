import PropTypes from 'prop-types';
import {
  Form, Input, Button, Typography, message,
} from 'antd';
import { connect } from 'react-redux';
import { updateFieldSession } from '../../redux/actions/session';
import { useUpdateUserUniqueLinkMutation } from '../../graphql/mutations/users';

const { Paragraph } = Typography;

const UpdateForm = ({
  setIsUpdatingCode, referralCode, dispatch, session,
}) => {
  UpdateForm.propTypes = {
    setIsUpdatingCode: PropTypes.func.isRequired,
    referralCode: PropTypes.string.isRequired,
  };
  const { graphSession } = session;
  const {
    userId,
  } = graphSession;

  // graphql
  const [updateReferralCode, { loading: loadingUpdateReferralCode }] = useUpdateUserUniqueLinkMutation({
    onError: (error) => {
      let errorMessage;
      const errorName = error.graphQLErrors[0]?.name;
      if (errorName === 'RecordAlreadyInDb') {
        errorMessage = 'This name is already in use.';
      } else {
        errorMessage = error.message;
      }
      message.error(`Error updating referral code. ${errorMessage}`);
    },
    awaitRefetchQueries: true,
  });

  const onFinish = (values) => {
    updateReferralCode({
      variables: {
        updateUserInput: {
          id: userId,
          uniqueLink: values.referralCode,
        },
      },
      onCompleted: () => {
        message.success('Referral code updated');
        dispatch(updateFieldSession('uniqueLink', values.referralCode));
        setIsUpdatingCode(false);
      },
    })
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="updateReferralCode"
      initialValues={{ referralCode }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        // label="code"
        name="referralCode"
        rules={[{ required: false, pattern: new RegExp('^[a-zA-Z0-9_.-]*$'), message: 'Special characters not allowed' }]}
        style={{ marginBottom: '0px' }}
      >
        <Input style={{ paddingLeft: '0px' }} />
      </Form.Item>

      <Form.Item>
        <Paragraph style={{ maxWidth: '175px' }}>
          Edit your custom URL here. Please input letters and numbers only. If you change your URL, your prior URL will not track referrals.
        </Paragraph>
        <Button type="link" htmlType="button" onClick={() => setIsUpdatingCode(false)}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={loadingUpdateReferralCode}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(UpdateForm);
