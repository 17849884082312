import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReviewByTaxYearInterviewIdQueryVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetReviewByTaxYearInterviewIdQuery = { __typename?: 'Query', ReviewByTaxYearInterviewId: { __typename?: 'ReviewsEntity', id: string, reviewContent?: string | null, reviewValue?: number | null, taxYearInterviewId?: string | null } };


export const GetReviewByTaxYearInterviewIdDocument = gql`
    query GetReviewByTaxYearInterviewId($taxYearInterviewId: String!) {
  ReviewByTaxYearInterviewId(taxYearInterviewId: $taxYearInterviewId) {
    id
    reviewContent
    reviewValue
    taxYearInterviewId
  }
}
    `;

/**
 * __useGetReviewByTaxYearInterviewIdQuery__
 *
 * To run a query within a React component, call `useGetReviewByTaxYearInterviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewByTaxYearInterviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewByTaxYearInterviewIdQuery({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetReviewByTaxYearInterviewIdQuery(baseOptions: Apollo.QueryHookOptions<GetReviewByTaxYearInterviewIdQuery, GetReviewByTaxYearInterviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewByTaxYearInterviewIdQuery, GetReviewByTaxYearInterviewIdQueryVariables>(GetReviewByTaxYearInterviewIdDocument, options);
      }
export function useGetReviewByTaxYearInterviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewByTaxYearInterviewIdQuery, GetReviewByTaxYearInterviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewByTaxYearInterviewIdQuery, GetReviewByTaxYearInterviewIdQueryVariables>(GetReviewByTaxYearInterviewIdDocument, options);
        }
export type GetReviewByTaxYearInterviewIdQueryHookResult = ReturnType<typeof useGetReviewByTaxYearInterviewIdQuery>;
export type GetReviewByTaxYearInterviewIdLazyQueryHookResult = ReturnType<typeof useGetReviewByTaxYearInterviewIdLazyQuery>;
export type GetReviewByTaxYearInterviewIdQueryResult = Apollo.QueryResult<GetReviewByTaxYearInterviewIdQuery, GetReviewByTaxYearInterviewIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetReviewByTaxYearInterviewId: 'GetReviewByTaxYearInterviewId'
  }
}