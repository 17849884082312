import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ServiceWorkerProvider } from './useServiceWorker';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';

import client from './graphql/client';
import App from './App';
import reduxStore from './redux/store/store';
import './internationalization/i18n';
import './styles/index.scss';
import { PlanAccessProvider } from './components/atoms/PlanAccessProvider';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false })],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in dev and then sample at a lower rate in prod.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <BrowserRouter>
          <ServiceWorkerProvider>
            <PlanAccessProvider>
              <App />
            </PlanAccessProvider>
          </ServiceWorkerProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
