import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from '../components/Layout/Page';
import Header from '../components/Layout/Header';
import ResetPassword from '../components/Pages/ResetPassword';
import { useGetPasswordResetIncludeFirmQuery } from '../graphql/queries/passwordReset';
import { useGetCustomDomainByNameLazyQuery } from '../graphql/queries/customDomain';

const ResetPasswordContainer = () => {
  const { id } = useParams();
  const [errorUniqueId, setErrorUniqueId] = useState(false);
  const [customLayout, setCustomLayout] = useState(null);

  const { origin } = window.location;

  // graphql
  // fetch user and firm name by PasswordResetId
  // this is just to find and display errors
  const { data: passwordResetData } = useGetPasswordResetIncludeFirmQuery({
    variables: { id },
    onError: (err) => {
      if (err.message.includes('1001')) {
        setErrorUniqueId('CannotFindRecordException');
      } else if (err.message.includes('4002')) {
        setErrorUniqueId('PasswordResetExpiredException');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: () => {
      setErrorUniqueId(null);
    },
  });
  // fetch CustomLayout by custom domain
  const [fetchCustomLayout, { data: customLayoutData }] = useGetCustomDomainByNameLazyQuery();

  useEffect(() => {
    if (origin && process.env.REACT_APP_TAXAROO_CLIENT_URL) {
      if (origin !== process.env.REACT_APP_TAXAROO_CLIENT_URL) {
        // fetch the CustomLayout
        const theDomain = origin.replace('https://', '').replace('http://', '');
        fetchCustomLayout({
          variables: { name: theDomain },
          onCompleted: (d) => {
            setCustomLayout(d?.CustomDomainByName?.FirmAccount?.CustomLayout || null)
          },
          onError: () => {
            setCustomLayout(null);
          }
        });
      }
    }
  }, [origin]);

  return (
    <Page customStyles={customLayout}>
      <Header logo={customLayout?.logo} customStyles={customLayout} />
      <ResetPassword errorUniqueId={errorUniqueId} id={id} />
    </Page>
  );
};

const mapStateToProps = ({ profile: { branding } }) => ({
  branding,
});

export default withRouter(connect(mapStateToProps)(ResetPasswordContainer));
