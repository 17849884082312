import * as Types from '../index';

import { gql } from '@apollo/client';
import { SectionEntityFragmentDoc } from '../common/fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddJobMutationVariables = Types.Exact<{
  addJobInput: Types.AddJobInput;
}>;


export type AddJobMutation = { __typename?: 'Mutation', AddTaxYearInterviewJob: { __typename?: 'TaxYearInterviewEntity', id: string } };

export type AnswersFragment = { __typename?: 'AnswerEntity', id: string, description: string, taxYearInterviewId: string, questionId: string, createAt: any, updateAt: any, completedAt?: any | null, requestAt?: any | null, isPrePopulated: boolean };

export type SaveAnswersMutationVariables = Types.Exact<{
  saveAnswerInput: Types.SaveAnswerInput;
}>;


export type SaveAnswersMutation = { __typename?: 'Mutation', saveAnswer: { __typename?: 'AnswerCheckSectionEntity', sectionIsComplete: boolean, answers: Array<{ __typename?: 'AnswerEntity', id: string, description: string, taxYearInterviewId: string, questionId: string, createAt: any, updateAt: any, completedAt?: any | null, requestAt?: any | null, isPrePopulated: boolean }> } };

export type CreateAnswerMutationVariables = Types.Exact<{
  createAnswerInput: Types.CreateAnswerInput;
}>;


export type CreateAnswerMutation = { __typename?: 'Mutation', createAnswer: { __typename?: 'AnswerEntity', id: string, description: string, taxYearInterviewId: string, questionId: string, createAt: any, updateAt: any, completedAt?: any | null, requestAt?: any | null, isPrePopulated: boolean } };

export type UpdateAnswerMutationVariables = Types.Exact<{
  updateAnswerInput: Types.UpdateAnswerInput;
}>;


export type UpdateAnswerMutation = { __typename?: 'Mutation', updateAnswer: { __typename?: 'AnswerEntity', id: string, description: string, taxYearInterviewId: string, questionId: string, createAt: any, updateAt: any, completedAt?: any | null, requestAt?: any | null, isPrePopulated: boolean } };

export type AddSubSectionMutationVariables = Types.Exact<{
  parentId: Types.Scalars['String'];
  addSubSectionInput: Types.AddSubSectionInput;
}>;


export type AddSubSectionMutation = { __typename?: 'Mutation', addSubSection: { __typename?: 'SectionEntity', id: string, parentId?: string | null, interviewId: string, order: number, createAt: any, updateAt: any, isSpecial: boolean, deleteAt?: any | null, specialType?: Types.SpecialSectionsType | null, Conditionals?: Array<{ __typename?: 'ConditionalEntity', id: string, createAt: any, updateAt?: any | null, optionId: string, sectionId: string, Options?: { __typename?: 'OptionsEntity', questionId: string } | null }> | null, Questions?: Array<{ __typename?: 'QuestionEntity', id: string, sectionId: string, type: Types.QuestionType, order: number, required: boolean, createAt: any, updateAt: any, deleteAt?: any | null, Options?: Array<{ __typename?: 'OptionsEntity', id: string, questionId: string, createAt: any, updateAt: any, deleteAt?: any | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null }> | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null }> | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null } };

export type DeleteSectionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteSectionMutation = { __typename?: 'Mutation', deleteSection: { __typename?: 'SectionEntity', id: string, parentId?: string | null, interviewId: string, order: number, createAt: any, updateAt: any, isSpecial: boolean, deleteAt?: any | null, specialType?: Types.SpecialSectionsType | null, Conditionals?: Array<{ __typename?: 'ConditionalEntity', id: string, createAt: any, updateAt?: any | null, optionId: string, sectionId: string, Options?: { __typename?: 'OptionsEntity', questionId: string } | null }> | null, Questions?: Array<{ __typename?: 'QuestionEntity', id: string, sectionId: string, type: Types.QuestionType, order: number, required: boolean, createAt: any, updateAt: any, deleteAt?: any | null, Options?: Array<{ __typename?: 'OptionsEntity', id: string, questionId: string, createAt: any, updateAt: any, deleteAt?: any | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null }> | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null }> | null, Translations?: Array<{ __typename?: 'TranslationEntity', id: string, description: string, optionId?: string | null, questionId?: string | null, languageId: string, sectionId?: string | null, createAt: any, updateAt: any, deleteAt?: any | null, Language?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null }> | null } };

export type SetTaxInterviewSectionCompleteValueMutationVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
  sectionId: Types.Scalars['String'];
  value: Types.Scalars['Boolean'];
}>;


export type SetTaxInterviewSectionCompleteValueMutation = { __typename?: 'Mutation', setTaxInterviewSectionCompleteValue: { __typename?: 'TaxYearInterviewSectionEntity', id: string, taxYearInterviewId: string, sectionId: string, markedAsComplete: boolean, prePopulatedConfirmed: boolean } };

export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutationVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
  sectionId: Types.Scalars['String'];
  value: Types.Scalars['Boolean'];
}>;


export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutation = { __typename?: 'Mutation', setTaxInterviewSectionPrePopulatedConfirmedValue: { __typename?: 'TaxYearInterviewSectionEntity', id: string, taxYearInterviewId: string, sectionId: string, markedAsComplete: boolean, prePopulatedConfirmed: boolean } };

export type CreateSectionAsCompleteMutationVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
  sectionId: Types.Scalars['String'];
}>;


export type CreateSectionAsCompleteMutation = { __typename?: 'Mutation', createSectionAsComplete: { __typename?: 'TaxYearInterviewSectionEntity', id: string, taxYearInterviewId: string, sectionId: string, markedAsComplete: boolean, prePopulatedConfirmed: boolean } };

export const AnswersFragmentDoc = gql`
    fragment Answers on AnswerEntity {
  id
  description
  taxYearInterviewId
  questionId
  createAt
  updateAt
  completedAt
  requestAt
  isPrePopulated
}
    `;
export const AddJobDocument = gql`
    mutation addJob($addJobInput: AddJobInput!) {
  AddTaxYearInterviewJob(addJobInput: $addJobInput) {
    id
  }
}
    `;
export type AddJobMutationFn = Apollo.MutationFunction<AddJobMutation, AddJobMutationVariables>;

/**
 * __useAddJobMutation__
 *
 * To run a mutation, you first call `useAddJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobMutation, { data, loading, error }] = useAddJobMutation({
 *   variables: {
 *      addJobInput: // value for 'addJobInput'
 *   },
 * });
 */
export function useAddJobMutation(baseOptions?: Apollo.MutationHookOptions<AddJobMutation, AddJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddJobMutation, AddJobMutationVariables>(AddJobDocument, options);
      }
export type AddJobMutationHookResult = ReturnType<typeof useAddJobMutation>;
export type AddJobMutationResult = Apollo.MutationResult<AddJobMutation>;
export type AddJobMutationOptions = Apollo.BaseMutationOptions<AddJobMutation, AddJobMutationVariables>;
export const SaveAnswersDocument = gql`
    mutation SaveAnswers($saveAnswerInput: SaveAnswerInput!) {
  saveAnswer(saveAnswerInput: $saveAnswerInput) {
    sectionIsComplete
    answers {
      ...Answers
    }
  }
}
    ${AnswersFragmentDoc}`;
export type SaveAnswersMutationFn = Apollo.MutationFunction<SaveAnswersMutation, SaveAnswersMutationVariables>;

/**
 * __useSaveAnswersMutation__
 *
 * To run a mutation, you first call `useSaveAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnswersMutation, { data, loading, error }] = useSaveAnswersMutation({
 *   variables: {
 *      saveAnswerInput: // value for 'saveAnswerInput'
 *   },
 * });
 */
export function useSaveAnswersMutation(baseOptions?: Apollo.MutationHookOptions<SaveAnswersMutation, SaveAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAnswersMutation, SaveAnswersMutationVariables>(SaveAnswersDocument, options);
      }
export type SaveAnswersMutationHookResult = ReturnType<typeof useSaveAnswersMutation>;
export type SaveAnswersMutationResult = Apollo.MutationResult<SaveAnswersMutation>;
export type SaveAnswersMutationOptions = Apollo.BaseMutationOptions<SaveAnswersMutation, SaveAnswersMutationVariables>;
export const CreateAnswerDocument = gql`
    mutation CreateAnswer($createAnswerInput: CreateAnswerInput!) {
  createAnswer(createAnswerInput: $createAnswerInput) {
    ...Answers
  }
}
    ${AnswersFragmentDoc}`;
export type CreateAnswerMutationFn = Apollo.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      createAnswerInput: // value for 'createAnswerInput'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, options);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const UpdateAnswerDocument = gql`
    mutation UpdateAnswer($updateAnswerInput: UpdateAnswerInput!) {
  updateAnswer(updateAnswerInput: $updateAnswerInput) {
    ...Answers
  }
}
    ${AnswersFragmentDoc}`;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      updateAnswerInput: // value for 'updateAnswerInput'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, options);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const AddSubSectionDocument = gql`
    mutation AddSubSection($parentId: String!, $addSubSectionInput: AddSubSectionInput!) {
  addSubSection(parentId: $parentId, addSubSectionInput: $addSubSectionInput) {
    ...SectionEntity
  }
}
    ${SectionEntityFragmentDoc}`;
export type AddSubSectionMutationFn = Apollo.MutationFunction<AddSubSectionMutation, AddSubSectionMutationVariables>;

/**
 * __useAddSubSectionMutation__
 *
 * To run a mutation, you first call `useAddSubSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubSectionMutation, { data, loading, error }] = useAddSubSectionMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      addSubSectionInput: // value for 'addSubSectionInput'
 *   },
 * });
 */
export function useAddSubSectionMutation(baseOptions?: Apollo.MutationHookOptions<AddSubSectionMutation, AddSubSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubSectionMutation, AddSubSectionMutationVariables>(AddSubSectionDocument, options);
      }
export type AddSubSectionMutationHookResult = ReturnType<typeof useAddSubSectionMutation>;
export type AddSubSectionMutationResult = Apollo.MutationResult<AddSubSectionMutation>;
export type AddSubSectionMutationOptions = Apollo.BaseMutationOptions<AddSubSectionMutation, AddSubSectionMutationVariables>;
export const DeleteSectionDocument = gql`
    mutation DeleteSection($id: String!) {
  deleteSection(id: $id) {
    ...SectionEntity
  }
}
    ${SectionEntityFragmentDoc}`;
export type DeleteSectionMutationFn = Apollo.MutationFunction<DeleteSectionMutation, DeleteSectionMutationVariables>;

/**
 * __useDeleteSectionMutation__
 *
 * To run a mutation, you first call `useDeleteSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSectionMutation, { data, loading, error }] = useDeleteSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSectionMutation, DeleteSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSectionMutation, DeleteSectionMutationVariables>(DeleteSectionDocument, options);
      }
export type DeleteSectionMutationHookResult = ReturnType<typeof useDeleteSectionMutation>;
export type DeleteSectionMutationResult = Apollo.MutationResult<DeleteSectionMutation>;
export type DeleteSectionMutationOptions = Apollo.BaseMutationOptions<DeleteSectionMutation, DeleteSectionMutationVariables>;
export const SetTaxInterviewSectionCompleteValueDocument = gql`
    mutation setTaxInterviewSectionCompleteValue($taxYearInterviewId: String!, $sectionId: String!, $value: Boolean!) {
  setTaxInterviewSectionCompleteValue(
    taxYearInterviewId: $taxYearInterviewId
    sectionId: $sectionId
    value: $value
  ) {
    id
    taxYearInterviewId
    sectionId
    markedAsComplete
    prePopulatedConfirmed
  }
}
    `;
export type SetTaxInterviewSectionCompleteValueMutationFn = Apollo.MutationFunction<SetTaxInterviewSectionCompleteValueMutation, SetTaxInterviewSectionCompleteValueMutationVariables>;

/**
 * __useSetTaxInterviewSectionCompleteValueMutation__
 *
 * To run a mutation, you first call `useSetTaxInterviewSectionCompleteValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaxInterviewSectionCompleteValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaxInterviewSectionCompleteValueMutation, { data, loading, error }] = useSetTaxInterviewSectionCompleteValueMutation({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *      sectionId: // value for 'sectionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetTaxInterviewSectionCompleteValueMutation(baseOptions?: Apollo.MutationHookOptions<SetTaxInterviewSectionCompleteValueMutation, SetTaxInterviewSectionCompleteValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaxInterviewSectionCompleteValueMutation, SetTaxInterviewSectionCompleteValueMutationVariables>(SetTaxInterviewSectionCompleteValueDocument, options);
      }
export type SetTaxInterviewSectionCompleteValueMutationHookResult = ReturnType<typeof useSetTaxInterviewSectionCompleteValueMutation>;
export type SetTaxInterviewSectionCompleteValueMutationResult = Apollo.MutationResult<SetTaxInterviewSectionCompleteValueMutation>;
export type SetTaxInterviewSectionCompleteValueMutationOptions = Apollo.BaseMutationOptions<SetTaxInterviewSectionCompleteValueMutation, SetTaxInterviewSectionCompleteValueMutationVariables>;
export const SetTaxInterviewSectionPrePopulatedConfirmedValueDocument = gql`
    mutation setTaxInterviewSectionPrePopulatedConfirmedValue($taxYearInterviewId: String!, $sectionId: String!, $value: Boolean!) {
  setTaxInterviewSectionPrePopulatedConfirmedValue(
    taxYearInterviewId: $taxYearInterviewId
    sectionId: $sectionId
    value: $value
  ) {
    id
    taxYearInterviewId
    sectionId
    markedAsComplete
    prePopulatedConfirmed
  }
}
    `;
export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutationFn = Apollo.MutationFunction<SetTaxInterviewSectionPrePopulatedConfirmedValueMutation, SetTaxInterviewSectionPrePopulatedConfirmedValueMutationVariables>;

/**
 * __useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation__
 *
 * To run a mutation, you first call `useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaxInterviewSectionPrePopulatedConfirmedValueMutation, { data, loading, error }] = useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *      sectionId: // value for 'sectionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation(baseOptions?: Apollo.MutationHookOptions<SetTaxInterviewSectionPrePopulatedConfirmedValueMutation, SetTaxInterviewSectionPrePopulatedConfirmedValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaxInterviewSectionPrePopulatedConfirmedValueMutation, SetTaxInterviewSectionPrePopulatedConfirmedValueMutationVariables>(SetTaxInterviewSectionPrePopulatedConfirmedValueDocument, options);
      }
export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutationHookResult = ReturnType<typeof useSetTaxInterviewSectionPrePopulatedConfirmedValueMutation>;
export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutationResult = Apollo.MutationResult<SetTaxInterviewSectionPrePopulatedConfirmedValueMutation>;
export type SetTaxInterviewSectionPrePopulatedConfirmedValueMutationOptions = Apollo.BaseMutationOptions<SetTaxInterviewSectionPrePopulatedConfirmedValueMutation, SetTaxInterviewSectionPrePopulatedConfirmedValueMutationVariables>;
export const CreateSectionAsCompleteDocument = gql`
    mutation createSectionAsComplete($taxYearInterviewId: String!, $sectionId: String!) {
  createSectionAsComplete(
    taxYearInterviewId: $taxYearInterviewId
    sectionId: $sectionId
  ) {
    id
    taxYearInterviewId
    sectionId
    markedAsComplete
    prePopulatedConfirmed
  }
}
    `;
export type CreateSectionAsCompleteMutationFn = Apollo.MutationFunction<CreateSectionAsCompleteMutation, CreateSectionAsCompleteMutationVariables>;

/**
 * __useCreateSectionAsCompleteMutation__
 *
 * To run a mutation, you first call `useCreateSectionAsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSectionAsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSectionAsCompleteMutation, { data, loading, error }] = useCreateSectionAsCompleteMutation({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useCreateSectionAsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSectionAsCompleteMutation, CreateSectionAsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSectionAsCompleteMutation, CreateSectionAsCompleteMutationVariables>(CreateSectionAsCompleteDocument, options);
      }
export type CreateSectionAsCompleteMutationHookResult = ReturnType<typeof useCreateSectionAsCompleteMutation>;
export type CreateSectionAsCompleteMutationResult = Apollo.MutationResult<CreateSectionAsCompleteMutation>;
export type CreateSectionAsCompleteMutationOptions = Apollo.BaseMutationOptions<CreateSectionAsCompleteMutation, CreateSectionAsCompleteMutationVariables>;
export const namedOperations = {
  Mutation: {
    addJob: 'addJob',
    SaveAnswers: 'SaveAnswers',
    CreateAnswer: 'CreateAnswer',
    UpdateAnswer: 'UpdateAnswer',
    AddSubSection: 'AddSubSection',
    DeleteSection: 'DeleteSection',
    setTaxInterviewSectionCompleteValue: 'setTaxInterviewSectionCompleteValue',
    setTaxInterviewSectionPrePopulatedConfirmedValue: 'setTaxInterviewSectionPrePopulatedConfirmedValue',
    createSectionAsComplete: 'createSectionAsComplete'
  },
  Fragment: {
    Answers: 'Answers'
  }
}