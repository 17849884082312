/* eslint-disable */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Icon } from '../components/Icon/Icon';
import { heading } from '../styles/fonts';
import { color } from '../styles/colors';

// eslint-disable-next-line react/display-name
const withOverlay = (Component, shouldHavePadding = true, isDocumentModal = false) => class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { close: false };
    this.mounted = false;
  }

  onCloseModal(e) {
    if (e.target === e.currentTarget && this.mounted) {
      this.setState({ close: true });
    }
  }

  componentDidMount() {
    this.mounted = true;
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
    this.mounted = false;
  }

  render() {
    const { title, size } = this.props;
    return (
      <div className={css(styles.overlay)} onClick={this.onCloseModal.bind(this)}>
        <div className={css(
          styles.modal,
          shouldHavePadding ? styles.modalPadding30 : styles.modalPadding0,
          size === 'small' && styles.small,
          size === 'medium' && styles.medium,
          size === 'large' && styles.large,
          isDocumentModal && styles.forDocs,
        )}
        >
          <div className={css(styles.title)}>
            {title && <span className={css(styles.h2)}>{title}</span>}
            <Icon exit onClick={this.onCloseModal.bind(this)} />
          </div>
          <Component close={this.state.close} {...this.props} />
        </div>
      </div>
    );
  }
};

const styles = StyleSheet.create({
  modalPadding30: {
    padding: '30px 20px',
    '@media (max-width: 550px)': { padding: '30px 20px' },
  },
  modalPadding0: {
    padding: '30px 20px',
    '@media (max-width: 550px)': { padding: '30px 0px' },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(159, 159, 159, 0.3)',
    overflowY: 'auto',
    zIndex: '10',
  },
  modal: {
    position: 'absolute',
    top: '40px',
    width: '100%',
    minHeight: '460px',
    marginBottom: '40px',
    backgroundColor: color.white,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    zIndex: '100',
  },
  small: {
    '@media (min-width: 400px)': {
      width: '360px',
      left: 'calc(50% - 180px)',
      borderRadius: '5px',
    },
    '@media (min-width: 640px)': {
      top: '170px',
      width: '420px',
      minHeight: '100px',
      padding: '40px',
      left: 'calc(50% - 210px)',
    },
  },
  medium: {
    '@media (min-width: 400px)': {
      width: 'calc(100% - 20px)',
      left: '10px',
      borderRadius: '5px',
    },
    '@media (min-width: 640px)': {
      top: '170px',
      width: 'calc(100% - 40px)',
      minHeight: '100px',
      padding: '40px',
      left: '20px',
    },
    '@media (min-width: 768px)': {
      top: '110px',
      width: '720px',
      left: 'calc(50% - 360px)',
    },
    '@media (min-width: 1024px)': {
      width: '820px',
      left: 'calc(50% - 410px)',
    },
  },
  large: {
    '@media (min-width: 400px)': {
      borderRadius: '5px',
    },
    '@media (min-width: 640px)': {
      width: 'calc(100% - 40px)',
      padding: '40px 40px 80px',
      left: '20px',
    },
    '@media (min-width: 768px)': {
      top: '110px',
      width: '720px',
      left: 'calc(50% - 360px)',
    },
    '@media (min-width: 1024px)': {
      width: '980px',
      left: 'calc(50% - 490px)',
    },
  },
  forDocs: {
    '@media (min-width: 1350px)': {
      width: '1200px',
      left: 'calc(50% - 600px)',
    },
    '@media (min-width: 1600px)': {
      width: '1400px',
      left: 'calc(50% - 700px)',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
    '@media (max-width: 550px)': {
      paddingRight: '20px',
      paddingLeft: '20px',
    },
  },
  h2: {
    ...heading.modalHeading,
  },
});

export default withOverlay;