import {
  CLEAR_DOCUMENTS,
  LOAD_DOCUMENTS,
  REMOVE_CLIENT_FILE,
  SET_DOCUMENTS,
} from '../actions/documents';

const initialStore = {
  documents: null,
};

export default (state = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_DOCUMENTS:
      return {
        documents: 'loading',
      };
    case SET_DOCUMENTS:
      return {
        documents: payload,
      };
    case CLEAR_DOCUMENTS:
      return {
        documents: null,
      };
    case REMOVE_CLIENT_FILE:
      return {
        documents: {
          ...state.documents,
          clientFiles: state.documents.clientFiles.filter((f) => f.id !== payload),
        },
      };
    default:
      return state;
  }
};
