/* eslint-disable quote-props */
export default {
  translation: {
    'words.save': 'Save',
    'words.other': 'Other',
    'header.previousUser': 'Return to your account',
    'header.logout': 'Logout',
    'header.settings': 'Settings',
    'header.titleTest': 'test head',
    'header.yourProjects': 'Your Projects',
    'header.createProject': 'Create Project',
    'header.createProject.tooltip': 'New projects are used for different years and different types of returns or engagements.',
    'header.signIn': 'Sign In',
    'settings.lang.es': 'Spanish',
    'settings.lang.en': 'English',
    'settings.loading': 'Loading',
    'settings.message.success': 'Saved!',
    'settings.message.save': 'Save Changes',
    'settings.changePassword': 'Change Password',
    'settings.pass.currentPassword': 'Current Password',
    'settings.pass.newPassword': 'New Password',
    'settings.pass.confirmNewPassword': 'Confirm New Password',
    'settings.pass.passwordMust': 'Password must be at least 8 characters long.',
    'settings.changeLanguage': 'Change Language',
    'settings.changeLanguage.error':
      'An error occurred while changing the language',
    'settings.changeLanguage.success': 'Language has been changed successfully',
    'settings.twoFactorAuthentication': 'Two-Factor Authentication',
    'settings.twofa.enable':
      'Enable two-factor authentication to add an additional layer of security to your account.',
    'settings.twofa.scan': 'Scan this barcode with an authenticator app like: Authy, Google Authenticator or Microsoft Authenticator.',
    'settings.twofa.getKey1': "If you can't scan the barcode above with your authenticator app",
    'settings.twofa.getKey2': 'Copy Key',
    'settings.twofa.getKey3': 'for manual entry into the app.',
    'settings.twofa.enterCode': 'Enter the six-digital code generated by your authenticator app',
    'settings.twofa.saveChanges': 'Save Changes',
    'project.complete': 'Complete',
    'project.inProgress': 'In Progress',
    'project.interview': 'Interview',
    'project.documents': 'Documents',
    'project.invoices': 'Invoices',
    'project.yourOpenTasks': 'Your Pending Tasks',
    'project.openTasks': 'Pending Tasks',
    'project.completedTasks': 'Completed Tasks',
    'contactinfo.title': 'Contact Info',
    'contactinfo.changeReview': 'Change Review',
    'contactinfo.leaveReview': 'Leave a Review',
    'contactinfo.yourRepresentative': 'Your representative',
    'contactinfo.taxQuestions': 'Tax questions',
    'contactinfo.yourReview': 'Your review',
    'contactinfo.scheduleAnAppointment': 'Schedule an Appointment',
    'referrals.title': 'Refer',
    'referrals.cta': 'Share your referral link',
    'referrals.totalReferred': 'Total Referred',
    'referrals.more': 'More ways to share',
    'referrals.share': 'Share',
    'referrals.copy': 'Copy',
    'referrals.copied': 'Copied!',
    'referrals.copyMessage': 'Copy message',
    'referrals.emailMessage': 'Email message',
    'referrals.completed': 'Referrals',
    'referrals.firstName': 'First Name',
    'referrals.lastName': 'Last Name',
    'referrals.tooltip': 'Use your unique link below to refer clients to the firm',
    'modal.addProject.taxYear': 'Tax Year',
    'modal.addProject.selectTaxYear': 'Select tax year',
    'modal.addProject.projectType': 'Project Type',
    'modal.addProject.selectProjectType': 'Select project type',
    'modal.addProject.close': 'Close',
    'modal.addProject.create': 'Create',
    'modal.addProject.required': 'Required',
    'modal.addProject.alreadyExistsError': 'You already have a project for this year and type in progress. Please use that or choose a different year/type.',
    'modal.addReview.comments': 'Comments',
    'modal.addReview.charactersRemaining': 'Characters remaining',
    'modal.addReview.placeholder': 'Your comment',
    'modal.addReview.submitReview': 'Submit Review',
    'modal.addReview.updateReview': 'Update Review',
    'interview.modalTitle': 'Complete Interview',
    'interview.information':
      'Fill out each section below to change to a green check and then you can submit.',
    'interview.submit': 'Submit',
    'interview.back': 'Back',
    'interview.markAsComplete': 'Mark as complete',
    'interview.markAsNotComplete': 'Mark as not complete',
    'interview.sectionHasPrepopulated': 'Some of your information was prepopulated from other interviews. Please, confirm that the data is correct.',
    'interview.confirmBtnLabel': 'I Confirm',
    'interview.interviewSection.addDependent': 'Add dependent',
    'interview.interviewSection.removeDependent': 'Remove last dependent',
    'interview.interviewSection.saveAndContinue': 'Save & Continue',
    'interview.interviewSection.loading': 'Loading...',
    'interview.close': 'Close',
    'interview.formfield.datepicker.placeholder': 'MM/DD/YYYY',
    'interview.formfield.dollars.placeholder': ' Dollar amounts only',
    'interview.formfield.dependent_name_first': 'Dependent First Name',
    'interview.formfield.dependent_name_last': 'Dependent Last Name',
    'interview.dependentSection.addDependent': 'Add Dependent',
    'interview.dependentSection.delete': 'Delete',
    'interview.dependentSection.dependents': 'Dependents',
    'interview.dependentSection.noDependents': 'You have not reported any dependents. If you need to add some, click Add Dependent below.',    
    'interview.dependentSection.maximumNumber': 'Maximum number of dependent sections!',    
    'invoices.notAvailable': 'No invoices issued yet.',
    'invoices.title': 'Invoices',
    'invoices.close': 'Close',
    'invoices.payInvoice': 'Pay Invoice',
    'invoices.paid': 'Paid',
    'invoices.requested': 'Unpaid',
    'invoices.processing': 'Processing',
    'invoices.canceled': 'Canceled',
    'invoices.view': 'View',
    'invoices.payment.info':
      'By providing your card information here, you will be immediately charged for the following',
    'invoices.payment.description': 'Description',
    'invoices.payment.priceEach': 'Price each',
    'invoices.payment.quantity': 'Quantity',
    'invoices.payment.itemTotal': 'Item total',
    'invoices.payment.totalCharge': 'Total to be charged',
    'invoices.payment.totalCurrency': ' ',
    'invoices.payment.payInvoice': 'Pay Invoice',
    'invoices.paymentForm.cardHolderName': 'Cardholder name',
    'invoices.paymentForm.cardNumber': 'Card number',
    'invoices.paymentForm.expirationDate': 'Expiration date',
    'invoices.paymentForm.payNow': 'Pay now',
    'invoiceView.noItemsAvailable': 'No items available',
    'invoiceView.description': 'Description',
    'invoiceView.pricePer': 'Price per',
    'invoiceView.quantity': 'Quantity',
    'invoiceView.itemTotal': 'Item Total',
    'dashboard.addProject.title': 'Add Project',
    'dashboard.addProject.success': 'The project has been successfully added.',
    'dashboard.invoices.title': 'Invoices',
    'dashboard.documents.title': 'Documents',
    'dashboard.addReview.title': 'Leave a Review',
    'documents.firm': 'Firm',
    'documents.documentsFrom': 'Documents From',
    'documents.documentsFrom.tooltip': 'Number of pending document approvals/signatures.',
    'documents.generalFirm': 'Firm',
    'documents.yourDocuments': 'Your Uploaded Documents',
    'documents.yourDocuments.tooltip': 'Number of pending document requests.',
    'documents.done': 'Done',
    'documents.clientDocuments.msgUpload': 'Drop files or click to upload',
    'documents.clientDocuments.msgLoading': 'Loading...',
    'documents.clientDocuments.notFormat':
      'File name could not be detected from selected file',
    'documents.clientDocuments.name': 'Document Name',
    'documents.clientDocuments.pendingFileRequest': 'Pending file request: {{fileName}}',
    'documents.clientDocuments.fileNameIsEmpty': 'filename was not provided',
    'documents.clientDocuments.fileSize': 'File Size',
    'documents.clientDocuments.date': 'Date',
    'documents.clientDocuments.notUploaded': 'You haven\'t uploaded any documents yet.',
    'documents.clientDocuments.whichDocUploaded': 'Which of the following documents did you just upload?',
    'documents.clientDocuments.whichDocTitle': 'Uploaded Documents',
    'documents.clientDocuments.uploadSuccess': 'File uploaded successfully.',
    'documents.clientDocuments.view': 'View',
    'documents.clientDocuments.delete': 'Delete',
    'documents.clientDocuments.cancel': 'Cancel',
    'documents.clientDocuments.deleteSuccess': 'File deleted successfully.',
    'documents.clientDocuments.areYouSure': 'Are you sure?',
    'documents.clientDocuments.deleteMessage': 'You are are about to delete this file.',
    'documents.clientDocument.notAllowedType': 'The document of this type is not allowed. You can upload texts, images, spreadsheets and PDFs only.',
    'documents.clientDocument.tooBig': 'The size of the uploaded file exceeds the maximum size of {{maxSizeMb}}MB. Please try uploading a different file.',    
    'documents.firmDocuments.status': 'Status',
    'documents.firmDocuments.description': 'Description',
    'documents.firmDocuments.requestedTime': 'Requested Time',
    'documents.firmDocuments.viewOriginal': 'View Original',
    'documents.firmDocuments.notUploaded': 'The firm has not uploaded any documents yet.',
    'documents.firmDocuments.complete': 'Complete',
    'documents.firmDocuments.sent': 'Sent',
    'documents.firmDocuments.finalCopy': 'Final Copy',
    'documents.firmDocuments.approverName': 'Review as {{approverName}}',
    'upcomingAppointments.title': 'Your Upcoming Appointment',
    'upcomingAppointments.type': 'Type',
    'upcomingAppointments.type.VIDEO': 'Video',
    'upcomingAppointments.type.CALL': 'Call',
    'upcomingAppointments.type.HOME': 'Home',
    'upcomingAppointments.type.OFFICE': 'Office',
    'upcomingAppointments.type.OTHER': 'Other place of service',
    'upcomingAppointments.status': 'Status',
    'upcomingAppointments.status.canceled': 'Canceled',
    'upcomingAppointments.status.complete': 'Complete',
    'upcomingAppointments.status.confirmed': 'Confirmed',
    'upcomingAppointments.status.incomplete': 'Incomplete',
    'upcomingAppointments.status.rescheduled': 'Rescheduled',
    'upcomingAppointments.status.scheduled': 'Scheduled',
    'upcomingAppointments.starts': 'Starts',
    'upcomingAppointments.ends': 'Ends',
    'upcomingAppointments.notes': 'Notes',
    'upcomingAppointments.join': 'Join',
    'upcomingAppointments.cancel': 'Cancel',
    // others
    'Complete interview': 'Complete interview',
    'Upload documents': 'Upload documents',
    'Upload document': 'Upload document',
    'Thank you for your submitting, we will be in contact with you soon':
      'Thank you for your submitting, we will be in contact with you soon',
    Saved: 'Saved',
    'Payment was successful': 'Payment was successful',
    'Your review successfully created': 'Your review successfully created',
    'Your review successfully updated': 'Your review successfully updated',
    'You already have a job for this tax year in progress!':
    'You already have a job for this tax year in progress!',
    'Project was successfully created!': 'Project was successfully created!',
    'Approve document': 'Approve document',
    'Pay invoice': 'Pay invoice',
    'Sign document': 'Sign document',
    // CHAT
    'scheduleCall.error': 'There is no more dates available for this day.',
    'scheduleCall.callScheduled': 'Call Scheduled',
    'scheduleCall.schedule': 'Schedule',
    'scheduleCall.selectOption': 'Select an option',
    'scheduleCall.duration': 'Duration',
    'scheduleCall.date': 'Date',
    'scheduleCall.next': 'Next',
    'scheduleCall.skipNotes': 'Skip notes',
    'scheduleCall.notes': 'Notes',
    'scheduleCall.scheduleCall': 'Schedule Appointment',
    'scheduleCall.noEventTypes': 'Your preparer does not have any times available for scheduling.',
    'chat.channelNotSetup': 'The current channel is not set up',
    'chat.downloadFile.error': 'This file is corrupted or has been deleted',
    'chat.incomingCall.accept': 'Accept',
    'chat.incomingCall.decline': 'Decline',
    'chat.incomingCall.title': 'Incoming Call…',
    'chat.chatInput.fileUploaded': 'File uploaded successfully.',
    'chat.chatInput.fileNotUploaded': 'File upload failed.',
    'chat.chatInput.selectProject': 'Select a project to upload a file.',
    'chat.chatInput.sendMessage': 'Send Message',
    'chat.userActive': 'Active Now',
    'chat.messagesList.missedCall': 'Declined Call',
    'videocall.call.meetingWith': 'Meeting with',
    'videocall.call.callScheduledFor':
      'This call is scheduled for {{date}} at {{timeH}}:{{timeM}} you may access it on that time.',
    'call.callErrors.description.cleanMeeting':
      'Please, choose your audio and video settings.',
    'call.callErrors.title.userNotInvited': 'User not invited to call',
    'call.callErrors.description.userNotInvited':
      'You are not invited to this call.',
    'call.callErrors.title.mustLogin': 'User not logged in',
    'call.callErrors.description.mustLogin': 'Log in to enter this call.',
    'call.callErrors.title.earlierAccess': 'This room is not available yet',
    'call.callErrors.title.laterAccess': 'This call has ended',
    'call.callErrors.description.laterAccess':
      'You may still access chat messages.',
    'call.callErrors.title.callCancelled': 'This video call was cancelled',
    'call.callErrors.description.callCancelled':
      'This video call was cancelled by one or more of the participants.',
    'call.callErrors.title.noCam': 'Camera permission denied',
    'call.callErrors.description.noCam':
      'You have not granted or blocked camera access. Please update your browser settings to allow access.',
    'call.callErrors.title.noMic': 'Microphone permission denied',
    'call.callErrors.description.noMic':
      'You have not granted or blocked microphone access. Please update your browser settings to allow access.',
    'call.callErrors.title.callExpired': 'This call has already expired',
    'call.callErrors.description.callExpired':
      'If you think this an error, please contact your Tax Preparer.',
    'call.join': 'Join Now',
    'videocall.me': 'Me',
    'videocall.callMenu.recordSession': 'Record Session',
    'videocall.callMenu.stopRecording': 'Stop Recording',
    'Invite.title': 'Register with {{- preparerName}}',
    'Invite.title.defaultPreparerName': 'our client portal',
    'Invite.titleInstructions': 'Enter the required details to get started',
    'Invite.titleSecure': 'Welcome to our secure portal',
    'Invite.create.error': 'There was an error creating the account.',
    'Invite.twoFactorCode.empty': 'Enter the six-digital code from your app',
    'Invite.side.alreadyClient': 'Already a client?',
    'Invite.side.message1':
      'Welcome to our user-friendly platform designed smooth and secure exchange of information with our firm. Get ready to experience our modern client experience that keeps everything in place for you with one login.',
    'Invite.side.message2':
      'Once you register, you can logon to the portal anytime using your email address. Link to the login screen from',
    'Invite.side.messageLink': '“logon to the portal”',
    'Invite.side.message3': '.',
    'Invite.form.alert.msg1':
      'An account with that email address is already registered with us. You will be redirected to login screen.',
    'Invite.form.alert.msg2': 'Need to',
    'Invite.form.alert.msg3': 'Invalid six-digital Two-Factor Authentication code.',
    'Invite.form.alert.password': 'Password is too weak.',
    'Invite.form.alert.link': 'reset your password',
    'Invite.form.alert.expired': 'This link has expired. Please contact your tax preparer to get a new one.',
    'InviteForm.placeholder.email': 'example@example.com',
    'InviteForm.language': 'Language',
    'InviteForm.taxFilingFor': 'What tax filing is this for?',
    'InviteForm.taxYearFor': 'Which tax year is this for?',
    'InviteForm.email': 'Email',
    'InviteForm.firstName': 'First Name',
    'InviteForm.lastName': 'Last Name',
    'InviteForm.landLine': 'Landline',
    'InviteForm.mobileNumber': 'Mobile Number',
    'InviteForm.workNumber': 'Work Number',
    'InviteForm.createPassword': 'Create Password',
    'InviteForm.confirmPassword': 'Confirm Password',
    'InviteForm.passwordsNotMatch': 'Passwords do not match',
    'InviteForm.twoFactor': 'Two-Factor authentication',
    'InviteForm.termsAndConditions': "I agree to the platform's",
    'InviteForm.termsAndConditionsLink': 'Terms of Use',
    'InviteForm.getStarted': 'Get Started',
    'InviteForm.required': 'Required!',
    'InviteForm.fixErrors': 'Please fix the errors above.',
    'InviteForm.success': 'Account created successfully!',
    'InviteForm.birthday': 'Date of Birth',
    'ChatInput.uploadTitle': 'Do you want to upload & send this file?',
    'taskType.DOCUMENTS': 'Upload your first document',
    'taskType.INTERVIEW': 'Complete your interview',
    'taskType.PAYMENTS': 'Make payment',
    'taskType.REQUEST_DOCUMENTS': 'Upload document',
    'taskType.SIGNATURES': 'Upload signature',
    'taskType.APPROVE_DOCUMENTS': 'Approve document',
    'datepicker.placeholder': 'Select date',
  },
  emojiMart: {
    search: 'Search',
    clear: 'Clear', // Accessible label on "clear" button
    notfound: 'No Emoji Found',
    skintext: 'Choose your default skin tone',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      smileys: 'Smileys & Emotion',
      people: 'People & Body',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
    categorieslabel: 'Emoji categories', // Accessible title for the list of categories
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  },
  countryList: {
    US: 'United States',
    AF: 'Afghanistan',
    AX: '\u00c5land Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AC: 'Ascension Island',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    IC: 'Canary Islands',
    CV: 'Cape Verde',
    BQ: 'Caribbean Netherlands',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    EA: 'Ceuta & Melilla',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'C\u00f4te d\u2019Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Cura\u00e7ao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong SAR China',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR China',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'R\u00e9union',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia & South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barth\u00e9lemy',
    SH: 'St. Helena',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre & Miquelon',
    VC: 'St. Vincent & Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Outlying Islands',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  AddressConfig: [
    {
      fieldType: 'text',
      label: 'Address Line 1',
      subName: '_address1',
      required: true,
    },
    {
      fieldType: 'text',
      label: 'Address Line 2',
      subName: '_address2',
      required: false,
    },
    {
      fieldType: 'text',
      label: 'City',
      subName: '_city',
      required: true,
    },
    {
      fieldType: 'text',
      label: 'State/Province/Region',
      subName: '_state',
      required: true,
    },
    {
      fieldType: 'text',
      label: 'ZIP/Postal Code',
      subName: '_zip',
      required: true,
    },
    {
      fieldType: 'dropdown',
      label: 'Country',
      subName: '_country',
      required: true,
    },
  ],
  yup: {
    required: 'Required',
    invalidEmail: 'Invalid email',
    phoneNumber: 'The number is not valid',
    passwordToShort: 'The password must have a minimum of 8 characters',
    passwordToLong: 'The password must have a maximum of 30 characters',
    passwordToWeak: 'The password is too weak',
  },
};
