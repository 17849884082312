import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input, Button, Upload, message, Progress, Dropdown, Menu, Modal,
} from 'antd';
import 'antd/dist/antd.min.css';
import {
  SendOutlined,
  PaperClipOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';
import OutsideAlerter from '../../helpers/OutSiderAlerter';
import SelectTaskModal from '../../Documents/components/ClientDocuments/SelectTaskModal';
import { useCreateMessageWithFileMutation } from '../../../graphql/mutations/messaging';

const MAX_ATTACHMENT_SIZE_MB = 5;
const MAX_ATTACHMENT_SIZE_B = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024;

const ChatInput = ({
  onSendMessage,
  projects,
  dispatch,
  taxPro,
}) => {
  const [val, setValue] = useState('');
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [showSelectTaskModal, setShowSelectTaskModal] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [openReqDocTasks, setOpenReqDocTasks] = useState([]);

  const { t: translation, i18n } = useTranslation();

  const { confirm } = Modal;

  const [sendMessageWithFile] = useCreateMessageWithFileMutation();

  useEffect(() => {
    if (projectId && projects.length > 0) {
      const theProject = projects.filter((item) => item.id === projectId)[0];
      const oReqDocTasks = theProject?.tasks && theProject?.tasks.filter((task) => !task.completedAt && task.taskType === 'REQUEST_DOCUMENTS');
      setOpenReqDocTasks(oReqDocTasks);
    }
  }, [projects, projectId]);

  const uploadPros = {
    name: 'file',
    onChange(inf) {
      const info = inf;
      if (info.file.status === 'uploading') {
        if (!uploadingStatus) {
          setUploadingStatus(true);
        }  
        if (info.event) {
          setUploadPercent(info.event.percent);
        }  
      }
      if (info.file.status === 'done') {
        setUploadingStatus(false);
        setUploadPercent(0);
        info.file.s3key = info.file.originFileObj.s3key;
        onSendMessage('', false, JSON.stringify(info.file));
      } else if (info.file.status === 'error') {
        message.error(
          `${info.file.name} ${translation('chat.chatInput.fileNotUploaded')}`,
        );
      }
    },
  };

  const sendMessage = () => {
    onSendMessage(val);
    setValue('');
  };

  const getBase64 = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  };

  // console.log("PROJECTS", projects);

  const uploadFile = async (options, idOfProject) => {
    const resultBase64 = await getBase64(options.file);
    const form = {
      channelUserId: taxPro,
      taxYearInterviewId: idOfProject,
      folder: 'client',
      appendName: true,
      file: {
        name: options.file.name,
        type: options.file.type,
        size: options.file.size,
        blob: resultBase64,
      },
    };

    const data = await sendMessageWithFile({
      variables: {
        createMessageWithFileInput: form,
      },
    });

    const { s3Key } = data.data.createMessageWithFile;
    options.file.s3key = s3Key; // eslint-disable-line no-param-reassign

    message.success({
      content: `${options.file.name} ${translation('chat.chatInput.fileUploaded')}`,
      duration: 3,
      className: 'custom-alert-message-success',
      style: {
        marginTop: '38px',
        textAlign: 'center',
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-35%)',
      },
    });

    options.onSuccess({}, options.file);
  };

  const isAllowedFileType = (type) => [
    'application/msword', // .doc
    'application/pdf', // .pdf
    'application/rtf', // .rtf
    'application/vnd.ms-excel', // .xls
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/vnd.oasis.opendocument.text', // .odt
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/xml', // .xml
  ].includes(type)
  || [
    'image/', // .bmp, .gif, .jpeg, .png, .tiff, .webp
    'text/', // .csv, .html, .txt
  ].some(
    (prefix) => type.startsWith(prefix),
  );  

  const showConfirm = (options, id) => {
    if (!isAllowedFileType(options?.file?.type)) {
      message.error(translation('documents.clientDocument.notAllowedType'));
      setUploadingStatus(false);
      return;
    }

    if (options.file.size > MAX_ATTACHMENT_SIZE_B) {
      message.error(translation('documents.clientDocument.tooBig', { maxSizeMb: MAX_ATTACHMENT_SIZE_MB }));
      setUploadingStatus(false);
      return;
    }

    setProjectId(id);
    confirm({
      title: translation('ChatInput.uploadTitle'),
      icon: <PaperClipOutlined />,
      content: options?.file?.name,
      onOk() {
        uploadFile(options, id);
      },
      onCancel() {
        setUploadingStatus(false);
      },
    });
  };

  const { name, onChange } = uploadPros;

  const projectsMenu = () => (
    <Menu>
      <Menu.Item style={{ fontWeight: 'bolder' }} disabled key={0}>
        {translation('chat.chatInput.selectProject')}
      </Menu.Item>
      {projects?.map((p) => (
        <Menu.Item key={p.id}>
          <Upload
            name={name}
            onChange={onChange}
            showUploadList={false}
            disabled={uploadingStatus}
            multiple={false}
            customRequest={(options) => showConfirm(options, p.id)}
          >
            {`${p.TaxYear.year} ${p.Interviews.name}`}
          </Upload>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.container}>
      {emojiVisible && (
        <OutsideAlerter onOutside={() => setEmojiVisible(false)}>
          <Picker
            set="apple"
            showPreview={false}
            showSkinTones={false}
            style={{
              width: 310,
              maxHeight: 320,
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              position: 'absolute',
              bottom: 60,
              right: 0,
            }}
            sheetSize={32}
            onSelect={(emoji) => {
              setValue(`${val} ${emoji.native} `);
            }}
            i18n={i18n.getDataByLanguage(i18n.language.toLowerCase()).emojiMart}
          />
        </OutsideAlerter>
      )}
      <div className={styles.containerInput}>
        <Dropdown
          overlayStyle={{ zIndex: 1200, position: 'fixed' }}
          overlay={projectsMenu()}
          trigger={['click']}
          placement="topLeft"
        >
          <Button
            className={`${styles.clip} ant-dropdown-link`}
            type="default"
            shape="circle"
            size="medium"
            onClick={(e) => e.preventDefault()}
          >
            {!uploadingStatus && <PaperClipOutlined />}
            {uploadingStatus && (
              <Progress
                type="circle"
                percent={uploadPercent}
                showInfo={false}
                width={30}
                style={{ marginRight: 8 }}
              />
            )}
          </Button>
        </Dropdown>
        <Input
          className={styles.input}
          placeholder={translation('chat.chatInput.sendMessage')}
          value={val}
          onPressEnter={sendMessage}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          className={styles.clip}
          type="default"
          shape="circle"
          icon={<SmileOutlined />}
          size="medium"
          onClick={() => setEmojiVisible(!emojiVisible)}
        />
        <Button
          className={styles.clip}
          type="default"
          shape="circle"
          icon={<SendOutlined />}
          size="medium"
          onClick={sendMessage}
        />
      </div>
      {showSelectTaskModal && (
        <SelectTaskModal
          dispatch={dispatch}
          numDocsUploaded={1}
          openDocReqTasks={openReqDocTasks}
          setShowSelectTaskModal={setShowSelectTaskModal}
        />
      )}
    </div>
  );
};

ChatInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // projects: state.projects.projects,
  dispatch: state.dispatch,
});

export default connect(mapStateToProps)(ChatInput);
