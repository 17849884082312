import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from './style.module.css';
import 'antd/dist/antd.min.css';

const Message = ({
  message, last, mine, name, createdAt,
}) => (
  <div className={`${mine ? styles.mine : styles.yours} ${styles.messages}`}>
    <div className={`${styles.message} ${last && styles.last} ${mine && styles.messageMine}`}>
      <div className={`${styles.userInfo}`}>
        {!mine && <Avatar size={25} icon={<UserOutlined />} />}
        <p className={`${styles.name} ${mine && styles.nameMine}`}>{name}</p>
        {mine && <Avatar size={25} icon={<UserOutlined />} />}
      </div>
      <p className={`${styles.text} ${mine && styles.textMine}`}>{message}</p>
      <p className={styles.date}>{moment(createdAt).format('LT')}</p>
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.string.isRequired,
  last: PropTypes.bool.isRequired,
  mine: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  createdAt: PropTypes.object.isRequired,
};

export default Message;
