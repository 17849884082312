import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Table,
} from 'antd';
import classes from './style.module.scss';
import Translation from '../atoms/Translation';

const ReferredCount = ({
  referred,
}) => {
  ReferredCount.propTypes = {
    // referred: PropTypes.arrayOf({}).isRequired, // array not allowed
  };

  const [showReferredModal, setShowReferredModal] = useState(false);

  const columns = [
    {
      title: <Translation text="referrals.firstName" />,
      dataIndex: 'UserInformation',
      render: ({ firstName }) => (
        <span>{firstName}</span>
      ),
      key: 'firstName',
    },
    {
      title: <Translation text="referrals.lastName" />,
      dataIndex: 'UserInformation',
      render: ({ lastName }) => (
        <span>{lastName}</span>
      ),
      key: 'lastName',
    },
  ];

  if (!referred || referred?.length < 1) {
    return null;
  }

  return (
    <>
      <div className={classes.referredCount}>
        <Translation text="referrals.totalReferred" />
        <span>:&nbsp;</span>
        <Button
          onClick={() => setShowReferredModal(true)}
          type="link"
          className={classes.referredCountButton}
        >
          {referred?.length}
        </Button>
      </div>
      <Modal
        visible={showReferredModal}
        footer={null}
        title={<Translation text="referrals.completed" />}
        onCancel={() => setShowReferredModal(false)}
      >
        <Table dataSource={referred} columns={columns} />
      </Modal>
    </>
  );
};

export default ReferredCount;
