import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Avatar, Row, Col, Typography, Button,
} from 'antd';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import UserLobbyPreview from '../../atoms/UserLobbyPreview';
import LobbyUsers from '../../atoms/LobbyUsers';
import styles from './style.module.css';

const getAcronim = (str) => {
  if (!str) return '';
  const names = str.split(' ');
  return `${names[0].charAt()}${names[1].charAt()}`;
};

const { Title, Text } = Typography;
const CallLobby = ({
  data, permission, config, onClickJoin, callError,
}) => {
  const { t: translation } = useTranslation();

  const RequestMicrophone = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        permission.audio.set(true);
        config.audio.set(true);
        config.streamAudio.set(stream);
      })
      .catch(() => {
        permission.audio.set(false);
        config.audio.set(false);
      });
  };

  const RequestVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        permission.video.set(true);
        config.video.set(true);
      })
      .catch(() => {
        permission.video.set(false);
        config.video.set(false);
      });
  };

  useEffect(() => {
    RequestMicrophone();
    RequestVideo();
    // eslint-disable-next-line
  }, []);

  const eventTypeName = data.Events ? data.Events.EventTypes.name : '';

  return (
    <div>
      <Row className={`${styles.row} ${!config.video.get && styles.rowOff}`}>
        {config.video.get && (
          <Webcam
            className={styles.webcam}
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
          />
        )}
        <Col className={styles.column} xs={{ span: 24, offset: 0 }}>
          {callError.imageURL && (
            <img
              src={callError.imageURL}
              className={styles.image}
              alt={translation(callError.title)}
            />
          )}
          <Title className={styles.title} level={2}>
            {callError.error ? translation(callError.title) : eventTypeName}
          </Title>
          {callError.error && (
            <Text className={styles.description}>
              {translation(callError.description)}
            </Text>
          )}
          {callError.error && callError.avatar && (
            <div style={{ padding: '20px 0' }}>
              <Avatar
                className={styles.ava}
                style={{ backgroundColor: '#22A7E7', fontSize: 25 }}
                src={
                  callError.avatarImage
                    ? `/public/viewFile/${callError.avatarImage}`
                    : ''
                }
                size={isMobile ? 100 : 180}
              >
                {getAcronim(callError.userName)}
              </Avatar>
            </div>
          )}
          {!callError.error && <LobbyUsers data={data.MeetingAttendees} />}
          <div className={styles.containerButtons}>
            {callError.joinCall && (
              <Button
                className={styles.join}
                onClick={onClickJoin}
                type="primary"
                shape="round"
                size="large"
              >
                {translation('call.join')}
              </Button>
            )}
            {callError.name === 'mustLogin' && (
              <Button
                className={styles.join}
                onClick={() => window.location.replace('/login')}
                type="primary"
                shape="round"
                size="large"
              >
                {translation('call.callErrors.button.mustLogin')}
              </Button>
            )}
            <UserLobbyPreview config={config} permission={permission} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

CallLobby.propTypes = {
  data: PropTypes.shape({
    Events: PropTypes.shape(),
    MeetingAttendees: PropTypes.arrayOf(PropTypes.shape()),
    Channels: PropTypes.shape(),
  }).isRequired,
  permission: PropTypes.shape({
    audio: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
    video: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
  }).isRequired,
  config: PropTypes.shape({
    audio: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
    video: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
    streamAudio: PropTypes.shape({
      get: PropTypes.shape(),
      set: PropTypes.func,
    }),
  }).isRequired,
  onClickJoin: PropTypes.func.isRequired,
  callError: PropTypes.shape({
    name: PropTypes.string,
    error: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    imageURL: PropTypes.string,
    joinCall: PropTypes.bool,
    avatar: PropTypes.bool,
    avatarImage: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
};
export default CallLobby;
