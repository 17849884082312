import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyEventsQuery = { __typename?: 'Query', MyEvents: Array<{ __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, Meetings: { __typename?: 'MeetingEntity', id: string }, EventTypes: { __typename?: 'EventTypeEntity', id: string, name: string } }> };


export const MyEventsDocument = gql`
    query MyEvents {
  MyEvents {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    Meetings {
      id
    }
    EventTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useMyEventsQuery__
 *
 * To run a query within a React component, call `useMyEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEventsQuery(baseOptions?: Apollo.QueryHookOptions<MyEventsQuery, MyEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyEventsQuery, MyEventsQueryVariables>(MyEventsDocument, options);
      }
export function useMyEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyEventsQuery, MyEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyEventsQuery, MyEventsQueryVariables>(MyEventsDocument, options);
        }
export type MyEventsQueryHookResult = ReturnType<typeof useMyEventsQuery>;
export type MyEventsLazyQueryHookResult = ReturnType<typeof useMyEventsLazyQuery>;
export type MyEventsQueryResult = Apollo.QueryResult<MyEventsQuery, MyEventsQueryVariables>;
export const namedOperations = {
  Query: {
    MyEvents: 'MyEvents'
  }
}