/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import AsyncSelect from "react-select/lib/Async";
import { Select, message } from 'antd';

import Button from "../Button/Button";
import { inputWithBorder, label } from "../../styles/controls";
import withOverlay from "../../utils/withOverlay";
import { grey } from "../../styles/colors";
import getTaxYears from '../helpers/taxYears';
import Error from "../Error";

import Translation from "../atoms/Translation";
import { useTranslation, withTranslation } from 'react-i18next';
import { useGetInterviewNamesLazyQuery } from "../../graphql/queries/interviews";
import { useAddJobMutation } from "../../graphql/mutations/taxYearInterviews";
import { useGetProgressStatusesByFirmAccountQuery } from "../../graphql/queries/settings";
import { usePlanAccess, Features } from "../atoms/PlanAccessProvider";

const { Option } = Select;

const AddProject = (props) => {
  const {
    close,
    history,
    session,
    preparerId,
  } = props;

  const { graphSession } = session;
  const { firmAccountId, entityId } = graphSession;
  const [years, setYears] = useState([]);
  const [selected, setSelected] = useState({ year: null, projectType: null });
  const [errors, setErrors] = useState({ years: null, projectType: null, network: null });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { accessObject } = usePlanAccess();

  const errorMsg = <Translation text="modal.addProject.required" />;

  const { data: progressStatusesData } = useGetProgressStatusesByFirmAccountQuery({
    variables: {
      id: firmAccountId,
    }
  });
  const [getInterviews] = useGetInterviewNamesLazyQuery();
  const [addJob] = useAddJobMutation();

  const progressStatuses = progressStatusesData?.FirmAccountById?.ProgressStatuses;
  const unassignedProgressStatus = (progressStatuses ?? []).find((x) => x.underlyingStatus === 'UNASSIGNED');
  const inProgressProgressStatus = (progressStatuses ?? []).find((x) => x.underlyingStatus === 'IN_PROGRESS');

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (close) {
      onClose();
    }
  }, [close]);

  const getParams = () => {
    const taxYears = getTaxYears();
    setYears(taxYears);
    setSelected((prev) => ({ ...prev, year: taxYears[0] }));
  }

  const onSelect = (value, type) => {
    setSelected((prev) => ({ ...prev, [type]: value }));
  }

  const onClose = (refresh) => {
    if (refresh) {
      history.push("/", true);
    } else {
      history.push("/");
    }
  }

  const onCreateProject = async () => {
    const { year, projectType } = selected;
    if (!year || !projectType) {
      setErrors({
        years: year ? null : errorMsg,
        projectType: projectType ? null : errorMsg,
        network: null,
      })
      return;
    }
    setLoading(true);
    // call create project mutation
    try {
      await addJob({
        variables: {
          addJobInput: {
            entityId,
            year: selected.year.toString(),
            interviewId: selected.projectType,
            sendEmail: false,
            progressStatusId: preparerId ? inProgressProgressStatus?.id : unassignedProgressStatus?.id,
            preparerId,
          }
        },
        refetchQueries: [
          'GetJobsByClientId' // Query name
        ],
        awaitRefetchQueries: true,
      });
      message.success(t('dashboard.addProject.success'));
      setLoading(false);
      onClose(true);
    } catch (error) {
      const errorName = error.graphQLErrors?.at(0)?.name;
      if (errorName === 'RecordAlreadyInDb') {
        message.error(t('modal.addProject.alreadyExistsError'));
      } else {
        message.error(error.toString());
      }
      setLoading(false);
    }
  }

  const {
    years: yearValidationError,
    projectType: projectValidationError,
    network: projectNetworkError,
  } = errors;
  return (
    <div>
      {!!projectNetworkError && (
        <>
          <Error value={{ msg: projectNetworkError }} />
          <div style={{ height: '15px' }} />
        </>
      )}
      <div className={css(styles.yearBlock)}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={css(styles.label)}>
          <Translation text="modal.addProject.taxYear" />
        </label>
        <Select
          value={selected.year}
          style={{ width: 141 }}
          onChange={(option) => onSelect(option, "year")}
          placeholder={t('modal.addProject.selectTaxYear')}
        >
          {years.map((year) => (
            <Option
              key={year}
              value={year}
            >
              {year}
            </Option>
          ))}
        </Select>
        {!!yearValidationError && (
          <Error value={{ msg: yearValidationError }} />
        )}
      </div>
      <div className={css(styles.jobBlock)}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={css(styles.label)}>
          <Translation text="modal.addProject.projectType" />
        </label>
        <AsyncSelect
          cacheOptions
          loadOptions={() => new Promise((resolve) => {
            getInterviews().then((r) => {
              if (!accessObject?.[Features.entityInterviews]) { // does not have access to entity interviews
                // this logic is same as on firms
                const availableInterview = r.data.interviewNames?.filter((i) => i?.name?.toLowerCase().includes('individual'))[0] || r.data.interviews?.[0];
                resolve(
                  [{
                    label: availableInterview.name,
                    value: availableInterview.id,
                  }]
                );
              }
              resolve(
                r.data.interviewNames.map((type) => ({
                  label: type.name,
                  value: type.id,
                }))
              );
            });
          })}
          defaultOptions
          onChange={(option) => onSelect(option.value, "projectType")}
          styles={customSelectStyles}
          placeholder={t('modal.addProject.selectProjectType')}
        />
        {!!projectValidationError && (
          <Error value={{ msg: projectValidationError }} />
        )}
      </div>
      <div className={css(styles.btnBlock)}>
        <Button
          label={<Translation text="modal.addProject.close" />}
          secondary
          style={{ width: "calc(50% - 10px)", marginRight: "20px" }}
          onClick={onClose}
        />
        <Button
          label={<Translation text="modal.addProject.create" />}
          blue
          style={{ width: "calc(50% - 10px)" }}
          onClick={onCreateProject}
          loading={loading}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  yearBlock: {
    marginBottom: "30px",
    width: "80px",
  },
  jobBlock: {
    marginBottom: "40px",
  },
  label: {
    ...label,
    display: "block",
    marginBottom: "10px",
  },
});

const font = {
  fontFamily: "Lato, sans-serif",
  fontSize: "16px",
  color: grey["700"],
  letterSpacing: "0.6px",
};

const customSelectStyles = {
  option: (base, state) => ({
    ...base,
    position: "relative",
    padding: "10px 18px",
    ...font,
    fontSize: 14,
    backgroundColor: state.isSelected ? grey["700"] : "white",
    color: state.isSelected ? "white" : grey["700"],
    "&:hover": {
      fontWeight: state.isSelected ? "normal" : "bold",
      backgroundColor: state.isSelected ? grey["700"] : grey["500"],
      color: "white",
    },
  }),
  control: () => ({
    ...inputWithBorder,
    position: "relative",
    display: "flex",
    minWidth: "80px",
    padding: "8px 14px 8px 2px",
    width: "auto",
    borderColor: '#d9d9d9',
    "&:after": {
      position: "absolute",
      top: 16,
      right: 8,
      display: "block",
      content: "''",
      width: 1,
      height: 1,
      borderTop: `5px solid ${grey["800"]}`,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "5px solid transparent",
    },
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: () => ({
    ...font,
    padding: 0,
  }),
  placeholder: () => ({
    ...font,
    // color: '#d9d9d9',
  }),
  singleValue: (base, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...base,
      opacity,
      transition,
      ...font,
      padding: 0,
    };
  },
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default withTranslation()(withOverlay(withRouter(connect(mapStateToProps)(AddProject))));