/* eslint-disable */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import User from './user';
import Phone from './phone';
import Email from './email';
import Arrow from './arrow';
import Star from './star';
import Eye from './eye';
import Download from './download';
import DownloadCloud from './downloadCloud';
import Upload from './upload';
import { color, grey } from '../../styles/colors';
import { Lato } from '../../styles/fonts';
import withHover from '../../utils/withHover';
import { CalendarOutlined } from "@ant-design/icons";

export const Icon = ({
  add,
  arrow,
  bg,
  remove,
  check,
  down,
  download,
  downloadCloud,
  email,
  exit,
  holder,
  hovered,
  icon,
  onClick,
  phone,
  progress,
  interactive,
  star,
  success,
  style = {},
  type,
  user,
  calendarOutlined,
}) => {
  const checkStyles = {
    position: 'relative',
    top: '-2px',
    width: '18px',
    height: '18px',
    marginRight: '14px',
    borderRadius: '50%',
    backgroundColor: color.green,
    ':after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      top: '6px',
      left: '5px',
      width: '8px',
      height: '3px',
      borderLeft: `1px solid ${color.white}`,
      borderBottom: `1px solid ${color.white}`,
      transform: 'rotate(-45deg)',
    },
  };

  const styles = StyleSheet.create({
    icon: {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: '16px',
      fontStyle: 'normal',
      svg: {
        transition: '0.2s ease',
      },
    },
    button: {
      width: '34px',
      height: '34px',
      padding: '4px',
      verticalAlign: 'middle',
      textAlign: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    buttonHovered: {
      backgroundColor: grey['300'],
    },
    holder: {
      position: 'relative',
      top: '-2px',
      width: '18px',
      height: '18px',
      marginRight: '14px',
      borderRadius: '50%',
      border: `2px solid ${color.lightBlue}`,
    },
    img: {
      verticalAlign: 'middle',
    },
    add: {
      width: '19px',
      height: '19px',
      lineHeight: '19px',
      marginRight: '10px',
      marginBottom: '2px',
      paddingLeft: '1px',
      fontSize: '17px',
      fontWeight: 'normal',
      color: color.white,
      textAlign: 'center',
      borderRadius: '50%',
      backgroundColor: color.blue,
    },
    remove: {
      width: '19px',
      height: '19px',
      lineHeight: '17px',
      marginRight: '10px',
      marginBottom: '2px',
      paddingTop: '1px',
      fontSize: '17px',
      fontWeight: 'normal',
      color: color.white,
      textAlign: 'center',
      borderRadius: '50%',
      backgroundColor: color.red,
    },
    check: {
      ...checkStyles,
    },
    interviewCheck: {
      ...checkStyles,
      marginRight: 0,
      width: '26px',
      height: '26px',
      ':after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        top: '7px',
        left: '6px',
        width: '13px',
        height: '6px',
        borderLeft: `1px solid ${color.white}`,
        borderBottom: `1px solid ${color.white}`,
        transform: 'rotate(-45deg)',
      },
    },
    lock: {
      width: '16px',
      marginLeft: '12px',
      marginTop: '-6px',
    },
    squareCheck: {
      ...checkStyles,
      borderRadius: 'none',
    },
    squareBlank: {
      ...checkStyles,
      borderRadius: 'none',
      backgroundColor: color.white,
      border: `1px solid ${grey['500']}`,
    },
    arrow: {
      position: 'relative',
      top: '-2px',
      transition: '0.8 ease',
      transform: 'rotate(180deg)',
    },
    right: {
      top: 0,
      transform: 'translateX(4px) rotate(-90deg)',
    },
    left: {
      top: 0,
      transform: 'translateX(-2px) translateY(-2px) rotate(90deg)',
    },
    down: {
      top: '-4px',
      transform: 'rotate(0deg)',
      transition: '0.8 ease',
    },
    progress: {
      width: '16px',
      height: '16px',
      marginRight: '8px',
      borderRadius: '50%',
      border: `1px solid ${color.white}`,
      background: 'url("/images/icons/inprog.svg") no-repeat 50% 50%',
    },
    success: {
      ...checkStyles,
      top: 0,
      width: '16px',
      height: '16px',
      marginRight: '8px',
      border: `1px solid ${color.white}`,
      ':after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        top: '4px',
        left: '3px',
        width: '8px',
        height: '3px',
        borderLeft: `1px solid ${color.white}`,
        borderBottom: `1px solid ${color.white}`,
        transform: 'rotate(-45deg)',
      },
    },
    star: { marginRight: '4px' },
    download: { position: 'relative', marginRight: '10px', top: '2px' },
    downloadCloud: {
      width: '34px',
      height: '34px',
      padding: '4px',
      textAlign: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    downloadCloudHovered: {
      backgroundColor: grey['300'],
    },
    exit: {
      width: '18px',
      height: '18px',
      lineHeight: '18px',
      fontSize: '30px',
      fontWeight: 'bold',
      color: grey['400'],
      textAlign: 'center',
      borderRadius: '50%',
      transform: 'translateY(-10px) translateX(10px) rotate(45deg)',
      cursor: 'pointer',
      transition: '0.2s ease',
      ':hover': {
        color: grey['600'],
      },
    },
    warn: {
      width: '26px',
      height: '26px',
      fontFamily: [Lato, 'sans-serif'],
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '26px',
      borderRadius: '50%',
      color: color.white,
      backgroundColor: color.orange,
    },
    customStyles: { ...style },
  });

  return (
    <i
      className={css(
        styles.icon,
        add && styles.add,
        holder && styles.holder,
        check && styles.check,
        remove && styles.remove,
        check === 'square' && styles.squareCheck,
        check === 'squareBlank' && styles.squareBlank,
        arrow && styles.arrow,
        arrow === 'toRight' && styles.right,
        arrow === 'toLeft' && styles.left,
        down && styles.down,
        exit && styles.exit,
        progress && styles.progress,
        success && styles.success,
        star && styles.star,
        download && styles.download,
        downloadCloud && styles.downloadCloud,
        downloadCloud && hovered && styles.downloadCloudHovered,
        type === 'button' && styles.button,
        type === 'button' && hovered && bg !== 'none' && styles.buttonHovered,
        type === 'upload' && styles.download,
        type === 'warn' && styles.warn,
        type === 'check' && styles.interviewCheck,
        styles.customStyles,
      )}
      onClick={onClick}
    >
      { (add || exit) && '+' }
      { remove && '-' }
      { user && <User />}
      { phone && <Phone />}
      { email && <Email />}
      { star && <Star filled={star === 'filled'} size={interactive ? 'middle' : false} />}
      { arrow && <Arrow fill={hovered ? color.blue : arrow === 'toLeft' ? '#d2d2d2' : grey['800']} />}
      { download && <Download fill={download === 'hovered' ? color.white : grey['800']} />}
      { downloadCloud && <DownloadCloud fill={hovered ? grey['100'] : color.white} />}
      { type === 'warn' && '!'}
      { icon === 'trash' && <img className={css(styles.img)} src="/images/icons/trash.svg" alt="" />}
      { icon === 'lock' && <img className={css(styles.img, styles.lock)} src="/images/icons/lock.svg" alt="" />}
      { type === 'upload' && <Upload />}
      { icon === 'eye' && <Eye fill={hovered ? color.blue : color.lightBlue} /> }
      { calendarOutlined && <CalendarOutlined style={{  fontSize: '20px', color:'rgb(90 140 244)' }} /> }
    </i>
  );
};

export const IconWithHover = withHover(Icon);