import { GET_SESSION, SET_SESSION, UPDATE_FIELD } from '../actions/session';

const initialStore = {
  user: null,
  graphSession: null,
};

export default (state = initialStore, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FIELD:
      return { ...state, graphSession: { ...state.graphSession, ...payload }, user: { ...state.user, ...payload } };
    case SET_SESSION:
      return { ...payload };
    case GET_SESSION:
    default:
      return state;
  }
};
