import {
  Button, Modal, Row, message, Spin
} from "antd";
import { useParams, useHistory, Redirect } from "react-router";
import { css, StyleSheet } from "aphrodite";
import { useMemo, useState, useEffect } from "react";
import Translation from "../../atoms/Translation";
import SectionStatusCard from "../../molecules/SectionStatusCard";
import { heading, Lato } from "../../../styles/fonts";
import { grey } from "../../../styles/colors";
import EditSection from "./EditSection";
import DependentSection from "./DependentSection";
import {
  useSetTaxInterviewSectionCompleteValueMutation,
  useCreateSectionAsCompleteMutation,
} from '../../../graphql/mutations/taxYearInterviews';
import {
  useGetTaxYearInterviewSectionsQuery, GetTaxYearInterviewSectionsDocument, useGetTaxYearInterviewIncludeInterviewsLazyQuery
} from '../../../graphql/queries/taxYearInterviews';

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    maxWidth: 980,
    minHeight: '460px'
  },
  title: {
    ...heading.modalHeading
  },
  bottomSpacing: {
    marginBottom: 24
  },
  titleContainer: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: grey['800'],
    letterSpacing: '0.6px',
    fontFamily: [Lato, 'sans-serif'],
    lineHeight: 1,
  },
  subTitleContainer: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: grey['800'],
    letterSpacing: '0.6px',
    fontFamily: [Lato, 'sans-serif'],
  }
});

export const getTitle = (Translations) => {
  const myLanguage = (localStorage.getItem("lang") || "en").toLowerCase();
  const questionTitle = Translations.find(
    ({ Language: { lang } }) => lang.toLowerCase() === myLanguage
  )?.description || Translations?.[0]?.description;

  return questionTitle;
};

// sync computed section complete values with values in DB
const taxYearInterviewSectionIdsSet = new Set();
const taxYearInterviewSectionIdsCompeltedMap = {};
let taxYearInterviewUpdateSectionsTimeout;

const InterviewSectionList = ({ setProjectsFromChild, projects }) => {
  const { projectId: taxYearInterviewId } = useParams();
  const taxYearInterview = projects?.find((x) => x?.id === taxYearInterviewId) ?? { Answers: [], Interviews: { Sections: [] } };
  const interview = taxYearInterview?.Interviews ?? { Sections: [] };
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState('');
  const [allAnswers, setAllAnswers] = useState([]);
  const currentSection = useMemo(() => interview.Sections?.find(({ id }) => id === currentSectionId) ?? {}, [currentSectionId]);
  const [taxYearInterviewSections, setTaxYearInterviewSections] = useState([]);

  const [getInterview, { loading: interviewDataLoading }] = useGetTaxYearInterviewIncludeInterviewsLazyQuery({
    onError: (error) => message.error(error.message)
  });
  const { data: taxYearInterviewSectionsData, loading: taxYearInterviewSectionsDataLoading } = useGetTaxYearInterviewSectionsQuery({ variables: { taxYearInterviewId }});
  const [setTaxInterviewSectionCompleteValue, { loading: sectionCompleteLoading }] = useSetTaxInterviewSectionCompleteValueMutation({
    onError: (err) => message.error(err.message),
    refetchQueries: [{ query: GetTaxYearInterviewSectionsDocument, variables: { taxYearInterviewId } }],
    awaitRefetchQueries: true,
  });
  const [createSectionAsComplete] = useCreateSectionAsCompleteMutation({
    refetchQueries: [{ query: GetTaxYearInterviewSectionsDocument, variables: { taxYearInterviewId } }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (taxYearInterviewSectionsData?.getTaxYearInterviewSections.length) {
      setTaxYearInterviewSections(taxYearInterviewSectionsData.getTaxYearInterviewSections);
    }
  }, [taxYearInterviewSectionsData]);

  useEffect(() => {
    if (!projects) return;

    setAllAnswers(taxYearInterview.Answers || []);

    if (!interview.Sections || !interview?.Sections?.length) {
      loadInterviewAsync();
    }
  }, [projects, taxYearInterviewId]);

  const loadInterviewAsync = async () => {
    const result = await getInterview({
      variables: {
        id: taxYearInterviewId,
      },
    });

    const interviewResult = result?.data?.GetTaxYearInterviewIncludeInterviews;
    if (interviewResult?.id) {
      setProjectsFromChild(interviewResult);
    }
  };

  const handleOnSelectSection = (id) => {
    setCurrentSectionId(id);
    setIsEditing(true);
  };

  const handleCloseModal = () => {
    history.push('/');
  };

  const handleOnGoBackClick = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      handleCloseModal();
    }

    if (currentSectionId.length) {
      setCurrentSectionId('')
    }
  };

  const collectSectionAsComplete = (sectionId) => {
    taxYearInterviewSectionIdsSet.add(`${sectionId}___${taxYearInterviewId}`);
    clearTimeout(taxYearInterviewUpdateSectionsTimeout);
    taxYearInterviewUpdateSectionsTimeout = setTimeout(async () => {
      const section2Create = [...taxYearInterviewSectionIdsSet]
        .filter((sectionId___interviewId) => taxYearInterviewSectionIdsCompeltedMap[sectionId___interviewId] === undefined);
      // should be only one in every interview
      if (section2Create[0]) {
        const [sectionIdCreate, taxYearInterviewIdCreate] = section2Create[0].split("___");
        await createSectionAsComplete({
          variables: {
            taxYearInterviewId: taxYearInterviewIdCreate,
            sectionId: sectionIdCreate,
          }
        });
        taxYearInterviewSectionIdsCompeltedMap[section2Create[0]] = true;
      }
      // console.log(taxYearInterviewSectionIdsSet, taxYearInterviewSectionIdsCompeltedMap);
    }, 500);
  }

  // const validConditional = (conditionals, answers) => {
  //   if (conditionals.length <= 0) {
  //     return false;
  //   }
  //   return conditionals.some((condition) => answers.find((answer) => answer.description === condition.optionId))
  // };

  const isResponse = (question, answers) => answers.some((answer) => answer.questionId === question.id && answer.description !== '');

  const validCompleteSection = (sections) => sections.every((section) => {
    // const conditionals = section.Conditionals ?? [];
    // const isOptionalSection = conditionals.length > 0 && !validConditional(conditionals, allAnswers);
    // if (isOptionalSection) {
    //   return true;
    // }
    const areQuestionsResponded = section.Questions.every((question) => (
      !question.required || isResponse(question, allAnswers)
    ));
    if (!areQuestionsResponded) {
      return false;
    }
    // DO NOT CHECK SUBSECTIONS ON REQUIRED FIEDLS
    // const subSections = section.SubSections ?? [];
    // const areSubSectionsCompleted = subSections.length === 0 || validCompleteSection(subSections);
    // if (!areSubSectionsCompleted) {
    //  return false;
    // }

    const markedAsComplete = taxYearInterviewSections?.find((ss) => ss.sectionId === section.id)?.markedAsComplete;
    if (markedAsComplete !== undefined) {
      return markedAsComplete;
    }

    // mark section green if it has required fields and all required fields are filled up
    const result = section.Questions.some((q) => q.required);
    if (result === true) {
      // sync computed section complete values with values in DB
      collectSectionAsComplete(section.id);
    }
    return result;
  });

  const getSectionType = (jobId, section, goToNextSection) => (section.specialType === 'DEPENDENT_INFORMATION'
    ? (
      <DependentSection
        taxYearInterviewId={jobId}
        section={section}
        answers={allAnswers}
        taxYearInterviewSections={taxYearInterviewSections}
        goToNextSection={goToNextSection}
      />
      )
    : (
      <EditSection
        jobId={jobId}
        section={section}
        interview={interview}
        allAnswers={allAnswers}
        taxYearInterviewSections={taxYearInterviewSections}
        goToNextSection={goToNextSection}
      />
  ));
 
  if (!Object.keys(interview).length) {
    return <Redirect to="/" />
  }

  const setSectionAsCompleteValue = async (sectionId, value) => {
    await setTaxInterviewSectionCompleteValue({
      variables: {
        taxYearInterviewId,
        sectionId,
        value,
      }
    });
  };

  const goToNextSection = () => {
    const currentSectionIndex = interview?.Sections?.findIndex((sec) => sec.id === currentSection.id);
    if (currentSectionIndex !== -1) {
      const nextSection = interview?.Sections[currentSectionIndex + 1];
      if (nextSection?.id) {
        setCurrentSectionId(nextSection.id);
      } else {
        setIsEditing(false);
      }
    }
  }

  return (
    <Modal
      visible
      style={{
        fontFamily: 'Lato, sans-serif',
      }}
      bodyStyle={{
        padding: 24,
        minHeight: 460,
      }}
      onCancel={handleCloseModal}
      title={(
        <div className={css(styles.titleContainer)}>
          <span className={css(styles.h2)}>
            {
              !isEditing 
                ? (<Translation text="interview.modalTitle" />)
                : (getTitle(interview.Sections.find(({ id }) => id === currentSectionId)?.Translations ?? []))
            }
          </span>
          {isEditing && (
            <Row justify="space-between" style={{ paddingTop: '15px' }}>
              <Button
                key="backOrCloseButton"
                type="primary"
                onClick={handleOnGoBackClick}
              >
                <Translation text="interview.back" />
              </Button>
              {taxYearInterviewSections?.find((ss) => ss.sectionId === currentSectionId)?.markedAsComplete
                ? (
                  <Button
                    key="markAsNotComlete"
                    type="secondary"
                    onClick={() => setSectionAsCompleteValue(currentSectionId, false)}
                    loading={sectionCompleteLoading}
                  >
                    <Translation text="interview.markAsNotComplete" />
                  </Button>
                )
                : (
                  <Button
                    key="markAsComlete"
                    type="primary"
                    onClick={() => setSectionAsCompleteValue(currentSectionId, true)}
                    loading={sectionCompleteLoading}
                  >
                    <Translation text="interview.markAsComplete" />
                  </Button>
              )}
            </Row>
          )}
        </div>
      )}
      footer={[
        <Button
          key="backOrCloseButton"
          onClick={handleCloseModal}
        >
          <Translation text="interview.close" />
        </Button>
      ]}
      className={css(styles.modal)}
    >
      {interviewDataLoading || taxYearInterviewSectionsDataLoading
        ? (
          <Row justify="center">
            <Spin size="large" />
          </Row>
        )
        : (
          <>
            <div className={`${css(styles.subTitleContainer)} ${css(styles.bottomSpacing)}`}>
              <Translation text="interview.information" />
            </div>
            {!isEditing
              ? interview.Sections?.map((section) => (
                <SectionStatusCard
                  key={section.id}
                  id={section.id}
                  onSelect={handleOnSelectSection}
                  title={getTitle(section.Translations)}
                  status={validCompleteSection([section])}
                />
                ))
              : getSectionType(taxYearInterviewId, currentSection, goToNextSection)}
          </>
      )}
      
    </Modal>
  );
};

export default InterviewSectionList;