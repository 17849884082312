/* eslint-disable */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { text } from '../styles/fonts';

export default ({ value, styles = {} }) => {
  const errorStyles = StyleSheet.create({
    error: {
      ...text.error, minHeight: '20px', padding: '3px 0', ...styles,
    },
  });
  if (!value) return null;
  return (
    <div className={css(errorStyles.error)}>
      {value.msg}
    </div>
  );
};