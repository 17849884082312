import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadFileFromS3MutationVariables = Types.Exact<{
  s3Key: Types.Scalars['String'];
}>;


export type DownloadFileFromS3Mutation = { __typename?: 'Mutation', downloadFile: { __typename?: 'FileSuccessEntity', success?: boolean | null, signedUrl?: string | null } };

export type LoginAsMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type LoginAsMutation = { __typename?: 'Mutation', LoginAs: { __typename?: 'SessionEntity', accessToken?: string | null, headToURL?: string | null, selectedFirmAccount?: string | null, RelatedFirms?: Array<{ __typename?: 'UserFirmAccountEntity', userId?: string | null, entityId?: string | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null } | null, Users?: { __typename?: 'UserEntity', UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null }> | null, UserFirmAccount?: { __typename?: 'UserFirmAccountEntity', userId?: string | null, entityId?: string | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null } | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', languageId?: string | null, email?: string | null, uniqueLink?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, firstName: string, lastName: string, birthday?: any | null, ssn?: string | null, profilePhoto?: string | null } | null, Languages?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null } | null } | null, Users?: { __typename?: 'UserEntity', languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, firstName: string, lastName: string, birthday?: any | null, ssn?: string | null, profilePhoto?: string | null } | null, Languages?: { __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null } | null } };

export type UpdateClientMutationVariables = Types.Exact<{
  updateClientInput: Types.UpdateClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', UpdateClient: { __typename?: 'UserSuccessEntity', success: boolean } };


export const DownloadFileFromS3Document = gql`
    mutation DownloadFileFromS3($s3Key: String!) {
  downloadFile(s3Key: $s3Key) {
    success
    signedUrl
  }
}
    `;
export type DownloadFileFromS3MutationFn = Apollo.MutationFunction<DownloadFileFromS3Mutation, DownloadFileFromS3MutationVariables>;

/**
 * __useDownloadFileFromS3Mutation__
 *
 * To run a mutation, you first call `useDownloadFileFromS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileFromS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFileFromS3Mutation, { data, loading, error }] = useDownloadFileFromS3Mutation({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *   },
 * });
 */
export function useDownloadFileFromS3Mutation(baseOptions?: Apollo.MutationHookOptions<DownloadFileFromS3Mutation, DownloadFileFromS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadFileFromS3Mutation, DownloadFileFromS3MutationVariables>(DownloadFileFromS3Document, options);
      }
export type DownloadFileFromS3MutationHookResult = ReturnType<typeof useDownloadFileFromS3Mutation>;
export type DownloadFileFromS3MutationResult = Apollo.MutationResult<DownloadFileFromS3Mutation>;
export type DownloadFileFromS3MutationOptions = Apollo.BaseMutationOptions<DownloadFileFromS3Mutation, DownloadFileFromS3MutationVariables>;
export const LoginAsDocument = gql`
    mutation LoginAs($userId: String!, $token: String!) {
  LoginAs(userId: $userId, token: $token) {
    accessToken
    headToURL
    selectedFirmAccount
    RelatedFirms {
      userId
      entityId
      FirmAccount {
        id
        name
      }
      Users {
        UserInformation {
          firstName
          lastName
        }
      }
      Roles {
        id
        description
      }
    }
    UserFirmAccount {
      userId
      entityId
      FirmAccount {
        id
        name
      }
      Entity {
        Users {
          UserInformation {
            id
            userId
            firstName
            lastName
            birthday
            ssn
            profilePhoto
          }
          Languages {
            id
            lang
            description
          }
          languageId
          email
          uniqueLink
        }
      }
      Users {
        UserInformation {
          id
          userId
          firstName
          lastName
          birthday
          ssn
          profilePhoto
        }
        Languages {
          id
          lang
          description
        }
        languageId
      }
      Roles {
        id
        description
      }
    }
  }
}
    `;
export type LoginAsMutationFn = Apollo.MutationFunction<LoginAsMutation, LoginAsMutationVariables>;

/**
 * __useLoginAsMutation__
 *
 * To run a mutation, you first call `useLoginAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsMutation, { data, loading, error }] = useLoginAsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginAsMutation(baseOptions?: Apollo.MutationHookOptions<LoginAsMutation, LoginAsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAsMutation, LoginAsMutationVariables>(LoginAsDocument, options);
      }
export type LoginAsMutationHookResult = ReturnType<typeof useLoginAsMutation>;
export type LoginAsMutationResult = Apollo.MutationResult<LoginAsMutation>;
export type LoginAsMutationOptions = Apollo.BaseMutationOptions<LoginAsMutation, LoginAsMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($updateClientInput: UpdateClientInput!) {
  UpdateClient(updateClientInput: $updateClientInput) {
    success
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      updateClientInput: // value for 'updateClientInput'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const namedOperations = {
  Mutation: {
    DownloadFileFromS3: 'DownloadFileFromS3',
    LoginAs: 'LoginAs',
    UpdateClient: 'UpdateClient'
  }
}