import momentTz from 'moment-timezone';

const getTimeZone = (time) => {
  const date = time ? momentTz(new Date(time)) : momentTz(new Date());
  let timeZone = momentTz.tz.guess();
  if (!timeZone) timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneAbreviation = date.tz(timeZone).format('z');

  return {
    large: timeZone,
    short: timeZoneAbreviation,
  };
};

export {
  getTimeZone,
};
