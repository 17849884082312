import { getLanguages } from '../../api/languages';

export const SET_LANGUAGES = 'SET_LANGUAGES';

export const setLanguages = (languages) => ({
  type: SET_LANGUAGES,
  payload: languages,
});

export const getAvailableLanguages = () => async (dispatch) => {
  const languages = await getLanguages();
  if (languages.length) {
    dispatch(setLanguages(languages));
  }
};
