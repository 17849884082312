/* eslint-disable */
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import { Tooltip } from "antd";
import withOverlay from "../../utils/withOverlay";
import { ClientDocuments } from "./components";
import FirmDocuments from "./FirmDocuments";
import Button from "../Button/Button";
import Badge from "../Badge";
import { color, grey } from "../../styles/colors";
import { Lato } from "../../styles/fonts";
import Translation from "../atoms/Translation";
import { BadgeKeys } from "./components/";

import { useGetFilesByTaxYearInterviewIdQuery } from '../../graphql/queries/settings';
import { useGetFirmAccountByIdQuery } from "../../graphql/queries/firmAccount";
import { useGetTasksByTaxYearInterviewIdQuery } from "../../graphql/queries/tasks";
import { useGetSignedFileByS3KeyLazyQuery } from "../../graphql/queries/files";
import { TaskType } from '../../graphql';

const Documents = (props) => {
  const {
    close,
    history,
    dispatch,
    shouldBeFromFirm,
    match,
    session
  } = props;
  
  // const {
  //   Entity,
  // } = project;
  // const { FirmAccount } = Entity.UserFirmAccount[0];


  const taxYearInterviewId = match.params.projectId;
  const {
    userId,
    lastName,
    firmAccountId,
    accessToken,
    email,
  } = session?.graphSession;
  const [showYourDocs, setShowYourDocs] = useState(true);

  const [getSignedFile, { data: signedFileData, loading: signedFileLoading, error: signedFileError }] = useGetSignedFileByS3KeyLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { data: filesData, loading: loadingFiles, error: filesError, refetch: refetchFiles } = useGetFilesByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId,
    },
  });

  const { data: firmAccountData, loading: loadingFirmAccount, error: firmAccountError } = useGetFirmAccountByIdQuery({
    variables: {
      id: firmAccountId,
    },
  });

  // get tasks by taxYearInterviewId
  const { data: tasksData, loading: loadingTasks, error: tasksError,  } = useGetTasksByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId,
    },
  });

  const firmAccount = firmAccountData?.FirmAccountById;
  const files = filesData?.FilesByTaxYearInterviewId || [];
  const clientFiles = files.filter((file) => !file.CompiledFile?.[0] && !file.SignedFile?.[0]);

  const tasks = tasksData?.tasksByTaxYearInterviewId;
  const notPaidInvoices = tasks
  && tasks.filter(
    (task) => task.taskType === TaskType.Payments && !task.completedAt && !task.deletedAt
  ).length;

  const firmFiles = files
    .filter((file) => file.CompiledFile?.length > 0)
    .map((file) => ({
      ...file,
      paymentLocked: notPaidInvoices > 0,
    }));

  const pendingClientFileCount = tasks && tasks.filter((task) => (
    [
      TaskType.RequestDocuments,
      TaskType.Documents
    ].includes(task.taskType) && !task.completedAt && !task.deletedAt  
  )).length;
  
  const pendingFirmFileCount = tasks
    && tasks.filter(
      (task) => (
        [
          TaskType.Signatures,
          TaskType.ApproveDocuments,
        ].includes(task.taskType)
      )
      && !task.completedAt && !task.deletedAt  
    ).length;

  useEffect(() => {
    // dispatch(getDocuments(taxYearInterviewId));
    if (shouldBeFromFirm) {
      setShowYourDocs(false);
    }
  }, []);

  useEffect(() => {
    if (close) {
      history.push("/");
    }
  }, [close]);

  const onClose = () => {
    history.push("/");
  }

  const urlParams = new URLSearchParams(window.location.search);
  const taskId = urlParams.get("taskId");
  const isTaskIdExist = !!taskId;

  return (
    <div>
      <div className={css(styles.tabs)}>
        <div
          onClick={() => setShowYourDocs(true)}
          className={css(styles.tab, showYourDocs && styles.activeTab)}
        >
          <Translation text="documents.yourDocuments" />
          {pendingClientFileCount > 0 && (
            <Badge badgeStyles={counterStyles} type="todo">
              <Tooltip title={<Translation text="documents.yourDocuments.tooltip" />}>
                {pendingClientFileCount}
              </Tooltip>
            </Badge>
          )}
        </div>
        <div
          onClick={() => setShowYourDocs(false)}
          className={css(styles.tab, !showYourDocs && styles.activeTab)}
        >
          <Translation text="documents.documentsFrom" />
          {" "}
          {firmAccount?.name || <Translation text="documents.generalFirm" />}
          {pendingFirmFileCount > 0 && (
            <Badge badgeStyles={counterStyles} type="todo">
              <Tooltip title={<Translation text="documents.documentsFrom.tooltip" />}>
                {pendingFirmFileCount}
              </Tooltip>
            </Badge>
          )}
        </div>
      </div>
      {showYourDocs && (
        <ClientDocuments
          files={clientFiles}
          tasks={tasks}
          taskId={taskId}
          isTaskIdExist={isTaskIdExist}
          userId={userId}
          lastName={lastName}
          dispatch={dispatch}
          projectId={taxYearInterviewId}
          firmAccountId={firmAccountId}
          accessToken={accessToken}
          getSignedFile={getSignedFile}
          refetchFiles={refetchFiles}
          loadingFiles={loadingFiles}
        />
      )}
      {!showYourDocs &&
        <FirmDocuments
          files={firmFiles}
          email={email}
          projectId={taxYearInterviewId}
          dispatch={dispatch}
          getSignedFile={getSignedFile}
        />}
      {files && !loadingFiles && (
        <div className={css(styles.btnBlock)}>
          {!showYourDocs ? <div className={css(styles.badgeKeysWrapper1)}><BadgeKeys /></div> : <div />}
          <Button
            blue
            label={<Translation text="documents.done" />}
            style={{
              minWidth: "155px",
              width: "calc(100% + 16px)",
              height: '33px',
              alignSelf: 'center',
              margin: "0 -8px",
              "@media (min-width: 400px)": { width: "auto", margin: 0 },
            }}
            onClick={onClose}
          />
        </div>
      )}
      {!showYourDocs && <div className={css(styles.badgeKeysWrapper2)}><BadgeKeys /></div>}
    </div>
  );
};

const screenSizes = {
  max1024: "@media (max-width: 1024px)",
  tablets: "@media (max-width: 700px)",
  smartphones: "@media (max-width: 420px)",
};

const counterStyles = {
  position: "absolute",
  top: "-7px",
  right: "30px",
  zIndex: 1,
  [screenSizes.max1024]: {
    top: "-15px",
    right: "2px",
  },
  [screenSizes.tablets]: {
    top: "-20px",
  },
};

const styles = StyleSheet.create({
  badgeKeysWrapper1: {
    display: 'none',
    "@media (min-width: 400px)": {
      display: 'block',
    },
  },
  badgeKeysWrapper2: {
    display: 'block',
    "@media (min-width: 400px)": {
      display: 'none',
    },
  },
  btnBlock: {
    paddingTop: "40px",
    textAlign: "right",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tabs: {
    display: "flex",
    width: "calc(100% + 40px)",
    margin: "0 -20px",
    "@media (min-width: 640px)": {
      margin: "0 -40px",
      width: "calc(100% + 80px)",
    },
  },
  tab: {
    position: "relative",
    flexBasis: "50%",
    textAlign: "center",
    padding: "6px 12px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.8px",
    borderBottom: `2px solid ${grey["700"]}`,
    color: grey["800"],
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    ":hover": {
      backgroundColor: grey["100"],
    },
    "@media (min-width: 640px)": {
      padding: "12px 24px",
      fontSize: "18px",
    },
  },
  activeTab: {
    borderBottom: `2px solid ${color.blue}`,
    color: color.blue,
  },
});

const mapStateToProps = (state, ownProps) => {
  const {
    session,
  } = state;
  return {
    session,
  };
};

export default withOverlay(
  withRouter(connect(mapStateToProps)(Documents)),
  true,
  true
);