import PropTypes from 'prop-types';
import {
  Alert, Button, Card, Form, Input, Row, Typography, Grid,
} from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Message } from '../../helpers/forms';
import styles from './style.module.scss';
import { useResetPasswordMutation } from '../../../graphql/mutations/passwordReset';

const { useBreakpoint } = Grid;
const { Title } = Typography;
const { Item } = Form;
const { Password } = Input;
const ResetPassword = ({ errorUniqueId, id }) => {
  const screens = useBreakpoint();

  // graphql
  // mutation to reset password
  const [resetPassword, { loading: loadingResetPassword }] = useResetPasswordMutation({
    onError: (err) => {
      Message(err.message, 'error');
    },
    onCompleted: () => {
      Message('Password reset. Please login.', 'success');
      setTimeout(() => {
        window.location.replace('/login');
      }, 500);
    },
  });


  const formik = useFormik({
    validationSchema: yup.object().shape({
      password: yup.string().required().typeError(),
      rePassword: yup.string().required().typeError(),
    }),
    initialValues: {
      password: '',
      rePassword: '',
    },
    onSubmit: async ({ password, rePassword }) => {
      if (password === rePassword) {
        try {
          resetPassword({
            variables: {
              resetPasswordInput: {
                uuid: id,
                password,
                passwordConfirmation: rePassword,
              },
            },
          });
        } catch ({ response }) {
          Message(response?.data?.message, 'error');
        }
      } else {
        formik.errors.rePassword = 'Passwords do not match.';
      }
    },
  });
  const {
    handleSubmit, handleChange, values, errors, touched,
  } = formik;

  if (errorUniqueId) {
    return (
      <div style={{ marginTop: 25, display: 'flex', justifyContent: 'center' }}>
        <div>
          <Card
            style={{
              padding: screens.md ? '10px 80px' : '5px',
              maxWidth: '900px',
            }}
          >
            <Row justify="center">
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Reset Your Password</Title>
                {errorUniqueId === 'CannotFindRecordException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="Record could not be found."
                    description="This could be because you have already reset your password. Try logging in, or requesting a password reset again."
                    style={{ marginTop: '40px', maxWidth: '500px' }}
                  />
                )}
                {errorUniqueId === 'PasswordResetExpiredException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="This link has expired."
                    description="Please request a new link to reset your password."
                    style={{ marginTop: '40px' }}
                  />
                )}
              </div>
            </Row>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        {errorUniqueId ? (
          <div>
            <h2>Reset password link invalid or it has expired.</h2>
            <p>
              For your protection, we deactivated your reset password link after
              a week passed. Please contact your tax preparer to have them
              resend an invitation.
            </p>
          </div>
        ) : (
          <Form
            onFinish={handleSubmit}
            className={styles.cardBody}
            layout="vertical"
          >
            <p className={styles.title}>Reset Your Password</p>

            <Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'The password is required.',
                },
                {
                  min: 6,
                  message: 'The password must be at least 6 characters of length.',
                },
                {
                  pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                  message: 'The password should include at least one lowercase letter,\n one uppercase letter \n and one number and one of these symbols: !@#$%^&*.',
                },
              ]}
            >
              <Password
                onChange={handleChange}
                value={values.password}
              />
            </Item>

            <Item
              label="Confirm Password"
              name="rePassword"
              rules={[
                {
                  required: true,
                  message: 'The password confirmation is required.',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                })
              ]}
            >
              <Password
                onChange={handleChange}
                value={values.rePassword}
              />
            </Item>

            <Button
              className={styles.btn}
              type="primary"
              size="large"
              htmlType="submit"
              loading={loadingResetPassword}
              block
            >
              Request Password Reset
            </Button>
          </Form>
        )}
      </Card>
    </div>
  );
};

ResetPassword.propTypes = {
  id: PropTypes.string.isRequired,
  errorUniqueId: PropTypes.bool.isRequired,
};

export default ResetPassword;
