import API from '../../api';
import { getBranding, getBrandingByFirm, getBrandingByUniqueId } from '../../api/branding';

export const SET_LANG = 'SET_LANG';
export const SET_BRANDING = 'SET_BRANDING';

export const setLang = (lang) => ({
  type: SET_LANG,
  payload: lang,
});

export const setBranding = (branding) => ({
  type: SET_BRANDING,
  payload: branding,
});

export const changePassword = async (passwords) => API.post('/client/change-password', passwords);

export const setUserLanguage = (lang) => async (dispatch) => {
  dispatch(setLang(lang));
};

export const getCustomBranding = () => async (dispatch) => {
  const { host } = window.location;
  if (host.includes(process.env.REACT_APP_CLIENT_DOMAIN)) {
    dispatch(setBranding(null));
    return;
  }
  const branding = await getBranding(host);
  if (branding && !branding.error) {
    dispatch(setBranding(branding.branding.branding));
  }
};

export const getCustomBrandingByFirm = (firmId) => async (dispatch) => {
  const branding = await getBrandingByFirm(firmId);
  // console.log('getCustomBranding', branding)
  if (branding && !branding.error) {
    dispatch(setBranding(branding.branding.branding));
  }
};

export const getCustomBrandingByUniqueId = (uniqueId) => async (dispatch) => {
  const branding = await getBrandingByUniqueId(uniqueId);
  // console.log('getCustomBranding', branding)
  if (branding && !branding.error) {
    dispatch(setBranding(branding.branding.branding));
  }
};
