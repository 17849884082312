import Api from '../../api/api';
import API from '../../api';

export const S3Policy = async (file, config, url) => {
  const { name: fileName, size: fileSize } = file;

  const getProfilePicS3Policy = await API({
    method: 'POST',
    url,
    data: {
      name: fileName,
      fileSize,
      ...config,
    },
  });

  if (getProfilePicS3Policy.data.error) return null;

  return getProfilePicS3Policy.data;
};

export const saveFile = async (s3P, action, options) => {
  const s3Pkg = s3P;
  const { finalName } = s3Pkg;

  const { file } = options;
  const contentType = file.type;
  const blobData = file;
  const fileSize = file.size;

  delete s3Pkg.s3Bucket;
  delete s3Pkg.finalName;

  const fd = new FormData();

  // eslint-disable-next-line
  for (const key in s3Pkg) {
    fd.append(key, s3Pkg[key]);
  }

  fd.append('Content-type', contentType);
  fd.append('Content-length', fileSize);
  fd.append('x-amz-meta-original-file-name', finalName);
  fd.append('x-amz-server-side-encryption', 'AES256');
  fd.append('acl', 'private');
  fd.append('file', blobData);

  const res = await Api.post(action, fd, {
    onUploadProgress: (e) => {
      const progress = {
        percent: Math.ceil((e.loaded / e.total) * 100),
      };
      return options.onProgress(progress);
    },
  });

  file.s3key = s3Pkg.key;
  options.onSuccess(res.data, file);

  await Api({
    url: '/service/S3/recordFile',
    data: {
      keyName: s3Pkg.key,
    },
    method: 'POST',
  });
};
