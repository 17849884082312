export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type ActivateClientEntity = {
  __typename?: 'ActivateClientEntity';
  message: Scalars['String'];
};

export type ActivateClientInput = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  uuid: Scalars['String'];
};

export type ActiveCampaignContactEntity = {
  __typename?: 'ActiveCampaignContactEntity';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Float']>;
};

export type AddJobInput = {
  email?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  interviewId: Scalars['String'];
  preparerId?: InputMaybe<Scalars['String']>;
  progressStatusId: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  year: Scalars['String'];
};

export type AddSubSectionInput = {
  Questions: Array<CreateInterviewQuestionInput>;
  Translations: Array<CreateTranslationInput>;
  interviewId: Scalars['String'];
  isSpecial: Scalars['Boolean'];
  order: Scalars['Int'];
  parentId?: InputMaybe<Scalars['String']>;
  specialType?: InputMaybe<SpecialSectionsType>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  BusinessInformation?: Maybe<BusinessInformationEntity>;
  UserInformation?: Maybe<UserInformationEntity>;
  businessInformationId?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  lineOne: Scalars['String'];
  lineTwo?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: AddressType;
  updateAt: Scalars['DateTime'];
  userInformationId?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export enum AddressType {
  Business = 'BUSINESS',
  Personal = 'PERSONAL'
}

export type AdministrativeRolesEntity = {
  __typename?: 'AdministrativeRolesEntity';
  Roles?: Maybe<RolesEntity>;
  User?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  roleId: Scalars['String'];
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type AliasesEntity = {
  __typename?: 'AliasesEntity';
  Items: Array<Scalars['String']>;
  Quantity: Scalars['Float'];
};

export type AnswerCheckSectionEntity = {
  __typename?: 'AnswerCheckSectionEntity';
  answers: Array<AnswerEntity>;
  sectionIsComplete: Scalars['Boolean'];
};

export type AnswerEntity = {
  __typename?: 'AnswerEntity';
  Questions?: Maybe<QuestionEntity>;
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  completedAt?: Maybe<Scalars['DateTime']>;
  /** Date of creation */
  createAt: Scalars['DateTime'];
  /** description of the answer */
  description: Scalars['String'];
  /** id of the answers */
  id: Scalars['String'];
  isPrePopulated: Scalars['Boolean'];
  /** Id of the question */
  questionId: Scalars['String'];
  requestAt?: Maybe<Scalars['DateTime']>;
  /** Id of the interview */
  taxYearInterviewId: Scalars['String'];
  /** Last date of update */
  updateAt: Scalars['DateTime'];
};

export type AvgRatingEntity = {
  __typename?: 'AvgRatingEntity';
  avgRating: Scalars['String'];
  id: Scalars['String'];
  numberOfReviews: Scalars['Float'];
  preparerName: Scalars['String'];
};

export type BillingPortalSessionFlowDataAfterCompletionHostedConfirmationInput = {
  custom_message?: InputMaybe<Scalars['String']>;
};

export type BillingPortalSessionFlowDataAfterCompletionInput = {
  hosted_confirmation?: InputMaybe<BillingPortalSessionFlowDataAfterCompletionHostedConfirmationInput>;
  redirect?: InputMaybe<BillingPortalSessionFlowDataAfterCompletionRedirectInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type BillingPortalSessionFlowDataAfterCompletionRedirectInput = {
  return_url?: InputMaybe<Scalars['String']>;
};

export type BillingPortalSessionFlowDataInput = {
  /** Behavior after the flow is completed. */
  after_completion?: InputMaybe<BillingPortalSessionFlowDataAfterCompletionInput>;
  /** Configuration when flow.type=subscription_cancel. */
  subscription_cancel?: InputMaybe<BillingPortalSessionFlowDataSubscriptionCancelInput>;
  /** Configuration when flow.type=subscription_update. */
  subscription_update?: InputMaybe<BillingPortalSessionFlowDataSubscriptionUpdateInput>;
  /** Configuration when flow.type=subscription_update_confirm. */
  subscription_update_confirm?: InputMaybe<BillingPortalSessionFlowDataSubscriptionUpdateConfirmInput>;
  /** Type of flow that the customer will go through. */
  type: Scalars['String'];
};

export type BillingPortalSessionFlowDataSubscriptionCancelInput = {
  subscription: Scalars['String'];
};

export type BillingPortalSessionFlowDataSubscriptionUpdateConfirmInput = {
  items: Array<BillingPortalSessionFlowDataSubscriptionUpdateConfirmItemInput>;
  subscription: Scalars['String'];
};

export type BillingPortalSessionFlowDataSubscriptionUpdateConfirmItemInput = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  price?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type BillingPortalSessionFlowDataSubscriptionUpdateInput = {
  subscription: Scalars['String'];
};

export type BooleanValue = {
  __typename?: 'BooleanValue';
  val: Scalars['Boolean'];
};

export type BusinessInformationEntity = {
  __typename?: 'BusinessInformationEntity';
  Address?: Maybe<Array<AddressEntity>>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Phones?: Maybe<Array<PhonesEntity>>;
  accountingPercent?: Maybe<Scalars['Float']>;
  bookKeepingPercent?: Maybe<Scalars['Float']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['Float']>;
  payrollPercent?: Maybe<Scalars['Float']>;
  taxPercent?: Maybe<Scalars['Float']>;
  taxResolutionPercent?: Maybe<Scalars['Float']>;
  taxReturns?: Maybe<Scalars['Float']>;
  yearsInBusiness?: Maybe<Scalars['Float']>;
};

export enum BusinessType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type ButtonsEntity = {
  __typename?: 'ButtonsEntity';
  Steps?: Maybe<Scalars['DateTime']>;
  action?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  popConfirm?: Maybe<Scalars['String']>;
  stepId?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type CanAddMemberEntity = {
  __typename?: 'CanAddMemberEntity';
  success: Scalars['Boolean'];
};

export type CanUpdateFileSystemEntity = {
  __typename?: 'CanUpdateFileSystemEntity';
  success: Scalars['Boolean'];
};

export type CanUpdateFileSystemInput = {
  newPath?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
  type: UpdateFileSystemType;
};

export type CertificateStatusEntity = {
  __typename?: 'CertificateStatusEntity';
  DomainValidationOptions?: Maybe<DomainValidationOptions>;
  FailureReason?: Maybe<FailureReason>;
  Status?: Maybe<CustomDomainStatuses>;
  id: Scalars['String'];
};

export type ChannelEntity = {
  __typename?: 'ChannelEntity';
  ChannelMembers?: Maybe<Array<ChannelMemberEntity>>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Meetings?: Maybe<Array<MeetingEntity>>;
  Messages?: Maybe<Array<MessageEntity>>;
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type ChannelMemberEntity = {
  __typename?: 'ChannelMemberEntity';
  Channel?: Maybe<ChannelEntity>;
  Users?: Maybe<UserEntity>;
  channelId: Scalars['String'];
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastConnection: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ClientsImportInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isUpdate: Scalars['Boolean'];
  jobType: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<CreatePhoneInput>>;
  preparer?: InputMaybe<Scalars['String']>;
  sendInvitation: Scalars['Boolean'];
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  taxYear: Scalars['Float'];
  updatedFields?: InputMaybe<Array<Scalars['String']>>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CnameStatusEntity = {
  __typename?: 'CnameStatusEntity';
  cnameStatus: Scalars['String'];
  distribution: DistributionSummaryEntity;
  hostname: Scalars['String'];
};

export type ConditionalEntity = {
  __typename?: 'ConditionalEntity';
  Options?: Maybe<OptionsEntity>;
  Section?: Maybe<SectionEntity>;
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  optionId: Scalars['String'];
  sectionId: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type CreateAddressInput = {
  businessInformationId?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  lineOne: Scalars['String'];
  lineTwo?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  type: AddressType;
  userInformationId?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type CreateAnswerInput = {
  /** Description for the answer */
  description: Scalars['String'];
  /** Id of the question */
  questionId: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
};

export type CreateBillingPortalSessionInput = {
  /** The ID of an existing configuration to use for this session. */
  configuration?: InputMaybe<Scalars['String']>;
  /** The ID of an existing customer. */
  customer?: InputMaybe<Scalars['String']>;
  /** Information about a specific flow for the customer to go through. */
  flow_data?: InputMaybe<BillingPortalSessionFlowDataInput>;
  /** The IETF language tag of the locale Customer Portal is displayed in. If blank or auto, the customer’s preferred_locales or browser’s locale is used. */
  locale?: InputMaybe<Scalars['String']>;
  /** (Connect only) The account for which the session was created on behalf of. */
  on_behalf_of?: InputMaybe<Scalars['String']>;
  /** The URL to which Stripe should send customers when they click on the link to return to your website. */
  return_url?: InputMaybe<Scalars['String']>;
};

export type CreateBulkMessageInput = {
  phoneIds: Array<Scalars['String']>;
  text: Scalars['String'];
};

export type CreateBulkMessageOutput = {
  __typename?: 'CreateBulkMessageOutput';
  message: Scalars['String'];
};

export type CreateBusinessInformationInput = {
  Address?: InputMaybe<Array<CreateAddressInput>>;
  Phones?: InputMaybe<Array<CreatePhoneInput>>;
  accountingPercent?: InputMaybe<Scalars['Float']>;
  bookKeepingPercent?: InputMaybe<Scalars['Float']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['Float']>;
  payrollPercent?: InputMaybe<Scalars['Float']>;
  taxPercent?: InputMaybe<Scalars['Float']>;
  taxResolutionPercent?: InputMaybe<Scalars['Float']>;
  taxReturns?: InputMaybe<Scalars['Float']>;
  yearsInBusiness?: InputMaybe<Scalars['Float']>;
};

export type CreateChannelInput = {
  currentUserId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  firmAccountId: Scalars['String'];
  userIdToConnect: Scalars['String'];
};

export type CreateCheckoutSessionAdjustableQuantityInput = {
  enabled: Scalars['Boolean'];
  maximum?: InputMaybe<Scalars['Float']>;
  minimum?: InputMaybe<Scalars['Float']>;
};

export type CreateCheckoutSessionInput = {
  adjustableQuantity?: InputMaybe<CreateCheckoutSessionAdjustableQuantityInput>;
  cancel_url?: InputMaybe<Scalars['String']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  priceId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  success_url?: InputMaybe<Scalars['String']>;
};

export type CreateClientFromInviteLinkInput = {
  FirmAccount?: InputMaybe<Array<CreateFirmAccountInput>>;
  UserInformation?: InputMaybe<CreateUserInformationInput>;
  codeOTP?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  enableOTP?: InputMaybe<Scalars['Boolean']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  interviewId?: InputMaybe<Scalars['String']>;
  languageId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  preparedId?: InputMaybe<Scalars['String']>;
  progressStatusId?: InputMaybe<Scalars['String']>;
  referredByUser?: InputMaybe<Scalars['String']>;
  secretOTP?: InputMaybe<Scalars['String']>;
  selectedFirmId?: InputMaybe<Scalars['String']>;
  sendActivationEmail?: InputMaybe<Scalars['Boolean']>;
  taxYear: Scalars['Float'];
};

export type CreateClientInput = {
  FirmAccount?: InputMaybe<Array<CreateFirmAccountInput>>;
  UserInformation?: InputMaybe<CreateUserInformationInput>;
  email?: InputMaybe<Scalars['String']>;
  interviewId?: InputMaybe<Scalars['String']>;
  languageId?: InputMaybe<Scalars['String']>;
  progressStatusId?: InputMaybe<Scalars['String']>;
  selectedFirmId?: InputMaybe<Scalars['String']>;
  sendActivationEmail: Scalars['Boolean'];
  taxYear: Scalars['Float'];
};

export type CreateConditionalInput = {
  optionId: Scalars['String'];
  sectionId: Scalars['String'];
};

export type CreateCustomDomainsInput = {
  certificateArn?: InputMaybe<Scalars['String']>;
  cloudfrontIdId?: InputMaybe<Scalars['String']>;
  firmAccountId: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<CustomDomainStatuses>;
};

export type CreateCustomLayoutInput = {
  firmAccountId?: InputMaybe<Scalars['String']>;
  headerBackgroundColor?: InputMaybe<Scalars['String']>;
  headerFontColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  pixelCode?: InputMaybe<Scalars['String']>;
};

export type CreateEventInput = {
  clientId: Scalars['String'];
  endDate: Scalars['DateTime'];
  eventTypeId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateEventTypeAvailabilityBracketInput = {
  endHour: Scalars['Float'];
  endMinute: Scalars['Float'];
  eventTypeAvailabilityId?: InputMaybe<Scalars['String']>;
  startHour: Scalars['Float'];
  startMinute: Scalars['Float'];
};

export type CreateEventTypeAvailabilityInput = {
  EventTypeAvailabilityBracket?: InputMaybe<Array<CreateEventTypeAvailabilityBracketInput>>;
  day: Scalars['String'];
  eventTypeId?: InputMaybe<Scalars['String']>;
};

export type CreateEventTypeInput = {
  EventTypeAvailability?: InputMaybe<Array<CreateEventTypeAvailabilityInput>>;
  active: Scalars['Boolean'];
  afterBuffer?: InputMaybe<Scalars['Float']>;
  beforeBuffer?: InputMaybe<Scalars['Float']>;
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Float'];
  maxEvents?: InputMaybe<Scalars['Float']>;
  minNotice?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type CreateFirmAccountInput = {
  businessType: BusinessType;
  name?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  type: FirmAccountType;
  uniqueLink?: InputMaybe<Scalars['String']>;
};

export type CreateFirmNotificationReminderInput = {
  firmNotificationId: Scalars['String'];
  frequency: Scalars['Float'];
  maxReminders: Scalars['Float'];
};

export type CreateInterviewInput = {
  /** Drake package */
  drakePackage?: InputMaybe<Scalars['String']>;
  /** Id of the firm, not required since we use the context of the application */
  firmAccountId?: InputMaybe<Scalars['String']>;
  /** Boolean to check if interview is live */
  isLive: Scalars['Boolean'];
  /** Boolean template value */
  isTemplate: Scalars['Boolean'];
  /** Name of the interview */
  name: Scalars['String'];
  /** Number of order */
  order?: InputMaybe<Scalars['Float']>;
  sections?: InputMaybe<Array<CreateInterviewSectionInput>>;
};

export type CreateInterviewOptionsInput = {
  translations: Array<CreateInterviewTranslationInput>;
};

export type CreateInterviewQuestionInput = {
  options?: InputMaybe<Array<CreateInterviewOptionsInput>>;
  order: Scalars['Float'];
  required: Scalars['Boolean'];
  translations: Array<CreateInterviewTranslationInput>;
  type: QuestionType;
};

export type CreateInterviewSectionInput = {
  order: Scalars['Float'];
  questions: Array<CreateInterviewQuestionInput>;
  subSections?: InputMaybe<Array<CreateInterviewSectionInput>>;
  translations: Array<CreateInterviewTranslationInput>;
};

export type CreateInterviewTranslationInput = {
  description: Scalars['String'];
  languageId: Scalars['String'];
};

export type CreateInvoicesInput = {
  description: Scalars['String'];
  paymentId?: InputMaybe<Scalars['String']>;
  priceEach: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type CreateJobTrackingInput = {
  approvalsCompletedAt?: InputMaybe<Scalars['DateTime']>;
  expectedCompletion?: InputMaybe<Scalars['DateTime']>;
  filesCompletedAt?: InputMaybe<Scalars['DateTime']>;
  interviewCompletedAt?: InputMaybe<Scalars['DateTime']>;
  paymentsCompletedAt?: InputMaybe<Scalars['DateTime']>;
  taxYearInterviewId: Scalars['String'];
};

export type CreateMessageInput = {
  attachment?: InputMaybe<Scalars['String']>;
  channelId: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type CreateMessageWithFileInput = {
  appendName: Scalars['Boolean'];
  channelUserId: Scalars['String'];
  file: FileMessageInput;
  folder: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
};

export type CreateNylasAuthDtoInput = {
  callbackUrl: Scalars['String'];
  email: Scalars['String'];
};

export type CreateNylasAuthorizeDtoInput = {
  code: Scalars['String'];
  stepId?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateMappedDriveUserInput = {
  deviceHostName: Scalars['String'];
  deviceUserName: Scalars['String'];
  macAddress: Scalars['String'];
  rcloneVersion: Scalars['String'];
  userTargetOsPlatform: TargetOsPlatform;
};

export type CreatePaymentInvoice = {
  description?: InputMaybe<Scalars['String']>;
  priceEach?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type CreatePaymentsInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  discountApplied?: InputMaybe<Scalars['Float']>;
  entityId: Scalars['String'];
  firmAccountId: Scalars['String'];
  invoices?: InputMaybe<Array<CreateInvoicesInput>>;
  paidOffline?: InputMaybe<Scalars['Boolean']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentProcessorFee?: InputMaybe<Scalars['Float']>;
  paymentProcessorId?: InputMaybe<Scalars['String']>;
  preparerFee?: InputMaybe<Scalars['Float']>;
  promisedAt?: InputMaybe<Scalars['DateTime']>;
  referralFee?: InputMaybe<Scalars['Float']>;
  requestAt?: InputMaybe<Scalars['DateTime']>;
  taskId?: InputMaybe<Scalars['String']>;
  taxYearId?: InputMaybe<Scalars['String']>;
  taxYearInterviewId: Scalars['String'];
};

export type CreatePhoneInput = {
  businessInformationId?: InputMaybe<Scalars['String']>;
  type: PhoneType;
  userInformationId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

/** New progressStatus data */
export type CreateProgressStatusesInput = {
  firmAccountId?: InputMaybe<Scalars['String']>;
  index: Scalars['Float'];
  status: Scalars['String'];
  underlyingStatus?: InputMaybe<TaxYearStatus>;
};

export type CreateRequestedFilesInput = {
  fileName: Scalars['String'];
  notes: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
};

export type CreateReviewInput = {
  reviewContent?: InputMaybe<Scalars['String']>;
  reviewValue: Scalars['Float'];
  taxYearInterviewId: Scalars['String'];
};

export type CreateSectionInput = {
  interviewId: Scalars['String'];
  isSpecial: Scalars['Boolean'];
  order: Scalars['Int'];
  parentId?: InputMaybe<Scalars['String']>;
  specialType?: InputMaybe<SpecialSectionsType>;
};

export type CreateSignatureFieldInput = {
  email: Scalars['String'];
  fieldHeightPercentage?: InputMaybe<Scalars['Float']>;
  fieldId?: InputMaybe<Scalars['String']>;
  fieldLeftPercentage?: InputMaybe<Scalars['Float']>;
  fieldTopPercentage?: InputMaybe<Scalars['Float']>;
  fieldType: FieldType;
  fieldWidthPercentage?: InputMaybe<Scalars['Float']>;
  fileApprovalId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  signatureId?: InputMaybe<Scalars['String']>;
  signatureImage?: InputMaybe<Scalars['String']>;
  signerId?: InputMaybe<Scalars['String']>;
  signerParty?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateSpecialSectionInput = {
  interviewId: Scalars['String'];
  order: Scalars['Float'];
  parentId?: InputMaybe<Scalars['String']>;
  type: SpecialSectionsType;
};

export type CreateStripeCustomerInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
};

export type CreateStripeInformationInput = {
  customerId: Scalars['String'];
  firmAccountId: Scalars['String'];
  plan?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
};

export type CreateStripePaymentMethodInput = {
  /** The card's CVC. It is highly recommended to always include this value. */
  cvc: Scalars['String'];
  /** Two-digit number representing the card's expiration month. */
  exp_month: Scalars['Float'];
  /** Four-digit number representing the card's expiration year. */
  exp_year: Scalars['Float'];
  /** ID of the payment method */
  id?: InputMaybe<Scalars['String']>;
  /** The card's last 4 digits. */
  last4?: InputMaybe<Scalars['String']>;
  /** The card number, as a string without any separators. */
  number: Scalars['String'];
};

export type CreateStripeSubscriptionInput = {
  contactId?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  isNewCustomer?: InputMaybe<Scalars['Boolean']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  paymentBehavior?: InputMaybe<Scalars['String']>;
  paymentMethodId?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  quantity: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTaskInput = {
  taskType: TaskType;
  taxYearInterviewId: Scalars['String'];
};

export type CreateTaxYearInput = {
  entityId: Scalars['String'];
  year: Scalars['Float'];
};

export type CreateTaxYearInterviewInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  interviewId: Scalars['String'];
  invoicedId?: InputMaybe<Scalars['String']>;
  jobTrackingId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  preparedId?: InputMaybe<Scalars['String']>;
  preparerAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  progressStatusId: Scalars['DateTime'];
  reviewStatus?: InputMaybe<ReviewStatus>;
  reviewedId?: InputMaybe<Scalars['String']>;
  taxYearId: Scalars['String'];
};

export type CreateTranslationInput = {
  description: Scalars['String'];
  languageId: Scalars['String'];
  optionId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  sectionId?: InputMaybe<Scalars['String']>;
};

export type CreateUserFirmAccountInput = {
  entityId?: InputMaybe<Scalars['String']>;
  firmAccountId: Scalars['String'];
  roleId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateUserInformationInput = {
  Address?: InputMaybe<Array<CreateAddressInput>>;
  Phones?: InputMaybe<Array<CreatePhoneInput>>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  BusinessInformation?: InputMaybe<CreateBusinessInformationInput>;
  FirmAccount?: InputMaybe<Array<CreateFirmAccountInput>>;
  UserInformation?: InputMaybe<CreateUserInformationInput>;
  contactId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firmName?: InputMaybe<Scalars['String']>;
  hearAboutUs?: InputMaybe<Scalars['String']>;
  languageId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  secretOTP?: InputMaybe<Scalars['String']>;
  selectedFirmId?: InputMaybe<Scalars['String']>;
  uniqueLink?: InputMaybe<Scalars['String']>;
};

export type CreateUserStepInput = {
  completed?: InputMaybe<Scalars['Boolean']>;
  stepId: Scalars['String'];
  userId: Scalars['String'];
};

export enum CustomDomainStatuses {
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Inactive = 'INACTIVE',
  Issued = 'ISSUED',
  PendingValidation = 'PENDING_VALIDATION',
  Revoked = 'REVOKED',
  ValidationTimedOut = 'VALIDATION_TIMED_OUT'
}

export type CustomDomainsEntity = {
  __typename?: 'CustomDomainsEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  certificateArn?: Maybe<Scalars['String']>;
  cloudfrontIdId?: Maybe<Scalars['String']>;
  createAt?: Maybe<Scalars['DateTime']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CustomDomainStatuses>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type CustomLayoutEntity = {
  __typename?: 'CustomLayoutEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  createAt?: Maybe<Scalars['DateTime']>;
  firmAccountId?: Maybe<Scalars['String']>;
  headerBackgroundColor?: Maybe<Scalars['String']>;
  headerFontColor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  pixelCode?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type DateValue = {
  __typename?: 'DateValue';
  val: Scalars['DateTime'];
};

export type DeleteStripeSubscriptionCancellationDetailsInput = {
  comment?: InputMaybe<Scalars['String']>;
  feedback?: InputMaybe<Scalars['String']>;
};

export type DeleteStripeSubscriptionInput = {
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']>;
  cancellation_details?: InputMaybe<DeleteStripeSubscriptionCancellationDetailsInput>;
  invoice_now?: InputMaybe<Scalars['Boolean']>;
  prorate?: InputMaybe<Scalars['Boolean']>;
};

export type DistributionSummaryEntity = {
  __typename?: 'DistributionSummaryEntity';
  ARN: Scalars['String'];
  Aliases: AliasesEntity;
  Comment: Scalars['String'];
  DomainName: Scalars['String'];
  Enabled: Scalars['Boolean'];
  HttpVersion: Scalars['String'];
  Id: Scalars['String'];
  IsIPV6Enabled: Scalars['Boolean'];
  LastModifiedTime: Scalars['DateTime'];
  PriceClass: Scalars['String'];
  Status: Scalars['String'];
  WebACLId: Scalars['String'];
};

export type DocumentApprovalUploadInput = {
  clientId: Scalars['String'];
  compiledHash: Scalars['String'];
  documentTitle: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  pages?: InputMaybe<Scalars['Float']>;
  reviewerNotes?: InputMaybe<Scalars['String']>;
  signerKBAs: Array<SignerKbaInput>;
  taxYearInterviewId: Scalars['String'];
};

export type DocumentSignatureUploadInput = {
  clientId: Scalars['String'];
  compiledHash: Scalars['String'];
  documentTitle: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Float'];
  pages?: InputMaybe<Scalars['Float']>;
  reviewerNotes?: InputMaybe<Scalars['String']>;
  signatureFields: Array<CreateSignatureFieldInput>;
  signerKBAs: Array<SignerKbaInput>;
  taxYearInterviewId: Scalars['String'];
};

export type DomainValidationOptions = {
  __typename?: 'DomainValidationOptions';
  name: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type DrakeFileEntity = {
  __typename?: 'DrakeFileEntity';
  fileContent: Scalars['String'];
  fileName: Scalars['String'];
};

export type Entity = {
  __typename?: 'Entity';
  Payments?: Maybe<Array<PaymentsEntity>>;
  TaxYear?: Maybe<Array<TaxYearEntity>>;
  UserEntity?: Maybe<Array<UserEntityEntity>>;
  UserFirmAccount?: Maybe<Array<UserFirmAccountEntity>>;
  Users?: Maybe<UserEntity>;
  createAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  ownerId: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type EntityOrderByInput = {
  Users?: InputMaybe<UserOrderByInput>;
  createAt?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type EventEntity = {
  __typename?: 'EventEntity';
  EventTypes: EventTypeEntity;
  Meetings: MeetingEntity;
  createAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  eventTypeId: Scalars['String'];
  id: Scalars['String'];
  meetingId: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  nylasEventId?: Maybe<Scalars['String']>;
  organizerId: Scalars['String'];
  sequence: Scalars['Float'];
  startDate: Scalars['DateTime'];
  status: EventStatuses;
  title: Scalars['String'];
  type: EventType;
  updateAt: Scalars['DateTime'];
};

export enum EventStatuses {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Confirmed = 'CONFIRMED',
  Incomplete = 'INCOMPLETE',
  Rescheduled = 'RESCHEDULED',
  Scheduled = 'SCHEDULED'
}

export enum EventType {
  Call = 'CALL',
  Home = 'HOME',
  Office = 'OFFICE',
  Other = 'OTHER',
  Video = 'VIDEO'
}

export type EventTypeAvailabilityBracketEntity = {
  __typename?: 'EventTypeAvailabilityBracketEntity';
  EventTypeAvailability?: Maybe<EventTypeAvailabilityEntity>;
  createAt: Scalars['DateTime'];
  endHour: Scalars['Float'];
  endMinute: Scalars['Float'];
  eventTypeAvailabilityId: Scalars['String'];
  id: Scalars['String'];
  startHour: Scalars['Float'];
  startMinute: Scalars['Float'];
  updateAt: Scalars['DateTime'];
};

export type EventTypeAvailabilityEntity = {
  __typename?: 'EventTypeAvailabilityEntity';
  EventType?: Maybe<EventTypeEntity>;
  EventTypeAvailabilityBracket?: Maybe<Array<EventTypeAvailabilityBracketEntity>>;
  createAt: Scalars['DateTime'];
  day: Scalars['String'];
  eventTypeId: Scalars['String'];
  id: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type EventTypeEntity = {
  __typename?: 'EventTypeEntity';
  EventTypeAvailability?: Maybe<Array<EventTypeAvailabilityEntity>>;
  Events?: Maybe<Array<EventEntity>>;
  User?: Maybe<UserEntity>;
  active: Scalars['Boolean'];
  afterBuffer?: Maybe<Scalars['Float']>;
  beforeBuffer?: Maybe<Scalars['Float']>;
  color: Scalars['String'];
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  id: Scalars['String'];
  maxEvents?: Maybe<Scalars['Float']>;
  minNotice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ExportSuccessEntity = {
  __typename?: 'ExportSuccessEntity';
  stringData?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FailureData = {
  __typename?: 'FailureData';
  failureReason?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export enum FailureReason {
  AdditionalVerificationRequired = 'ADDITIONAL_VERIFICATION_REQUIRED',
  CaaError = 'CAA_ERROR',
  DomainNotAllowed = 'DOMAIN_NOT_ALLOWED',
  DomainValidationDenied = 'DOMAIN_VALIDATION_DENIED',
  InvalidPublicDomain = 'INVALID_PUBLIC_DOMAIN',
  NoAvailableContacts = 'NO_AVAILABLE_CONTACTS',
  Other = 'OTHER',
  PcaAccessDenied = 'PCA_ACCESS_DENIED',
  PcaInvalidArgs = 'PCA_INVALID_ARGS',
  PcaInvalidArn = 'PCA_INVALID_ARN',
  PcaInvalidDuration = 'PCA_INVALID_DURATION',
  PcaInvalidState = 'PCA_INVALID_STATE',
  PcaLimitExceeded = 'PCA_LIMIT_EXCEEDED',
  PcaNameConstraintsValidation = 'PCA_NAME_CONSTRAINTS_VALIDATION',
  PcaRequestFailed = 'PCA_REQUEST_FAILED',
  PcaResourceNotFound = 'PCA_RESOURCE_NOT_FOUND',
  SlrNotFound = 'SLR_NOT_FOUND'
}

export type FetchNylasEventsDto = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export enum FieldType {
  Date = 'DATE',
  Signature = 'SIGNATURE',
  Text = 'TEXT'
}

export type FileApprovalEntity = {
  __typename?: 'FileApprovalEntity';
  FileRequests?: Maybe<FileRequestEntity>;
  KBA?: Maybe<Array<KbaEntity>>;
  SignatureFields?: Maybe<Array<SignatureFieldEntity>>;
  Tasks?: Maybe<TaskEntity>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  fileRequestId: Scalars['String'];
  id: Scalars['String'];
  kbaEnable: Scalars['Boolean'];
  name: Scalars['String'];
  taskId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type FileApprovalUpdateInput = {
  action: Scalars['String'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  kBA: KbaUpdateInput;
  name: Scalars['String'];
  signatureFields?: InputMaybe<SignatureFieldsInput>;
  taskId?: InputMaybe<Scalars['String']>;
};

export type FileDownloadEntity = {
  __typename?: 'FileDownloadEntity';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FileEntity = {
  __typename?: 'FileEntity';
  CompiledFile?: Maybe<Array<FileRequestEntity>>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Signatures?: Maybe<Array<SignatureEntity>>;
  SignedFile?: Maybe<Array<FileRequestEntity>>;
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  Users?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  documentReviewed?: Maybe<Scalars['Boolean']>;
  documentUsed?: Maybe<Scalars['Boolean']>;
  firmAccountId?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['Float']>;
  s3Key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  taxYearInterviewId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  uploadedBy: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type FileMessageEntity = {
  __typename?: 'FileMessageEntity';
  deleteAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  folder: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  s3Key: Scalars['String'];
  size: Scalars['Float'];
  taxYearInterviewId: Scalars['String'];
  uploadedBy: Scalars['String'];
};

export type FileMessageInput = {
  blob: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Float'];
  type: Scalars['String'];
};

export type FileRequestEntity = {
  __typename?: 'FileRequestEntity';
  CompiledFile?: Maybe<FileEntity>;
  File?: Maybe<FileEntity>;
  FileApproval?: Maybe<Array<FileApprovalEntity>>;
  FileApprovals?: Maybe<Array<FileApprovalEntity>>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Signatures?: Maybe<Array<SignatureEntity>>;
  SignedFile?: Maybe<FileEntity>;
  Tasks?: Maybe<TaskEntity>;
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  Users?: Maybe<UserEntity>;
  clientId?: Maybe<Scalars['String']>;
  compiledFileId?: Maybe<Scalars['String']>;
  compiledHash?: Maybe<Scalars['String']>;
  createAt: Scalars['DateTime'];
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  signatureComplete?: Maybe<Scalars['DateTime']>;
  signatureRequest?: Maybe<Scalars['DateTime']>;
  signedFileId?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  taxYearInterviewId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type FileSuccessEntity = {
  __typename?: 'FileSuccessEntity';
  signedUrl?: Maybe<Scalars['String']>;
  signedUrlArray?: Maybe<Array<FileDownloadEntity>>;
  success?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type FileTemplateEntity = {
  __typename?: 'FileTemplateEntity';
  Files?: Maybe<FileEntity>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  createAt?: Maybe<Scalars['DateTime']>;
  deleteAt?: Maybe<Scalars['DateTime']>;
  fileName?: Maybe<Scalars['String']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type FilteredLocalNamesEntity = {
  __typename?: 'FilteredLocalNamesEntity';
  legacyRemoteNames: Array<Scalars['String']>;
  legacyRemotePath: Scalars['String'];
  localNames: Array<Scalars['String']>;
  localPath: Scalars['String'];
  newRemoteNames: Array<Scalars['String']>;
  newRemotePath: Scalars['String'];
};

export type FilteredLocalNamesInput = {
  macAddress: Scalars['String'];
  path: Scalars['String'];
};

export type FinishedSigningInput = {
  TextFields?: InputMaybe<Array<FinishedSigningTextFieldInput>>;
  fileApprovalId: Scalars['String'];
  signatureImageData?: InputMaybe<Scalars['String']>;
};

export type FinishedSigningTextFieldInput = {
  description: Scalars['String'];
  id: Scalars['String'];
};

export type FirmAccountEntity = {
  __typename?: 'FirmAccountEntity';
  BusinessInformation?: Maybe<BusinessInformationEntity>;
  CustomDomains?: Maybe<CustomDomainsEntity>;
  CustomLayout?: Maybe<CustomLayoutEntity>;
  FileRequests?: Maybe<Array<FileRequestEntity>>;
  Files?: Maybe<Array<FileEntity>>;
  FirmNotifications?: Maybe<Array<FirmNotificationsEntity>>;
  Interviews?: Maybe<Array<InterviewEntity>>;
  Payments?: Maybe<Array<PaymentsEntity>>;
  ProgressStatuses?: Maybe<Array<ProgressStatusesEntity>>;
  StripeInformation?: Maybe<StripeInformationEntity>;
  UserFirmAccounts?: Maybe<Array<UserFirmAccountEntity>>;
  Users?: Maybe<UserEntity>;
  businessType: BusinessType;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  type: FirmAccountType;
  uniqueLink?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export enum FirmAccountType {
  Arearep = 'AREAREP',
  Enterprise = 'ENTERPRISE',
  Office = 'OFFICE',
  Standard = 'STANDARD'
}

export type FirmLogoEntity = {
  __typename?: 'FirmLogoEntity';
  base64: Scalars['String'];
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type FirmNotificationReminderEntity = {
  __typename?: 'FirmNotificationReminderEntity';
  FirmNotification?: Maybe<FirmNotificationsEntity>;
  createAt: Scalars['DateTime'];
  firmNotificationId: Scalars['String'];
  frequency: Scalars['Float'];
  id: Scalars['String'];
  maxReminders: Scalars['Float'];
  updateAt: Scalars['DateTime'];
};

export type FirmNotificationsEntity = {
  __typename?: 'FirmNotificationsEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  FirmNotificationReminder?: Maybe<FirmNotificationReminderEntity>;
  Notifications?: Maybe<NotificationsEntity>;
  active: Scalars['Boolean'];
  createAt: Scalars['DateTime'];
  firmAccountId: Scalars['String'];
  id: Scalars['String'];
  notificationId: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type FirmSettingsEntity = {
  __typename?: 'FirmSettingsEntity';
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: SettingsType;
  value: SettingsValue;
};

export type GetStripeCustomerInput = {
  customerId: Scalars['String'];
};

export type GroupedFirmNotificationsEntity = {
  __typename?: 'GroupedFirmNotificationsEntity';
  CLIENT?: Maybe<Array<FirmNotificationsEntity>>;
  REMINDERS?: Maybe<Array<FirmNotificationsEntity>>;
  TEAM?: Maybe<Array<FirmNotificationsEntity>>;
};

export type InterviewEntity = {
  __typename?: 'InterviewEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  Sections?: Maybe<Array<SectionEntity>>;
  TaxYearInterview?: Maybe<Array<TaxYearInterviewEntity>>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  drakePackage?: Maybe<Scalars['String']>;
  firmAccountId: Scalars['String'];
  id: Scalars['String'];
  isLive: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  updateAt: Scalars['DateTime'];
};

export type InterviewSuccessOpEntity = {
  __typename?: 'InterviewSuccessOpEntity';
  success?: Maybe<Scalars['Boolean']>;
};

export type InterviewsOrderByInput = {
  createAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type InvoicesEntity = {
  __typename?: 'InvoicesEntity';
  Payment?: Maybe<PaymentsEntity>;
  createAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  paymentId: Scalars['String'];
  priceEach: Scalars['Float'];
  quantity: Scalars['Float'];
  updateAt: Scalars['DateTime'];
};

export type JobDirNamesEntity = {
  __typename?: 'JobDirNamesEntity';
  jobDirNames: Array<Scalars['String']>;
};

export type JobDirNamesInput = {
  jobIds: Array<Scalars['String']>;
};

export type JobTrackingEntity = {
  __typename?: 'JobTrackingEntity';
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  approvalsCompletedAt?: Maybe<Scalars['DateTime']>;
  createAt?: Maybe<Scalars['DateTime']>;
  expectedCompletion?: Maybe<Scalars['DateTime']>;
  filesCompletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  interviewCompletedAt?: Maybe<Scalars['DateTime']>;
  paymentsCompletedAt?: Maybe<Scalars['DateTime']>;
  taxYearInterviewId: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type JobTrackingOrderByInput = {
  approvalsCompletedAt?: InputMaybe<Scalars['String']>;
  createAt?: InputMaybe<Scalars['String']>;
  expectedCompletion?: InputMaybe<Scalars['String']>;
  filesCompletedAt?: InputMaybe<Scalars['String']>;
  interviewCompletedAt?: InputMaybe<Scalars['String']>;
  paymentsCompletedAt?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type KbaFieldEntity = {
  __typename?: 'KBAFieldEntity';
  KBA?: Maybe<KbaEntity>;
  KBAId: Scalars['String'];
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  type: QuestionType;
  updateAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type KbaFieldsUpdateInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  type: QuestionType;
  value: Scalars['String'];
};

export enum KbaType {
  Basic = 'BASIC',
  Enhanced = 'ENHANCED',
  Without = 'WITHOUT'
}

export type KbaUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  kBaFields?: InputMaybe<Array<KbaFieldsUpdateInput>>;
  type: KbaType;
};

export type KbaAnswerEntity = {
  __typename?: 'KbaAnswerEntity';
  KBAQuestionId?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
  createAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type KbaEntity = {
  __typename?: 'KbaEntity';
  FileApproval?: Maybe<FileApprovalEntity>;
  KBAFields?: Maybe<Array<KbaFieldEntity>>;
  KBAQuestions?: Maybe<Array<KbaQuestionEntity>>;
  createAt: Scalars['DateTime'];
  fileApprovalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isIdScanPassed: Scalars['Boolean'];
  isIdScanPending: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  numFailedIdScans?: Maybe<Scalars['Float']>;
  numKbaAttempts?: Maybe<Scalars['Float']>;
  numKbaFailedAttempts?: Maybe<Scalars['Float']>;
  numRequestedIdScans?: Maybe<Scalars['Float']>;
  type: KbaType;
  updateAt: Scalars['DateTime'];
};

export type KbaQuestionEntity = {
  __typename?: 'KbaQuestionEntity';
  KBAId?: Maybe<Scalars['String']>;
  createAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
  kbaAnswers?: Maybe<Array<KbaAnswerEntity>>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type LanguagesEntity = {
  __typename?: 'LanguagesEntity';
  Translations?: Maybe<Array<TranslationEntity>>;
  Users?: Maybe<Array<UserEntity>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lang: Scalars['String'];
};

export type LegacyTaxYearInterviewAnswersEntity = {
  __typename?: 'LegacyTaxYearInterviewAnswersEntity';
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  interviewForm?: Maybe<Scalars['JSON']>;
  taxYearInterviewId: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type LoginInput = {
  code?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  selectedFirmAccount?: InputMaybe<Scalars['String']>;
};

export type MappedDriveApplicationInfoEntity = {
  __typename?: 'MappedDriveApplicationInfoEntity';
  awsRegion: Scalars['String'];
  bucketName: Scalars['String'];
  mappedDriveBucketName: Scalars['String'];
};

export type MappedDriveApplicationKeysEntity = {
  __typename?: 'MappedDriveApplicationKeysEntity';
  awsKeys: Scalars['String'];
  durationSeconds: Scalars['Float'];
};

export type MappedDriveApplicationKeysInput = {
  macAddress: Scalars['String'];
};

export type MappedDriveUserEntity = {
  __typename?: 'MappedDriveUserEntity';
  accessToMappedDrive: Scalars['Boolean'];
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type MeetingAttendeesEntity = {
  __typename?: 'MeetingAttendeesEntity';
  Meetings?: Maybe<MeetingEntity>;
  UnregisteredUsers?: Maybe<UnregisteredUser>;
  Users?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  meetingId: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  unregisteredUserId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type MeetingEntity = {
  __typename?: 'MeetingEntity';
  Channels?: Maybe<ChannelEntity>;
  Events?: Maybe<EventEntity>;
  MeetingAttendees: Array<MeetingAttendeesEntity>;
  channelId: Scalars['String'];
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type MemberAddInput = {
  birthday: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageId: Scalars['String'];
  lastName: Scalars['String'];
  roleId: Scalars['String'];
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  Channels?: Maybe<ChannelEntity>;
  Users?: Maybe<UserEntity>;
  attachment?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  isSms: Scalars['Boolean'];
  readDate?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ActivateClientAccount: Scalars['String'];
  AddTaxYearInterviewJob: TaxYearInterviewEntity;
  AgoraAccessToken: Scalars['String'];
  AssignTaxYearInterviewJob: TaxYearInterviewEntity;
  ChangeStatusTaxYearInterviewJob: TaxYearInterviewEntity;
  ClientChannel?: Maybe<ChannelEntity>;
  CreateClient: UserEntity;
  CreateClientFromInviteLink: UserEntity;
  CreateFileTemplate: Scalars['String'];
  CreateInterview: InterviewEntity;
  CreateManyRequestedFiles: Scalars['String'];
  CreatePayments: PaymentsEntity;
  CustomDomainResendEmails: Scalars['Boolean'];
  DeleteClient: UserFirmAccountEntity;
  DeleteClientRequestedFile: RequestedFilesEntity;
  DeleteFileTemplate: FileTemplateEntity;
  DeleteInterview: InterviewEntity;
  DeleteJob: TaxYearInterviewEntity;
  DocumentApprovalUpload: Scalars['String'];
  DocumentSignatureUpload: Scalars['String'];
  EncryptMyToken: SessionEntity;
  GetOnBoardingLink: Scalars['String'];
  GetSiteBrandingLogoPresignedURL: Scalars['String'];
  GetUserProfilePhotoPresignedURL: Scalars['String'];
  ImportClientsFromFile: Scalars['Boolean'];
  Login: SessionEntity;
  LoginAs: SessionEntity;
  LoginAsFirm: SessionEntity;
  MarkFileApprovalsComplete: FileRequestEntity;
  PreparerAvailability: PreparerAvailabilityEntity;
  PreparerEventTypes: Array<PreparerEventTypeEntity>;
  RecoverDeletedClient: Scalars['String'];
  Register: SessionEntity;
  RemoveClient2FA: UserEntity;
  RemoveFileRequest: FileRequestEntity;
  ResendActivation: UserSuccessEntity;
  ResendFileRequest: FileRequestEntity;
  ResetPassword: Scalars['String'];
  ResumeSubscription: StripeSubscriptionEntity;
  ReturnToAccount: SessionEntity;
  SearchCompleted: Array<UserFirmAccountEntity>;
  SearchInProgress: Array<UserFirmAccountEntity>;
  SearchUnassigned: Array<UserFirmAccountEntity>;
  SendActivationEmailToManyClients: UserSuccessFailureEntity;
  SendInterview: UserSuccessEntity;
  SendInvoiceReminder: Scalars['String'];
  StartTaxYearInterviewJob: TaxYearInterviewEntity;
  TaxpayerDeleteFile: FileEntity;
  UpdateClient: UserSuccessEntity;
  UpdateClientFromInviteLink: ActivateClientEntity;
  UpdateEventTypeTimezone: Scalars['Boolean'];
  UpdateFileTemplate: FileTemplateEntity;
  UpdateJobNotes: TaxYearInterviewEntity;
  UpdateTaxYear: TaxYearEntity;
  UpdateUserInformation: UserInformationEntity;
  UploadTaxpayerFile: Scalars['Boolean'];
  ValidateClientsImport: Array<ValidateClientEntity>;
  VerifyDistribution: VerifyDistribution;
  addMemberToFirm: Array<UserFirmAccountEntity>;
  addSignedFileToDB: FileEntity;
  addSubSection: SectionEntity;
  cancelEvent: EventEntity;
  copyInterview: InterviewEntity;
  createAnswer: AnswerEntity;
  createBillingPortalSession: Scalars['String'];
  createBulkMessage: CreateBulkMessageOutput;
  createBusinessInformation: BusinessInformationEntity;
  createChannel: ChannelEntity;
  createCheckoutSession: Scalars['String'];
  createConditionals: Array<ConditionalEntity>;
  createCustomDomains: CustomDomainsEntity;
  createCustomLayout: CustomLayoutEntity;
  createEvent: EventEntity;
  createEventType: EventTypeEntity;
  createFirmNotificationReminder: FirmNotificationReminderEntity;
  createFirmSetting: FirmSettingsEntity;
  createMessage: MessageEntity;
  createMessageWithFile: FileMessageEntity;
  createOrGetStripeCustomer: StripeCustomerEntity;
  createOrGetStripeInformation: StripeInformationEntity;
  createOrUpdateMappedDriveUser: MappedDriveUserEntity;
  createPasswordResetByEmail: PasswordResetEntity;
  createPaymentMethod: StripeCardPaymentMethodEntity;
  createProgressStatuses: ProgressStatusesEntity;
  createRequestedFiles: RequestedFilesEntity;
  createReview: ReviewsEntity;
  createSection: SectionEntity;
  createSectionAsComplete: TaxYearInterviewSectionEntity;
  createSpecialSection: SectionEntity;
  createSubscription: StripeSubscriptionEntity;
  createTask: TaskEntity;
  createTaxYear: TaxYearEntity;
  createTaxYearInterview: TaxYearInterviewEntity;
  createTranslation: TranslationEntity;
  createUser: UserEntity;
  createUserFirmAccount: UserFirmAccountEntity;
  createUserStep: UserStepEntity;
  deleteClientFile: FileSuccessEntity;
  deleteNylasAccount: Scalars['Boolean'];
  deleteSection: SectionEntity;
  deleteSubscription: StripeSubscriptionEntity;
  deleteTeamMember: UserEntity;
  deleteTwoStepAuthFromUser: UserSuccessEntity;
  deleteUser: UserEntity;
  downloadAllFiles: FileSuccessEntity;
  downloadFile: FileSuccessEntity;
  exportToPDF: ExportSuccessEntity;
  finishedSigning: Scalars['String'];
  generateClientOTP: GenerateOtpEntity;
  generatePreSignedPutUrl: FileSuccessEntity;
  getNylasAuthUrl: NylasAuthUrlEntity;
  getNylasCalendarEvents: Array<NylasEventEntitiy>;
  handleNylasAuthCallback: NylasApiAccountEntity;
  markFileApproved: FileApprovalEntity;
  markFilesNotReceived: Array<TaskEntity>;
  markFilesReceived: Array<TaskEntity>;
  markInterviewAsCompleted: TaxYearInterviewEntity;
  markInterviewAsNotCompleted: TaxYearInterviewEntity;
  markPaymentsCompleted: Array<TaskEntity>;
  markPaymentsNotCompleted: Array<TaskEntity>;
  reactivateTeamMember: UserFirmAccountEntity;
  removeAnswer: AnswerEntity;
  removeBusinessInformation: BusinessInformationEntity;
  removeClient: UserFirmAccountEntity;
  removeCustomDomain: CustomDomainsEntity;
  removeEvent: EventEntity;
  removeEventType: EventTypeEntity;
  removeFirmNotificationReminder?: Maybe<FirmNotificationReminderEntity>;
  removeMessage: MessageEntity;
  removePayment: Scalars['String'];
  removeProgressStatuses: ProgressStatusesEntity;
  removeReviews: ReviewsEntity;
  removeSection: SectionEntity;
  removeTask: TaskEntity;
  removeTaxYear: TaxYearEntity;
  removeTranslation: TranslationEntity;
  removeUser: UserFirmAccountEntity;
  removeUserEntity: UserEntityEntity;
  removeUserStep: UserStepEntity;
  saveAnswer: AnswerCheckSectionEntity;
  sendEKbaText: KbaEntity;
  sendWelcomeEmailToAllClients: Scalars['String'];
  setTaxInterviewSectionCompleteValue: TaxYearInterviewSectionEntity;
  setTaxInterviewSectionPrePopulatedConfirmedValue: TaxYearInterviewSectionEntity;
  softDeleteInterview: InterviewSuccessOpEntity;
  switchOrderOfProgressStatuses: Scalars['String'];
  updateAnswer: AnswerEntity;
  updateApprovalSignatureRequest: FileRequestEntity;
  updateBusinessInformation: BusinessInformationEntity;
  updateCompiledFilePreSignedUrl: Scalars['String'];
  updateConditionals: Array<ConditionalEntity>;
  updateCustomLayout: CustomLayoutEntity;
  updateEvent: EventEntity;
  updateEventType: EventTypeEntity;
  updateFile: FileEntity;
  updateFileName: FileEntity;
  updateFileSystem: UpdateFileSystemEntity;
  updateFirmAccount: FirmAccountEntity;
  updateFirmNotificationReminderWithFirmNotificationId: FirmNotificationReminderEntity;
  updateFirmNotificationsStatus: Array<FirmNotificationsEntity>;
  updateFirmSetting: FirmSettingsEntity;
  updateFirmUniqueLink: FirmAccountEntity;
  updateInterview: InterviewEntity;
  updateInterviewState?: Maybe<InterviewEntity>;
  updateMemberRole: Array<UserFirmAccountEntity>;
  updateMessage: MessageEntity;
  updateMessages: Array<MessageEntity>;
  updatePaymentWithInvoices: PaymentsEntity;
  updatePayments: PaymentsEntity;
  updatePixels: CustomLayoutEntity;
  updateProgressStatus: ProgressStatusesEntity;
  updateRandomUserUniqueLink: UserEntity;
  updateReview: ReviewsEntity;
  updateSection: SectionEntity;
  updateSelfUser: UserEntity;
  updateStripeSubscription: StripeSubscriptionEntity;
  updateTask: TaskEntity;
  updateTaxYearInterview: TaxYearInterviewEntity;
  updateTaxYearInterviewProgressStatus: TaxYearInterviewEntity;
  updateTranslation: TranslationEntity;
  updateUser: UserEntity;
  updateUserEntity: UserEntityEntity;
  updateUserFirmAccount: UserFirmAccountEntity;
  updateUserPassword: UserEntity;
  updateUserProfilePhoto: UserInformationEntity;
  updateUserStep: UserStepEntity;
  updateUserUniqueLink: UserEntity;
  uploadFileToFirmOnDB: FileEntity;
  uploadFileToUserOnDB: FileEntity;
  uploadTaxpayerFilePresigedUrl: FileSuccessEntity;
  upsertContact?: Maybe<ActiveCampaignContactEntity>;
  upsertJobTrackingByTaxYearInterviewId: JobTrackingEntity;
  validateEmailAddress: ReoonResponseEntity;
  validateKBA: KbaEntity;
  validateOTP: ValidateOtpEntity;
  verifyKBAQuestions: KbaEntity;
};


export type MutationActivateClientAccountArgs = {
  activateClientInput: ActivateClientInput;
};


export type MutationAddTaxYearInterviewJobArgs = {
  addJobInput: AddJobInput;
};


export type MutationAgoraAccessTokenArgs = {
  channelId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAssignTaxYearInterviewJobArgs = {
  updateTaxYearInterviewInput: UpdateTaxYearInterviewInput;
};


export type MutationChangeStatusTaxYearInterviewJobArgs = {
  updateTaxYearInterviewInput: UpdateTaxYearInterviewInput;
};


export type MutationClientChannelArgs = {
  preparerId: Scalars['String'];
};


export type MutationCreateClientArgs = {
  createClientInput: CreateClientInput;
};


export type MutationCreateClientFromInviteLinkArgs = {
  createClientFromInviteLinkInput: CreateClientFromInviteLinkInput;
};


export type MutationCreateFileTemplateArgs = {
  fileName: Scalars['String'];
  templateName: Scalars['String'];
};


export type MutationCreateInterviewArgs = {
  createInterviewInput: CreateInterviewInput;
};


export type MutationCreateManyRequestedFilesArgs = {
  createRequestedFilesInput: Array<CreateRequestedFilesInput>;
};


export type MutationCreatePaymentsArgs = {
  createPaymentsInput: CreatePaymentsInput;
};


export type MutationDeleteClientArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClientRequestedFileArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFileTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInterviewArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['String'];
};


export type MutationDocumentApprovalUploadArgs = {
  documentApprovalUploadInput: DocumentApprovalUploadInput;
};


export type MutationDocumentSignatureUploadArgs = {
  documentSignatureUploadInput: DocumentSignatureUploadInput;
};


export type MutationGetOnBoardingLinkArgs = {
  returnUrl: Scalars['String'];
};


export type MutationGetSiteBrandingLogoPresignedUrlArgs = {
  mimeType: Scalars['String'];
};


export type MutationGetUserProfilePhotoPresignedUrlArgs = {
  updateUserInformationInput: UpdateUserInformationInput;
};


export type MutationImportClientsFromFileArgs = {
  importClientsFromFile: Array<ClientsImportInput>;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationLoginAsArgs = {
  token: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationLoginAsFirmArgs = {
  firmId: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationMarkFileApprovalsCompleteArgs = {
  fileRequestId: Scalars['String'];
};


export type MutationPreparerAvailabilityArgs = {
  preparerAvailabilityInput: PreparerAvailabilityInput;
};


export type MutationPreparerEventTypesArgs = {
  preparerId: Scalars['String'];
};


export type MutationRecoverDeletedClientArgs = {
  userFirmAccountId: Scalars['String'];
};


export type MutationRegisterArgs = {
  createUserInput: CreateUserInput;
  stripeCardInput?: InputMaybe<CreateStripePaymentMethodInput>;
};


export type MutationRemoveClient2FaArgs = {
  userId: Scalars['String'];
};


export type MutationRemoveFileRequestArgs = {
  id: Scalars['String'];
};


export type MutationResendActivationArgs = {
  userId: Scalars['String'];
};


export type MutationResendFileRequestArgs = {
  fileRequestId: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationResumeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type MutationSearchCompletedArgs = {
  searchClientInput: SearchClientInput;
};


export type MutationSearchInProgressArgs = {
  searchClientInput: SearchClientInput;
};


export type MutationSearchUnassignedArgs = {
  searchClientInput: SearchClientInput;
};


export type MutationSendActivationEmailToManyClientsArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationSendInterviewArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type MutationSendInvoiceReminderArgs = {
  paymentId: Scalars['String'];
};


export type MutationStartTaxYearInterviewJobArgs = {
  updateTaxYearInterviewInput: Scalars['String'];
};


export type MutationTaxpayerDeleteFileArgs = {
  id: Scalars['String'];
};


export type MutationUpdateClientArgs = {
  updateClientInput: UpdateClientInput;
};


export type MutationUpdateClientFromInviteLinkArgs = {
  updateClientFromInviteLinkInput: UpdateClientFromInviteLinkInput;
};


export type MutationUpdateEventTypeTimezoneArgs = {
  timezone: Scalars['String'];
};


export type MutationUpdateFileTemplateArgs = {
  id: Scalars['String'];
  templateName: Scalars['String'];
};


export type MutationUpdateJobNotesArgs = {
  id: Scalars['String'];
  notes: Scalars['String'];
};


export type MutationUpdateTaxYearArgs = {
  updateTaxYearInput: UpdateTaxYearInput;
};


export type MutationUpdateUserInformationArgs = {
  updateUserInformationInput: UpdateUserInformationInput;
};


export type MutationUploadTaxpayerFileArgs = {
  uploadTaxpayerFileInput: UploadTaxpayerFileInput;
};


export type MutationValidateClientsImportArgs = {
  validateClientsArray: Array<ValidateClientFromFileInput>;
};


export type MutationAddMemberToFirmArgs = {
  firmAccountId: Scalars['String'];
  memberAddInput: MemberAddInput;
};


export type MutationAddSignedFileToDbArgs = {
  s3Key: Scalars['String'];
};


export type MutationAddSubSectionArgs = {
  addSubSectionInput: AddSubSectionInput;
  parentId: Scalars['String'];
};


export type MutationCancelEventArgs = {
  id: Scalars['String'];
};


export type MutationCopyInterviewArgs = {
  id: Scalars['String'];
};


export type MutationCreateAnswerArgs = {
  createAnswerInput: CreateAnswerInput;
};


export type MutationCreateBillingPortalSessionArgs = {
  createBillingPortalInput: CreateBillingPortalSessionInput;
};


export type MutationCreateBulkMessageArgs = {
  createBulkMessage: CreateBulkMessageInput;
};


export type MutationCreateBusinessInformationArgs = {
  createBusinessInformationInput: CreateBusinessInformationInput;
};


export type MutationCreateChannelArgs = {
  createChannelInput: CreateChannelInput;
};


export type MutationCreateCheckoutSessionArgs = {
  createCheckoutSessionInput: CreateCheckoutSessionInput;
};


export type MutationCreateConditionalsArgs = {
  createConditionalsInput: Array<CreateConditionalInput>;
};


export type MutationCreateCustomDomainsArgs = {
  createCustomDomainsInput: CreateCustomDomainsInput;
};


export type MutationCreateCustomLayoutArgs = {
  createCustomLayoutInput: CreateCustomLayoutInput;
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};


export type MutationCreateEventTypeArgs = {
  createEventTypeInput: CreateEventTypeInput;
};


export type MutationCreateFirmNotificationReminderArgs = {
  createFirmNotificationReminderInput: CreateFirmNotificationReminderInput;
};


export type MutationCreateFirmSettingArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateMessageArgs = {
  createMessageInput: CreateMessageInput;
};


export type MutationCreateMessageWithFileArgs = {
  createMessageWithFileInput: CreateMessageWithFileInput;
};


export type MutationCreateOrGetStripeCustomerArgs = {
  createStripeCustomerInput: CreateStripeCustomerInput;
};


export type MutationCreateOrGetStripeInformationArgs = {
  createStripeInformationInput: CreateStripeInformationInput;
};


export type MutationCreateOrUpdateMappedDriveUserArgs = {
  createOrUpdateMappedDriveUserInput: CreateOrUpdateMappedDriveUserInput;
};


export type MutationCreatePasswordResetByEmailArgs = {
  email: Scalars['String'];
};


export type MutationCreatePaymentMethodArgs = {
  createStripePaymentMethodInput: CreateStripePaymentMethodInput;
  customerId: Scalars['String'];
};


export type MutationCreateProgressStatusesArgs = {
  createProgressStatusesInput: CreateProgressStatusesInput;
};


export type MutationCreateRequestedFilesArgs = {
  createRequestedFilesInput: CreateRequestedFilesInput;
};


export type MutationCreateReviewArgs = {
  createReviewInput: CreateReviewInput;
};


export type MutationCreateSectionArgs = {
  createSectionInput: CreateSectionInput;
};


export type MutationCreateSectionAsCompleteArgs = {
  sectionId: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
};


export type MutationCreateSpecialSectionArgs = {
  createSpecialSectionInput: CreateSpecialSectionInput;
};


export type MutationCreateSubscriptionArgs = {
  createStripeSubscriptionInput: CreateStripeSubscriptionInput;
};


export type MutationCreateTaskArgs = {
  createTaskInput: CreateTaskInput;
};


export type MutationCreateTaxYearArgs = {
  createTaxYearInput: CreateTaxYearInput;
};


export type MutationCreateTaxYearInterviewArgs = {
  createTaxYearInterviewInput: CreateTaxYearInterviewInput;
};


export type MutationCreateTranslationArgs = {
  createTranslationInput: CreateTranslationInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationCreateUserFirmAccountArgs = {
  createUserFirmAccountInput: CreateUserFirmAccountInput;
};


export type MutationCreateUserStepArgs = {
  createUserStepInput: CreateUserStepInput;
};


export type MutationDeleteClientFileArgs = {
  s3Key: Scalars['String'];
};


export type MutationDeleteNylasAccountArgs = {
  accountId: Scalars['String'];
};


export type MutationDeleteSectionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSubscriptionArgs = {
  deleteStripeSubscriptionInput?: InputMaybe<DeleteStripeSubscriptionInput>;
  subscriptionId: Scalars['String'];
};


export type MutationDeleteTeamMemberArgs = {
  firmAccountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDeleteTwoStepAuthFromUserArgs = {
  validateOtp: ValidateOtpInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDownloadAllFilesArgs = {
  s3Keys: Array<Scalars['String']>;
};


export type MutationDownloadFileArgs = {
  s3Key: Scalars['String'];
};


export type MutationExportToPdfArgs = {
  paymentId: Scalars['String'];
};


export type MutationFinishedSigningArgs = {
  finishedSigningInput: FinishedSigningInput;
};


export type MutationGenerateClientOtpArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePreSignedPutUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};


export type MutationGetNylasAuthUrlArgs = {
  createNylasAuthDtoInput: CreateNylasAuthDtoInput;
};


export type MutationGetNylasCalendarEventsArgs = {
  fetchNylasEventsDto: FetchNylasEventsDto;
};


export type MutationHandleNylasAuthCallbackArgs = {
  createNylasAuthorizeDtoInput: CreateNylasAuthorizeDtoInput;
};


export type MutationMarkFileApprovedArgs = {
  id: Scalars['String'];
};


export type MutationMarkFilesNotReceivedArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type MutationMarkFilesReceivedArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type MutationMarkInterviewAsCompletedArgs = {
  id: Scalars['String'];
};


export type MutationMarkInterviewAsNotCompletedArgs = {
  id: Scalars['String'];
};


export type MutationMarkPaymentsCompletedArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type MutationMarkPaymentsNotCompletedArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type MutationReactivateTeamMemberArgs = {
  firmAccountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveAnswerArgs = {
  id: Scalars['String'];
};


export type MutationRemoveBusinessInformationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveClientArgs = {
  entityId: Scalars['String'];
};


export type MutationRemoveCustomDomainArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEventArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEventTypeArgs = {
  id: Scalars['String'];
};


export type MutationRemoveFirmNotificationReminderArgs = {
  id: Scalars['String'];
};


export type MutationRemoveMessageArgs = {
  id: Scalars['String'];
};


export type MutationRemovePaymentArgs = {
  id: Scalars['String'];
};


export type MutationRemoveProgressStatusesArgs = {
  id: Scalars['String'];
};


export type MutationRemoveReviewsArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSectionArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTaskArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTaxYearArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTranslationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUserEntityArgs = {
  entityId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveUserStepArgs = {
  stepId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSaveAnswerArgs = {
  saveAnswerInput: SaveAnswerInput;
};


export type MutationSendEKbaTextArgs = {
  kbaId: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationSendWelcomeEmailToAllClientsArgs = {
  sendWelcomeEmailInput: SendWelcomeEmailInput;
};


export type MutationSetTaxInterviewSectionCompleteValueArgs = {
  sectionId: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationSetTaxInterviewSectionPrePopulatedConfirmedValueArgs = {
  sectionId: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
  value: Scalars['Boolean'];
};


export type MutationSoftDeleteInterviewArgs = {
  id: Scalars['String'];
};


export type MutationSwitchOrderOfProgressStatusesArgs = {
  switchOrderOfProgressStatusesInput: SwitchOrderOfProgressStatusesInput;
};


export type MutationUpdateAnswerArgs = {
  updateAnswerInput: UpdateAnswerInput;
};


export type MutationUpdateApprovalSignatureRequestArgs = {
  input: UpdateApprovalSignatureRequestInput;
};


export type MutationUpdateBusinessInformationArgs = {
  updateBusinessInformationInput: UpdateBusinessInformationInput;
};


export type MutationUpdateCompiledFilePreSignedUrlArgs = {
  compiledHash: Scalars['String'];
  fileRequestId: Scalars['String'];
  fileSize: Scalars['Float'];
};


export type MutationUpdateConditionalsArgs = {
  updateConditionalInput: Array<UpdateConditionalInput>;
};


export type MutationUpdateCustomLayoutArgs = {
  updateCustomLayoutInput: UpdateCustomLayoutInput;
};


export type MutationUpdateEventArgs = {
  updateEventInput: UpdateEventInput;
};


export type MutationUpdateEventTypeArgs = {
  updateEventTypeInput: UpdateEventTypeInput;
};


export type MutationUpdateFileArgs = {
  updateFileInput: UpdateFileInput;
};


export type MutationUpdateFileNameArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateFileSystemArgs = {
  updateFileSystemInput: UpdateFileSystemInput;
};


export type MutationUpdateFirmAccountArgs = {
  updateFirmAccountInput: UpdateFirmAccountInput;
};


export type MutationUpdateFirmNotificationReminderWithFirmNotificationIdArgs = {
  updateFirmNotificationReminderInput: UpdateFirmNotificationReminderInput;
};


export type MutationUpdateFirmNotificationsStatusArgs = {
  updateFirmNotificationsStatusInput: UpdateFirmNotificationsStatusInput;
};


export type MutationUpdateFirmSettingArgs = {
  id: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUpdateFirmUniqueLinkArgs = {
  updateFirmAccountInput: UpdateFirmAccountInput;
};


export type MutationUpdateInterviewArgs = {
  updateInterviewInput: UpdateInterviewInput;
};


export type MutationUpdateInterviewStateArgs = {
  id: Scalars['String'];
  isLive: Scalars['Boolean'];
};


export type MutationUpdateMemberRoleArgs = {
  firmAccountId: Scalars['String'];
  newEmail: Scalars['String'];
  newRoleId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateMessageArgs = {
  updateMessageInput: UpdateMessageInput;
};


export type MutationUpdateMessagesArgs = {
  updateMessagesInput: Array<UpdateMessageInput>;
};


export type MutationUpdatePaymentWithInvoicesArgs = {
  updatePaymentsInput: UpdatePaymentWithInvoicesInput;
};


export type MutationUpdatePaymentsArgs = {
  updatePaymentsInput: UpdatePaymentsInput;
};


export type MutationUpdatePixelsArgs = {
  updateCustomLayoutInput: UpdateCustomLayoutInput;
};


export type MutationUpdateProgressStatusArgs = {
  updateProgressStatusInput: UpdateProgressStatusesInput;
};


export type MutationUpdateRandomUserUniqueLinkArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateReviewArgs = {
  updateReviewInput: UpdateReviewInput;
};


export type MutationUpdateSectionArgs = {
  updateSectionInput: UpdateSectionInput;
};


export type MutationUpdateSelfUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateStripeSubscriptionArgs = {
  finishTrial?: InputMaybe<Scalars['Boolean']>;
  resume?: InputMaybe<Scalars['Boolean']>;
  updateStripSubscriptionInput: UpdateStripeSubscriptionInput;
};


export type MutationUpdateTaskArgs = {
  updateTaskInput: UpdateTaskInput;
};


export type MutationUpdateTaxYearInterviewArgs = {
  updateTaxYearInterviewInput: UpdateTaxYearInterviewInput;
};


export type MutationUpdateTaxYearInterviewProgressStatusArgs = {
  id: Scalars['String'];
  progressStatusId: Scalars['String'];
};


export type MutationUpdateTranslationArgs = {
  updateTranslationInput: UpdateTranslationInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserEntityArgs = {
  entityId: Scalars['String'];
  updateUserEntityInput: UpdateUserEntityInput;
  userId: Scalars['String'];
};


export type MutationUpdateUserFirmAccountArgs = {
  updateUserFirmAccountInput: UpdateUserFirmAccountInput;
};


export type MutationUpdateUserPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateUserProfilePhotoArgs = {
  updateUserInformationInput: UpdateUserInformationInput;
};


export type MutationUpdateUserStepArgs = {
  updateUserStepInput: UpdateUserStepInput;
};


export type MutationUpdateUserUniqueLinkArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadFileToFirmOnDbArgs = {
  fileRoute: Scalars['String'];
  size: Scalars['Float'];
  taxYearInterviewId: Scalars['String'];
};


export type MutationUploadFileToUserOnDbArgs = {
  fileRoute: Scalars['String'];
  size: Scalars['Float'];
  taxYearInterviewId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUploadTaxpayerFilePresigedUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
};


export type MutationUpsertContactArgs = {
  syncContactInput: SyncContactInput;
};


export type MutationUpsertJobTrackingByTaxYearInterviewIdArgs = {
  createJobTrackingInput: CreateJobTrackingInput;
};


export type MutationValidateEmailAddressArgs = {
  email: Scalars['String'];
};


export type MutationValidateKbaArgs = {
  kbaId: Scalars['String'];
  validateKBAInput: ValidateKbaInput;
};


export type MutationValidateOtpArgs = {
  validateOtp: ValidateOtpInput;
};


export type MutationVerifyKbaQuestionsArgs = {
  kbaId: Scalars['String'];
  questionAnswers: Array<QuestionAnswerInput>;
};

export enum NotificationType {
  Client = 'CLIENT',
  Reminders = 'REMINDERS',
  Team = 'TEAM'
}

export type NotificationsEntity = {
  __typename?: 'NotificationsEntity';
  FirmNotifications?: Maybe<Array<FirmNotificationsEntity>>;
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  type: NotificationType;
  updateAt: Scalars['DateTime'];
};

export type NumberValue = {
  __typename?: 'NumberValue';
  val: Scalars['Float'];
};

export type NylasAccountEntity = {
  __typename?: 'NylasAccountEntity';
  User?: Maybe<UserEntity>;
  accountId?: Maybe<Scalars['String']>;
  createAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type NylasApiAccountEntity = {
  __typename?: 'NylasApiAccountEntity';
  accessToken?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkedAt?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  organizationUnit?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  syncState?: Maybe<Scalars['String']>;
};

export type NylasAuthUrlEntity = {
  __typename?: 'NylasAuthUrlEntity';
  url: Scalars['String'];
};

export type NylasEventAttendees = {
  __typename?: 'NylasEventAttendees';
  email: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type NylasEventEntitiy = {
  __typename?: 'NylasEventEntitiy';
  attendees: Array<NylasEventAttendees>;
  color: Scalars['String'];
  duration: Scalars['Float'];
  endDate: Scalars['DateTime'];
  iCalUID: Scalars['String'];
  id: Scalars['String'];
  notes: Scalars['String'];
  organizerId: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: EventStatuses;
  title: Scalars['String'];
  type: EventType;
};

export type OnboardingClientDataEntity = {
  __typename?: 'OnboardingClientDataEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  UserInformation?: Maybe<UserInformationEntity>;
  alreadyActive: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
  languageId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
};

export type OpenaiChatCompletionEntity = {
  __typename?: 'OpenaiChatCompletionEntity';
  choices?: Maybe<Array<OpenaiChoiceEntity>>;
  completion?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  usage?: Maybe<Array<OpenaiUsageEntity>>;
};

export type OpenaiChoiceEntity = {
  __typename?: 'OpenaiChoiceEntity';
  finish_reason: Scalars['String'];
  index: Scalars['Float'];
  message: OpenaiMessageEntity;
};

export type OpenaiMessageEntity = {
  __typename?: 'OpenaiMessageEntity';
  content: Scalars['String'];
  role: Scalars['String'];
};

export type OpenaiUsageEntity = {
  __typename?: 'OpenaiUsageEntity';
  completion_tokens: Scalars['Float'];
  prompt_tokens: Scalars['Float'];
  total_tokens: Scalars['Float'];
};

export type OptionsEntity = {
  __typename?: 'OptionsEntity';
  Conditional?: Maybe<ConditionalEntity>;
  Question?: Maybe<QuestionEntity>;
  Translations?: Maybe<Array<TranslationEntity>>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type PaginationTaxYearInterviewResponseEntity = {
  __typename?: 'PaginationTaxYearInterviewResponseEntity';
  data: Array<TaxYearInterviewEntity>;
  pagination: TaxYearInterviewPagination;
};

export type PaginationUserFirmAccountResponseEntity = {
  __typename?: 'PaginationUserFirmAccountResponseEntity';
  data: Array<UserFirmAccountEntity>;
  pagination: UserFirmAccountPagination;
};

export type PasswordResetEntity = {
  __typename?: 'PasswordResetEntity';
  Users?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type PaymentsEntity = {
  __typename?: 'PaymentsEntity';
  Entity?: Maybe<Entity>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Invoices?: Maybe<Array<InvoicesEntity>>;
  Tasks?: Maybe<TaskEntity>;
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  discountApplied?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paidOffline?: Maybe<Scalars['Boolean']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  paymentProcessorFee?: Maybe<Scalars['Float']>;
  paymentProcessorId?: Maybe<Scalars['String']>;
  preparerFee?: Maybe<Scalars['Float']>;
  promisedAt?: Maybe<Scalars['DateTime']>;
  referralFee?: Maybe<Scalars['Float']>;
  requestAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  taxYearId?: Maybe<Scalars['String']>;
  taxYearInterviewId?: Maybe<Scalars['String']>;
  totalDollarAmount?: Maybe<Scalars['Float']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionEntity = {
  __typename?: 'PermissionEntity';
  RolePermissions?: Maybe<Array<RolePermissionEntity>>;
  createAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updateAt: Scalars['DateTime'];
  value: Scalars['Boolean'];
};

export enum PhoneType {
  Home = 'HOME',
  Landline = 'LANDLINE',
  Mobile = 'MOBILE',
  Office = 'OFFICE',
  Personal = 'PERSONAL',
  Work = 'WORK'
}

export type PhonesEntity = {
  __typename?: 'PhonesEntity';
  BusinessInformation?: Maybe<BusinessInformationEntity>;
  UserInformation?: Maybe<UserInformationEntity>;
  businessInformationId?: Maybe<Scalars['String']>;
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  type: PhoneType;
  updateAt: Scalars['DateTime'];
  userInformationId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type PreparedUserEntity = {
  __typename?: 'PreparedUserEntity';
  UserFirmAccount?: Maybe<Array<UserFirmAccountEntity>>;
  UserInformation: UserInformationEntity;
  email: Scalars['String'];
  id: Scalars['String'];
  uniqueLink?: Maybe<Scalars['String']>;
};

export type PreparerAvailabilityEntity = {
  __typename?: 'PreparerAvailabilityEntity';
  brackets: Array<PreparerEventTypeAvailabilityBracketEntity>;
  eventsInDay: Scalars['Float'];
};

export type PreparerAvailabilityInput = {
  afterBuffer?: InputMaybe<Scalars['Float']>;
  availabilityBrackets: Array<PreparerEventTypeAvailabilityBracketInput>;
  beforeBuffer?: InputMaybe<Scalars['Float']>;
  day: Scalars['String'];
  minNotice?: InputMaybe<Scalars['Float']>;
  preparerId: Scalars['String'];
  timezone: Scalars['String'];
  today: Scalars['DateTime'];
};

export type PreparerEventTypeAvailabilityBracketEntity = {
  __typename?: 'PreparerEventTypeAvailabilityBracketEntity';
  endHour: Scalars['Float'];
  endMinute: Scalars['Float'];
  id: Scalars['String'];
  startHour: Scalars['Float'];
  startMinute: Scalars['Float'];
};

export type PreparerEventTypeAvailabilityBracketInput = {
  endHour: Scalars['Float'];
  endMinute: Scalars['Float'];
  id: Scalars['String'];
  startHour: Scalars['Float'];
  startMinute: Scalars['Float'];
};

export type PreparerEventTypeAvailabilityEntity = {
  __typename?: 'PreparerEventTypeAvailabilityEntity';
  EventTypeAvailabilityBracket?: Maybe<Array<PreparerEventTypeAvailabilityBracketEntity>>;
  day: Scalars['String'];
  id: Scalars['String'];
};

export type PreparerEventTypeEntity = {
  __typename?: 'PreparerEventTypeEntity';
  EventTypeAvailability?: Maybe<Array<PreparerEventTypeAvailabilityEntity>>;
  afterBuffer?: Maybe<Scalars['Float']>;
  beforeBuffer?: Maybe<Scalars['Float']>;
  duration: Scalars['Float'];
  id: Scalars['String'];
  maxEvents?: Maybe<Scalars['Float']>;
  minNotice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type ProgressStatusesEntity = {
  __typename?: 'ProgressStatusesEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  createAt: Scalars['DateTime'];
  firmAccountId: Scalars['String'];
  id: Scalars['String'];
  index: Scalars['Float'];
  status: Scalars['String'];
  underlyingStatus: TaxYearStatus;
  updateAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  AIMessageSuggestion?: Maybe<OpenaiChatCompletionEntity>;
  BusinessInformation: BusinessInformationEntity;
  ClientDetails: UserEntity;
  ClientOnboardingData: OnboardingClientDataEntity;
  ClientRequestedFiles: Array<RequestedFilesEntity>;
  Clients: PaginationUserFirmAccountResponseEntity;
  ClientsByTaxYearStatus: PaginationTaxYearInterviewResponseEntity;
  CurrentStripeInformation: StripeInformationEntity;
  CustomDomainByName: CustomDomainsEntity;
  CustomDomainCertificateStatus: CertificateStatusEntity;
  CustomDomainCnameStatus: CnameStatusEntity;
  CustomDomainUser?: Maybe<CustomDomainsEntity>;
  CustomLayout?: Maybe<CustomLayoutEntity>;
  DeletedClients: PaginationUserFirmAccountResponseEntity;
  FileTemplates: Array<FileTemplateEntity>;
  FilesByTaxYearInterviewId: Array<FileEntity>;
  FindUsersFirmAccountByRoleId: Array<UserFirmAccountEntity>;
  FindUsersFirmAccountByUserId: Array<UserFirmAccountEntity>;
  FirmAccountById: FirmAccountEntity;
  FirmAccountForInviteLink: FirmAccountEntity;
  FirmReviewsFiltered: Array<ReviewsEntity>;
  FirmTaxPreparers?: Maybe<Array<TaxPreparersEntity>>;
  GetAllEntityDataByEntityId: Entity;
  GetAllPlans: Array<StripePlansInformationEntity>;
  GetCancellationPrice: StripePriceEntity;
  GetClientSecretFromPaymentIntentId: Scalars['String'];
  GetCloudFrontDistributionByCertificateArn: DistributionSummaryEntity;
  GetConnectAccountId: Scalars['String'];
  GetFirmLogo?: Maybe<FirmLogoEntity>;
  GetInvoices: Array<StripeInvoiceEntity>;
  GetOTPBoolean: UserSuccessEntity;
  GetPaymentIntents: Array<StripePaymentIntentWithRefundsEntity>;
  GetPlan: StripePlansInformationEntity;
  GetPlansByProductId: Array<StripePlansInformationEntity>;
  GetSubscription: StripeSubscriptionEntity;
  GetSubscriptionByContext: StripeSubscriptionEntity;
  GetTaxYearInterviewForChannel: Array<TaxYearInterviewEntity>;
  GetTaxYearInterviewIncludeInterviews: TaxYearInterviewEntity;
  GetUpcomingInvoice: StripeInvoiceUpcomingEntity;
  GetUserByInviteLink: UserEntity;
  IsDomainLive: Scalars['Boolean'];
  JobsAndPaymentsByTaxYearStatus: Array<TaxYearInterviewEntity>;
  MyEventTypes: Array<EventTypeEntity>;
  MyEvents: Array<EventEntity>;
  Phones: Array<PhonesEntity>;
  ProgressStatuses: Array<ProgressStatusesEntity>;
  ProgressStatusesEntity: ProgressStatusesEntity;
  RequestedFile: RequestedFilesEntity;
  RequestedFileByTaskId: RequestedFilesEntity;
  ReviewByTaxYearInterviewId: ReviewsEntity;
  Roles: Array<RolesEntity>;
  SearchPreparers: Array<UserFirmAccountEntity>;
  SignedFileByFileId: Scalars['String'];
  SignedFileByS3Key: Scalars['String'];
  UserByEmail: UserEntity;
  UserById: UserEntity;
  UserFirmAccount: UserFirmAccountEntity;
  UserFirmAccountByFirmAccount: Array<UserFirmAccountEntity>;
  UserFirmAccountByFirmAccountAndFilter: Array<UserFirmAccountEntity>;
  UserForInviteLinkWithResetPasswordId: UserEntity;
  UserInformation: UserInformationEntity;
  UserMeeting: MeetingEntity;
  canAddMemberToFirm: CanAddMemberEntity;
  canUpdateFileSystem: CanUpdateFileSystemEntity;
  channel: ChannelEntity;
  channelMessages: Array<MessageEntity>;
  fileApproval: FileApprovalEntity;
  fileRequestsByTaxYearInterviewId: Array<FileRequestEntity>;
  filteredLocalNames: FilteredLocalNamesEntity;
  findAllUsersInFirm: Array<UserEntity>;
  findTask: TaskEntity;
  firmsAverageRating: Scalars['String'];
  generateOTP: GenerateOtpEntity;
  getAllFileRequestsFromInterview: Array<TaskEntity>;
  getAllFilesByClient: Array<FileEntity>;
  getAllPaymentsFromInterview: Array<TaskEntity>;
  getChannelMessages: Array<MessageEntity>;
  getClientFilesFromDb: Array<FileEntity>;
  getFirmFilesFromDb: Array<FileEntity>;
  getFirmNotifications: GroupedFirmNotificationsEntity;
  getFirmSetting: FirmSettingsEntity;
  getFirmSettings: Array<FirmSettingsEntity>;
  getKBAQuestionAnswers: KbaEntity;
  getNylasAccounts: Array<NylasApiAccountEntity>;
  getPlanAccessLevel: StripePlanTypeEntity;
  getSignatureFieldsByFileApprovalId: Array<SignatureFieldEntity>;
  getSignedSignatureBySignatureFieldId: Scalars['String'];
  getStripeCustomer: StripeCustomerEntity;
  getTaxYearInterviewSections: Array<TaxYearInterviewSectionEntity>;
  interview?: Maybe<InterviewEntity>;
  interviewNames: Array<InterviewEntity>;
  interviewNamesByFirmAccountId: Array<InterviewEntity>;
  isActiveFirm: Scalars['Boolean'];
  jobDirNames: JobDirNamesEntity;
  jobsByClientId: Array<TaxYearInterviewEntity>;
  languages: Array<LanguagesEntity>;
  mappedDriveApplicationInfo: MappedDriveApplicationInfoEntity;
  mappedDriveApplicationKeys: MappedDriveApplicationKeysEntity;
  message: MessageEntity;
  passwordResetIncludeFirm: PasswordResetEntity;
  payment: PaymentsEntity;
  paymentsByTaxYearInterviewId: Array<PaymentsEntity>;
  preparerAverageRating: AvgRatingEntity;
  referralsByUserId: Array<UserEntity>;
  section: SectionEntity;
  tasksByTaxYearInterviewId: Array<TaskEntity>;
  taxYear: TaxYearEntity;
  taxYearInterview: TaxYearInterviewEntity;
  taxYearInterviewDrakeFile: DrakeFileEntity;
  taxYearInterviewIncludePreparer: TaxYearInterviewEntity;
  taxYearInterviewsStatus: Array<TaxYearInterviewEntity>;
  translation: TranslationEntity;
  userChannels: Array<UserChannelEntity>;
  userEntity: UserEntityEntity;
  userStep: UserStepEntity;
  userSteps: Array<UserStepEntity>;
  whoAmI: UserFirmAccountEntity;
};


export type QueryAiMessageSuggestionArgs = {
  channelId: Scalars['String'];
};


export type QueryClientDetailsArgs = {
  id: Scalars['String'];
};


export type QueryClientRequestedFilesArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryClientsArgs = {
  orderBy?: UserFirmAccountOrderByInput;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryClientsByTaxYearStatusArgs = {
  orderBy?: TaxYearInterviewOrderByInput;
  progressStatusId: Scalars['String'];
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCustomDomainByNameArgs = {
  name: Scalars['String'];
};


export type QueryDeletedClientsArgs = {
  orderBy?: UserFirmAccountOrderByInput;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryFilesByTaxYearInterviewIdArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryFindUsersFirmAccountByRoleIdArgs = {
  filters: ReadUserFirmAccountInput;
  firmAccountId: Scalars['String'];
  roleId: Scalars['String'];
};


export type QueryFindUsersFirmAccountByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryFirmAccountByIdArgs = {
  id: Scalars['String'];
};


export type QueryFirmAccountForInviteLinkArgs = {
  firmId: Scalars['String'];
  preparerId?: InputMaybe<Scalars['String']>;
};


export type QueryFirmReviewsFilteredArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  preparerId?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetAllEntityDataByEntityIdArgs = {
  id: Scalars['String'];
};


export type QueryGetClientSecretFromPaymentIntentIdArgs = {
  paymentIntentId: Scalars['String'];
};


export type QueryGetConnectAccountIdArgs = {
  firmAccountId: Scalars['String'];
};


export type QueryGetFirmLogoArgs = {
  id: Scalars['String'];
};


export type QueryGetPlanArgs = {
  planId: Scalars['String'];
};


export type QueryGetPlansByProductIdArgs = {
  productId: Scalars['String'];
};


export type QueryGetSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};


export type QueryGetTaxYearInterviewForChannelArgs = {
  channelUserId: Scalars['String'];
};


export type QueryGetTaxYearInterviewIncludeInterviewsArgs = {
  id: Scalars['String'];
};


export type QueryGetUserByInviteLinkArgs = {
  firmAccountId: Scalars['String'];
  uniqueLink: Scalars['String'];
};


export type QueryIsDomainLiveArgs = {
  url: Scalars['String'];
};


export type QueryJobsAndPaymentsByTaxYearStatusArgs = {
  dateFilterKey?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  progressStatusIds: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryMyEventTypesArgs = {
  timezone: Scalars['String'];
};


export type QueryPhonesArgs = {
  userInformationId: Scalars['String'];
};


export type QueryProgressStatusesEntityArgs = {
  id: Scalars['String'];
};


export type QueryRequestedFileArgs = {
  id: Scalars['String'];
};


export type QueryRequestedFileByTaskIdArgs = {
  taskId: Scalars['String'];
};


export type QueryReviewByTaxYearInterviewIdArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QuerySearchPreparersArgs = {
  searchPreparersInput: SearchPreparerInput;
};


export type QuerySignedFileByFileIdArgs = {
  fileId: Scalars['String'];
};


export type QuerySignedFileByS3KeyArgs = {
  download?: InputMaybe<Scalars['Boolean']>;
  s3Key: Scalars['String'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByIdArgs = {
  id: Scalars['String'];
};


export type QueryUserFirmAccountArgs = {
  firmAccountId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryUserFirmAccountByFirmAccountArgs = {
  firmAccountId: Scalars['String'];
};


export type QueryUserFirmAccountByFirmAccountAndFilterArgs = {
  filterDelete: Scalars['Boolean'];
  firmAccountId: Scalars['String'];
};


export type QueryUserForInviteLinkWithResetPasswordIdArgs = {
  passwordResetId: Scalars['String'];
};


export type QueryUserInformationArgs = {
  id: Scalars['String'];
};


export type QueryUserMeetingArgs = {
  id: Scalars['String'];
};


export type QueryCanAddMemberToFirmArgs = {
  firmAccountId: Scalars['String'];
};


export type QueryCanUpdateFileSystemArgs = {
  canUpdateFileSystemInput: CanUpdateFileSystemInput;
};


export type QueryChannelArgs = {
  id: Scalars['String'];
};


export type QueryChannelMessagesArgs = {
  channelId: Scalars['String'];
};


export type QueryFileApprovalArgs = {
  id: Scalars['String'];
};


export type QueryFileRequestsByTaxYearInterviewIdArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryFilteredLocalNamesArgs = {
  filteredLocalNamesInput: FilteredLocalNamesInput;
};


export type QueryFindAllUsersInFirmArgs = {
  firmAccountId: Scalars['String'];
  searchWord?: InputMaybe<Scalars['String']>;
};


export type QueryFindTaskArgs = {
  id: Scalars['String'];
};


export type QueryGetAllFileRequestsFromInterviewArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryGetAllFilesByClientArgs = {
  userId: Scalars['String'];
};


export type QueryGetAllPaymentsFromInterviewArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryGetChannelMessagesArgs = {
  channelId: Scalars['String'];
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetClientFilesFromDbArgs = {
  taxYearInterviewId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetFirmFilesFromDbArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryGetFirmSettingArgs = {
  name: Scalars['String'];
};


export type QueryGetKbaQuestionAnswersArgs = {
  kbaId: Scalars['String'];
};


export type QueryGetSignatureFieldsByFileApprovalIdArgs = {
  fileApprovalId: Scalars['String'];
};


export type QueryGetSignedSignatureBySignatureFieldIdArgs = {
  signatureFieldId: Scalars['String'];
};


export type QueryGetStripeCustomerArgs = {
  getStripeCustomerInput: GetStripeCustomerInput;
};


export type QueryGetTaxYearInterviewSectionsArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryInterviewArgs = {
  id: Scalars['String'];
};


export type QueryInterviewNamesArgs = {
  filterLiveInterviews?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInterviewNamesByFirmAccountIdArgs = {
  filterLiveInterviews?: InputMaybe<Scalars['Boolean']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
};


export type QueryJobDirNamesArgs = {
  jobDirNamesInput: JobDirNamesInput;
};


export type QueryJobsByClientIdArgs = {
  clientId: Scalars['String'];
  firmAccountId: Scalars['String'];
};


export type QueryMappedDriveApplicationKeysArgs = {
  mappedDriveApplicationKeysInput: MappedDriveApplicationKeysInput;
};


export type QueryMessageArgs = {
  id: Scalars['String'];
};


export type QueryPasswordResetIncludeFirmArgs = {
  id: Scalars['String'];
};


export type QueryPaymentArgs = {
  id: Scalars['String'];
};


export type QueryPaymentsByTaxYearInterviewIdArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  taxYearInterviewId: Scalars['String'];
};


export type QueryPreparerAverageRatingArgs = {
  preparerId: Scalars['String'];
};


export type QueryReferralsByUserIdArgs = {
  userId: Scalars['String'];
};


export type QuerySectionArgs = {
  id: Scalars['String'];
};


export type QueryTasksByTaxYearInterviewIdArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryTaxYearArgs = {
  id: Scalars['String'];
};


export type QueryTaxYearInterviewArgs = {
  id: Scalars['String'];
};


export type QueryTaxYearInterviewDrakeFileArgs = {
  taxYearInterviewId: Scalars['String'];
};


export type QueryTaxYearInterviewIncludePreparerArgs = {
  id: Scalars['String'];
};


export type QueryTaxYearInterviewsStatusArgs = {
  progressStatusId: Scalars['String'];
};


export type QueryTranslationArgs = {
  id: Scalars['String'];
};


export type QueryUserChannelsArgs = {
  searchWord?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryUserEntityArgs = {
  entityId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryUserStepArgs = {
  stepId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryUserStepsArgs = {
  userId: Scalars['String'];
};

export type QuestionAnswerInput = {
  answerId: Scalars['String'];
  questionId: Scalars['String'];
};

export type QuestionEntity = {
  __typename?: 'QuestionEntity';
  Answers?: Maybe<Array<AnswerEntity>>;
  Options?: Maybe<Array<OptionsEntity>>;
  Section?: Maybe<SectionEntity>;
  Translations?: Maybe<Array<TranslationEntity>>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  forwardable: Scalars['Boolean'];
  id: Scalars['String'];
  order: Scalars['Float'];
  required: Scalars['Boolean'];
  sectionId: Scalars['String'];
  tag?: Maybe<QuestionTag>;
  type: QuestionType;
  updateAt: Scalars['DateTime'];
};

export enum QuestionTag {
  BankAccountNumber = 'BANK_ACCOUNT_NUMBER',
  BankMethod = 'BANK_METHOD',
  BankName = 'BANK_NAME',
  BankRoutingNumber = 'BANK_ROUTING_NUMBER',
  DependentAnnualIncome = 'DEPENDENT_ANNUAL_INCOME',
  DependentBirthDate = 'DEPENDENT_BIRTH_DATE',
  DependentFirstName = 'DEPENDENT_FIRST_NAME',
  DependentFullTimeStudent = 'DEPENDENT_FULL_TIME_STUDENT',
  DependentLastName = 'DEPENDENT_LAST_NAME',
  DependentMonthsInHome = 'DEPENDENT_MONTHS_IN_HOME',
  DependentRelationship = 'DEPENDENT_RELATIONSHIP',
  DependentSsn = 'DEPENDENT_SSN',
  FilingStatus = 'FILING_STATUS',
  SpouseBirthDate = 'SPOUSE_BIRTH_DATE',
  SpouseFirstName = 'SPOUSE_FIRST_NAME',
  SpouseLastName = 'SPOUSE_LAST_NAME',
  SpouseOccupation = 'SPOUSE_OCCUPATION',
  SpousePhone = 'SPOUSE_PHONE',
  SpouseSsn = 'SPOUSE_SSN',
  TaxpayerAddress_1 = 'TAXPAYER_ADDRESS_1',
  TaxpayerAddress_2 = 'TAXPAYER_ADDRESS_2',
  TaxpayerBirthDate = 'TAXPAYER_BIRTH_DATE',
  TaxpayerCity = 'TAXPAYER_CITY',
  TaxpayerFirstName = 'TAXPAYER_FIRST_NAME',
  TaxpayerLastName = 'TAXPAYER_LAST_NAME',
  TaxpayerOccupation = 'TAXPAYER_OCCUPATION',
  TaxpayerPhone = 'TAXPAYER_PHONE',
  TaxpayerSsn = 'TAXPAYER_SSN',
  TaxpayerState = 'TAXPAYER_STATE',
  TaxpayerZip = 'TAXPAYER_ZIP'
}

export enum QuestionType {
  Date = 'DATE',
  Dropdown = 'DROPDOWN',
  Email = 'EMAIL',
  EncryptedText = 'ENCRYPTED_TEXT',
  Float = 'FLOAT',
  Phone = 'PHONE',
  Radio = 'RADIO',
  Ssn = 'SSN',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA'
}

export type ReadUserFirmAccountInput = {
  searchQuery?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum ReminderType {
  RequestDocuments = 'REQUEST_DOCUMENTS',
  RequestPayment = 'REQUEST_PAYMENT'
}

export type RemindersEntity = {
  __typename?: 'RemindersEntity';
  FileRequest?: Maybe<FileRequestEntity>;
  FirmNotificationReminder?: Maybe<FirmNotificationReminderEntity>;
  User?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  daysLeft?: Maybe<Scalars['Float']>;
  fileRequestId?: Maybe<Scalars['String']>;
  firmNotificationReminderId: Scalars['String'];
  frequency?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  lastNotificationAt: Scalars['DateTime'];
  paymentId?: Maybe<Scalars['String']>;
  requestedFileId?: Maybe<Scalars['String']>;
  type: ReminderType;
  updateAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ReoonResponseEntity = {
  __typename?: 'ReoonResponseEntity';
  domainSuggestion?: Maybe<Scalars['String']>;
  isDisposable: Scalars['Boolean'];
  isFormatValid: Scalars['Boolean'];
  isMxFound: Scalars['Boolean'];
};

export type RequestedFilesEntity = {
  __typename?: 'RequestedFilesEntity';
  Task?: Maybe<TaskEntity>;
  TaxYearInterview: TaxYearInterviewEntity;
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt?: Maybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  taxYearInterviewId: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  uuid: Scalars['String'];
};

export enum ReviewStatus {
  Accepted = 'ACCEPTED',
  In = 'IN',
  Rejected = 'REJECTED'
}

export type ReviewsEntity = {
  __typename?: 'ReviewsEntity';
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  createAt?: Maybe<Scalars['DateTime']>;
  deleteAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  reviewContent?: Maybe<Scalars['String']>;
  reviewValue?: Maybe<Scalars['Float']>;
  taxYearInterviewId?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type RolePermissionEntity = {
  __typename?: 'RolePermissionEntity';
  Permission?: Maybe<PermissionEntity>;
  Roles?: Maybe<Array<RolesEntity>>;
  createAt: Scalars['DateTime'];
  permissionId: Scalars['String'];
  roleId: Scalars['String'];
};

export type RolesEntity = {
  __typename?: 'RolesEntity';
  AdministrativeRoles?: Maybe<Array<AdministrativeRolesEntity>>;
  RolePermissions?: Maybe<RolePermissionEntity>;
  UserFirmAccounts?: Maybe<Array<UserFirmAccountEntity>>;
  createAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type SaveAnswerInput = {
  /** List answers */
  answers: Array<CreateAnswerInput>;
  /** Section to edit */
  sectionId: Scalars['String'];
  /** Current Tax Year Interview */
  taxYearInterviewId: Scalars['String'];
};

export type SearchClientInput = {
  searchWord: Scalars['String'];
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};

export type SearchPreparerInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type SectionEntity = {
  __typename?: 'SectionEntity';
  Conditionals?: Maybe<Array<ConditionalEntity>>;
  Interview?: Maybe<InterviewEntity>;
  Questions?: Maybe<Array<QuestionEntity>>;
  SubSection?: Maybe<SectionEntity>;
  SubSections?: Maybe<Array<SectionEntity>>;
  Translations?: Maybe<Array<TranslationEntity>>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  interviewId: Scalars['String'];
  isSpecial: Scalars['Boolean'];
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  specialType?: Maybe<SpecialSectionsType>;
  updateAt: Scalars['DateTime'];
};

export type SendWelcomeEmailInput = {
  body?: InputMaybe<Scalars['String']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type SessionEntity = {
  __typename?: 'SessionEntity';
  Entity?: Maybe<Entity>;
  RelatedFirms?: Maybe<Array<UserFirmAccountEntity>>;
  UserFirmAccount?: Maybe<UserFirmAccountEntity>;
  accessToken?: Maybe<Scalars['String']>;
  headToURL?: Maybe<Scalars['String']>;
  selectedFirmAccount?: Maybe<Scalars['String']>;
};

export enum SettingsType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING'
}

export type SettingsValue = BooleanValue | DateValue | NumberValue | StringValue;

export type SignatureEntity = {
  __typename?: 'SignatureEntity';
  SignatureFields?: Maybe<Array<SignatureFieldEntity>>;
  createAt: Scalars['DateTime'];
  fileId: Scalars['String'];
  fileRequestId: Scalars['String'];
  id: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type SignatureFieldEntity = {
  __typename?: 'SignatureFieldEntity';
  Signature?: Maybe<SignatureEntity>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  fieldHeightPercentage?: Maybe<Scalars['Float']>;
  fieldId?: Maybe<Scalars['String']>;
  fieldLeftPercentage?: Maybe<Scalars['Float']>;
  fieldTopPercentage?: Maybe<Scalars['Float']>;
  fieldType: FieldType;
  fieldWidthPercentage?: Maybe<Scalars['Float']>;
  fileApprovalId: Scalars['String'];
  id: Scalars['String'];
  page?: Maybe<Scalars['Float']>;
  signatureId?: Maybe<Scalars['String']>;
  signatureImage?: Maybe<Scalars['String']>;
  signerParty?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type SignatureFieldInput = {
  fieldHeightPercentage: Scalars['Float'];
  fieldLeftPercentage: Scalars['Float'];
  fieldTopPercentage: Scalars['Float'];
  fieldType: FieldType;
  fieldWidthPercentage: Scalars['Float'];
  id: Scalars['String'];
  page: Scalars['Float'];
  title?: InputMaybe<Scalars['String']>;
};

export type SignatureFieldsInput = {
  create: Array<SignatureFieldInput>;
  delete: Array<SignatureFieldInput>;
  update: Array<SignatureFieldInput>;
};

export type SignerKbaInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  lastFourSSN?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  signatureKBAType: KbaType;
  signerEmail: Scalars['String'];
  signerId: Scalars['String'];
  signerName: Scalars['String'];
};

export enum SpecialSectionsType {
  BasicPersonalInformation = 'BASIC_PERSONAL_INFORMATION',
  DependentInformation = 'DEPENDENT_INFORMATION',
  DirectDepositInformation = 'DIRECT_DEPOSIT_INFORMATION'
}

export type StepsEntity = {
  __typename?: 'StepsEntity';
  Buttons?: Maybe<Array<ButtonsEntity>>;
  createAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  tooltip?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
};

export type StringValue = {
  __typename?: 'StringValue';
  val: Scalars['String'];
};

export type StripeAddressEntity = {
  __typename?: 'StripeAddressEntity';
  /** City/District/Suburb/Town/Village. */
  city?: Maybe<Scalars['String']>;
  /** 2-letter country code. */
  country?: Maybe<Scalars['String']>;
  /** Address line 1 (Street address/PO Box/Company name). */
  line1?: Maybe<Scalars['String']>;
  /** Address line 2 (Apartment/Suite/Unit/Building). */
  line2?: Maybe<Scalars['String']>;
  /** ZIP or postal code. */
  postal_code?: Maybe<Scalars['String']>;
  /** State/County/Province/Region. */
  state?: Maybe<Scalars['String']>;
};

export type StripeBillingThresholdsEntity = {
  __typename?: 'StripeBillingThresholdsEntity';
  usage_gte?: Maybe<Scalars['Float']>;
};

export type StripeCardPaymentMethodEntity = {
  __typename?: 'StripeCardPaymentMethodEntity';
  /** The card's CVC. It is highly recommended to always include this value. */
  cvc: Scalars['String'];
  /** Two-digit number representing the card's expiration month. */
  exp_month: Scalars['Float'];
  /** Four-digit number representing the card's expiration year. */
  exp_year: Scalars['Float'];
  /** ID of the payment method */
  id?: Maybe<Scalars['String']>;
  /** The card's last 4 digits. */
  last4?: Maybe<Scalars['String']>;
  /** The card number, as a string without any separators. */
  number: Scalars['String'];
};

export type StripeCustomerEntity = {
  __typename?: 'StripeCustomerEntity';
  /** The customer's address. */
  address?: Maybe<StripeAddressEntity>;
  /** Current balance, if any, being stored on the customer. If negative, the customer has credit to apply to their next invoice. If positive, the customer has an amount owed that will be added to their next invoice. The balance does not refer to any unpaid invoices; it solely takes into account amounts that have yet to be successfully applied to any invoice. This balance is only taken into account as invoices are finalized. */
  balance: Scalars['Float'];
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Float'];
  /** Three-letter [ISO code for the currency](https://stripe.com/docs/currencies) the customer can be charged in for recurring billing purposes. */
  currency?: Maybe<Scalars['String']>;
  /** ID of the default payment source for the customer. If you are using payment methods created via the PaymentMethods API, see the [invoice_settings.default_payment_method](https://stripe.com/docs/api/customers/object#customer_object-invoice_settings-default_payment_method) field instead. */
  default_source?: Maybe<Scalars['String']>;
  /** When the customer's latest invoice is billed by charging automatically, 'delinquent' is 'true' if the invoice's latest charge failed. When the customer's latest invoice is billed by sending an invoice, 'delinquent' is 'true' if the invoice isn't paid by its due date. */
  delinquent?: Maybe<Scalars['Boolean']>;
  /** An arbitrary string attached to the object. Often useful for displaying to users. */
  description?: Maybe<Scalars['String']>;
  /** The customer's email address. */
  email?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** The prefix for the customer used to generate unique invoice numbers. */
  invoice_prefix?: Maybe<Scalars['String']>;
  invoice_settings?: Maybe<StripeInvoiceSettingsEntity>;
  /** Has the value 'true' if the object exists in live mode or the value 'false' if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. */
  metadata?: Maybe<Scalars['String']>;
  /** The customer's address. */
  name?: Maybe<Scalars['String']>;
  /** The suffix of the customer's next invoice number, e.g., 0001. */
  next_invoice_sequence?: Maybe<Scalars['Float']>;
  /** The customer's phone number. */
  phone?: Maybe<Scalars['String']>;
  /** The customer's preferred locales (languages), ordered by preference. */
  preferred_locales?: Maybe<Array<Scalars['String']>>;
};

export type StripeInformationEntity = {
  __typename?: 'StripeInformationEntity';
  FirmAccount?: Maybe<FirmAccountEntity>;
  connectAccountId?: Maybe<Scalars['String']>;
  createAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  finishedOnboarding: Scalars['Boolean'];
  firmAccountId: Scalars['String'];
  id: Scalars['String'];
  plan?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type StripeInvoiceEntity = {
  __typename?: 'StripeInvoiceEntity';
  amountDue: Scalars['Float'];
  created: Scalars['DateTime'];
  id: Scalars['String'];
  number: Scalars['String'];
  paid: Scalars['Float'];
  pdf?: Maybe<Scalars['String']>;
};

export type StripeInvoiceSettingsCustomFieldEntity = {
  __typename?: 'StripeInvoiceSettingsCustomFieldEntity';
  /** The name of the custom field. */
  name?: Maybe<Scalars['String']>;
  /** The value of the custom field. */
  value?: Maybe<Scalars['String']>;
};

export type StripeInvoiceSettingsEntity = {
  __typename?: 'StripeInvoiceSettingsEntity';
  /** Default custom fields to be displayed on invoices for this customer. */
  custom_fields?: Maybe<StripeInvoiceSettingsCustomFieldEntity>;
  /** ID of a payment method that's attached to the customer, to be used as the customer's default payment method for subscriptions and invoices. */
  default_payment_method?: Maybe<Scalars['String']>;
  /** Default footer to be displayed on invoices for this customer. */
  footer?: Maybe<Scalars['String']>;
};

export type StripeInvoiceUpcomingEntity = {
  __typename?: 'StripeInvoiceUpcomingEntity';
  amountDue: Scalars['Float'];
  created: Scalars['DateTime'];
  discount: Scalars['String'];
  endingBalance: Scalars['Float'];
  nextPayment: Scalars['DateTime'];
  number: Scalars['String'];
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  startingBalance: Scalars['Float'];
};

export type StripeMetadataEntity = {
  __typename?: 'StripeMetadataEntity';
  alt?: Maybe<Scalars['String']>;
  custom_plan?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_post_trial?: Maybe<Scalars['String']>;
  has_roo_access?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  taxaroo_plus?: Maybe<Scalars['String']>;
};

export type StripePaymentIntentWithRefundsEntity = {
  __typename?: 'StripePaymentIntentWithRefundsEntity';
  /** Amount intended to be collected by this PaymentIntent. A positive integer representing how much to charge in the smallest currency unit. */
  amount?: Maybe<Scalars['Float']>;
  /** Amount that can be captured from this PaymentIntent. */
  amount_capturable?: Maybe<Scalars['Float']>;
  /** Amount that was collected by this PaymentIntent. */
  amount_received?: Maybe<Scalars['Float']>;
  /** The amount of the application fee (if any) that will be requested to be applied to the payment and transferred to the application owner's Stripe account. */
  application_fee_amount?: Maybe<Scalars['Float']>;
  /** Populated when `status` is `canceled`, this is the time at which the PaymentIntent was canceled. Measured in seconds since the Unix epoch. */
  canceled_at?: Maybe<Scalars['Float']>;
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  client_secret?: Maybe<Scalars['String']>;
  /** Controls the settings for the payment confirmation. */
  confirmation_method?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created?: Maybe<Scalars['Float']>;
  /** Three-letter ISO currency code, in lowercase. Must be a supported currency. */
  currency?: Maybe<Scalars['String']>;
  /** An arbitrary string attached to the object. Often useful for displaying to users. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id?: Maybe<Scalars['String']>;
  /** Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object?: Maybe<Scalars['String']>;
  /** The list of payment method types (e.g. card) that this PaymentIntent is allowed to use. */
  payment_method_types?: Maybe<Array<Scalars['String']>>;
  /** Email address that the receipt for the resulting payment will be sent to. */
  receipt_email?: Maybe<Scalars['String']>;
  /** Refund data for each payment intent. */
  refunds?: Maybe<Array<StripeRefundEntity>>;
  /** For non-card charges, you can use this value as the complete description that appears on your customers' statements. Must contain at least one letter, maximum 22 characters. */
  statement_descriptor?: Maybe<Scalars['String']>;
  /** Provides information about a card payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that's set on the account to form the complete statement descriptor. Maximum 22 characters for the concatenated descriptor. */
  statement_descriptor_suffix?: Maybe<Scalars['String']>;
  /** A string that identifies the resulting payment as part of a group. */
  transfer_group?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodAccsDebitEntity = {
  __typename?: 'StripePaymentMethodAccsDebitEntity';
  bank_name?: Maybe<Scalars['String']>;
  fingerprint: Scalars['String'];
  institution_number?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  transit_number?: Maybe<Scalars['String']>;
};

export type StripePaymentMethodEntity = {
  __typename?: 'StripePaymentMethodEntity';
  acss_debit?: Maybe<StripePaymentMethodAccsDebitEntity>;
  card?: Maybe<StripePaymentMethodEntity>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Float'];
  /** The ID of the Customer to which this PaymentMethod is saved. This will not be set when the PaymentMethod has not been saved to a Customer. */
  customer?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id: Scalars['String'];
  /** Has the value 'true' if the object exists in live mode or the value 'false' if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
};

export enum StripePlanType {
  Free = 'FREE',
  Lite = 'LITE',
  Pro = 'PRO',
  Trial = 'TRIAL'
}

export type StripePlanTypeEntity = {
  __typename?: 'StripePlanTypeEntity';
  planType?: Maybe<StripePlanType>;
};

export type StripePlansInformationEntity = {
  __typename?: 'StripePlansInformationEntity';
  /** Whether the plan can be used for new purchases. */
  active?: Maybe<Scalars['Boolean']>;
  /** The unit amount in %s to be charged, represented as a whole integer if possible. Only set if 'billing_scheme=per_unit'. */
  amount?: Maybe<Scalars['Float']>;
  /** The unit amount in %s to be charged, represented as a decimal string with at most 12 decimal places. Only set if 'billing_scheme=per_unit'. */
  amount_decimal?: Maybe<Scalars['String']>;
  /** Describes how to compute the price per period. Either 'per_unit' or 'tiered'. 'per_unit' indicates that the fixed amount (specified in 'amount') will be charged per unit in 'quantity' (for plans with 'usage_type=licensed'), or per unit of total usage (for plans with 'usage_type=metered'). 'tiered' indicates that the unit pricing will be computed using a tiering strategy as defined using the 'tiers' and 'tiers_mode' attributes. */
  billing_scheme?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created?: Maybe<Scalars['Float']>;
  /** Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies). */
  currency?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id?: Maybe<Scalars['String']>;
  /** The frequency at which a subscription is billed. One of 'day', 'week', 'month' or 'year'. */
  interval?: Maybe<Scalars['String']>;
  /** The number of intervals (specified in the 'interval' attribute) between subscription billings. For example, 'interval=month' and 'interval_count=3' bills every 3 months. */
  interval_count?: Maybe<Scalars['Float']>;
  /** Has the value 'true' if the object exists in live mode or the value 'false' if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** The subscription id. */
  metadata?: Maybe<StripeMetadataEntity>;
  /** A brief description of the plan, hidden from customers. */
  nickname?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object?: Maybe<Scalars['String']>;
  /** The product whose pricing this plan determines. */
  product?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  tiers?: Maybe<Array<StripeTiersEntity>>;
  /** Defines if the tiering price should be 'graduated' or 'volume' based. In 'volume'-based tiering, the maximum quantity within a period determines the per unit price. In 'graduated' tiering, pricing can change as the quantity grows. */
  tiers_mode?: Maybe<Scalars['String']>;
  /** Default number of trial days when subscribing a customer to this plan using ['trial_from_plan=true'](https://stripe.com/docs/api#create_subscription-trial_from_plan). */
  trial_period_days?: Maybe<Scalars['Float']>;
};

export type StripePriceEntity = {
  __typename?: 'StripePriceEntity';
  active?: Maybe<Scalars['Boolean']>;
  billing_scheme?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  lookup_key?: Maybe<Scalars['String']>;
  metadata?: Maybe<StripeMetadataEntity>;
  nickname?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  product?: Maybe<StripeProductEntity>;
  tax_behavior?: Maybe<Scalars['String']>;
  tiers?: Maybe<Array<StripeTiersEntity>>;
  tiers_mode?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Float']>;
  unit_amount_decimal?: Maybe<Scalars['String']>;
};

export type StripeProductEntity = {
  __typename?: 'StripeProductEntity';
  /** Whether the product is currently available for purchase. */
  active?: Maybe<Scalars['Boolean']>;
  /** A list of up to 5 attributes that each SKU can provide values for (e.g., '["color", "size"]'). */
  attributes?: Maybe<Array<Scalars['String']>>;
  /** A short one-line description of the product, meant to be displayable to the customer. Only applicable to products of 'type=good'. */
  caption?: Maybe<Scalars['String']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created?: Maybe<Scalars['Float']>;
  /** The product's description, meant to be displayable to the customer. Use this field to optionally store a long form explanation of the product being sold for your own rendering purposes. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id?: Maybe<Scalars['String']>;
  /** A list of up to 8 URLs of images for this product, meant to be displayable to the customer. */
  images?: Maybe<Array<Scalars['String']>>;
  /** Has the value 'true' if the object exists in live mode or the value 'false' if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** Unique identifier for the object. */
  name: Scalars['String'];
  /** String representing the object's type. Objects of the same type share the same value. */
  object?: Maybe<Scalars['String']>;
  /** Time at which the object was last updated. Measured in seconds since the Unix epoch. */
  updated: Scalars['Float'];
  /** A URL of a publicly-accessible webpage for this product. */
  url: Scalars['String'];
};

export type StripeRefundEntity = {
  __typename?: 'StripeRefundEntity';
  /** Amount, in %s. */
  amount: Scalars['Float'];
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created: Scalars['Float'];
  /** Three-letter ISO currency code, in lowercase. Must be a supported currency. */
  currency: Scalars['String'];
  /** An arbitrary string attached to the object. Often useful for displaying to users. (Available on non-card refunds only) */
  description?: Maybe<Scalars['String']>;
  /** If the refund failed, the reason for refund failure if known. Possible values are `lost_or_stolen_card`, `expired_or_canceled_card`, or `unknown`. */
  failure_reason?: Maybe<Scalars['String']>;
  /** Unique identifier for the object. */
  id?: Maybe<Scalars['String']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object: Scalars['String'];
  /** This is the transaction number that appears on email receipts sent for this refund. */
  receipt_number?: Maybe<Scalars['String']>;
  /** Status of the refund. For credit card refunds, this can be `pending`, `succeeded`, or `failed`. For other types of refunds, it can be `pending`, `succeeded`, `failed`, or `canceled`. Refer to our refunds documentation for more details. */
  status?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionEntity = {
  __typename?: 'StripeSubscriptionEntity';
  /** A non-negative decimal between 0 and 100, with at most two decimal places. This represents the percentage of the subscription invoice subtotal that will be transferred to the application owner's Stripe account. */
  application_fee_percent?: Maybe<Scalars['Float']>;
  /** Determines the date of the first full invoice, and, for plans with 'month' or 'year' intervals, the day of the month for subsequent invoices. */
  billing_cycle_anchor?: Maybe<Scalars['Float']>;
  /** A date in the future at which the subscription will automatically get canceled */
  cancel_at?: Maybe<Scalars['Float']>;
  /** If the subscription has been canceled with the 'at_period_end' flag set to 'true', 'cancel_at_period_end' on the subscription will be true. You can use this attribute to determine whether a subscription that has a status of active is scheduled to be canceled at the end of the current period. */
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  /** If the subscription has been canceled, the date of that cancellation. If the subscription was canceled with 'cancel_at_period_end', 'canceled_at' will reflect the time of the most recent update request, not the end of the subscription period when the subscription is automatically moved to a canceled state. */
  canceled_at?: Maybe<Scalars['Float']>;
  /** Time at which the object was created. Measured in seconds since the Unix epoch. */
  created?: Maybe<Scalars['Float']>;
  /** End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created. */
  current_period_end?: Maybe<Scalars['Float']>;
  /** Start of the current period that the subscription has been invoiced for. */
  current_period_start?: Maybe<Scalars['Float']>;
  /** ID of the customer who owns the subscription. */
  customer?: Maybe<Scalars['String']>;
  /** Number of days a customer has to pay invoices generated by this subscription. This value will be 'null' for subscriptions where 'collection_method=charge_automatically'. */
  days_until_due?: Maybe<Scalars['Float']>;
  /** ID of the default payment method for the subscription. It must belong to the customer associated with the subscription. This takes precedence over 'default_source'. If neither are set, invoices will use the customer's [invoice_settings.default_payment_method](https://stripe.com/docs/api/customers/object#customer_object-invoice_settings-default_payment_method) or [default_source](https://stripe.com/docs/api/customers/object#customer_object-default_source). */
  default_payment_method?: Maybe<Scalars['String']>;
  /** ID of the default payment source for the subscription. It must belong to the customer associated with the subscription and be in a chargeable state. If 'default_payment_method' is also set, 'default_payment_method' will take precedence. If neither are set, invoices will use the customer's [invoice_settings.default_payment_method](https://stripe.com/docs/api/customers/object#customer_object-invoice_settings-default_payment_method) or [default_source](https://stripe.com/docs/api/customers/object#customer_object-default_source). */
  default_source?: Maybe<Scalars['String']>;
  /** If the subscription has ended, the date the subscription ended. */
  ended_at?: Maybe<Scalars['Float']>;
  /** The subscription id. */
  id?: Maybe<Scalars['String']>;
  /** The subscription id. */
  items: StripeSubscriptionItemsEntity;
  /** Has the value 'true' if the object exists in live mode or the value 'false' if the object exists in test mode. */
  livemode?: Maybe<Scalars['Boolean']>;
  /** The subscription id. */
  metadata?: Maybe<StripeMetadataEntity>;
  /** Specifies the approximate timestamp on which any pending invoice items will be billed according to the schedule provided at 'pending_invoice_item_interval'. */
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']>;
  /** String representing the object's type. Objects of the same type share the same value. */
  object?: Maybe<Scalars['String']>;
  /** If specified, payment collection for this subscription will be paused */
  pause_collection?: Maybe<StripeSubscriptionPauseCollection>;
  /** You can use this [SetupIntent](https://stripe.com/docs/api/setup_intents) to collect user authentication when creating a subscription without immediate payment or updating a subscription's payment method, allowing you to optimize for off-session payments. Learn more in the [SCA Migration Guide](https://stripe.com/docs/billing/migration/strong-customer-authentication#scenario-2). */
  pending_setup_intent?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  /** The subscription id. */
  schedule?: Maybe<Scalars['String']>;
  /** The subscription id. */
  start_date?: Maybe<Scalars['Float']>;
  /** The subscription id. */
  status?: Maybe<Scalars['String']>;
  /** If the subscription has a trial, the end of that trial. */
  trial_end?: Maybe<Scalars['Float']>;
  /** If the subscription has a trial, the beginning of that trial. */
  trial_start?: Maybe<Scalars['Float']>;
};

export type StripeSubscriptionItemEntity = {
  __typename?: 'StripeSubscriptionItemEntity';
  billing_thresholds?: Maybe<StripeBillingThresholdsEntity>;
  created?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  plan?: Maybe<StripePlansInformationEntity>;
  quantity?: Maybe<Scalars['Float']>;
  subscription?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionItemsEntity = {
  __typename?: 'StripeSubscriptionItemsEntity';
  data?: Maybe<Array<StripeSubscriptionItemEntity>>;
  has_more?: Maybe<Scalars['Boolean']>;
  object?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionPauseCollection = {
  __typename?: 'StripeSubscriptionPauseCollection';
  behavior: Scalars['String'];
  resumes_at?: Maybe<Scalars['Float']>;
};

export type StripeTiersEntity = {
  __typename?: 'StripeTiersEntity';
  flat_amount?: Maybe<Scalars['Float']>;
  flat_amount_decimal?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Float']>;
  unit_amount_decimal?: Maybe<Scalars['String']>;
  up_to?: Maybe<Scalars['Float']>;
};

/** New progressStatus data */
export type SwitchOrderOfProgressStatusesInput = {
  idOne: Scalars['String'];
  idTwo: Scalars['String'];
  indexOne: Scalars['Float'];
  indexTwo: Scalars['Float'];
};

export type SyncContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export enum TargetOsPlatform {
  Darwin_386 = 'darwin_386',
  DarwinAmd64 = 'darwin_amd64',
  DarwinArm = 'darwin_arm',
  DarwinArm64 = 'darwin_arm64',
  DragonflyAmd64 = 'dragonfly_amd64',
  Freebsd_386 = 'freebsd_386',
  FreebsdAmd64 = 'freebsd_amd64',
  FreebsdArm = 'freebsd_arm',
  Independent = 'independent',
  Linux_386 = 'linux_386',
  LinuxAmd64 = 'linux_amd64',
  LinuxArm = 'linux_arm',
  LinuxArm64 = 'linux_arm64',
  LinuxMips = 'linux_mips',
  LinuxMips64 = 'linux_mips64',
  LinuxMips64le = 'linux_mips64le',
  LinuxMipsle = 'linux_mipsle',
  LinuxPpc64 = 'linux_ppc64',
  LinuxPpc64le = 'linux_ppc64le',
  Netbsd_386 = 'netbsd_386',
  NetbsdAmd64 = 'netbsd_amd64',
  NetbsdArm = 'netbsd_arm',
  Openbsd_386 = 'openbsd_386',
  OpenbsdAmd64 = 'openbsd_amd64',
  OpenbsdArm = 'openbsd_arm',
  Plan9_386 = 'plan9_386',
  Plan9Amd64 = 'plan9_amd64',
  SolarisAmd64 = 'solaris_amd64',
  Windows_386 = 'windows_386',
  WindowsAmd64 = 'windows_amd64'
}

export type TaskEntity = {
  __typename?: 'TaskEntity';
  FileApproval?: Maybe<FileApprovalEntity>;
  FileRequests?: Maybe<Array<FileRequestEntity>>;
  Payments?: Maybe<Array<PaymentsEntity>>;
  RequestedFiles?: Maybe<RequestedFilesEntity>;
  TaxYear?: Maybe<TaxYearEntity>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  taskType?: Maybe<TaskType>;
  taxYearId?: Maybe<Scalars['String']>;
  taxYearInterviewId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export enum TaskType {
  ApproveDocuments = 'APPROVE_DOCUMENTS',
  Documents = 'DOCUMENTS',
  Interview = 'INTERVIEW',
  Payments = 'PAYMENTS',
  RequestDocuments = 'REQUEST_DOCUMENTS',
  Signatures = 'SIGNATURES'
}

export type TaxPreparersEntity = {
  __typename?: 'TaxPreparersEntity';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TaxYearEntity = {
  __typename?: 'TaxYearEntity';
  Entity?: Maybe<Entity>;
  FileRequests?: Maybe<Array<FileRequestEntity>>;
  Payments?: Maybe<Array<PaymentsEntity>>;
  TaxYearInterview?: Maybe<Array<TaxYearInterviewEntity>>;
  createAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  id: Scalars['String'];
  year: Scalars['Float'];
};

export type TaxYearInterviewEntity = {
  __typename?: 'TaxYearInterviewEntity';
  Answers?: Maybe<Array<AnswerEntity>>;
  FileRequests?: Maybe<Array<FileRequestEntity>>;
  Interviews?: Maybe<InterviewEntity>;
  JobTracking?: Maybe<JobTrackingEntity>;
  LegacyTaxYearInterviewAnswers?: Maybe<Array<LegacyTaxYearInterviewAnswersEntity>>;
  Payments?: Maybe<Array<PaymentsEntity>>;
  PreparedUser?: Maybe<PreparedUserEntity>;
  ProgressStatuses?: Maybe<ProgressStatusesEntity>;
  ReviewedUser?: Maybe<PreparedUserEntity>;
  Reviews?: Maybe<Array<ReviewsEntity>>;
  Tasks?: Maybe<Array<TaskEntity>>;
  TaxYear?: Maybe<TaxYearEntity>;
  TaxYearInterviewSections?: Maybe<Array<TaxYearInterviewSectionEntity>>;
  assignedAt: Scalars['DateTime'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  interviewId: Scalars['String'];
  jobTrackingId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  preparedId?: Maybe<Scalars['String']>;
  preparerAcceptedAt?: Maybe<Scalars['DateTime']>;
  progressStatusId?: Maybe<Scalars['String']>;
  reviewStatus?: Maybe<ReviewStatus>;
  reviewedId?: Maybe<Scalars['String']>;
  taxYearId: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type TaxYearInterviewOrderByInput = {
  Interviews?: InputMaybe<InterviewsOrderByInput>;
  JobTracking?: InputMaybe<JobTrackingOrderByInput>;
  PreparedUser?: InputMaybe<UserOrderByInput>;
  ReviewedUser?: InputMaybe<UserOrderByInput>;
  TaxYear?: InputMaybe<TaxYearOrderByInput>;
  assignedAt?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['String']>;
  createAt?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  reviewStatus?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type TaxYearInterviewPagination = {
  __typename?: 'TaxYearInterviewPagination';
  total: Scalars['Int'];
};

export type TaxYearInterviewSectionEntity = {
  __typename?: 'TaxYearInterviewSectionEntity';
  Section?: Maybe<SectionEntity>;
  TaxYearInterview?: Maybe<TaxYearInterviewEntity>;
  createAt: Scalars['DateTime'];
  id: Scalars['String'];
  markedAsComplete: Scalars['Boolean'];
  prePopulatedConfirmed: Scalars['Boolean'];
  sectionId: Scalars['String'];
  taxYearInterviewId: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type TaxYearOrderByInput = {
  Entity?: InputMaybe<EntityOrderByInput>;
  createAt?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export enum TaxYearStatus {
  All = 'ALL',
  Completed = 'COMPLETED',
  Custom = 'CUSTOM',
  InProgress = 'IN_PROGRESS',
  Unassigned = 'UNASSIGNED'
}

export type TranslationEntity = {
  __typename?: 'TranslationEntity';
  Language?: Maybe<LanguagesEntity>;
  Option?: Maybe<OptionsEntity>;
  Question?: Maybe<QuestionEntity>;
  Section?: Maybe<SectionEntity>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  languageId: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type UnregisteredUser = {
  __typename?: 'UnregisteredUser';
  MeetingAttendees?: Maybe<Array<MeetingAttendeesEntity>>;
  createAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  updateAt: Scalars['DateTime'];
};

export type UpdateAddressInput = {
  businessInformationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressType>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateAnswerInput = {
  description: Scalars['String'];
  /** Id of the answer that will be updated */
  id: Scalars['String'];
};

export type UpdateApprovalSignatureRequestInput = {
  fileApprovals: Array<FileApprovalUpdateInput>;
  id: Scalars['String'];
  isCompiledFileUpdated: Scalars['Boolean'];
  notes?: InputMaybe<Scalars['String']>;
  taxYearInterviewId: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateBusinessInformationInput = {
  Address?: InputMaybe<Array<UpdateAddressInput>>;
  Phones?: InputMaybe<Array<UpsertPhoneInput>>;
  accountingPercent?: InputMaybe<Scalars['Float']>;
  bookKeepingPercent?: InputMaybe<Scalars['Float']>;
  businessName?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<BusinessType>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  other?: InputMaybe<Scalars['Float']>;
  payrollPercent?: InputMaybe<Scalars['Float']>;
  taxPercent?: InputMaybe<Scalars['Float']>;
  taxResolutionPercent?: InputMaybe<Scalars['Float']>;
  taxReturns?: InputMaybe<Scalars['Float']>;
  yearsInBusiness?: InputMaybe<Scalars['Float']>;
};

export type UpdateClientFromInviteLinkInput = {
  UserInformation?: InputMaybe<CreateUserInformationInput>;
  codeOTP?: InputMaybe<Scalars['String']>;
  enableOTP?: InputMaybe<Scalars['Boolean']>;
  languageId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetId: Scalars['String'];
  secretOTP?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UpdateClientInput = {
  email: Scalars['String'];
  languageId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  userInformation: UpdateUserInformationInput;
};

export type UpdateConditionalInput = {
  id: Scalars['String'];
  optionId: Scalars['String'];
};

export type UpdateCustomLayoutInput = {
  headerBackgroundColor?: InputMaybe<Scalars['String']>;
  headerFontColor?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  pixelCode?: InputMaybe<Scalars['String']>;
};

export type UpdateEventInput = {
  clientId: Scalars['String'];
  endDate: Scalars['DateTime'];
  eventTypeId: Scalars['String'];
  id: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateEventTypeInput = {
  EventTypeAvailability?: InputMaybe<Array<CreateEventTypeAvailabilityInput>>;
  active?: InputMaybe<Scalars['Boolean']>;
  afterBuffer?: InputMaybe<Scalars['Float']>;
  beforeBuffer?: InputMaybe<Scalars['Float']>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maxEvents?: InputMaybe<Scalars['Float']>;
  minNotice?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateFileInput = {
  documentReviewed?: InputMaybe<Scalars['Boolean']>;
  documentUsed?: InputMaybe<Scalars['Boolean']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  pages?: InputMaybe<Scalars['Float']>;
  s3Key?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  taxYearInterviewId?: InputMaybe<Scalars['String']>;
  uploadedBy?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateFileSystemEntity = {
  __typename?: 'UpdateFileSystemEntity';
  success: Scalars['Boolean'];
};

export type UpdateFileSystemInput = {
  newPath?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
  type: UpdateFileSystemType;
};

export enum UpdateFileSystemType {
  CreateDir = 'CREATE_DIR',
  CreateFile = 'CREATE_FILE',
  RemoveDir = 'REMOVE_DIR',
  RemoveFile = 'REMOVE_FILE',
  Rename = 'RENAME'
}

export type UpdateFirmAccountInput = {
  businessType?: InputMaybe<BusinessType>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FirmAccountType>;
  uniqueLink?: InputMaybe<Scalars['String']>;
};

export type UpdateFirmNotificationInput = {
  active: Scalars['Boolean'];
  firmNotificationReminderId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  notificationId: Scalars['String'];
};

export type UpdateFirmNotificationReminderInput = {
  firmNotificationId?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maxReminders?: InputMaybe<Scalars['Float']>;
};

export type UpdateFirmNotificationsStatusInput = {
  firmNotifications: Array<UpdateFirmNotificationInput>;
};

export type UpdateInterviewInput = {
  Sections?: InputMaybe<Array<UpdateInterviewSectionInput>>;
  /** Id of the record that will be updated */
  id: Scalars['String'];
  isDeleting?: InputMaybe<Scalars['Boolean']>;
  /** Boolean to check if interview is live */
  isLive?: InputMaybe<Scalars['Boolean']>;
  /** Boolean template value */
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Name of the interview  */
  name: Scalars['String'];
  operation?: InputMaybe<Scalars['String']>;
  temporalId?: InputMaybe<Scalars['String']>;
};

export type UpdateInterviewOptionsInput = {
  Translations?: InputMaybe<Array<UpdateInterviewTranslationInput>>;
  id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
};

export type UpdateInterviewQuestionInput = {
  Options?: InputMaybe<Array<UpdateInterviewOptionsInput>>;
  Translations?: InputMaybe<Array<UpdateInterviewTranslationInput>>;
  id?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  required?: InputMaybe<Scalars['Boolean']>;
  sectionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionType>;
};

export type UpdateInterviewSectionInput = {
  Questions?: InputMaybe<Array<UpdateInterviewQuestionInput>>;
  SubSections?: InputMaybe<Array<UpdateInterviewSectionInput>>;
  Translations?: InputMaybe<Array<UpdateInterviewTranslationInput>>;
  id?: InputMaybe<Scalars['String']>;
  interviewId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  parentId?: InputMaybe<Scalars['String']>;
};

export type UpdateInterviewTranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  languageId?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  sectionId?: InputMaybe<Scalars['String']>;
};

export type UpdateMessageInput = {
  attachment?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  readDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentInvoice = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  priceEach?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type UpdatePaymentWithInvoicesInput = {
  invoices2Create?: InputMaybe<Array<CreatePaymentInvoice>>;
  invoices2Delete?: InputMaybe<Array<UpdatePaymentInvoice>>;
  invoices2Update?: InputMaybe<Array<UpdatePaymentInvoice>>;
  paymentId: Scalars['String'];
};

export type UpdatePaymentsInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  discountApplied?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  paidOffline?: InputMaybe<Scalars['Boolean']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentProcessorFee?: InputMaybe<Scalars['Float']>;
  paymentProcessorId?: InputMaybe<Scalars['String']>;
  preparerFee?: InputMaybe<Scalars['Float']>;
  promisedAt?: InputMaybe<Scalars['DateTime']>;
  referralFee?: InputMaybe<Scalars['Float']>;
  requestAt?: InputMaybe<Scalars['DateTime']>;
  taskId?: InputMaybe<Scalars['String']>;
  taxYearId?: InputMaybe<Scalars['String']>;
  taxYearInterviewId?: InputMaybe<Scalars['String']>;
  totalDollarAmount?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateProgressStatusesInput = {
  firmAccountId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  index?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  underlyingStatus?: InputMaybe<TaxYearStatus>;
};

export type UpdateReviewInput = {
  id: Scalars['String'];
  reviewContent?: InputMaybe<Scalars['String']>;
  reviewValue?: InputMaybe<Scalars['Float']>;
  taxYearInterviewId?: InputMaybe<Scalars['String']>;
};

export type UpdateSectionInput = {
  id: Scalars['String'];
  isSpecial?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  specialType?: InputMaybe<SpecialSectionsType>;
};

export type UpdateStripeSubscriptionCancellationDetailsInput = {
  comment?: InputMaybe<Scalars['String']>;
  feedback?: InputMaybe<Scalars['String']>;
};

export type UpdateStripeSubscriptionInput = {
  application_fee_percent?: InputMaybe<Scalars['Float']>;
  cancel_at?: InputMaybe<Scalars['Float']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']>;
  cancellation_details?: InputMaybe<UpdateStripeSubscriptionCancellationDetailsInput>;
  collection_method?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Scalars['String']>;
  days_until_due?: InputMaybe<Scalars['Float']>;
  default_payment_method?: InputMaybe<Scalars['String']>;
  default_source?: InputMaybe<Scalars['String']>;
  default_tax_rates?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  expand?: InputMaybe<Array<Scalars['String']>>;
  items?: InputMaybe<Array<UpdateStripeSubscriptionItemInput>>;
  off_session?: InputMaybe<Scalars['Boolean']>;
  on_behalf_of?: InputMaybe<Scalars['String']>;
  pause_collection?: InputMaybe<UpdateStripeSubscriptionPauseCollectionInput>;
  payment_behavior?: InputMaybe<Scalars['String']>;
  promotion_code?: InputMaybe<Scalars['String']>;
  proration_behavior?: InputMaybe<Scalars['String']>;
  proration_date?: InputMaybe<Scalars['Float']>;
  trial_from_plan?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateStripeSubscriptionItemInput = {
  clear_usage?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  price_data?: InputMaybe<UpdateStripeSubscriptionItemPriceDataInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  tax_rates?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateStripeSubscriptionItemPriceDataInput = {
  currency: Scalars['String'];
  product: Scalars['String'];
  recurring: UpdateStripeSubscriptionItemPriceDataRecurringInput;
  unit_amount?: InputMaybe<Scalars['Float']>;
  unit_amount_decimal?: InputMaybe<Scalars['String']>;
};

export type UpdateStripeSubscriptionItemPriceDataRecurringInput = {
  interval: Scalars['String'];
  interval_count?: InputMaybe<Scalars['Float']>;
};

export type UpdateStripeSubscriptionPauseCollectionInput = {
  behavior: Scalars['String'];
  resumes_at?: InputMaybe<Scalars['Float']>;
};

export type UpdateTaskInput = {
  completedAt: Scalars['DateTime'];
  id: Scalars['String'];
  taskType?: InputMaybe<TaskType>;
};

export type UpdateTaxYearInput = {
  id: Scalars['String'];
  year?: InputMaybe<Scalars['Float']>;
};

export type UpdateTaxYearInterviewInput = {
  assignedAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  entityId?: InputMaybe<Scalars['String']>;
  /** Id of the tax year interview */
  id?: InputMaybe<Scalars['String']>;
  interviewId?: InputMaybe<Scalars['String']>;
  invoicedId?: InputMaybe<Scalars['String']>;
  jobTrackingId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  preparedId?: InputMaybe<Scalars['String']>;
  preparerAcceptedAt?: InputMaybe<Scalars['DateTime']>;
  previousYear?: InputMaybe<Scalars['String']>;
  progressStatusId?: InputMaybe<Scalars['String']>;
  reviewStatus?: InputMaybe<ReviewStatus>;
  reviewedId?: InputMaybe<Scalars['String']>;
  taxYearId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type UpdateTranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  languageId?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  sectionId?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEntityInput = {
  userId: Scalars['String'];
};

export type UpdateUserFirmAccountInput = {
  entityId?: InputMaybe<Scalars['String']>;
  firmAccountId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInformationInput = {
  Address?: InputMaybe<Array<UpsertAddressInput>>;
  Phones?: InputMaybe<Array<UpsertPhoneInput>>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  BusinessInformation?: InputMaybe<CreateBusinessInformationInput>;
  UserInformation?: InputMaybe<UpdateUserInformationInput>;
  contactId?: InputMaybe<Scalars['String']>;
  deleteAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firmName?: InputMaybe<Scalars['String']>;
  hearAboutUs?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  languageId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  secretOTP?: InputMaybe<Scalars['String']>;
  selectedFirmId?: InputMaybe<Scalars['String']>;
  uniqueLink?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserStepInput = {
  completed: Scalars['Boolean'];
  stepId: Scalars['String'];
  userId: Scalars['String'];
};

export type UploadTaxpayerFileInput = {
  fileName: Scalars['String'];
  size: Scalars['Float'];
  taxYearInterviewId: Scalars['String'];
};

export type UpsertAddressInput = {
  businessInformationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  lineOne?: InputMaybe<Scalars['String']>;
  lineTwo?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressType>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpsertPhoneInput = {
  businessInformationId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PhoneType>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserChannelEntity = {
  __typename?: 'UserChannelEntity';
  ChannelMembers?: Maybe<Array<ChannelMemberEntity>>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Meetings?: Maybe<Array<MeetingEntity>>;
  Messages?: Maybe<Array<MessageEntity>>;
  createAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  firmAccountId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  online?: Maybe<Scalars['Boolean']>;
  updateAt: Scalars['DateTime'];
};

export type UserEntity = {
  __typename?: 'UserEntity';
  AdministrativeRoles?: Maybe<AdministrativeRolesEntity>;
  ChannelMembers?: Maybe<Array<ChannelMemberEntity>>;
  Entity?: Maybe<Entity>;
  EventTypes?: Maybe<EventTypeEntity>;
  FileRequests?: Maybe<Array<FileRequestEntity>>;
  Files?: Maybe<Array<FileEntity>>;
  FirmAccount?: Maybe<Array<FirmAccountEntity>>;
  Languages?: Maybe<LanguagesEntity>;
  MeetingAttendees?: Maybe<Array<MeetingAttendeesEntity>>;
  Messages?: Maybe<Array<MessageEntity>>;
  NylasAccounts?: Maybe<Array<NylasAccountEntity>>;
  PasswordReset?: Maybe<PasswordResetEntity>;
  ReferredByUser?: Maybe<UserEntity>;
  Reminders?: Maybe<Array<RemindersEntity>>;
  TaxYearInterviews?: Maybe<Array<TaxYearInterviewEntity>>;
  UserEntity?: Maybe<Array<UserEntityEntity>>;
  UserFirmAccount?: Maybe<Array<UserFirmAccountEntity>>;
  UserInformation?: Maybe<UserInformationEntity>;
  contactId?: Maybe<Scalars['String']>;
  createAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  deleteAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  has2Fa?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  invitationEmailSent?: Maybe<Scalars['Boolean']>;
  languageId?: Maybe<Scalars['String']>;
  referredById?: Maybe<Scalars['String']>;
  referredUsers?: Maybe<Array<UserEntity>>;
  uniqueLink?: Maybe<Scalars['String']>;
  updateAt: Scalars['DateTime'];
};

export type UserEntityEntity = {
  __typename?: 'UserEntityEntity';
  Entity?: Maybe<Entity>;
  User?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserFirmAccountEntity = {
  __typename?: 'UserFirmAccountEntity';
  Entity?: Maybe<Entity>;
  FirmAccount?: Maybe<FirmAccountEntity>;
  Roles?: Maybe<RolesEntity>;
  Users?: Maybe<UserEntity>;
  createAt: Scalars['DateTime'];
  deleteAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  firmAccountId: Scalars['String'];
  id: Scalars['String'];
  roleId: Scalars['String'];
  updateAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type UserFirmAccountOrderByInput = {
  Entity?: InputMaybe<EntityOrderByInput>;
  createAt?: InputMaybe<Scalars['String']>;
  deleteAt?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type UserFirmAccountPagination = {
  __typename?: 'UserFirmAccountPagination';
  total: Scalars['Int'];
};

export type UserInformationEntity = {
  __typename?: 'UserInformationEntity';
  Address?: Maybe<Array<AddressEntity>>;
  Phones?: Maybe<Array<PhonesEntity>>;
  Users?: Maybe<UserEntity>;
  birthday?: Maybe<Scalars['DateTime']>;
  createAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserInformationOrderByInput = {
  createAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type UserOrderByInput = {
  Entity?: InputMaybe<EntityOrderByInput>;
  UserInformation?: InputMaybe<UserInformationOrderByInput>;
  createAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['String']>;
};

export type UserStepEntity = {
  __typename?: 'UserStepEntity';
  Steps?: Maybe<StepsEntity>;
  completed: Scalars['Boolean'];
  stepId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserSuccessEntity = {
  __typename?: 'UserSuccessEntity';
  apiToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UserSuccessFailureEntity = {
  __typename?: 'UserSuccessFailureEntity';
  failures?: Maybe<Array<FailureData>>;
  success: Scalars['Boolean'];
  successCount: Scalars['Float'];
};

export type ValidateClientEntity = {
  __typename?: 'ValidateClientEntity';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  isUpdate: Scalars['Boolean'];
  jobType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<PhonesEntity>>;
  preparer?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxYear?: Maybe<Scalars['Float']>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  zip?: Maybe<Scalars['String']>;
};

export type ValidateClientFromFileInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<CreatePhoneInput>>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ValidateKbaInput = {
  DOB: Scalars['String'];
  last4SSN: Scalars['String'];
  lastName: Scalars['String'];
};

export type ValidateOtpEntity = {
  __typename?: 'ValidateOtpEntity';
  code: Scalars['String'];
  secretOTP?: Maybe<Scalars['String']>;
};

export type ValidateOtpInput = {
  code: Scalars['String'];
  secretOTP?: InputMaybe<Scalars['String']>;
};

export type VerifyDistribution = {
  __typename?: 'VerifyDistribution';
  cloudFrontDomain: Scalars['String'];
  status: Scalars['String'];
};

export type GenerateOtpEntity = {
  __typename?: 'generateOtpEntity';
  codeQR: Scalars['String'];
  key: Scalars['String'];
};
