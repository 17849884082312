import { Avatar, Badge, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import API from '../../../api';
import styles from './style.module.css';
import 'antd/dist/antd.min.css';

const getAcronim = (str) => {
  if (!str) return '';
  const names = str.split(' ');
  return `${names[0].charAt()}${names[1].charAt()}`;
};

const UserHeader = ({
  status, name, avatar, lastMessage, onClick,
}) => {
  const { t: translation } = useTranslation();
  // const [avatarURL, setAvatarURL] = useState('');

  /* const getLink = async (key) => {
    if (!key) return '';
    try {
      const { data } = await API.get(`/files/view/${key}`);
      setAvatarURL(data.url);
      return key;
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (avatar) {
      getLink(avatar);
    }
  }, [avatar]); */

  return (
    <div className={styles.container}>
      <Badge dot className={styles.badge} color={status ? 'green' : 'gray'}>
        <Avatar
          style={{ backgroundColor: '#22A7E7' }}
          src={avatar}
          size={50}
          onClick={onClick}
        >
          {getAcronim(name)}
        </Avatar>
      </Badge>
      <div className={styles.continfo}>
        <Typography className={styles.name} onClick={onClick}>
          {name}
        </Typography>
        <p>{status ? translation('chat.userActive') : lastMessage}</p>
      </div>
    </div>
  );
};

UserHeader.defaultProps = {
  status: undefined,
  avatar: '',
  lastMessage: '',
};

UserHeader.propTypes = {
  status: PropTypes.bool,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  lastMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default UserHeader;
