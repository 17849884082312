import React from 'react';
import { Badge, Grid } from 'antd';
import { CheckCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { css, StyleSheet } from 'aphrodite';

const { useBreakpoint } = Grid;

const styles = StyleSheet.create({
  wrapper: {
    // marginTop: '15px',
  },
  KeyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 0px',
    fontSize: '11px',
    alignItems: 'center',
  },
  badgeKeyStyles: {
    padding: '4px 6px',
    borderRadius: '21px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 640px)': {
      padding: '4px 6px',
    },
  },
  badgeKeySent: {
    background: '#fdcb5c',
  },
  badgeKeyComplete: {
    background: '#81ddb1',
  },
});

const BadgeKeys = () => {
  const screens = useBreakpoint();

  if (!screens.md) {
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.KeyWrapper)}>
          <Badge
            className={css(styles.badgeKeyStyles, styles.badgeKeySent)}
            count={<RedoOutlined style={{ display: 'contents', fontSize: '11px' }} />}
          />
          <span>&nbsp;=  Request Sent</span>
        </div>
        <div className={css(styles.KeyWrapper)}>
          <Badge
            className={css(styles.badgeKeyStyles, styles.badgeKeyComplete)}
            count={<CheckCircleOutlined style={{ display: 'contents', fontSize: '11px' }} />}
          />
          <span>&nbsp;=  Request Complete</span>
        </div>
      </div>
    );
  }

  return <div />;
};

export default BadgeKeys;
