import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import styles from './style.module.css';
import 'antd/dist/antd.min.css';

const Message = ({
  createdAt, text, readDate, isAdmin,
}) => {
  const size = isAdmin ? '1.5rem' : '1rem';
  const MissedCall = () => (
    <svg height={size} viewBox="0 0 58 53" width={size} xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" fill="none" fillRule="evenodd">
        <g
          id="067---missed-call"
          fill={readDate ? 'rgb(150, 150, 150)' : 'rgb(247, 60, 60)'}
          fillRule="nonzero"
          transform="translate(-1)"
        >
          <path
            id="Shape"
            d="m40.186 45.419 9.973 7.236c.718772.5218232 1.7101307.4442607 2.339-.183l5.975-5.961c.5551-.5583757.6871506-1.4108493.327-2.111-4.583-9.028-15.745-15.4-28.8-15.4s-24.217 6.372-28.8 15.4c-.36263121.700743-.23129132 1.5554626.325 2.115l5.975 5.957c.6288693.6272607 1.62022802.7048232 2.339.183l9.973-7.236c.3558102-.2582442.6048037-.6377814.7-1.067l1.2-5.424c.1796818-.7893569.7864093-1.4115326 1.571-1.611 4.4028926-1.1226838 9.0171074-1.1226838 13.42 0 .7845624.1991727 1.3911146.8215648 1.57 1.611l1.2 5.424c.0985037.4311862.3523139.8110116.713 1.067z"
          />
          <path
            id="Shape"
            d="m51 0-17.307 1.923 5.571 5.571-9.385 9.385-15.886-14.121c-.7987092-.7279533-1.9272131-.97007565-2.9542462-.63383834s-1.7938561 1.19886511-2.00742241 2.2582243c-.21356632 1.05935919.1591331 2.15171417.97566861 2.85961404l18 16c1.1871701 1.0550093 2.9908878 1.0019588 4.114-.121l11.385-11.385 5.571 5.571z"
          />
        </g>
      </g>
    </svg>
  );

  return (
    <div className={isAdmin ? styles.containerAdmin : styles.container}>
      <div className={isAdmin ? styles.containerIconAdmin : styles.containerIcon}>
        <Icon component={MissedCall} />
        <p
          style={{
            marginLeft: '.5rem',
            color: readDate ? 'rgb(150, 150, 150)' : 'rgb(247, 60, 60)',
          }}
        >
          {text}
        </p>
      </div>
      <p className={isAdmin ? styles.sendAdmin : styles.send}>{moment(createdAt).format('LT')}</p>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  readDate: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Message;
