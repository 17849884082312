import React from 'react';
import PropTypes from 'prop-types';
import TwoFA from '../Components/TwoFA';
import Password from '../Components/Password';
import Language from '../Components/Language';
import ClientInfo from '../Components/ClientInfo';
import './layout.css';
import Security from '../Components/Security'

const SettingsLayout = (props) => {
  const {
    dispatch,
    userId,
  } = props;

  return (
    <>
      <ClientInfo />
      <Security />
      <Password userId={userId} />
      <Language />
    </>
  );
};

SettingsLayout.defaultProps = {
  
};

SettingsLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default SettingsLayout;
