import React from 'react';
import PropTypes from 'prop-types';
import LobbyUser from '../LobbyUser';
import styles from './style.module.css';
import { getName } from '../../helpers/userInformation';

const LobbyUsers = ({ data }) => (
  <div className={styles.container}>
    {data ? (
      data.map((element) => <LobbyUser key={element.id} name={getName(element)} />)
    ) : (
      <p>No one else is here</p>
    )}
  </div>
);
LobbyUsers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default LobbyUsers;
