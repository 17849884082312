import React from 'react';
import PropTypes from 'prop-types';
import { color } from '../../styles/colors';

const Star = ({ filled, size }) => {
  const starFill = filled ? color.blue : 'transparent';
  const width = size === 'middle' ? 22 : 10;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="1 1 22 21">
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" stroke={color.blue} fill={starFill} />
    </svg>
  );
};

Star.defaultProps = {
  filled: false,
  size: '',
};

Star.propTypes = {
  filled: PropTypes.bool,
  size: PropTypes.string,
};

export default Star;
