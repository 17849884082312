import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, message as AntdMessage } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import DownloadFile from '../DownloadFile';
import Meeting from '../Meeting';
import styles from './style.module.css';
import { useDownloadFileFromS3Mutation } from '../../../graphql/mutations/clients';


const Message = ({
  message, attachment, last, mine, createdAt, isSms,
}) => {
  const attach = attachment ? JSON.parse(attachment) : null;

  const [downloadFile] = useDownloadFileFromS3Mutation({
    onError: (err) => AntdMessage.error(err.message),
  });

  const getLink = async (s3Key) => {
    if (!s3Key) return '';

    const download = await downloadFile({
      variables: {
        s3Key,
      },
    });

    return download?.data?.downloadFile?.signedUrl;
  };

  const openLink = async (key) => {
    const url = await getLink(key);
    window.open(url, '_blank');
  };

  return (
    <div
      className={`${mine ? styles.mine : styles.yours} ${styles.messages}`}
    >
      <div className={`${!isSms ? styles.message : styles.messageSms} ${last && styles.last}`}>
        {Boolean(attach) && (
          <div className={`${styles.text} ${mine && styles.textMine}`}>
            <Button
              type="link"
              size="middle"
              onClick={() => openLink(attach.s3key)}
            >
              <FileOutlined />
              {attach.name.length > 20
                ? `${attach.name.substring(0, 20)}...`
                : attach.name}
            </Button>
            <DownloadFile
              getUrl={async () => getLink(attach.s3key)}
              name={attach.name}
            />
          </div>
        )}
        {!isSms && !attach && (
          <>
            {message.includes('#{meetingId}=')
            ? (
              <Meeting meetingId={message.split('=')[1]} />
            )
            : (
              <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{message}</p>
            )}
          </>
        )}
        {isSms && !attach && (
          <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{message}</p>
        )}
      </div>
      <p className={styles.send}>
        {moment(createdAt).format('LT')}
        {' '}
        {!isSms ? 'Chat' : 'SMS'}
      </p>
    </div>
  );
};

Message.defaultProps = {
  attachment: null,
};

Message.propTypes = {
  message: PropTypes.string.isRequired,
  attachment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  last: PropTypes.bool.isRequired,
  mine: PropTypes.bool.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
};

export default Message;
