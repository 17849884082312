import { SET_LANG, SET_BRANDING } from '../actions/profile';

const initialState = {
  language: null,
  branding: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LANG:
      return {
        ...state,
        language: { ...state.language, ...payload },
      };
    case SET_BRANDING:
      return {
        ...state,
        branding: { ...state.branding, ...payload },
      };
    default:
      return state;
  }
};
