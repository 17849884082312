import React from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import { Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import styles from './style.module.css';
import './style.css';

const ChatButton = ({ onClick }) => (
  <Button
    className={styles.btn}
    onClick={onClick}
    type="primary"
    shape="circle"
    icon={<MessageOutlined />}
  />
);

ChatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ChatButton;
