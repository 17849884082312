import { useState } from 'react';
import {
  Row,
  Input,
  Form,
  Button,
  message,
  Alert,
} from 'antd';
import DatePicker from '../../../../atoms/DatePicker';
import { useValidateKbaMutation } from '../../../../../graphql/mutations/kba';

const FreeKBA = ({
  kbaId,
  numKbaFailedAttempts,
}) => {
  console.log('FreeKBA');
  const [numAttemptsRemaining, setNumAttemptsRemaining] = useState(3 - numKbaFailedAttempts);

  const [callValidateKba, { loading: loadingValidateKba }] = useValidateKbaMutation();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const { lastName, lastFourSSN, dateOfBirth } = values;

    // convert the selected DOB to the date string we're looking for "mm/dd/yyyy"
    function pad(s) { return (s < 10) ? `0${s}` : s; }
    const d = new Date(dateOfBirth);
    const dateString = [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');

    try {
      const result = await callValidateKba({
        variables: {
          kbaId,
          validateKBAInput: {
            last4SSN: lastFourSSN,
            DOB: dateString,
            lastName,
          },
        }
      });
      console.log('result:', result);
      if (result.data.validateKBA.isValidated) {
        message.success('Success')
      }
    } catch (error) {
      message.error(error.toString());
      setNumAttemptsRemaining((prev) => prev - 1);
    }
  };

  return (
    <div
      style={{
        maxWidth: '500px',
        margin: '0 auto',
        textAlign: 'center',
        padding: '50px 50px',
      }}
    >
      <Row
        justify="center"
        style={{
          marginBottom: '20px',
          marginTop: '50px',
        }}
      >
        {(numAttemptsRemaining <= 2) && (
          <Alert
            description={numAttemptsRemaining > 0
              ? `Only ${numAttemptsRemaining} attempts remaining.`
              : `No more attempts remaining.`}
            type={numAttemptsRemaining > 0 ? 'warning' : 'error'}
            showIcon
            style={{ marginBottom: 20, marginTop: -5 }}
          />
        )}
        <h1>
          Please verify your identity to access documents.
        </h1>
      </Row>
      <Form
        name="free_kba"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true, message: 'Please input your last name.' }]}
        >
          <Input placeholder="Your last name" />
        </Form.Item>
        <Form.Item
          name="lastFourSSN"
          label="Last 4 digits of your SSN"
          rules={[{ required: true, message: 'Please input the last four digits of you social security number.' }]}
        >
          <Input
            type="text"
            placeholder="Last four digits of your social security number"
          />
        </Form.Item>
        <Form.Item
          name="dateOfBirth"
          label="Date of birth"
          rules={[{ required: true, message: 'Please input date of birth.' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '100%' }}
            loading={loadingValidateKba}
          >
            Verify Identity
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FreeKBA;
