import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from './style.module.css';

const UserCall = ({ name, avatar }) => (
  <div className={styles.container}>
    <Avatar icon={<UserOutlined />} src={avatar} />
    <p>{name}</p>
  </div>
);
UserCall.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  avatar: PropTypes.string.isRequired,
};
export default UserCall;
