import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinishedSigningMutationVariables = Types.Exact<{
  finishedSigningInput: Types.FinishedSigningInput;
}>;


export type FinishedSigningMutation = { __typename?: 'Mutation', finishedSigning: string };

export type MarkFileApprovedMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type MarkFileApprovedMutation = { __typename?: 'Mutation', markFileApproved: { __typename?: 'FileApprovalEntity', id: string } };

export type UploadTaxpayerFileMutationVariables = Types.Exact<{
  uploadTaxpayerFileInput: Types.UploadTaxpayerFileInput;
}>;


export type UploadTaxpayerFileMutation = { __typename?: 'Mutation', UploadTaxpayerFile: boolean };

export type UploadTaxpayerFilePresigedUrlMutationVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
  fileName: Types.Scalars['String'];
  contentType: Types.Scalars['String'];
}>;


export type UploadTaxpayerFilePresigedUrlMutation = { __typename?: 'Mutation', uploadTaxpayerFilePresigedUrl: { __typename?: 'FileSuccessEntity', signedUrl?: string | null, url?: string | null } };

export type TaxpayerDeleteFileMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type TaxpayerDeleteFileMutation = { __typename?: 'Mutation', TaxpayerDeleteFile: { __typename?: 'FileEntity', id: string, deleteAt?: any | null } };

export type UpdateFileNameMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type UpdateFileNameMutation = { __typename?: 'Mutation', updateFileName: { __typename?: 'FileEntity', id: string, userId?: string | null, firmAccountId?: string | null, taxYearInterviewId?: string | null, s3Key?: string | null, name?: string | null, size?: number | null, folder?: string | null, pages?: number | null, documentUsed?: boolean | null, createAt: any, updateAt: any } };


export const FinishedSigningDocument = gql`
    mutation FinishedSigning($finishedSigningInput: FinishedSigningInput!) {
  finishedSigning(finishedSigningInput: $finishedSigningInput)
}
    `;
export type FinishedSigningMutationFn = Apollo.MutationFunction<FinishedSigningMutation, FinishedSigningMutationVariables>;

/**
 * __useFinishedSigningMutation__
 *
 * To run a mutation, you first call `useFinishedSigningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishedSigningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishedSigningMutation, { data, loading, error }] = useFinishedSigningMutation({
 *   variables: {
 *      finishedSigningInput: // value for 'finishedSigningInput'
 *   },
 * });
 */
export function useFinishedSigningMutation(baseOptions?: Apollo.MutationHookOptions<FinishedSigningMutation, FinishedSigningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishedSigningMutation, FinishedSigningMutationVariables>(FinishedSigningDocument, options);
      }
export type FinishedSigningMutationHookResult = ReturnType<typeof useFinishedSigningMutation>;
export type FinishedSigningMutationResult = Apollo.MutationResult<FinishedSigningMutation>;
export type FinishedSigningMutationOptions = Apollo.BaseMutationOptions<FinishedSigningMutation, FinishedSigningMutationVariables>;
export const MarkFileApprovedDocument = gql`
    mutation MarkFileApproved($id: String!) {
  markFileApproved(id: $id) {
    id
  }
}
    `;
export type MarkFileApprovedMutationFn = Apollo.MutationFunction<MarkFileApprovedMutation, MarkFileApprovedMutationVariables>;

/**
 * __useMarkFileApprovedMutation__
 *
 * To run a mutation, you first call `useMarkFileApprovedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFileApprovedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFileApprovedMutation, { data, loading, error }] = useMarkFileApprovedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkFileApprovedMutation(baseOptions?: Apollo.MutationHookOptions<MarkFileApprovedMutation, MarkFileApprovedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkFileApprovedMutation, MarkFileApprovedMutationVariables>(MarkFileApprovedDocument, options);
      }
export type MarkFileApprovedMutationHookResult = ReturnType<typeof useMarkFileApprovedMutation>;
export type MarkFileApprovedMutationResult = Apollo.MutationResult<MarkFileApprovedMutation>;
export type MarkFileApprovedMutationOptions = Apollo.BaseMutationOptions<MarkFileApprovedMutation, MarkFileApprovedMutationVariables>;
export const UploadTaxpayerFileDocument = gql`
    mutation UploadTaxpayerFile($uploadTaxpayerFileInput: UploadTaxpayerFileInput!) {
  UploadTaxpayerFile(uploadTaxpayerFileInput: $uploadTaxpayerFileInput)
}
    `;
export type UploadTaxpayerFileMutationFn = Apollo.MutationFunction<UploadTaxpayerFileMutation, UploadTaxpayerFileMutationVariables>;

/**
 * __useUploadTaxpayerFileMutation__
 *
 * To run a mutation, you first call `useUploadTaxpayerFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTaxpayerFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTaxpayerFileMutation, { data, loading, error }] = useUploadTaxpayerFileMutation({
 *   variables: {
 *      uploadTaxpayerFileInput: // value for 'uploadTaxpayerFileInput'
 *   },
 * });
 */
export function useUploadTaxpayerFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadTaxpayerFileMutation, UploadTaxpayerFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTaxpayerFileMutation, UploadTaxpayerFileMutationVariables>(UploadTaxpayerFileDocument, options);
      }
export type UploadTaxpayerFileMutationHookResult = ReturnType<typeof useUploadTaxpayerFileMutation>;
export type UploadTaxpayerFileMutationResult = Apollo.MutationResult<UploadTaxpayerFileMutation>;
export type UploadTaxpayerFileMutationOptions = Apollo.BaseMutationOptions<UploadTaxpayerFileMutation, UploadTaxpayerFileMutationVariables>;
export const UploadTaxpayerFilePresigedUrlDocument = gql`
    mutation uploadTaxpayerFilePresigedUrl($taxYearInterviewId: String!, $fileName: String!, $contentType: String!) {
  uploadTaxpayerFilePresigedUrl(
    taxYearInterviewId: $taxYearInterviewId
    fileName: $fileName
    contentType: $contentType
  ) {
    signedUrl
    url
  }
}
    `;
export type UploadTaxpayerFilePresigedUrlMutationFn = Apollo.MutationFunction<UploadTaxpayerFilePresigedUrlMutation, UploadTaxpayerFilePresigedUrlMutationVariables>;

/**
 * __useUploadTaxpayerFilePresigedUrlMutation__
 *
 * To run a mutation, you first call `useUploadTaxpayerFilePresigedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTaxpayerFilePresigedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTaxpayerFilePresigedUrlMutation, { data, loading, error }] = useUploadTaxpayerFilePresigedUrlMutation({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadTaxpayerFilePresigedUrlMutation(baseOptions?: Apollo.MutationHookOptions<UploadTaxpayerFilePresigedUrlMutation, UploadTaxpayerFilePresigedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTaxpayerFilePresigedUrlMutation, UploadTaxpayerFilePresigedUrlMutationVariables>(UploadTaxpayerFilePresigedUrlDocument, options);
      }
export type UploadTaxpayerFilePresigedUrlMutationHookResult = ReturnType<typeof useUploadTaxpayerFilePresigedUrlMutation>;
export type UploadTaxpayerFilePresigedUrlMutationResult = Apollo.MutationResult<UploadTaxpayerFilePresigedUrlMutation>;
export type UploadTaxpayerFilePresigedUrlMutationOptions = Apollo.BaseMutationOptions<UploadTaxpayerFilePresigedUrlMutation, UploadTaxpayerFilePresigedUrlMutationVariables>;
export const TaxpayerDeleteFileDocument = gql`
    mutation TaxpayerDeleteFile($id: String!) {
  TaxpayerDeleteFile(id: $id) {
    id
    deleteAt
  }
}
    `;
export type TaxpayerDeleteFileMutationFn = Apollo.MutationFunction<TaxpayerDeleteFileMutation, TaxpayerDeleteFileMutationVariables>;

/**
 * __useTaxpayerDeleteFileMutation__
 *
 * To run a mutation, you first call `useTaxpayerDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxpayerDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxpayerDeleteFileMutation, { data, loading, error }] = useTaxpayerDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaxpayerDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<TaxpayerDeleteFileMutation, TaxpayerDeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaxpayerDeleteFileMutation, TaxpayerDeleteFileMutationVariables>(TaxpayerDeleteFileDocument, options);
      }
export type TaxpayerDeleteFileMutationHookResult = ReturnType<typeof useTaxpayerDeleteFileMutation>;
export type TaxpayerDeleteFileMutationResult = Apollo.MutationResult<TaxpayerDeleteFileMutation>;
export type TaxpayerDeleteFileMutationOptions = Apollo.BaseMutationOptions<TaxpayerDeleteFileMutation, TaxpayerDeleteFileMutationVariables>;
export const UpdateFileNameDocument = gql`
    mutation updateFileName($id: String!, $name: String!) {
  updateFileName(id: $id, name: $name) {
    id
    userId
    firmAccountId
    taxYearInterviewId
    s3Key
    name
    size
    folder
    pages
    documentUsed
    createAt
    updateAt
  }
}
    `;
export type UpdateFileNameMutationFn = Apollo.MutationFunction<UpdateFileNameMutation, UpdateFileNameMutationVariables>;

/**
 * __useUpdateFileNameMutation__
 *
 * To run a mutation, you first call `useUpdateFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileNameMutation, { data, loading, error }] = useUpdateFileNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateFileNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileNameMutation, UpdateFileNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileNameMutation, UpdateFileNameMutationVariables>(UpdateFileNameDocument, options);
      }
export type UpdateFileNameMutationHookResult = ReturnType<typeof useUpdateFileNameMutation>;
export type UpdateFileNameMutationResult = Apollo.MutationResult<UpdateFileNameMutation>;
export type UpdateFileNameMutationOptions = Apollo.BaseMutationOptions<UpdateFileNameMutation, UpdateFileNameMutationVariables>;
export const namedOperations = {
  Mutation: {
    FinishedSigning: 'FinishedSigning',
    MarkFileApproved: 'MarkFileApproved',
    UploadTaxpayerFile: 'UploadTaxpayerFile',
    uploadTaxpayerFilePresigedUrl: 'uploadTaxpayerFilePresigedUrl',
    TaxpayerDeleteFile: 'TaxpayerDeleteFile',
    updateFileName: 'updateFileName'
  }
}