/* eslint-disable */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { Icon } from '../Icon/Icon';
import Loader from '../Loader/Loader';
import { color, grey } from '../../styles/colors';
import { Lato } from '../../styles/fonts';

const Button = ({
  blue,
  card,
  create,
  isHovered,
  deleteBtn,
  loading,
  secondary,
  disabled,
  iconCustomBackground,
  icon,
  label,
  onClick,
  activeTask,
  primary,
  text,
  fullwidth,
  style = {},
}) => {
  const styles = StyleSheet.create({
    button: {
      position: 'relative',
      display: 'inline-block',
      minHeight: '46px',
      lineHeight: '28px',
      padding: '6px',
      fontFamily: [Lato, 'sans-serif'],
      fontWeight: 'bold',
      fontSize: '12px',
      letterSpacing: '0.6px',
      textAlign: 'center',
      color: grey['800'],
      borderRadius: '6px',
      outline: 'none',
      border: 'none',
      transition: '0.2s ease',
      cursor: 'pointer',
      '@media (min-width: 768px)': {
        minHeight: '50px',
        padding: '10px',
        fontSize: '16px',
        letterSpacing: '0.8px',
      },
    },
    primary: {
      backgroundColor: grey['200'],
      ':hover': {
        backgroundColor: color.blue,
        color: color.white,
      },
    },
    blue: {
      backgroundColor: color.blue,
      color: color.white,
      ':hover': {
        backgroundColor: color.lightBlue,
        color: color.white,
      },
    },
    secondary: {
      backgroundColor: grey['200'],
      ':hover': {
        backgroundColor: grey['400'],
        color: color.white,
      },
    },
    card: {
      display: 'block',
      width: '100%',
      minHeight: '58px',
      padding: '10px 30px',
      textAlign: 'left',
      borderRadius: '1px',
      backgroundColor: color.white,
      boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
      color: grey['500'],
    },
    activeTask: {
      color: grey['800'],
      ':hover': {
        backgroundColor: color.blue,
        color: color.white,
        boxShadow: '0 1px 5px 0 rgba(120, 156, 220, 0.4)',
      },
    },
    fullwidth: { width: '100%' },
    customStyles: style,
    text: {
      alignSelf: 'flex-start',
      minHeight: '16px',
      margin: '0 -4px',
      padding: '4px 20px 4px 4px',
      fontSize: '16px',
      color: color.blue,
      backgroundColor: 'transparent',
      '@media (min-width: 768px)': {
        alignSelf: 'flex-start',
        minHeight: '16px',
        margin: '0 -4px',
        padding: '4px 20px 4px 4px',
        fontSize: '16px',
        textAlign: 'left',
        letterSpacing: '0.6px',
      },
    },
    deleteBtn: {
      color: color.red,
    },
    greyText: {
      color: '#d2d2d2',
    },
  });

  return (
    <button
      className={css(
        styles.button,
        primary && styles.primary,
        blue && styles.blue,
        secondary && styles.secondary,
        fullwidth && styles.fullwidth,
        card && styles.card,
        create && styles.text,
        deleteBtn && styles.deleteBtn,
        activeTask && styles.activeTask,
        text && styles.text,
        text === 'grey' && styles.greyText,
        styles.customStyles,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      { card && activeTask && <Icon holder /> }
      { card && !activeTask && <Icon check /> }
      { create && <Icon add style={iconCustomBackground && { backgroundColor: iconCustomBackground }} /> }
      { deleteBtn && <Icon remove /> }
      { text === 'grey' && <Icon arrow="toLeft" />}
      { !loading && icon === 'download' && <Icon download={isHovered ? 'hovered' : 'normal'} /> }
      { !loading && label }
      { !loading && icon === 'lock' && <Icon icon="lock" /> }
      { loading && <Loader />}
      { text && text !== 'grey' && <Icon arrow="toRight" hovered /> }
    </button>
  );
};

export default Button;