export const SET_INVOICES_LIST = 'SET_INVOICES_LIST';
export const INVOICES_LIST_ISLOADING = 'INVOICES_LIST_ISLOADING';
export const VIEW_INVOICE = 'VIEW_INVOICE';
export const CLOSE_INVOICE_VIEW = 'CLOSE_INVOICE_VIEW';
export const SHOW_PAYMENT = 'SHOW_PAYMENT';
export const RESET_INVOICES = 'RESET_INVOICES';
export const SHOW_MULTIPLE_PAYMENT = 'SHOW_MULTIPLE_PAYMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export const invoicesLoading = () => ({
  type: INVOICES_LIST_ISLOADING,
});

export const setInvoicesList = (list) => ({
  type: SET_INVOICES_LIST,
  payload: list,
});

export const viewInvoice = (invoice) => ({
  type: VIEW_INVOICE,
  payload: invoice,
});

export const closeInvoiceView = () => ({
  type: CLOSE_INVOICE_VIEW,
});

export const showPayment = (payment) => ({
  type: SHOW_PAYMENT,
  payload: payment,
});

export const showMultiplePayment = () => ({
  type: SHOW_MULTIPLE_PAYMENT,
});

export const resetInvoices = () => ({
  type: RESET_INVOICES,
});
