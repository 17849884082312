import store from '../../redux/store/store';

const callWoopraMethod = (methodName, params, eventName) => {
  try {
    // eslint-disable-next-line
    const woopra = window['woopra'];
    if (woopra) {
      if (eventName) {
        return woopra[methodName](eventName, params);
      }
      return woopra[methodName](params);
    }
    console.warn('woopra is not defined');
  } catch (e) {
    console.log('woopra error', e);
  }

  return undefined;
};

const getUserFromSession = () => {
  const user = store.store.getState().session?.graphSession;
  return {
    email: user?.email,
    name: user?.firstName ? `${user?.firstName} ${user?.lastName}` : undefined,
    role: user?.userRole,
  };
};

export const trackSignIn = () => {
  const identity = getUserFromSession();
  callWoopraMethod('identify', identity);
  callWoopraMethod('track', identity, 'signin');
};

export const trackSignOut = () => {
  const identity = getUserFromSession();
  callWoopraMethod('identify', identity);
  callWoopraMethod('track', identity, 'signout');
};

export const setWoopraIdentityFromStorage = () => {
  const identity = getUserFromSession();
  callWoopraMethod('identify', identity).push();
};
