import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col, Divider, Typography, Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import InviteForm from '../../organisms/InviteForm';

import style from './style.module.scss';

const { Title } = Typography;

const Invite = ({
  firm, user, isPersonalInviteLink, referredBy, uniqueId,
}) => {
  const { t: translation } = useTranslation();
  const preparer = (isPersonalInviteLink && firm?.UserFirmAccounts?.length) ? firm?.UserFirmAccounts[0]?.Users : null;
  const preparerName = `${preparer?.UserInformation?.firstName} ${preparer?.UserInformation?.lastName}`;
  const firmName = firm?.name || translation('Invite.title.defaultPreparerName');
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  return (
    <div className={style.master}>
      <div className={style.container}>
        <div className={style.head}>
          <Col span={24}>
            <Title level={3}>
              {translation('Invite.title', { preparerName: preparer?.UserInformation?.firstName ? preparerName : firmName })}
            </Title>
          </Col>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <div className={style.content}>
          <div className={style.left}>
            <Title level={4} style={{ textAlign: 'center' }}>
              {translation('Invite.titleInstructions')}
            </Title>
            {alreadyRegistered && (
              <Alert
                message={(
                  <div>
                    <div>{translation('Invite.form.alert.msg1')}</div>
                    <div>
                      {translation('Invite.form.alert.msg2')}
                      {' '}
                      <a href="/forgot-password">
                        {translation('Invite.form.alert.link')}
                      </a>
                      ?
                    </div>
                  </div>
                )}
                closable
                type="warning"
                onClose={() => setAlreadyRegistered(false)}
              />
            )}
            <InviteForm
              customTextColor={{ color: '#000' }}
              firmAccountId={firm.id}
              referredBy={referredBy}
              preparer={preparer}
              user={user}
              uniqueId={uniqueId}
              progressStatuses={firm.ProgressStatuses}
            />
          </div>
          <div className={style.rigth}>
            <Col span={24} style={{ textAlign: 'center', color: '#808080' }}>
              <Divider style={{ marginTop: '25px' }} />
              <p
                className={style.Invite__sideDescription}
              >
                {translation('Invite.side.message1')}
                {' '}
              </p>
              <p
                className={style.Invite__sideDescription}
              >
                {translation('Invite.side.message2')}
                {' '}
                <a href="/login">{translation('Invite.side.messageLink')}</a>
                {' '}
                {translation('Invite.side.message3')}
                {' '}
                {!!uniqueId && (
                  <b style={{ wordBreak: 'break-all' }}>
                    (
                    {firm?.client?.username}
                    )
                  </b>
                )}
              </p>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

Invite.defaultProps = {
  customLayout: null,
  user: undefined,
};

Invite.propTypes = {
  firm: PropTypes.shape().isRequired,
  user: PropTypes.object,
  customLayout: PropTypes.shape({
    headerBackgroundColor: PropTypes.string,
    pageFontColor: PropTypes.string,
  }),
  isPersonalInviteLink: PropTypes.bool.isRequired,
};

export default connect(null)(Invite);
