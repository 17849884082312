import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { Alert as AntdAlert, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { setDefaultLocale } from 'react-datepicker';
import { useApolloClient } from "@apollo/client";
import Page from '../components/Layout/Page';
import Header from '../components/Layout/Header';
import PageTitle from '../components/Layout/PageTitle';
import UpcomingAppointments from '../components/organisms/UpcomingAppointments';
import ProjectCard from '../components/ProjectCard';
import ProjectCardLoading from '../components/ProjectCardLoading';
import AddProject from '../components/Modals/AddProject';
import AddReview from '../components/Modals/AddReview';
import InterviewSectionList from '../components/organisms/InterviewList';
import Documents from '../components/Documents/Documents';
import Invoices from '../components/Invoices/Invoices';
import Settings from '../components/Settings';
import ClientChat from '../components/organisms/ClientChat';
import ScheduleCall from '../components/molecules/ScheduleCall';
import ScheduleModal from '../components/organisms/ScheduleModal';
import Translation from '../components/atoms/Translation';
import { setSession } from '../redux/actions/session';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import { resetInvoices } from "../redux/actions/invoices";
// import { formatProjects } from '../helpers/Projects';
import { useGetCustomLayoutsQuery, useGetJobsByClientIdQuery, useGetFirmSettingQuery } from "../graphql/queries/settings";
import { setProjects } from '../redux/actions/projects';
import { useClientChannelMutation } from '../graphql/mutations/appointments';
import { GetUserChannelsDocument } from '../graphql/queries/messaging';

const mapStateToProps = (state) => ({
  // user: state.session.user,
  language: state.profile.language,
  projects: state.projects,
  dispatch: state.dispatch,
  channels: state.channels,
  session: state.session,
});

// todo: remove the mapStateToProps and the projects
const Projects = connect(mapStateToProps)((props) => {
  const {
    projects: client, jobs, channelId, disableTaxarooCalendar,
  } = props;

  return (
    <>
      {jobs
        && jobs.length > 0
        && jobs.map((job) => (
          <ProjectCard
            key={job.id}
            project={job}
            client={client}
            channelId={channelId}
            disableTaxarooCalendar={disableTaxarooCalendar}
          />
        ))}
    </>
  );
});

// todo: remove this
Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  jobs: PropTypes.arrayOf(PropTypes.shape({})),
  client: PropTypes.shape({
    FirmAccounts: PropTypes.shape({
      customBranding: PropTypes.shape({}),
      firmName: PropTypes.string,
    }),
  }).isRequired,
  // user: PropTypes.shape({}).isRequired,
  // channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const Dashboard = (props) => {
  const {
    dispatch, history, i18n, channels, projects: { projects }, session,
  } = props;
  const query = new URLSearchParams(useLocation().search);
  const paymentSuccess = query.get('paymentSuccess');
  const setChatVisible = query.get('setChatVisible');
  const { graphSession } = session;
  const isPreparer = ['TAX_PREPARER', 'STAFF'].includes(graphSession.userRole);
  const {
    userId,
    firmAccountId,
    language,
    firstName,
    lastName,
  } = graphSession;
  const apolloClient = useApolloClient();

  const [refetchJobs, setRefetchJobs] = useState(paymentSuccess === 'true');
  const { data: customLayoutData } = useGetCustomLayoutsQuery();
  const { CustomLayout } = customLayoutData || {};
  const logo = CustomLayout?.logo;
  const nonCachedLogo = logo ? `${logo}?t=${new Date().getTime()}` : logo;
  const [channelId, setChannelId] = useState(undefined);
  const [channelIdLoading, setChannelIdLoading] = useState(false);

  const { data: taxYearData, loading: loadingJobs, refetch: refetchGetJobs } = useGetJobsByClientIdQuery({
    variables: {
      clientId: userId,
      firmAccountId,
    },
  });

  const [clientChannelMutation] = useClientChannelMutation({
    onError: (err) => {
      if (!isPreparer) {
        message.error(err.message);
      }
    },
    refetchQueries: [{query: GetUserChannelsDocument}],
  });

  const jobsData = taxYearData?.jobsByClientId;

  const { data: firmSettingsData } = useGetFirmSettingQuery({ variables: { name: 'disable-taxaroo-calendar' } });

  // for when jobs need to be hard refreshed i.e. stripe webhook
  useEffect(() => {
    if (refetchJobs) {
      // wait for the webhook to complete
      setTimeout(() => {
        // refetchGetJobs();
        apolloClient.resetStore();
        dispatch(resetInvoices());
        setRefetchJobs(false);
      }, 1500);
    }
  }, [refetchJobs]);

  const isUserLoggedIn = (graphSession && graphSession?.accessToken);
  const jobWithPreparerId = (jobsData ?? []).find((x) => !!x.preparedId);
  const preparerId = jobWithPreparerId?.preparedId ?? taxYearData?.FirmAccountById?.ownerId;

  const receiveChannelId = () => {
    setChannelIdLoading(true);
    clientChannelMutation({
      variables: {
        preparerId
      },
      onCompleted: (data) => {
        setChannelId(data?.ClientChannel?.id);
        setChannelIdLoading(false);
      },
      onError: (err) => {
        setChannelIdLoading(false);
      }
    });
  };

  if (!channelId && preparerId && !channelIdLoading) {
    receiveChannelId();
  }

  useEffect(() => {
    if (graphSession && !graphSession?.accessToken) {
      dispatch(setSession(null, ''));
    }
  }, [graphSession?.accessToken, dispatch]);


  useEffect(() => {
    const messageString = query.get('message');
    if (messageString) {
      message.success(messageString);
    }
  }, []);

  useEffect(() => {
    if (taxYearData && taxYearData?.jobsByClientId) {
      dispatch(setProjects(taxYearData.jobsByClientId));
    }
  }, [taxYearData]);

  const setProjectsFromChild = (newProjectValues) => {
    // console.log(projects, newProjectValues);
    dispatch(setProjects(
      projects?.some((p) => p.id === newProjectValues.id)
          ? projects.map((project) => {
              if (project.id === newProjectValues.id) {
                return {
                  ...project,
                  Answers: newProjectValues.Answers,
                  Interviews: newProjectValues.Interviews,
                }
              }
              return project;
            })
          : (projects || []).concat([newProjectValues])
    ));
  };

  return (
    <Page customStyles={CustomLayout}>
      {isPreparer && (
        <AntdAlert
          message={`
            You are logged in to the client portal with your firm login credentials.
            Please log in to the firm portal where you can then log in as your client or operate your firm dashboard.
          `}  
          type="info"
          showIcon
          closable
          style={{ maxWidth: '500px', margin: '0 auto' }}
        />
      )}

      <Header isUserLoggedIn={isUserLoggedIn} dispatch={dispatch} logo={nonCachedLogo} />
      
      {paymentSuccess && (
        <AntdAlert
          message="Payment Successful."
          type="success"
          showIcon
          closable
          style={{ maxWidth: '300px', margin: '15px auto' }}
        />
      )}
            
      <Route
        exact
        path="/"
        render={() => (
          <>
            {isUserLoggedIn && <UpcomingAppointments userId={userId} />}
            <PageTitle customBranding={CustomLayout} />
            {loadingJobs
              ? <ProjectCardLoading />
              : (
                <Projects 
                  client={graphSession}
                  jobs={jobsData}
                  channelId={channelId}
                  disableTaxarooCalendar={firmSettingsData?.getFirmSetting?.value.boolValue ?? false}
                />
            )}
          </>
        )}
      />
      <Route
        path="/add-project"
        render={() => (
          <>
            <AddProject
              title={<Translation text="dashboard.addProject.title" />}
              size="small"
              preparerId={jobWithPreparerId ? jobWithPreparerId.preparedId : null}
            />
          </>
        )}
      />
      <Route
        path="/settings"
        render={() => (
          <>
            <Settings userId={userId} dispatch={dispatch} />
          </>
        )}
      />
      <Route
        path="/add-review/:projectId"
        render={() => (
          <>
            <AddReview
              title={<Translation text="dashboard.addReview.title" />}
              size="small"
            />
          </>
        )}
      />
      <Route
        path="/interview/:projectId"
        render={() => (
          <InterviewSectionList
            projects={projects}
            setProjectsFromChild={setProjectsFromChild}
          />
        )}
      />
      <Route
        path="/documents/:projectId"
        render={() => (
          <Documents
            title={<Translation text="dashboard.documents.title" />}
            size="large"
          />
        )}
      />
      {/* todo: were we using this? */}
      {/* <Route
        path="/signatures/:projectId"
        render={() => (
          <Documents
            title={<Translation text="dashboard.documents.title" />}
            size="large"
            shouldBeFromFirm
          />
        )}
      /> */}

      {channels && (
        <Route
          path="/payments/:projectId"
          render={() => (
            <Invoices
              title={<Translation text="dashboard.invoices.title" />}
              size="medium"
              setProjectsFromChild={setProjectsFromChild}
            />
          )}
        />
      )}

      {isUserLoggedIn && !firmSettingsData?.getFirmSetting?.value.boolValue && (
        <Route
          path="/schedule/appointment/:channelName"
          render={(ScheduleModalProps) => (
            <ScheduleModal history={history}>
              <ScheduleCall
                // eslint-disable-next-line react/prop-types
                // channelName={ScheduleModalProps.match.params.channelName}
                evenTypesUser={preparerId}
                userId={preparerId}
                organizerId={userId}
                organizerName={`${firstName} ${lastName}`}
                closeSchedule={() => {
                  history.push('/');
                }}
                onSendMessage={() => { }}
              />
            </ScheduleModal>
          )}
        />
      )}

      <Alert stack={{ limit: 3 }} timeout={3000} />
      {isUserLoggedIn && channelId && (
        <ClientChat
          taxPro={preparerId}
          userId={userId}
          dispatch={dispatch}
          disableTaxarooCalendar={firmSettingsData?.getFirmSetting?.value.boolValue ?? false}
          setChatVisible={setChatVisible}
        />
      )}
    </Page>
  );
};

export default withTranslation()(
  withRouter(connect(mapStateToProps)(Dashboard)),
);
