import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge, Skeleton, message } from 'antd';
import useMultiChannels from '../../hooks/useMultiChannels';
import ChatButton from '../../atoms/ChatButton';
import Chat from '../Chat';
import styles from './style.module.css';
import i18n from 'i18next';

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

const ClientChat = ({
  taxPro,
  userId,
  dispatch,
  disableTaxarooCalendar,
  setChatVisible,
}) => {
  const [visible, setVisible] = useState(setChatVisible);
  const [badgeCount, setBadgeCount] = useState(null);
  const [currentChannelId, setCurrentChannelId] = useState(0);

  const [
     channels,
     messages,
     idLastMessage,
     readHistory,
     incomingCall,
     setIncomingCall,
     firmList,
     getMoreMessages,
     loadingMoreMessages,
  ] = useMultiChannels(userId, currentChannelId, visible, setCurrentChannelId, dispatch);

  const playAudio = () => {
    const audioEl = document.getElementsByClassName('audio-element')[0];
    audioEl.play();
  };

  useEffect(() => {
    if (channels.length && channels[0]?.id) {
      let totalMsg = 0;
      channels.forEach(
        // eslint-disable-next-line no-return-assign
        (ch) => (totalMsg += messages[ch.id].filter(
          (e) => !e.readDate && userId !== e.userId,
        ).length),
      );
      if (idLastMessage && totalMsg && badgeCount < totalMsg) {
        playAudio();
      }
      setBadgeCount(totalMsg || '');
    }
    // eslint-disable-next-line
  }, [channels, messages, idLastMessage]);

  useEffect(() => {
    if (!currentChannelId || !visible) {
      return;
    }  
    readHistory(currentChannelId);
    // eslint-disable-next-line
  }, [currentChannelId]);

  const clickHandler = () => {
    if (currentChannelId === undefined) {
      message.error(i18n.t(`chat.channelNotSetup`));
      return
    }

    setVisible(!visible);
    // eslint-disable-next-line no-unused-expressions
    channels.length && setCurrentChannelId(channels[0].id);
    // eslint-disable-next-line no-unused-expressions
    channels.length && readHistory(channels[0].id);
  };

  return (
    <div className={styles.container}>
      <audio className="audio-element">
        <source src="../swiftly.mp3" />
        <track kind="captions" />
      </audio>
      {currentChannelId ? (
        <Chat
          setVisible={setVisible}
          taxPro={taxPro}
          userId={userId}
          visible={visible || false}
          incomingCall={incomingCall}
          setIncomingCall={setIncomingCall}
          firmList={firmList}
          setCurrentChannelId={setCurrentChannelId}
          channelInfo={channels?.find((ch) => ch?.id === currentChannelId)}
          messages={messages}
          newMessage={idLastMessage}
          disableTaxarooCalendar={disableTaxarooCalendar}
          getMoreMessages={getMoreMessages}
          loadingMoreMessages={loadingMoreMessages}
        />
      ) : (
        <div className={`${styles.containerLoad} ${visible && styles.visible}`}>
          <Skeleton active avatar />
          <Skeleton active />
          <Skeleton active />
        </div>
      )}
      <Badge
        count={badgeCount}
        className={styles.badge}
        placement="start"
        style={{ marginRight: '45px' }}
      >
        {' '}
        <ChatButton onClick={clickHandler} />
      </Badge>
    </div>
  );
};

ClientChat.defaultProps = {
  taxPro: null,
};

ClientChat.propTypes = {
  taxPro: PropTypes.oneOfType([
    PropTypes.string,
    null,
  ]),
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ClientChat);
