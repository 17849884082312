import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import Translation from '../../atoms/Translation';
import statuses from './status';

// import { getUpcomingAppointments } from '../../../redux/actions/appointments';
import { utcToCustomTime } from '../../../utils/utcToCustomTime';
import { ApolloError } from '@apollo/client';

import './styles.css';
import styles from './styles.module.css';
import { getTimeZone } from '../../../helpers/time';
import { useMyEventsQuery, MyEventsDocument } from "../../../graphql/queries/appointments";
import { useCancelEventMutation } from "../../../graphql/mutations/appointments";
import { Popconfirm, message } from 'antd';
// import { message } from 'taxaroo-ui'

const UpcomingAppointments = ({ appointments, userId, dispatch }) => {
  // const { upcomingAppointments } = appointments;
  // const query = new URLSearchParams(useLocation().search);
  // const action = query.get('action');

  /* useEffect(() => {
    if (action === 'appointmenttrue' || action === 'appointmentfalse') {
      // update the appointments in redux
      dispatch(getUpcomingAppointments(userId, new Date().toJSON()));
    }
  }, []); */

  const { data: upcomingAppointments } = useMyEventsQuery();

  // useEffect(() => {
  //   if (!appointments.upcomingAppointments.length && userId) {
  //     dispatch(getUpcomingAppointments(userId, new Date().toJSON()));
  //   }
  // }, [appointments.upcomingAppointments, userId, action]);

  const [cancelEventMutation, { loading: loadingCancelEvent }] = useCancelEventMutation({
    onError: (err) => {
      message.error(err.message, 10);
    },
    onCompleted: () => message.success('Appointment Cancelled'),
    refetchQueries: [MyEventsDocument],
    awaitRefetchQueries: true,
  });

  const handleCancelAppointment = async (upcomingAppointmentId) => {
    await cancelEventMutation({ variables: { id: upcomingAppointmentId } })
  }

  if (!upcomingAppointments?.MyEvents.length) return <></>;

  return (
    <div className="upcomingAppointments--container">
      <div className={styles.card}>
        <div>
          <span className={styles.title}>
            <Translation text="upcomingAppointments.title" />
          </span>
        </div>
        <div className={styles.appointmentsListContainer}>
          <>
            {upcomingAppointments.MyEvents.map((upcomingAppointment) => (
              <div
                className={styles.appointment}
                key={upcomingAppointment.id}
              >
                {upcomingAppointment.title && (
                  <h3>{upcomingAppointment.EventTypes.name}</h3>
                )}
                <p className={styles.key}>
                  <span className={styles.keyTitle}>
                    <Translation text="upcomingAppointments.status" />
                    :
                    {' '}
                  </span>
                  <span className={styles.value}>
                    <Translation
                      text={statuses[upcomingAppointment.status]}
                    />
                  </span>
                </p>
                {upcomingAppointment.type
                  && (
                    <p className={styles.key}>
                      <span className={styles.keyTitle}>
                        <Translation text="upcomingAppointments.type" />
                        :
                        {' '}
                      </span>
                      <span className={styles.value}>
                        <Translation text={`upcomingAppointments.type.${upcomingAppointment.type}`} />
                      </span>
                    </p>
                  )}
                <p className={styles.key}>
                  <span className={styles.keyTitle}>
                    <Translation text="upcomingAppointments.starts" />
                    :
                    {' '}
                  </span>
                  <span className={styles.value}>
                    {utcToCustomTime(upcomingAppointment.startDate, false)}
                    {' '}
                    (
                    {getTimeZone(upcomingAppointment.startDate).short}
                    )
                  </span>
                </p>
                <p className={styles.key}>
                  <span className={styles.keyTitle}>
                    <Translation text="upcomingAppointments.ends" />
                    :
                    {' '}
                  </span>
                  <span className={styles.value}>
                    {utcToCustomTime(upcomingAppointment.endDate, false)}
                    {' '}
                    (
                    {getTimeZone(upcomingAppointment.endDate).short}
                    )
                  </span>
                </p>
                {upcomingAppointment.notes && (
                  <p className={styles.key}>
                    <span className={styles.keyTitle}>
                      <Translation text="upcomingAppointments.notes" />
                      :
                      {' '}
                    </span>
                    <span className={styles.valueNotes}>
                      {upcomingAppointment.notes}
                    </span>
                  </p>
                )}
                {(upcomingAppointment.status !== 'CANCELED') && (!upcomingAppointment.type
                  || upcomingAppointment.type === 'VIDEO')
                  && (
                    <div className={styles.btnBlock}>
                      <a
                        rel="noopener noreferrer"
                        className={styles.join}
                        href={`/videocall/${upcomingAppointment.Meetings.id}`}
                      >
                        <Translation text="upcomingAppointments.join" />
                      </a>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => handleCancelAppointment(upcomingAppointment.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <p
                          className={styles.cancel}
                        >
                          <Translation text="upcomingAppointments.cancel" />
                        </p>
                      </Popconfirm>
                    </div>
                  )}
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ appointments }) => ({
  appointments,
});

UpcomingAppointments.propTypes = {
  appointments: PropTypes.shape().isRequired,
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UpcomingAppointments);
