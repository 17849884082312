import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['interview', 'projects'],
};

const middleware = [thunk];

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, process.env.REACT_APP_ENV !== 'production' ? enhancer : applyMiddleware(...middleware));
const persistor = persistStore(store);

const reduxStore = {
  store,
  persistor,
};

export default reduxStore;
