import {
  Alert, Button, Card, Form, Input,
} from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Message } from '../../helpers/forms';
import styles from './style.module.scss';
import { useCreatePasswordResetByEmailMutation } from '../../../graphql/mutations/passwordReset';

const { Item } = Form;
const ForgotPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // graphql
  // mutation to create password reset
  const [createPasswordReset, { loading: loadingCreatePasswordReset }] = useCreatePasswordResetByEmailMutation({
    onError: (err) => {
      let errMessage;
      if (err.graphQLErrors?.at(0)?.name === 'CannotFindRecordException') {
        errMessage = 'Username/email not registered';
      } else if (err.graphQLErrors?.at(0)?.message === 'Error code 1001') { 
        errMessage = 'Email address not found';
      } else {
        errMessage = err.message;
      }
      Message(errMessage, 'error');
    },
    onCompleted: () => {
      Message('Password reset link sent.', 'success');
    },
  });
  
  const formik = useFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    initialValues: {
      email: urlParams.get('email') || '',
    },
    onSubmit: async (data) => {
      try {
        await createPasswordReset({
          variables: {
            email: data.email,
          },
        });
      } catch ({ response }) {
        Message(response?.data?.message, 'error');
      }
    },
  });

  const {
    handleSubmit, handleChange, values, errors, touched,
  } = formik;

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Form onFinish={handleSubmit} className={styles.cardBody}>
          <h2>Forgot Your Password?</h2>
          <p>
            Enter your email address below and we&apos;ll send you a secure
            reset link
          </p>
          <Item
            validateStatus={touched.email && errors.email && 'error'}
            help={
              touched.email
              && errors.email && <Alert message={errors.email} type="error" />
            }
          >
            <Input
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
              value={values.email}
            />
          </Item>

          <Button
            className={styles.btn}
            type="primary"
            size="large"
            htmlType="submit"
            loading={loadingCreatePasswordReset}
            block
          >
            Request Password Reset
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
