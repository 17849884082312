export const getFirmName = (firm) => {
  if (firm.name) {
    return firm.name;
  }
  
  const userInfo = firm.Users?.UserInformation;
  if (userInfo) {
    return `${userInfo.firstName} ${userInfo.lastName}`;
  }

  return null;
}
