import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Page from '../components/Layout/Page';
import Header from '../components/Layout/Header';
import Call from '../components/Pages/Call';
import { useGetCustomLayoutsQuery } from "../graphql/queries/settings";

const VideCallContainer = (props) => {
  const { meetingName } = useParams();
  const { data: customLayoutData } = useGetCustomLayoutsQuery();
  
  const {
    session: {
      graphSession: { userId, accessToken, entityId }
    },
    dispatch,
  } = props;

  // console.log("VideCallContainer", props, userId);

  return (
    <Page customStyles={customLayoutData?.CustomLayout || {}}>
      <Header
        isUserLoggedIn={Boolean(accessToken)}
        dispatch={dispatch}
        logo={customLayoutData?.CustomLayout?.logo || undefined}
        isTaxPro={entityId === null}
      />
      <Call meetingId={meetingName} userId={userId} />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
  session: state.session,
});

export default connect(mapStateToProps)(VideCallContainer);
