import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearSession } from '../../../redux/actions/session';

const PreviousUser = ({ session, dispatch }) => {
  useEffect(() => {
    const deleteSession = async () => {
      await dispatch(clearSession());
    };
    deleteSession();
  }, []);

  useEffect(() => {
    if (!session.graphSession) {
      const previousUser = localStorage.getItem('previousUser');
      localStorage.removeItem('previousUser');
      window.location.replace(previousUser);
    }
  }, [session]);
  return (<></>);
};

PreviousUser.propTypes = {
  session: PropTypes.shape({
    user: PropTypes.shape({}),
    accessToken: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(PreviousUser);
