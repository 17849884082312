import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { AudioMutedOutlined, UserOutlined } from '@ant-design/icons';
import styles from './style.module.css';

const PlaceHolderVideo = ({
  small, muted, name, className,
}) => {
  let displayInitials = name.charAt(0).toUpperCase();
  if (name.split(' ')[1]) displayInitials += name.split(' ')[1].charAt(0)?.toUpperCase();
  return (
    <div className={className}>
      {muted && <AudioMutedOutlined className={styles.icon} />}
      <Avatar
        className={small ? styles.avatarsmall : styles.avatar}
        icon={name === '' && <UserOutlined />}
      >
        {displayInitials}
      </Avatar>
    </div>
  );
};

PlaceHolderVideo.defaultProps = {
  name: '',
  className: '',
  small: false,
  muted: false,
};

PlaceHolderVideo.propTypes = {
  small: PropTypes.bool,
  muted: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default PlaceHolderVideo;
