import { FC, useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Space,
  Input,
  Switch,
  message,
  Layout,
} from 'antd';
import styles from './style.module.css';
import Button from 'react-bootstrap/Button';
import Translation from '../../../atoms/Translation'
import { useGetOtpBooleanQuery, useGenerateOtpQuery, GetOtpBooleanDocument } from '../../../../graphql/queries/settings'
import { useDeleteTwoStepAuthFromUserMutation, useValidateOtpMutation } from '../../../../graphql/mutations/settings'

const { Paragraph, Text } = Typography;

const Security = () => {
  const [code, setCode] = useState('')
  const [switch2FAValue, setSwitch2FAValue] = useState(undefined);
  const [twoStepAuthChanged, setTwoStepAuthChanged] = useState(false);
  const [twoStepValueToChange, setTwoStepValueToChange] = useState(undefined);
  const { data, loading } = useGenerateOtpQuery();

  // Get OTP existance
  const {
    data: OTPBooleanData,
    loading: loadingGetOTPBooleanData
  } = useGetOtpBooleanQuery();

  const copyToClipboard = () => navigator.clipboard.writeText(data.generateOTP.key);

  const [validateOtpMutation, { loading: loadingValidateOTP }] = useValidateOtpMutation({
    onCompleted: () => {
      message.success('Enabled two step auth');
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    },
    onError: () => message.info('Please provide a valid code'),
    variables: {
      validateOtp: {
        code,
        secretOTP: data?.generateOTP?.key,
      },
    },
    refetchQueries: [
      {
        query: GetOtpBooleanDocument
      },
    ],
    awaitRefetchQueries: true
  });

  const [
    deleteTwoStepFromUser,
    { loading: loadingDeleteTwoStepFromUser }
  ] = useDeleteTwoStepAuthFromUserMutation({
    onCompleted: () => {
      message.success('Disabled two step auth');
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    },
    onError: () => message.info('Please provide a valid code'),
    variables: {
      validateOtp: {
        code
      }
    },
    refetchQueries: [{
      query: GetOtpBooleanDocument
    }],
    awaitRefetchQueries: true
  })

  // Set initial switch value and listen for mutations to update the original value
  useEffect(() => {
    if (OTPBooleanData) {
      const success = OTPBooleanData?.GetOTPBoolean.success;
      setSwitch2FAValue(success);
    }
  }, [OTPBooleanData]);

  const twoStepAuthEnabledSwitchOnChange = (enabled) => {
    setSwitch2FAValue(enabled);
    if (OTPBooleanData?.GetOTPBoolean.success !== enabled) {
      setTwoStepAuthChanged(true);
      setTwoStepValueToChange(!OTPBooleanData?.GetOTPBoolean.success);
    }
    if (OTPBooleanData?.GetOTPBoolean.success === enabled) {
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    }
  };

  const handleOTPOnSaveChanges = () => {
    if (twoStepValueToChange === true || twoStepValueToChange === false) {
      if (twoStepValueToChange === true) {
        validateOtpMutation();
      }
      if (twoStepValueToChange === false) {
        deleteTwoStepFromUser();
      }
    } else {
      message.info('No changes to save');
    }
  };

  return (
    <div className="card p-4 container my-3">
      <h2>
        <Translation text="settings.twoFactorAuthentication" />
      </h2>
      <hr style={{ margin: 0 }} />
      <div>
        <Layout className={styles.container} style={{background: 'white', margin: '1rem 0'}}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={2} className={styles.marginTop18}>
              <Col md={20} xs={12}>
                <Text>
                  <Translation text="settings.twofa.enable" />
                </Text>
              </Col>
              <Col md={4} xs={12}>
                <Switch
                  onChange={twoStepAuthEnabledSwitchOnChange}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={switch2FAValue}
                  disabled={loadingGetOTPBooleanData}
                />
              </Col>
            </Row>
            
            {twoStepAuthChanged && twoStepValueToChange === true && (
              <>
                <div className={styles.marginTop18}>
                  <Translation text="settings.twofa.scan" />
                  <br />
                  <img
                    className={styles.square}
                    src={
                      `data:image/svg+xml;utf8,${data.generateOTP.codeQR}`
                    }
                    alt="QR Code"
                  />
                  <br />
                  <Translation text="settings.twofa.getKey1" />
                  {' '}
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      copyToClipboard();
                      message.info('Copied to clipboard');
                    }}
                  >
                    <Translation text="settings.twofa.getKey2" />
                  </Button>
                  {' '}
                  <Translation text="settings.twofa.getKey3" />
                </div>
                <Row gutter={2} className={styles.marginTop18}>
                  <Translation text="settings.twofa.enterCode" />
                </Row>
                <Row>
                  <Input
                    style={{width: '10rem'}}
                    size="large"
                    value={code}
                    onPressEnter={handleOTPOnSaveChanges}
                    maxLength={6}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </Row>
              </>
            )}

            {twoStepAuthChanged && twoStepValueToChange === false && (
              <>
                <Row gutter={2} className={styles.marginTop18}>
                  <Text>
                    To disable the two-step authentication.
                    Please enter the six-digit code from your app.
                  </Text>
                </Row>
                <Row>  
                  <Input
                    style={{width: '10rem'}}
                    size="large"
                    placeholder="Six Digit Code"
                    value={code}
                    onPressEnter={handleOTPOnSaveChanges}
                    maxLength={6}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </Row>
              </>
            )}

            <div className={styles.buttonDiv}>
              <Button
                variant="primary"
                type="submit"
                disabled={!twoStepAuthChanged}
                onClick={() => {
                  handleOTPOnSaveChanges();
                }}
              >
                <Translation text="settings.twofa.saveChanges" />
              </Button>
            </div>
          </Space>
        </Layout>
      </div>
    </div>    
  );
};

export default Security;
