import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <path fill={fill} fillRule="nonzero" d="M8.016 0h2.968c.494 0 .891.397.891.89v6.235h3.254c.661 0 .991.798.524 1.265l-5.645 5.648a.719.719 0 0 1-1.013 0L3.344 8.39a.74.74 0 0 1 .523-1.265h3.258V.891c0-.494.397-.891.89-.891zM19 13.953v4.156c0 .494-.397.891-.89.891H.89a.888.888 0 0 1-.89-.89v-4.157c0-.493.397-.89.89-.89h5.445l1.818 1.818a1.902 1.902 0 0 0 2.694 0l1.818-1.819h5.444c.494 0 .891.398.891.891zm-4.602 3.266a.744.744 0 0 0-.742-.742.744.744 0 0 0-.742.742c0 .408.334.742.742.742a.744.744 0 0 0 .742-.742zm2.375 0a.744.744 0 0 0-.742-.742.744.744 0 0 0-.742.742c0 .408.334.742.742.742a.744.744 0 0 0 .742-.742z" />
  </svg>

);

Download.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default Download;
