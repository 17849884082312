import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserSwitchOutlined } from '@ant-design/icons';
import {
  Button, List, Avatar, Popover, Badge,
} from 'antd';
import styles from './style.module.css';
import toolTipWrapper from '../CustomToolTip';
import API from '../../../api';
import './style.css';

const getAcronim = (str) => {
  if (!str) return '';
  const names = str.trim().split(' ');
  return `${names[0].charAt()}${names[1].charAt()}`.toUpperCase();
};

const ItemList = ({ item, setCurrentChannelId, setVisibilityFirmList }) => {
  const [avatar, setAvatar] = useState('');

  const getLink = async (key) => {
    if (!key) return '';
    try {
      const { data } = await API.get(`/files/view/${key}`);
      setAvatar(data.url);
      return key;
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (item.avatar) {
      getLink(item.avatar);
    }
  }, [item.avatar]);

  return (
    <List.Item>
      <List.Item.Meta
        avatar={(
          <Badge
            count={item.unreadMsgCount}
            className={styles.badge}
            placement="start"
            style={{ marginRight: '45px' }}
          >
            <Avatar style={{ backgroundColor: '#22A7E7' }} src={avatar}>
              {getAcronim(item.name)}
            </Avatar>
          </Badge>
      )}
        title={item.name}
        onClick={() => {
          setCurrentChannelId(item.channelId);
          setVisibilityFirmList(false);
        }}
        description={item.online ? 'Online' : 'Offline'}
      />
    </List.Item>
  );
};

ItemList.propTypes = {
  setCurrentChannelId: PropTypes.bool.isRequired,
  setVisibilityFirmList: PropTypes.func.isRequired,
  item: PropTypes.shape({
    unreadMsgCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    channelId: PropTypes.number.isRequired,
    status: PropTypes.bool.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
};

const FirmListChat = ({
  firmList,
  setCurrentChannelId,
  isShowFirmList,
  setVisibilityFirmList,
}) => (
  <>
    <Popover
      content={(
        <List
          itemLayout="horizontal"
          dataSource={firmList}
          size="large"
          bordered
          renderItem={(item) => (
            <ItemList
              item={item}
              setCurrentChannelId={setCurrentChannelId}
              setVisibilityFirmList={setVisibilityFirmList}
            />
          )}
        />
      )}
      title="Contacts"
      trigger="click"
      visible={isShowFirmList}
      onVisibleChange={() => firmList.length > 1 && setVisibilityFirmList(!isShowFirmList)}
      placement="bottomRight"
      overlayClassName="popoverHelper"
    >
      {toolTipWrapper(
        <Button
          className={`${styles.btn} ${firmList.length < 2 && styles.disabled}`}
          onClick={() => firmList.length > 1 && setVisibilityFirmList(!isShowFirmList)}
          size="small"
          type="default"
          shape="circle"
          disabled={firmList.length < 2}
          icon={<UserSwitchOutlined />}
        />,
        true,
        'Contacts',
      )}
    </Popover>
  </>
);

FirmListChat.defaultProps = {
  firmList: [],
};

FirmListChat.propTypes = {
  firmList: PropTypes.arrayOf(PropTypes.object),
  setCurrentChannelId: PropTypes.func.isRequired,
  isShowFirmList: PropTypes.bool.isRequired,
  setVisibilityFirmList: PropTypes.func.isRequired,
};

export default FirmListChat;
