/* eslint-disable */
import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { Icon } from "../Icon/Icon";
import Button from "../Button/Button";
import { closeInvoiceView, showPayment } from "../../redux/actions/invoices";
import { color, grey } from "../../styles/colors";
import { heading } from "../../styles/fonts";
import { jsPDF } from "jspdf";
import { useGetCustomLayoutsQuery } from "../../graphql/queries/settings";
import { useGetFirmLogoLazyQuery, useGetPreparerWithTaxYearInterviewQuery } from "../../graphql/queries/payments";
import { message, Spin, Row } from "antd";
import { getFirmName } from "../../utils/firms";
import { getFullName } from "../../utils/getFullName";
import { isMobileView } from "../../utils/screen";
import { useGetTaxYearInterviewIncludeInterviewsLazyQuery } from '../../graphql/queries/taxYearInterviews';

class InvoiceItem extends React.Component {
  render() {
    const { item, lastItem } = this.props;
    return (
      <div className={css(table.row, lastItem && table.rowLast)}>
        <div className={css(table.cell, table.cellFirst)}>
          {item.description}
        </div>
        <div className={css(table.cell)}>${item.priceEach.toFixed(2)}</div>
        <div className={css(table.cell)}>{item.quantity}</div>
        <div className={css(table.cell, table.cellLast)}>
          ${(item.priceEach * item.quantity).toFixed(2)}
        </div>
      </div>
    );
  }
}

const InvoiceTable = (props) => {
  const { invoice, preparer, totalDollarAmount } = props;

  if (!preparer) return <></>;

  return (
    <div>
      <div>
        <div>
          <p className={css(styles.businessPhone)}>
            <b>{preparer?.UserInformation?.firstName} {preparer?.UserInformation?.lastName}</b>
          </p>
        </div>
        <div>
          <p className={css(styles.businessAddress)}>
            <span className={css(styles.street)}>
              {preparer?.FirmAccount?.Address?.[0]?.lineOne}
            </span>
          </p>
          <p className={css(styles.businessAddress)}>
            <span className={css(styles.street)}>
              {preparer?.FirmAccount?.Address?.[0]?.lineTwo}
            </span>
          </p>
          <p className={css(styles.businessAddress)}>
            <span className={css(styles.street)}>
              {preparer?.FirmAccount?.Address?.[0]?.city && `${preparer?.FirmAccount?.Address?.[0]?.city}, ${preparer?.FirmAccount?.Address?.[0]?.state}`}
              {preparer?.FirmAccount?.Address?.[0]?.zip}
            </span>
          </p>
        </div>
        <div>
          <p className={css(styles.businessPhone)}>
            <span className={css(styles.street)}>
              {preparer?.FirmAccount?.Phones?.[0]?.value?.replace("+1", "")}
            </span>
          </p>
        </div>
      </div>
      <div className={css(table.header)}>
        <div className={css(table.headerCell, table.headerCellFirst)}>
          Description
        </div>
        <div className={css(table.headerCell)}>Price per</div>
        <div className={css(table.headerCell)}>Quantity</div>
        <div className={css(table.headerCell, table.headerCellLast)}>
          Item Total
        </div>
      </div>

      {invoice && invoice.Invoices.map((item, i, arr) => (
        <InvoiceItem key={i} item={item} lastItem={i === arr.length - 1} />
      ))}
      {invoice && (
        <div className={css(table.total)}>
          <span className={css(table.totalTitle)}>Total:</span>
          <span className={css(table.totalValue)}>
            ${totalDollarAmount.toFixed(2)}
          </span>
        </div>
      )}
    </div>
  );
}

const InvoiceView = (props) => {
  const { invoice, dispatch, projects, setProjectsFromChild } = props;

  const [logoDataURI, setLogoDataURI] = useState(null);
  const [getFirmLogo] = useGetFirmLogoLazyQuery();

  const taxYearInterview = projects?.find((x) => x?.id === invoice?.taxYearInterviewId) ?? { Answers: [], Interviews: { Sections: [] } };
  const interview = taxYearInterview?.Interviews ?? { Sections: [] };

  // graphql
  // get logo
  const { data: customLayoutData } = useGetCustomLayoutsQuery();
  const customLayout = customLayoutData?.CustomLayout || {};
  const { logo } = customLayout;
  // get preparer data
  const { data: preparerData, loading: loadingPreparerData } = useGetPreparerWithTaxYearInterviewQuery({
    variables: {
      taxYearInterviewId: invoice?.taxYearInterviewId,
      firmAccountId: invoice?.firmAccountId,
    },
    onError: (error) => {
      message.error(error.message);
    }
  });

  const [getInterview, { loading: interviewDataLoading }] = useGetTaxYearInterviewIncludeInterviewsLazyQuery({
    onError: (error) => message.error(error.message)
  });

  const preparer = preparerData?.taxYearInterviewIncludePreparer?.PreparedUser || {};
  const totalDollarAmount = invoice?.totalDollarAmount;

  useEffect(() => {
    if (!projects) return;

    if (!interview.Sections || !interview?.Sections?.length) {
      loadInterviewAsync();
    }
  }, [projects])

  const loadInterviewAsync = async () => {
    const result = await getInterview({
      variables: {
        id: invoice?.taxYearInterviewId,
      },
    });

    const interviewResult = result?.data?.GetTaxYearInterviewIncludeInterviews;
    if (interviewResult?.id) {
      setProjectsFromChild(interviewResult);
    }
  };

  useEffect(() => {
    if (logo) {
      fetch(logo, { mode: 'no-cors' })
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          // todo: this is not working
          // console.log('setting logoDataURI', reader.readAsDataURL(blob));
          reader.onloadend = () => {
            setLogoDataURI(reader.readAsDataURL(blob));
          };
        });
    }
  }, [logo]);

  const hasItems = (invoice) => {
    return invoice && invoice.Invoices && invoice.Invoices.length > 0;
  };

  const onClose = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(closeInvoiceView());
    }
  };

  const onOpenPayment = () => {
    dispatch(showPayment(invoice));
  };

  const getInterviewAnswer = (section, questionDescription, answers) => {
    const question = section.Questions?.find((q) => q.Translations?.some((t) => t.description === questionDescription));
    const answer = question ? answers.find((a) => a.questionId === question.id) : null;
    return answer?.description;
  };

  const extractClientAddressFromInterview = (taxYearInterviewId) => {
    const projectData = projects?.find((x) => x?.id === taxYearInterviewId);
    const interview = projectData?.Interviews;
    const answers = projectData?.Answers;
    
    const clientSection = interview?.Sections?.find((s) => s.Translations?.some((t) => t.description === 'Basic Personal Information'));
    if (clientSection) {
      const lineOne = getInterviewAnswer(clientSection, 'Address Line 1', answers);
      const lineTwo = getInterviewAnswer(clientSection, 'Address Line 2', answers);
      const city = getInterviewAnswer(clientSection, 'City', answers);
      const state = getInterviewAnswer(clientSection, 'State/Province/Region', answers);
      const zip = getInterviewAnswer(clientSection, 'ZIP/Postal Code', answers);
      return { lineOne, lineTwo, city, state, zip };
    }

    const businessSection = interview?.Sections?.find((s) => s.Translations?.some((t) => t.description === 'Business Information'));
    if (businessSection) {
      const lineOne = getInterviewAnswer(businessSection, 'Address Line 1', answers);
      const lineTwo = getInterviewAnswer(businessSection, 'Address Line 2', answers);
      const city = getInterviewAnswer(businessSection, 'City', answers);
      const state = getInterviewAnswer(businessSection, 'State/Province/Region', answers);
      const zip = getInterviewAnswer(businessSection, 'ZIP/Postal Code', answers);
      return { lineOne, lineTwo, city, state, zip };
    }

    return null;
  };

  const combineLines = (line1, line2) => (
    `${line1 ?? ''}${line1 && line2 ? ', ' : ''}${line2 ?? ''}`
  );

  const combineAddress = (city, state, zip) => (
    `${city?.trim() ?? ''}${city && state ? ', ' : ''}${state?.trim() ?? ''}${(city || state) && zip ? ' ' : ''}${zip?.trim() ?? ''}`
  );

  const download = async (invoice, totalDollarAmount) => {
    const PDF_LOGO_WIDTH = 60;
    const PDF_LOGO_HEIGHT = 30;

    const firm = invoice?.FirmAccount;

    const { data: logoData } = await getFirmLogo({
      variables: { id: firm?.id },
    });

    const firmName = getFirmName(firm);
    const firmUserInfo = firm?.Users?.UserInformation;
    const firmAddress = firm?.BusinessInformation?.Address?.[0] ?? firmUserInfo?.Address?.[0];
    const firmAddressLine = combineLines(firmAddress?.lineOne, firmAddress?.lineTwo);
    const firmAddressTail = combineAddress(firmAddress?.city, firmAddress?.state, firmAddress?.zip);
    const firmPhone = firm?.BusinessInformation?.Phones?.[0]?.value ?? firm?.Users?.UserInformation?.Phones?.[0]?.value ?? '';

    const client = invoice?.Entity?.Users;
    const clientInformation = client?.UserInformation || {};
    const clientAddress = clientInformation?.Address?.[0] || extractClientAddressFromInterview(invoice.taxYearInterviewId);
    const clientAddressLine = combineLines(clientAddress?.lineOne, clientAddress?.lineTwo);
    const clientAddressTail = combineAddress(clientAddress?.city, clientAddress?.state, clientAddress?.zip);
    const clientPhone = clientInformation?.Phones?.[0].value ?? '';

    const doc = new jsPDF();

    const firmLogo = logoData?.GetFirmLogo;
    if (firmLogo) {
      const logoWidth = firmLogo?.width;
      const logoHeight = firmLogo?.height;
      const ratio = Math.min(PDF_LOGO_WIDTH / logoWidth, PDF_LOGO_HEIGHT / logoHeight);
      doc.addImage(firmLogo?.base64, 10, 15, logoWidth * ratio, logoHeight * ratio);
    }
    
    const date = new Date(invoice?.requestAt);
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const prettyDate = `${month}-${day}-${date.getFullYear()}`;

    const dy = 10; // line height
    let y = 10;
    doc.text(`${firmName || ''}`, 200, y += dy, { align: "right" });
    doc.text(firmAddressLine, 200, y += dy, { align: "right" });
    doc.text(firmAddressTail, 200, y += dy, { align: "right" });
    doc.text(firmPhone, 200, y += dy, { align: "right" });
    
    y += dy;
    doc.line(10, y, 205, y);
    
    doc.text(`${getFullName(clientInformation)}`, 10, y += dy);
    doc.text(clientAddressLine, 10, y += dy);
    doc.text(clientAddressTail, 10, y += dy);
    doc.text(clientPhone, 10, y += dy);
    
    y += dy * 0.5;
    doc.line(10, y, 205, y);
    
    {prettyDate && prettyDate !== "NaN-NaN-NaN" && doc.text(`Date: ${prettyDate}`, 10, y += dy);}

    y += dy * 3.5;
    doc.text("Description", 10, y);
    doc.text("Price Each", 90, y);
    doc.text("Quantity", 140, y);
    doc.text("Item total", 180, y);

    y += dy * 0.2;
    doc.line(10, y, 70, y);
    doc.line(85, y, 118, y);
    doc.line(130, y, 161, y);
    doc.line(170, y, 203, y);
    
    y += dy * 0.8;
    invoice.Invoices.forEach((el) => {
      doc.text(el.description, 10, y);
      doc.text(`$${el.priceEach}`, 118, y, { align: "right" });
      doc.text(String(el.quantity), 161, y, { align: "right" });
      doc.text(`$${el.priceEach * el.quantity}`, 203, y, {
        align: "right",
      });
      y += dy;
    });
    
    doc.line(10, y, 205, y);
    
    y += dy * 0.8;
    doc.text("Total", 150, y);
    doc.text(`$${totalDollarAmount?.toFixed(2)}`, 203, y, { align: "right" });
    doc.autoPrint();
    //This is a key for printing
    doc.output("dataurlnewwindow");
  } // end downlaod

  const printButton = (
    <Button
      label="Print"
      secondary
      onClick={() => download(invoice, totalDollarAmount)}
      style={{ minWidth: "155px", float: "left" }}
    />
  );

  const closeButton = (
    <Button
      label="Close"
      secondary
      onClick={onClose}
      style={{ minWidth: "155px" }}
    />
  );

  const payButton = (
    <Button
      label="Pay Invoice"
      blue
      onClick={onOpenPayment}
      style={{  minWidth: '155px', marginLeft: '18px' }}
    />
  );

  const canPay = !invoice?.completedAt && invoice?.status !== 'processing' && !invoice?.deletedAt;

  if (interviewDataLoading) {
    return (
      <Row justify="center">
        <Spin size="large"></Spin>
      </Row>
    )
  }

  return (
    <div className={css(styles.overlay)} onClick={onClose}>
      <div className={css(styles.modal)}>
        <div className={css(styles.title)}>
          <span className={css(styles.h2)}>View Invoice</span>
          {/* <div><Icon user /> {firm.firmName}</div> */}
          <div className={css(styles.blockLogoCompany)}>
          {logo &&
              <img
              src={logo}
              alt="Site Logo"
              className={css(styles.imageInvoiceCompany)}
            />
          }
          </div>
          <Icon exit onClick={onClose} />
        </div>

        {loadingPreparerData && <Spin />}

        {!hasItems(invoice) && <span>No items available.</span>}

        {hasItems(invoice) && !loadingPreparerData && (
          <>
            <InvoiceTable
              invoice={invoice}
              preparer={preparer}
              totalDollarAmount={totalDollarAmount}
            />
            {!isMobileView() && (
              <div className={css(styles.btnBlock)}>
                {printButton}
                {closeButton}
                {canPay && payButton}
              </div>  
            )}
            {isMobileView() && (
              <>
                <div className={css(styles.btnBlock)}>
                  {printButton}
                  {closeButton}
                </div>
                {canPay && (
                  <div className={css(styles.btnBlock2)}>
                    {payButton}
                  </div>
                )}  
              </>  
            )}
          </>
        )}
      </div>
    </div>
  );
}

const width = {
  first: "calc(100% - 380px)",
  second: "120px",
  third: "120px",
  last: "140px",
};

const screenSize = {
  smartphone: "@media (max-width: 550px)",
};

// const print = StyleSheet.create({
//   header: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     width: "100%",
//     borderTopLeftRadius: "8px",
//     borderTopRightRadius: "8px",
//   },
//   headerCell: {
//     fontSize: "15px",
//     textTransform: "none",
//     textAlign: "right",
//     width: width.second,
//   },
//   headerCellLast: {
//     textAlign: "right",
//     textTransform: "none",
//     paddingRight: "40px",
//     width: width.last,
//   },
//   headerCellFirst: {
//     textTransform: "none",
//     paddingLeft: "40px",
//     textAlign: "left",
//     width: width.first,
//   },
//   invoiceDateAndId: {
//     fontSize: "15px",
//     fontWeight: "bold",
//     margin: "0",
//   },
//   headerHr: {
//     backgroundColor: "black",
//   },
//   hr: {
//     backgroundColor: "black",
//     margin: "0",
//   },
//   blockDate: {
//     margin: "40px 0px",
//   },
//   headerAboutCompany: {
//     display: "inline-block",
//     position: "relative",
//     left: "80%",
//     top: "30px",
//     marginBottom: "10px",
//   },
//   row: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },
//   cell: {
//     textAlign: "right",
//     textTransform: "none",
//     fontWeight: "bold",
//     width: width.second,
//   },
//   cellFirst: {
//     textTransform: "none",
//     paddingLeft: "40px",
//     fontWeight: "bold",
//     textAlign: "left",
//     width: width.first,
//   },
//   cellLast: {
//     textAlign: "right",
//     textTransform: "none",
//     fontWeight: "bold",
//     paddingRight: "40px",
//     width: width.last,
//   },
//   total: {
//     padding: "10px 0px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//   },
//   totalTitle: { fontSize: "15px" },
//   totalValue: {
//     fontWeight: "bold",
//     marginLeft: "30px",
//     paddingRight: "40px",
//   },
// });

const table = StyleSheet.create({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: color.mattBlue,
  },
  headerCell: {
    padding: "15px 10px",
    color: color.white,
    ...heading.table,
    textAlign: "right",
    width: width.second,
    [screenSize.smartphone]: {
      width: "70px",
      fontSize: "9px",
      fontWeight: "400",
      whiteSpace: "nowrap",
    },
  },
  headerCellFirst: {
    paddingLeft: "40px",
    textAlign: "left",
    width: width.first,
    [screenSize.smartphone]: {
      paddingLeft: "15px",
      width: "calc(100% - 210px)",
      fontSize: "9px",
      fontWeight: "400",
    },
  },
  headerCellLast: {
    paddingRight: "40px",
    width: width.last,
    [screenSize.smartphone]: {
      paddingRight: "7px",
      width: "80px",
      fontSize: "9px",
      fontWeight: "400",
      whiteSpace: "nowrap",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "66px",
    marginTop: "2px",
    backgroundColor: grey["300"],
  },
  rowLast: { borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" },
  cell: {
    ...heading.h3,
    padding: "10px",
    textAlign: "right",
    textTransform: "none",
    width: width.second,
    [screenSize.smartphone]: {
      width: "70px",
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  cellFirst: {
    paddingLeft: "40px",
    textAlign: "left",
    width: width.first,
    [screenSize.smartphone]: {
      paddingLeft: "15px",
      width: "calc(100% - 210px)",
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  cellLast: {
    paddingRight: "40px",
    width: width.last,
    [screenSize.smartphone]: {
      paddingRight: "7px",
      width: "80px",
      fontSize: "12px",
      fontWeight: "bold",
    },
  },
  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "54px",
    marginTop: "4px",
    padding: "10px 40px",
    borderRadius: "8px",
    backgroundColor: grey["300"],
    [screenSize.smartphone]: { padding: "10px 7px" },
  },
  totalTitle: { ...heading.h3, fontSize: "13px" },
  totalValue: {
    marginLeft: "30px",
    ...heading.h3,
    letterSpacing: "1.3px",
    color: color.green,
  },
});

const styles = StyleSheet.create({
  blockLogoCompany: {
    position: "absolute",
    right: "10px",
    top: "1px"
  },
  addressCompanyPrint: {
    fontSize: "15px",
    fontWeight: "bold",
    display: "inline-block",
  },
  photoCompanyPrint: {
    fontSize: "15px",
    fontWeight: "bold",
    display: "inline-block",
  },
  imageLogoPrint: {
    width: "70px",
    height: "70px",
  },
  imageInvoiceCompany: {
    display: "inline-block",
    height: "76px",
    marginLeft: "10px",
  },
  businessAddress: {
    fontSize: "15px",
    verticalAlign: "middle",
    margin: 0,
  },

  businessPhone: {
    fontSize: "15px",
    display: "inline-block",
    verticalAlign: "middle",
    margin: 0,
  },

  street: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: 2,
  },

  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(159, 159, 159, 0.3)",
    overflowY: "auto",
    zIndex: "10",
  },
  modal: {
    position: "absolute",
    top: "40px",
    width: "100%",
    minHeight: "370px",
    marginBottom: "40px",
    padding: "30px 20px",
    backgroundColor: color.white,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    zIndex: "100",
    "@media (min-width: 400px)": {
      width: "calc(100% - 20px)",
      left: "10px",
      borderRadius: "5px",
    },
    "@media (min-width: 640px)": {
      top: "170px",
      width: "calc(100% - 40px)",
      minHeight: "100px",
      padding: "40px",
      left: "20px",
    },
    "@media (min-width: 768px)": {
      top: "110px",
      width: "720px",
      left: "calc(50% - 360px)",
    },
    "@media (min-width: 1024px)": {
      width: "820px",
      left: "calc(50% - 410px)",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
    position: "relative",
  },
  h2: {
    ...heading.modalHeading,
  },
  btnBlock: {
    marginTop: 36,
    textAlign: 'right',
  },
  btnBlock2: {
    marginTop: 18,
    textAlign: 'center',
  },
});

export default InvoiceView;