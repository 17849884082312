import { useState } from 'react';
import {
  Button, Alert, message, Row
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import EditSection from '../EditSection';
import { useTranslation } from 'react-i18next';

const DependentSection = ({
  section,
  taxYearInterviewId,
  answers,
  taxYearInterviewSections,
  goToNextSection,
}) => {
  const { t: translation } = useTranslation();

  const { SubSections } = section;
  const notEmptyAnswers = answers.filter((a) => a.description);
  const subsectionsWithData = SubSections
    .map((ss) => {
      const qIds = ss.Questions.map((q) => q.id);
      if (notEmptyAnswers.some((a) => qIds.includes(a.questionId))) {
        return ss;
      }
      return undefined;
    })
    .filter((val) => val);
  const [subsections, setSubsections] = useState(subsectionsWithData);

  const handleDeleteSection = async (id) => {
    setSubsections(subsections.filter((sub) => sub.id !== id));
  };

  const onAddDependentClick = async () => {
    if (subsections?.length >= 6) {
      message.error(translation('interview.dependentSection.maximumNumber'));
      return;
    }

    const section2Add = SubSections.find((ss) => !subsections.some((subs) => subs.id === ss.id));
    setSubsections([...subsections, section2Add]);
  };

  return (
    <div>
      {!subsections?.length && (
        <Alert
          showIcon
          type="info"
          message={translation('interview.dependentSection.dependents')}
          description={(
            <strong>
              {translation('interview.dependentSection.noDependents')}
            </strong>
          )}
        />
      )}
      <Row style={{ padding: "0 0 20px 0" }}>
        <Button
          icon={<PlusOutlined />}
          onClick={onAddDependentClick}
        >
          {translation('interview.dependentSection.addDependent')}
        </Button>
      </Row>
      <div>
        <EditSection
          jobId={taxYearInterviewId}
          section={{
            ...section,
            SubSections: [...subsections]
          }}
          allAnswers={answers}
          taxYearInterviewSections={taxYearInterviewSections}
          goToNextSection={goToNextSection}
          handleDeleteSection={handleDeleteSection}
        />
      </div>
    </div>
  );
};

export default DependentSection;
