import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import { CloseOutlined } from '@ant-design/icons';
import UserHeader from '../../atoms/UserHeader';
import ScheduleButton from '../../atoms/ScheduleButton';
import styles from './style.module.css';
import { usePlanAccess, Features } from '../../atoms/PlanAccessProvider';
import 'antd/dist/antd.min.css';

const ChatHeader = ({
  data, setVisible, setSchedule, schedule,
  Contact, disableTaxarooCalendar,
}) => {
  if (!data) return null;
  const {
    name, status, lastMessage, avatar,
  } = data;
  const { accessObject } = usePlanAccess();
  return (
    <div className={styles.container}>
      <div className={styles.containerUserInfo}>
        <UserHeader
          name={name}
          status={status}
          avatar={avatar}
          lastMessage={lastMessage}
          onClick={() => {}}
        />
        <div>
          <Contact />
          {!disableTaxarooCalendar && accessObject?.[Features.appointments] && <ScheduleButton onClick={() => setSchedule(!schedule)} />}
          <Button
            className={styles.closeBtn}
            onClick={() => setVisible(false)}
            size="small"
            type="default"
            shape="circle"
            icon={<CloseOutlined />}
          />
        </div>
      </div>
    </div>
  );
};

ChatHeader.propTypes = {
  data: PropTypes.shape().isRequired,
  setVisible: PropTypes.func.isRequired,
  setSchedule: PropTypes.func.isRequired,
  schedule: PropTypes.bool.isRequired,
  Contact: PropTypes.func.isRequired,
};

export default ChatHeader;
