import { StyleSheet } from "aphrodite";
import { Lato } from "../../styles/fonts";
import { grey } from "../../styles/colors";
import { label, inputWithBorder } from "../../styles/controls";

const styles = StyleSheet.create({
  btnHolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "35px",
  },
  starsBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  blockTitle: {
    marginRight: "16px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.6px",
    color: grey["600"],
  },
  label: {
    ...label,
    display: "inline-block",
    marginBottom: "10px",
    letterSpacing: "0.9px",
  },
  textArea: {
    ...inputWithBorder,
    width: "100%",
    height: "100px",
    padding: "10px",
    overflowY: "auto",
    overflowX: "none",
    resize: "none",
  },
  infoBlock: {
    marginTop: "10px",
    fontFamily: [Lato, "sans-serif"],
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "0.4px",
    color: grey["600"],
  },
});

export default styles;
