import moment from 'moment';

const MS_PER_MINUTE = 60000;

/**
 * @function
 * @param {string} format12Hrs - Hour in format 12hrs (1:15pm)
 * @returns {object} {format12Hrs, format24Hrs, hours, minutes}ß
 */
const parseFormat12Hrs = (format12Hrs) => {
  const pm = format12Hrs.toUpperCase().includes('PM');
  const cleanHrs = format12Hrs.toUpperCase().replace('AM', '').replace('PM', '');

  const horMin = cleanHrs.split(':');
  const hrInt = parseInt(horMin[0], 10);
  const hr = (hrInt === 12 ? 0 : hrInt) + (pm ? 12 : 0);
  const min = horMin[1];
  return {
    format12Hrs,
    format24Hrs: `${hr}:${min}`,
    hours: hr,
    minutes: parseInt(min, 10),
  };
};

/**
 * @function
 * @param  {string} hours - Range hours (9:00am - 1:15pm)
 * @param  {string} separator - Range separator Default('-')
 * @returns {object} { startHour, endHour }
 */
const parseHoursRange = (hours, separator = '-') => {
  const hoursArray = hours.split(separator);
  const startHour = parseFormat12Hrs(hoursArray[0].trim());
  const endHour = parseFormat12Hrs(hoursArray[1].trim());
  return { startHour, endHour };
};

/**
 * @function
 * @param {moment} day - day moment()
 * @param {number} hours - hours
 * @param {number} minutes - minutes
 * @returns {string} Date
 */
const createDate = (day, hours, minutes) => moment(day.format())
  .set({
    hours,
    minutes,
  })
  .format();

/**
 * @function
 * @param {string} from - Start time
 * @param {string} to - End time
 * @param {number} duration - minutes
 * @returns {Array} Brackets
 */
const getBrackets = (from, to, duration) => {
  const fromArray = `${from}`.split(':');
  const toArray = `${to}`.split(':');
  const toTime = moment().set({ hour: toArray[0], minute: toArray[1] });
  const start = moment().set({ hour: fromArray[0], minute: fromArray[1] });
  const end = moment(start).add(duration, 'm');
  const times = [];
  do {
    times.push({
      startHour: start.hour(),
      startMinute: start.minute(),
      endHour: end.hour(),
      endMinute: end.minute(),
    });
    start.add(duration, 'm');
    end.add(duration, 'm');
  } while (start.isBefore(toTime));

  return times;
};

/**
 * @function
 * @param {number} duration - minutes
 * @param {string} init -Start time
 * @returns {Array} Schedules
 */
const getSchedules = (duration, init) => {
  const time = `${init}`.split(':');
  const start = moment().set(init ? { hour: time[0], minute: time[1] } : { hour: 0, minute: 0 });
  const end = moment().set(init ? { hour: 24, minute: 0 } : { hour: 23, minute: 59 });
  const times = [];
  do {
    times.push({
      value: start.format('HH:mm'),
      label: start.format('HH:mm'),
    });
    start.add(duration, 'm');
  } while (start.isSameOrBefore(end));

  return times;
};

const getTime = (hour, minute) => moment().set({ hour, minute });

export {
  parseHoursRange,
  parseFormat12Hrs,
  createDate,
  getBrackets,
  getSchedules,
  getTime,
  MS_PER_MINUTE,
};
