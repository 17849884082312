import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import style from './style.module.css';

const defaultProps = {
  onChange: () => undefined,
  defaultCountry: "US",
};

function PhoneInputField(props) {
  const {
    onChange,
  } = props;

  return (
    <div className={style.phoneContainer}>
      <PhoneInput
        defaultCountry="US"
        onChange={onChange}
        {...props}
      />
    </div>
  );
}

PhoneInputField.defaultProps = defaultProps;

export default PhoneInputField;
