import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import AudioMuted from '../../../assets/image/mic-off.png';
import Audio from '../../../assets/image/mic-on.png';
import VideoMuted from '../../../assets/image/camera-off.png';
import Video from '../../../assets/image/camera-on.png';
import styles from './style.module.css';

const UserLobbyPreview = ({ permission, config }) => (
  <>
    <div className={styles.container}>
      <div className={styles.containerButtons}>
        <Tooltip
          placement="top"
          title={`${config.audio.get ? 'Mute' : 'Unmute'}`}
        >
          <Button
            disabled={!permission.audio.get}
            className={`${styles.btnMedia} ${
              config.audio.get && styles.btnMediaActive
            }`}
            type="primary"
            icon={
                config.audio.get ? (
                  <img alt="audio on" src={Audio} />
                ) : (
                  <img alt="audio off" src={AudioMuted} />
                )
              }
            onClick={() => {
              config.audio.set(!config.audio.get);
            }}
          />
        </Tooltip>
        <Tooltip
          placement="top"
          title={`${config.video.get ? 'Turn Off Camera' : 'Turn On Camera'}`}
        >
          <Button
            disabled={!permission.video.get}
            className={`${styles.btnMedia} ${
              config.video.get && styles.btnMediaActive
            }`}
            type="primary"
            icon={
                config.video.get ? (
                  <img alt="Video on" src={Video} />
                ) : (
                  <img alt="Video off" src={VideoMuted} />
                )
              }
            onClick={() => {
              config.video.set(!config.video.get);
            }}
          />
        </Tooltip>
      </div>
    </div>
  </>
);
UserLobbyPreview.propTypes = {
  permission: PropTypes.shape({
    audio: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
    video: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
  }).isRequired,
  config: PropTypes.shape({
    audio: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
    video: PropTypes.shape({
      get: PropTypes.bool,
      set: PropTypes.func,
    }),
  }).isRequired,
};
export default UserLobbyPreview;
