import { SET_CHANNELS } from '../actions/channel';

const initialState = {
  channels: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CHANNELS:
      return [
        ...payload,
      ];
    default:
      return state;
  }
};
