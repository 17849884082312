import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useTranslation } from 'react-i18next';
import useAgora from './hooks/useAgora';
import CallMenu from '../../molecules/CallMenu';
import UserCallList from '../../molecules/UserCallList';
import PlaceHolderVideo from '../../atoms/PlaceHolderVideo';
import { getName } from '../../helpers/userInformation';
import OnCallChat from '../../molecules/OnCallChat';
import useChannel from '../../hooks/useChannel';
import MediaPlayer from '../../atoms/MediaPlayer';
import styles from './style.module.scss';

const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

const VideoCall = ({
  userId,
  permission,
  initialConfig,
  leaveCall,
  members,
  meeting,
  chatChannel,
}) => {
  const [visibleChat, setVisibleChat] = useState(false);
  const [visibleUsers, setVisibleUsers] = useState(false);
  const [fullScreen, setFullScreen] = useState(undefined);
  const { channel, idLastMessage, videoCall } = useChannel(userId, chatChannel);
  const [initialmessages, setInitialMessages] = useState(null);
  const [messages, setMessages] = useState(null);
  const [badgeCount, setBadgeCount] = useState(null);
  const {
    leave,
    join,
    local,
    users,
    joinState,
    mute,
    unmute,
    shareScreen,
    // recording,
  } = useAgora(client, initialConfig, userId, meeting.channelId);

  const { t: translation } = useTranslation();
  const onClose = () => {
    setVisibleChat(false);
  };
  const onCloseUsers = () => {
    setVisibleUsers(false);
  };

  const endCall = async () => {
    await leave();
    leaveCall();
  };

  const getMemberName = (id) => {
    const user = members.find((member) => member.userId === id);
    if (user) return `${user.firstName} ${user.lastName}`;
    return '';
  };

  const isTaxPro = () => false; /* {
    const user = members.find((member) => member.userId === userId);
    return user ? user.Users.isTaxPro : false;
  }; */

  const openNotification = (name, text) => {
    notification.open({
      message: name,
      description: text,
      onClick: () => {
        setInitialMessages(messages);
        setBadgeCount(null);
        setVisibleChat(!visibleChat);
      },
    });
  };

  const playAudio = () => {
    const audioEl = document.getElementsByClassName('audio-element')[0];
    audioEl.play();
  };

  useEffect(() => {
    if (channel) {
      if (messages === null || initialmessages === null) {
        setInitialMessages(channel.Messages.length);
        setMessages(channel.Messages.length);
      } else if (channel.Messages.length !== messages) {
        if (!visibleChat) {
          setBadgeCount(channel.Messages.length - initialmessages);
          openNotification(
            channel.Messages[channel.Messages.length - 1].Users.email,
            channel.Messages[channel.Messages.length - 1].text,
          );
          playAudio();
        } else {
          setInitialMessages(channel.Messages.length);
        }
        setMessages(channel.Messages.length);
      }
    }
  }, [channel, idLastMessage]);

  useEffect(() => {
    const joinCall = async () => {
      await join();
      setFullScreen(userId);
    };
    if (!joinState) joinCall();
  }, [joinState]);

  useEffect(() => {
    if (videoCall) setFullScreen(videoCall);
  }, [videoCall]);

  const renderMediaPlayer = (user) => (
    <MediaPlayer
      videoTrack={user.screenTrack ? user.screenTrack : user.videoTrack}
      audioTrack={user.audioTrack}
      className={styles.mediaPlayer}
      classContainerStatus={styles.containerStatusSmall}
      placeholder={() => (
        <PlaceHolderVideo
          name={user.uid === userId ? getMemberName(userId) : getMemberName(user.uid)}
        />
      )}
      name={
        user.uid === userId
          ? translation('videocall.me')
          : getMemberName(user.uid)
      }
    />
  );

  const getFullScreen = () => {
    const user = users.find(({ uid }) => uid === fullScreen);
    if (!user) return null;
    return renderMediaPlayer(user);
  };

  return (
    <div className={styles.container}>
      <audio className="audio-element">
        <source src="../../swiftly.mp3" />
        <track kind="captions" />
      </audio>
      <div className={styles.fullUser}>{getFullScreen()}</div>
      <div className={styles.usersContainer}>
        {users.map(
          (user) => user.uid !== fullScreen && (
          <div
            key={user.uid}
            className={styles.user}
            onClick={() => setFullScreen(user.uid)}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            {renderMediaPlayer(user)}
          </div>
          ),
        )}
      </div>

      <CallMenu
        count={badgeCount}
        onClickChat={() => {
          setInitialMessages(messages);
          setBadgeCount(null);
          setVisibleChat(!visibleChat);
        }}
        microphone={{
          status: !!local.audio,
          disabled: !permission.audio,
          onClick: () => (local.audio ? mute('audio') : unmute('audio')),
        }}
        camera={{
          status: !!local.video,
          disabled: !permission.video,
          onClick: () => (local.video ? mute('video') : unmute('video')),
        }}
        screen={{
          status: !!local.screen,
          onClick: () => shareScreen(chatChannel, !local.screen),
        }}
        remoteSharingScreen={false}
        // recording={recording}
        isTaxPro={isTaxPro()}
        onClickEndCall={endCall}
      />

      <OnCallChat
        channelName={meeting.channelName}
        visible={visibleChat}
        onClose={onClose}
        userId={userId}
      />
      <UserCallList
        data={members}
        visible={visibleUsers}
        onClose={onCloseUsers}
      />
    </div>
  );
};

VideoCall.propTypes = {
  userId: PropTypes.string.isRequired,
  permission: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
  }).isRequired,
  initialConfig: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
  }).isRequired,
  leaveCall: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  meeting: PropTypes.shape().isRequired,
  chatChannel: PropTypes.string.isRequired,
};
export default VideoCall;
