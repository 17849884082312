/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
 Row, Input, Button, message 
} from 'antd';
import { useUpdateFileNameMutation } from '../../../../graphql/mutations/files';

export const badgeBgColors = {
  orange: 'rgb(235,145,58)',
  green: 'rgb(114,194,64)',
  blue: 'rgb(50,73,196)',
  red: 'rgb(250,73,96)',
};

const FileNameClickableOpen = ({
 id, s3Key, name, openLink 
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState(name);

  const [updateFileNameMutation, { loading }] = useUpdateFileNameMutation({
    onError: (err) => message.error(err.message, 10),
  });

  const updateFileName = async () => {
    const result = await updateFileNameMutation({
      variables: {
        id,
        name: fileName,
      },
    });

    setIsEdit(false);
    setFileName(result.data?.updateFileName?.name || name);
    if (result.data?.updateFileName?.name) {
      message.success('FIle name changed', 10);
    }
  };

  /*
    From Docs about useState: The value you want the state to be initially. It can be a value of any type,
    but there is a special behavior for functions. This argument is ignored after the initial render.
  */
  useEffect(() => { setFileName(name); }, [name]);

  return (
    <Row>
      {isEdit
        ? (
          <Input
            value={fileName}
            disabled={loading}
            onChange={(e) => setFileName(e.target.value)}
            suffix={(
              <Row>
                <CloseOutlined
                  style={{ color: badgeBgColors.red, marginRight: '5px' }}
                  onClick={() => { setIsEdit(false); setFileName(name); }}
                />
                <CheckOutlined
                  style={{ color: badgeBgColors.green }}
                  onClick={() => { updateFileName(); }}
                />
              </Row>
            )}
          />
        )
        : (
          <Button
            type="link"
            onClick={() => openLink(s3Key)}
          >
            {fileName}
          </Button>
        )}

      {!isEdit && (
        <Button
          type="link"
          icon={<EditOutlined style={{ fontSize: '16px' }} />}
          onClick={() => { setIsEdit(true); }}
          title="Change file name"
        />
      )}
    </Row>
  );
};

export default FileNameClickableOpen;