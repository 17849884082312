import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateKbaMutationVariables = Types.Exact<{
  kbaId: Types.Scalars['String'];
  validateKBAInput: Types.ValidateKbaInput;
}>;


export type ValidateKbaMutation = { __typename?: 'Mutation', validateKBA: { __typename?: 'KbaEntity', id: string, isValidated: boolean, numKbaFailedAttempts?: number | null } };

export type SendEKbaTextMutationVariables = Types.Exact<{
  kbaId: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
}>;


export type SendEKbaTextMutation = { __typename?: 'Mutation', sendEKbaText: { __typename?: 'KbaEntity', id: string, isValidated: boolean, numKbaFailedAttempts?: number | null } };

export type VerifyKbaQuestionsMutationVariables = Types.Exact<{
  kbaId: Types.Scalars['String'];
  questionAnswers: Array<Types.QuestionAnswerInput> | Types.QuestionAnswerInput;
}>;


export type VerifyKbaQuestionsMutation = { __typename?: 'Mutation', verifyKBAQuestions: { __typename?: 'KbaEntity', id: string, isValidated: boolean } };


export const ValidateKbaDocument = gql`
    mutation validateKBA($kbaId: String!, $validateKBAInput: ValidateKbaInput!) {
  validateKBA(kbaId: $kbaId, validateKBAInput: $validateKBAInput) {
    id
    isValidated
    numKbaFailedAttempts
  }
}
    `;
export type ValidateKbaMutationFn = Apollo.MutationFunction<ValidateKbaMutation, ValidateKbaMutationVariables>;

/**
 * __useValidateKbaMutation__
 *
 * To run a mutation, you first call `useValidateKbaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateKbaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateKbaMutation, { data, loading, error }] = useValidateKbaMutation({
 *   variables: {
 *      kbaId: // value for 'kbaId'
 *      validateKBAInput: // value for 'validateKBAInput'
 *   },
 * });
 */
export function useValidateKbaMutation(baseOptions?: Apollo.MutationHookOptions<ValidateKbaMutation, ValidateKbaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateKbaMutation, ValidateKbaMutationVariables>(ValidateKbaDocument, options);
      }
export type ValidateKbaMutationHookResult = ReturnType<typeof useValidateKbaMutation>;
export type ValidateKbaMutationResult = Apollo.MutationResult<ValidateKbaMutation>;
export type ValidateKbaMutationOptions = Apollo.BaseMutationOptions<ValidateKbaMutation, ValidateKbaMutationVariables>;
export const SendEKbaTextDocument = gql`
    mutation sendEKbaText($kbaId: String!, $phoneNumber: String!) {
  sendEKbaText(kbaId: $kbaId, phoneNumber: $phoneNumber) {
    id
    isValidated
    numKbaFailedAttempts
  }
}
    `;
export type SendEKbaTextMutationFn = Apollo.MutationFunction<SendEKbaTextMutation, SendEKbaTextMutationVariables>;

/**
 * __useSendEKbaTextMutation__
 *
 * To run a mutation, you first call `useSendEKbaTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEKbaTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEKbaTextMutation, { data, loading, error }] = useSendEKbaTextMutation({
 *   variables: {
 *      kbaId: // value for 'kbaId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendEKbaTextMutation(baseOptions?: Apollo.MutationHookOptions<SendEKbaTextMutation, SendEKbaTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEKbaTextMutation, SendEKbaTextMutationVariables>(SendEKbaTextDocument, options);
      }
export type SendEKbaTextMutationHookResult = ReturnType<typeof useSendEKbaTextMutation>;
export type SendEKbaTextMutationResult = Apollo.MutationResult<SendEKbaTextMutation>;
export type SendEKbaTextMutationOptions = Apollo.BaseMutationOptions<SendEKbaTextMutation, SendEKbaTextMutationVariables>;
export const VerifyKbaQuestionsDocument = gql`
    mutation verifyKBAQuestions($kbaId: String!, $questionAnswers: [QuestionAnswerInput!]!) {
  verifyKBAQuestions(kbaId: $kbaId, questionAnswers: $questionAnswers) {
    id
    isValidated
  }
}
    `;
export type VerifyKbaQuestionsMutationFn = Apollo.MutationFunction<VerifyKbaQuestionsMutation, VerifyKbaQuestionsMutationVariables>;

/**
 * __useVerifyKbaQuestionsMutation__
 *
 * To run a mutation, you first call `useVerifyKbaQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyKbaQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyKbaQuestionsMutation, { data, loading, error }] = useVerifyKbaQuestionsMutation({
 *   variables: {
 *      kbaId: // value for 'kbaId'
 *      questionAnswers: // value for 'questionAnswers'
 *   },
 * });
 */
export function useVerifyKbaQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<VerifyKbaQuestionsMutation, VerifyKbaQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyKbaQuestionsMutation, VerifyKbaQuestionsMutationVariables>(VerifyKbaQuestionsDocument, options);
      }
export type VerifyKbaQuestionsMutationHookResult = ReturnType<typeof useVerifyKbaQuestionsMutation>;
export type VerifyKbaQuestionsMutationResult = Apollo.MutationResult<VerifyKbaQuestionsMutation>;
export type VerifyKbaQuestionsMutationOptions = Apollo.BaseMutationOptions<VerifyKbaQuestionsMutation, VerifyKbaQuestionsMutationVariables>;
export const namedOperations = {
  Mutation: {
    validateKBA: 'validateKBA',
    sendEKbaText: 'sendEKbaText',
    verifyKBAQuestions: 'verifyKBAQuestions'
  }
}