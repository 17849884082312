// import API from '../../api';
import { getProjectTasks } from '../../api/projects';
// import { formatProjects } from '../../helpers/Projects';

export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECTS_LOADING = 'SET_PROJECTS_LOADING';
export const SET_PROJECT_TASKS = 'SET_PROJECT_TASKS';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const SET_PROJECT_ANSWER = 'SET_PROJECT_ANSWER';
export const UPDATE_PROJECT_ANSWER = 'UPDATE_PROJECT_ANSWER';
export const SET_INTERVIEW_SECTION_DEPENDENT = 'SET_INTERVIEW_SECTION_DEPENDENT';
export const REMOVE_INTERVIEW_SECTION_DEPENDENT = 'REMOVE_INTERVIEW_SECTION_DEPENDENT';
export const SAVE_PROJECT_ANSWERS = 'SAVE_PROJECT_ANSWERS';
export const COMPLETE_INTERVIEW_TASK = 'COMPLETE_INTERVIEW_TASK';


export const setProjects = (projects) => ({
  type: SET_PROJECTS,
  payload: projects,
});

export const setProjectsLoading = (value) => ({
  type: SET_PROJECTS_LOADING,
  payload: value,
});

export const setActiveProject = (id) => ({
  type: SET_ACTIVE_PROJECT,
  payload: id,
});

export const setProjectTasks = (projectId, tasks = []) => ({
  type: SET_PROJECT_TASKS,
  payload: { projectId, tasks },
});

/* export const getProjects = () => (dispatch) => {
  dispatch(setProjectsLoading(true));
  return API
    .get('client-tax-year-statuses/projects')
    .then((response) => {
      const projectsFiltered = response.data.filter((a) => !a.preparerDeletedAt);
      let projectsFormat;
      // format
      try {
        projectsFormat = formatProjects(projectsFiltered);
      } catch (e) {
        // console.log('error formatting projects:', e);
        projectsFormat = [];
      }
      // sort
      try {
        // sort by taxYear
        projectsFormat.sort((a, b) => b.taxYear - a.taxYear);
      } catch (e) {
        // console.log('error sorting projects:', e);
      }
      dispatch(setProjects(projectsFormat));
      dispatch(setProjectsLoading(false));
    });
}; */

export const refreshTaskList = (projectId) => (dispatch) => {
  dispatch(setActiveProject(projectId));
  return getProjectTasks(projectId).then(
    (response) => dispatch(setProjectTasks(projectId, response)),
  );
};

export const completeTask = (projectId, taskIds = []) => ({
  type: COMPLETE_TASK,
  payload: { projectId, taskIds },
});

/* export const leaveReview = (projectId, config, history) => (
  dispatch,
) => API.post(`client-tax-year-statuses/review/${projectId}`, config).then((response) => {
  if (response.data.success) {
    history.push('/');
    dispatch(getProjects());
  }
}); */

export const setProjectAnswer = (id, newAnswer) => ({
  type: SET_PROJECT_ANSWER,
  payload: {
    id,
    newAnswer
  }
});

export const updateProjectAnswer = (projectId, updatedAnswer) => ({
  type: UPDATE_PROJECT_ANSWER,
  payload: {
    projectId,
    updatedAnswer
  }
});

export const setInterviewSectionDependent = (projectId, newDependentSection) => ({
  type: SET_INTERVIEW_SECTION_DEPENDENT,
  payload: {
    projectId,
    newDependentSection,
  }
});

export const removeInterviewSectionDependent = (projectId, dependentSection) => ({
  type: REMOVE_INTERVIEW_SECTION_DEPENDENT,
  payload: {
    projectId,
    dependentSection,
  },
});

export const saveProjectAnswers = (projectId, answer) => ({
  type: SAVE_PROJECT_ANSWERS,
  payload: {
    projectId,
    answer
  }
});

export const completeInterviewTask = (projectId) => ({
  type: COMPLETE_INTERVIEW_TASK,
  payload: {
    projectId,
  }
})
