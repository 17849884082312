import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomDomainByNameQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type GetCustomDomainByNameQuery = { __typename?: 'Query', CustomDomainByName: { __typename?: 'CustomDomainsEntity', id: string, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, uniqueLink?: string | null, CustomLayout?: { __typename?: 'CustomLayoutEntity', id: string, firmAccountId?: string | null, headerBackgroundColor?: string | null, headerFontColor?: string | null, logo?: string | null, pixelCode?: string | null } | null } | null } };


export const GetCustomDomainByNameDocument = gql`
    query GetCustomDomainByName($name: String!) {
  CustomDomainByName(name: $name) {
    id
    FirmAccount {
      id
      uniqueLink
      CustomLayout {
        id
        firmAccountId
        headerBackgroundColor
        headerFontColor
        logo
        pixelCode
      }
    }
  }
}
    `;

/**
 * __useGetCustomDomainByNameQuery__
 *
 * To run a query within a React component, call `useGetCustomDomainByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomDomainByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomDomainByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCustomDomainByNameQuery(baseOptions: Apollo.QueryHookOptions<GetCustomDomainByNameQuery, GetCustomDomainByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomDomainByNameQuery, GetCustomDomainByNameQueryVariables>(GetCustomDomainByNameDocument, options);
      }
export function useGetCustomDomainByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomDomainByNameQuery, GetCustomDomainByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomDomainByNameQuery, GetCustomDomainByNameQueryVariables>(GetCustomDomainByNameDocument, options);
        }
export type GetCustomDomainByNameQueryHookResult = ReturnType<typeof useGetCustomDomainByNameQuery>;
export type GetCustomDomainByNameLazyQueryHookResult = ReturnType<typeof useGetCustomDomainByNameLazyQuery>;
export type GetCustomDomainByNameQueryResult = Apollo.QueryResult<GetCustomDomainByNameQuery, GetCustomDomainByNameQueryVariables>;
export const namedOperations = {
  Query: {
    GetCustomDomainByName: 'GetCustomDomainByName'
  }
}