import React from 'react';
import PropTypes from 'prop-types';

const DragAndDrop = ({
  className,
  onDrop,
  onDragOver,
  onDragEnter,
  children,
  click,
}) => {
  const handleDragEnter = (event) => {
    event.preventDefault();
    onDragEnter(event);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    onDragOver(event);
  };

  const uploadFile = (event) => {
    event.preventDefault();
    const files = event.dataTransfer?.files || event.target?.files;
    onDrop([...files]);
  };

  return click ? (
    <label
      htmlFor="file"
      className={className}
      onDrop={(event) => uploadFile(event)}
      onDragOver={(event) => handleDragOver(event)}
      onDragEnter={(event) => handleDragEnter(event)}
    >
      <input
        id="file"
        type="file"
        style={{ display: 'none' }}
        onChange={uploadFile}
      />
      {children}
    </label>
  ) : (
    <div
      className={className}
      onDrop={(event) => uploadFile(event)}
      onDragOver={(event) => handleDragOver(event)}
      onDragEnter={(event) => handleDragEnter(event)}
    >
      {children}
    </div>
  );
};

DragAndDrop.defaultProps = {
  className: '',
  onDrop: () => {},
  onDragOver: () => {},
  onDragEnter: () => {},
  children: null,
  click: true,
};

DragAndDrop.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  children: PropTypes.element,
  click: PropTypes.bool,
};

export default DragAndDrop;
