import {
  Row,
  Col,
  Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FieldType } from '../../../../../graphql';

const TextField = ({
  id,
  top,
  left,
  height,
  width,
  complete,
  title,
  description,
  handleFieldClick,
  pendingSubmit,
  transformation,
  isMobileView,
}) => {
  const content = (
    <div
      className={`field ${complete && "completed-field"}`}
      style={{
        top,
        left,
        height,
        width,
        cursor: pendingSubmit ? 'pointer' : 'default',
        display: isMobileView ? 'inline-flex' : 'block',
      }}
      onClick={() => {
        if (pendingSubmit) {
          handleFieldClick(FieldType.Text, id);
        }
      }}
    >
      <Row
        justify="center"
        gutter={10}
        style={{ transform: `scale(${transformation})` }}
      >
        <Col span={6}>
          {pendingSubmit && (
            <div style={{ textAlign: 'center' }}>
              <EditOutlined color="#595959" />
            </div>
          )}
        </Col>
        <Col span={18}>
          <p style={{ textDecoration: 'underline', color: '#595959', margin: 0 }}>
            {description || title || 'Text'}
          </p>
        </Col>
      </Row>
    </div>
  );

  return description ? (
    <Tooltip title={title}>
      {content}
    </Tooltip>  
  ) : content;
}

export default TextField;
