import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './style.module.css';

const IncommingCall = ({ name, endCall, onDecline }) => {
  const { t: translation } = useTranslation();

  const onClickAcceptButton = () => {
    endCall();
    window.open(`/videocall/${name}`, '_blank');
  };

  const onClickDeclineButton = () => {
    onDecline();
    endCall();
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{translation('chat.incomingCall.title')}</p>
      <div className={styles.containerButtons}>
        <div className={styles.containerButton}>
          <Button
            className={styles.btn}
            style={{ backgroundColor: '#F54343', borderColor: '#F54343' }}
            type="primary"
            shape="circle"
            icon={<PhoneOutlined />}
            size="large"
            onClick={onClickDeclineButton}
          />
          <p className={styles.btntext}>
            {translation('chat.incomingCall.decline')}
          </p>
        </div>
        <div className={styles.containerButtonRight}>
          <Button
            className={styles.btn2}
            style={{ backgroundColor: '#52C41A', borderColor: '#52C41A' }}
            type="primary"
            shape="circle"
            icon={<PhoneOutlined />}
            size="large"
            onClick={onClickAcceptButton}
          />
          <p className={styles.btntext}>
            {translation('chat.incomingCall.accept')}
          </p>
        </div>
      </div>
    </div>
  );
};

IncommingCall.propTypes = {
  name: PropTypes.string.isRequired,
  endCall: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default IncommingCall;
