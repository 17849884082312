import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const LobbyUser = ({ name }) => (
  <div className={styles.container}>
    <p>{name}</p>
  </div>
);
LobbyUser.propTypes = {
  name: PropTypes.string.isRequired,
};
export default LobbyUser;
