import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import styles from './style.module.css';
import 'antd/dist/antd.min.css';

const ScheduleButton = ({ onClick }) => (
  <Button
    className={styles.btn}
    onClick={onClick}
    size="small"
    type="default"
    shape="circle"
    icon={<CalendarOutlined />}
  />
);
ScheduleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ScheduleButton;
