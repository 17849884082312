/* eslint-disable */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Icon } from '../../Icon/Icon';

import { color } from '../../../styles/colors';
import { heading } from '../../../styles/fonts';
import Translation from '../../atoms/Translation';

import './style.css';

/**
 * @param props
 */
function ScheduleModal(props) {
  return (
    <div className={`schedule-modal--container ${css(styles.overlay)}`}>
      <div className={css(styles.modal)}>
        <div className={css(styles.title)}>
          <span className={css(styles.h2)}><Translation text="contactinfo.scheduleAnAppointment" /></span>
          <Icon exit onClick={() => props.history.push('/')} style={{ position: 'absolute', right: 40 }} />
        </div>
        {props.children}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(159, 159, 159, 0.3)',
    overflowY: 'auto',
    zIndex: '10',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '40px',
    left: '50%',
    width: '100%',
    maxWidth: '430px',
    minHeight: '250px',
    margin: '0 auto',
    marginBottom: '20px',
    padding: '30px 20px 20px',
    backgroundColor: color.white,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    overflow: 'visible',
    zIndex: '100',
    transform: 'translateX(-50%)',
    '@media (min-width: 400px)': {
      width: 'calc(100% - 20px)',
      borderRadius: '5px',
    },
    '@media (min-width: 640px)': {
      width: 'calc(100% - 40px)',
      padding: '40px 40px 60px',
    },
    '@media (min-width: 768px)': {
      top: '110px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '29px',
  },
  titleBlank: {
    marginBottom: 0,
  },
  h2: {
    ...heading.modalHeading,
  },
  sectionTitle: {
    marginTop: '40px',
  },
  listHolder: {
    minHeight: '200px',
  },
  btnBlock: {
    marginTop: '40px',
    textAlign: 'right',
  },
});

export default ScheduleModal;
