import { useState, useEffect } from 'react';
import moment from 'moment';
import Pusher from 'pusher-js';
// import API from '../../api';
import * as config from '../../api/config';
import { getName, getAvatar } from '../helpers/userInformation';

const accessToken = localStorage.getItem('accessToken');

/* const getChannelInfo = async (channel) => {
  const result = await API.get(`channels/${channel}`);
  return result.data;
}; */

const subscribe = (name) => {
  const pusher = new Pusher(config.pusherKey, {
    cluster: 'us2',
    authEndpoint: `${config.serverUrl}/pusher`,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
  return pusher.subscribe(`presence-${name}`);
};

const readHistoryNotCatch = (channelName) => {
  // API.put(`messages/read/${channelName}`).then(() => {});
};

let lastCall;

const getInfo = (channel, userId, status) => {
  let name = '';
  let lastMessage = '';
  let avatar = '';
  let clientData = null;

  if (channel) {
    const pro = channel.ChannelMembers.find(
      (member) => member.userId !== userId,
    );

    lastMessage = channel.Messages.length > 0
      ? moment(
        channel.Messages[channel.Messages.length - 1].updatedAt,
      ).format('MMM DD, YYYY')
      : '';
    name = getName(pro);

    const admin = channel.ChannelMembers.find(
      (member) => member.Users.Clients_Clients_clientUserIdToUsers,
    );
    clientData = admin.Users.Clients_Clients_clientUserIdToUsers;
    avatar = getAvatar(pro);
  }
  return {
    name,
    status,
    avatar,
    lastMessage,
    clientData,
  };
};

const useChannel = (userId, channelName, incomingCall, setIncomingCall, newMessageId, status) => {
  const [channel, setChannel] = useState(null);
  const [newMessage, setNewMessage] = useState({ id: newMessageId, message: '' });

  const endCall = () => {
    setIncomingCall(null);
  };

  const onMessage = (id, message) => {
    setNewMessage({ id, message });
  };

  useEffect(() => {
    const addMessage = ({ message }) => {
      const updatedChannel = channel;
      if (updatedChannel) {
        updatedChannel.Messages.push({
          ...message,
          readDate: new Date(),
        });
        readHistoryNotCatch(updatedChannel.name, userId);
      }
      setChannel(updatedChannel);
      setNewMessage(undefined);
    };

    const connectChannel = async () => {
      /* const channelNC = await getChannelInfo(channelName);

      const channelListener = subscribe(channelNC.name);
      channelListener.bind('chat', (message) => {
        onMessage(channelNC.id, message);
      });
      channelNC.channel = channelListener;
      setChannel(channelNC); */
    };

    if (!channel) connectChannel();
    if (channel && newMessage) addMessage(newMessage);
  }, [channel, newMessage]);

  useEffect(() => {
    lastCall = incomingCall;
  }, [incomingCall]);

  useEffect(() => () => {
    if (channel && lastCall) {
      /* API.post('messages', {
        message: {
          isMissedCall: true,
        },
        connection: {
          channelId: channel.id,
          userId,
        },
      }); */
    }
  }, [channel]);

  return {
    channel,
    newMessage: newMessage ? newMessage.message.id : 0,
    getInfo: getInfo(channel, userId, status),
    endCall,
  };
};

export default useChannel;
