import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChatHeader from '../../molecules/ChatHeader';
import MessagesList from '../../molecules/MessagesList';
import ChatInput from '../../molecules/ChatInput';
import ScheduleCall from '../../molecules/ScheduleCall';
import IncommingCall from '../../molecules/IncommingCall';
import styles from './style.module.css';
import CustomizeRenderEmpty from '../../molecules/MessagesList/CustomizeEmpty';
import FirmListChat from '../../atoms/FirmListChat';
import { message as AntMessage } from 'antd';
import { useSendChatMessageMutation } from '../../../graphql/mutations/messaging';
import { getFullName } from '../../../utils/getFullName';
import { useGetTaxYearInterviewForChannelLazyQuery } from '../../../graphql/queries/taxYearInterviews';

const Chat = ({
  taxPro,
  userId,
  visible,
  setVisible,
  incomingCall,
  setIncomingCall,
  firmList,
  setCurrentChannelId,
  channelInfo,
  newMessage,
  disableTaxarooCalendar,
  messages,
  getMoreMessages,
  loadingMoreMessages,
}) => {
  const [schedule, setSchedule] = useState(false);
  const [isShowFirmList, setVisibilityFirmList] = useState(false);
  const [projects, setProjects] = useState([]);

  const channelName = channelInfo?.name;
  const clientDetails = channelInfo?.ChannelMembers[0].userId === userId
    ? channelInfo?.ChannelMembers[0]
    : channelInfo?.ChannelMembers[1];

  const [sendChatMesage, { loading: loadingSendMessage }] = useSendChatMessageMutation({
    onError: (err) => AntMessage.error(err.message)
  });

  const [getTaxYearInterview] = useGetTaxYearInterviewForChannelLazyQuery({
    variables: { channelUserId: taxPro },
  });

  const callProjects = async () => {
    const data = await getTaxYearInterview();
    setProjects(data?.data?.GetTaxYearInterviewForChannel);
  };

  const onSendMessage = async (val, isMissedCall = false, attachment) => {
    if (val || isMissedCall || attachment) {
      const message = {
        text: val,
        isMissedCall,
      };

      setSchedule(false);

      await sendChatMesage({
        variables: {
          createMessageInput: {
            channelId: channelInfo?.id,
            text: val,
            attachment,
            phone: undefined,
          }
        }
      });
    }
  };

  useEffect(() => {
    if (taxPro) {
      callProjects();
    }
  }, [taxPro]);

  useEffect(() => {
    if (incomingCall) setVisible(true);
    // eslint-disable-next-line
  }, [incomingCall]);

  const onDecline = () => {
    onSendMessage(null, true);
  };

  const sortedMessages = messages[channelInfo?.id].sort((a, b) => {
    if (new Date(a.createAt) > new Date(b.createAt)) return 1;
    if (new Date(a.createAt) < new Date(b.createAt)) return -1;
    return 0;
  });

  const idLastMessage = sortedMessages
    ? sortedMessages[sortedMessages.length - 1]?.id
    : undefined;

  const chatContainer = schedule ? (
    <div className={styles.scheduleContainer}>
      <ScheduleCall
        onSendMessage={onSendMessage}
        channelName={channelName}
        evenTypesUser={taxPro}
        userId={taxPro}
        organizerId={userId}
        closeSchedule={() => setSchedule(false)}
        organizerName={getFullName(clientDetails.Users?.UserInformation)}
      />
    </div>
  ) : (
    visible
    && channelInfo
    && messages[channelInfo?.id]
      && CustomizeRenderEmpty(<MessagesList
        userId={userId}
        data={sortedMessages}
        idLastMessage={idLastMessage}
        getMoreMessages={getMoreMessages}
        loadingMoreMessages={loadingMoreMessages}
        channelId={channelInfo?.id}
      />,
        <div>
          <div>No Messages Yet</div>
          <div><i>Send one below</i></div>
        </div>)
  );

  return (
    <div className={`${styles.container} ${visible && styles.visible} ${isShowFirmList && styles.blur}`}>
      <ChatHeader
        data={firmList.find((e) => e.channelId === channelInfo?.id)}
        setVisible={setVisible}
        setSchedule={setSchedule}
        schedule={schedule}
        Contact={() => !!(firmList.length > 1)
          && (
          <FirmListChat
            firmList={firmList}
            setCurrentChannelId={setCurrentChannelId}
            isShowFirmList={isShowFirmList}
            setVisibilityFirmList={setVisibilityFirmList}
          />
          )}
        disableTaxarooCalendar={disableTaxarooCalendar}
      />

      {incomingCall ? (
        <IncommingCall
          name={incomingCall}
          endCall={setIncomingCall(null)}
          onDecline={onDecline}
        />
      ) : (
        chatContainer
      )}
      <ChatInput
        onSendMessage={onSendMessage}
        projects={projects}
        userId={userId}
        taxPro={taxPro}
      />
    </div>
  );
};

Chat.defaultProps = {
  taxPro: null,
  incomingCall: null,
  firmList: [],
};

Chat.propTypes = {
  taxPro: PropTypes.string,
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  incomingCall: PropTypes.bool,
  setIncomingCall: PropTypes.func.isRequired,
  firmList: PropTypes.arrayOf(PropTypes.object),
  setCurrentChannelId: PropTypes.func.isRequired,
  channelInfo: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.bool,
    id: PropTypes.string.isRequired,
  }).isRequired,
  newMessage: PropTypes.string.isRequired,
  messages: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default Chat;
