import { useState } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import classes from './style.module.scss';
import Translation from '../../../atoms/Translation';
import { IconWithHover } from '../../../Icon/Icon';
import { useUpdateUserPasswordMutation } from '../../../../graphql/mutations/settings';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const Password = (props) => {
  const { session } = props;
  const { graphSession } = session;
  const { userId } = graphSession;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(null);
  const [state, setState] = useState(initialState);
  const [showPasssword, setShowPasssword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const { t: translation } = useTranslation();
  const [updatePassword] = useUpdateUserPasswordMutation();

  const { currentPassword, newPassword, confirmPassword } = state;
  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const validateForm = () => {
    const error = {};

    if (currentPassword.trim() === '') error.currentPassword = 'Current Password is required.';

    if (newPassword.trim() === '') error.newPassword = 'New Password is required.';
    else if (newPassword.trim().length < 8) error.newPassword = 'It must be at least 8 characters long.';

    if (confirmPassword.trim() === '' || confirmPassword.trim() !== newPassword.trim()) error.confirmPassword = 'Passwords do not match.';

    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errosValidated = validateForm();
    setErrors(errosValidated);
    if (Object.keys(errosValidated).length === 0) {
      setLoading(true);
      try {
        const result = await updatePassword({
          variables: {
            userId,
            oldPassword: state.currentPassword,
            newPassword: state.newPassword,
          }
        });
        if (result?.data?.updateUserPassword?.id) {
          setMsgSuccess(<Translation text="settings.message.success" />);
          setState(initialState);
        }
      } catch (error) {
        setErrors({ currentPassword: 'Current Password is incorrect.' })
      } finally {
        setLoading(false);
      }
    }
  };

  const {
    currentPassword: showCurrent,
    newPassword: showNew,
    confirmPassword: showConfirm,
  } = showPasssword;

  const styleEye = {
    border: '1px solid #ced4da',
    borderBottomRightRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
  };

  return (
    <div className="card p-4 container my-3">
      <h2>
        <Translation text="settings.changePassword" />
      </h2>
      <hr style={{ margin: 0 }} />
      <div className={classes.container}>
        <Form className="col-8" style={{ margin: '1rem 0' }} onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              <Translation text="settings.pass.currentPassword" />
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className={errors.currentPassword ? 'is-invalid' : ''}
                name="currentPassword"
                type={showCurrent ? 'text' : 'password'}
                placeholder={translation('settings.pass.currentPassword')}
                value={currentPassword}
                onChange={handleChange}
              />
              <InputGroup.Append style={styleEye}>
                <IconWithHover
                  type="button"
                  icon="eye"
                  bg="none"
                  onClick={() => setShowPasssword({
                    ...showPasssword,
                    currentPassword: !showCurrent,
                  })}
                />
              </InputGroup.Append>
            </InputGroup>
            {errors.currentPassword && (
              <div className={classes.invalid}>{errors.currentPassword}</div>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Translation text="settings.pass.newPassword" />
              <Tooltip title={translation('settings.pass.passwordMust')}>
                <InfoCircleOutlined
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    top: '-0.5em',
                    left: '0.5em',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className={errors.newPassword ? 'is-invalid' : ''}
                name="newPassword"
                type={showNew ? 'text' : 'password'}
                placeholder={translation('settings.pass.newPassword')}
                value={newPassword}
                onChange={handleChange}
              />
              <InputGroup.Append style={styleEye}>
                <IconWithHover
                  type="button"
                  icon="eye"
                  bg="none"
                  onClick={() => setShowPasssword({ ...showPasssword, newPassword: !showNew })}
                />
              </InputGroup.Append>
            </InputGroup>
            {errors.newPassword && <div className={classes.invalid}>{errors.newPassword}</div>}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <Translation text="settings.pass.confirmNewPassword" />
              <Tooltip title={translation('settings.pass.passwordMust')}>
                <InfoCircleOutlined
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    top: '-0.5em',
                    left: '0.5em',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className={errors.confirmPassword ? 'is-invalid' : ''}
                name="confirmPassword"
                type={showConfirm ? 'text' : 'password'}
                placeholder={translation('settings.pass.confirmNewPassword')}
                value={confirmPassword}
                onChange={handleChange}
              />
              <InputGroup.Append style={styleEye}>
                <IconWithHover
                  type="button"
                  icon="eye"
                  bg="none"
                  onClick={() => setShowPasssword({
                    ...showPasssword,
                    confirmPassword: !showConfirm,
                  })}
                />
              </InputGroup.Append>
            </InputGroup>
            {errors.confirmPassword && (
              <div className={classes.invalid}>{errors.confirmPassword}</div>
            )}
          </Form.Group>
          <Button variant="primary" type="submit">
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">
                  <Translation text="settings.loading" />
                  ...
                </span>
              </div>
            ) : (
              <Translation text="settings.changePassword" />
            )}
          </Button>
          {msgSuccess && (
            <span data-cy="changesSaved" className={classes.status}>
              <i className="fa fa-check" />
              {' '}
              {msgSuccess}
            </span>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(Password);
