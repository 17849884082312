import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTasksByTaxYearInterviewIdQueryVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetTasksByTaxYearInterviewIdQuery = { __typename?: 'Query', tasksByTaxYearInterviewId: Array<{ __typename?: 'TaskEntity', id: string, taxYearId?: string | null, taskType?: Types.TaskType | null, completedAt?: any | null, deletedAt?: any | null, createAt: any, updateAt: any, RequestedFiles?: { __typename?: 'RequestedFilesEntity', id: string, taskId?: string | null, fileName: string, notes?: string | null, completedAt?: any | null } | null, FileApproval?: { __typename?: 'FileApprovalEntity', id: string, taskId?: string | null, completedAt?: any | null, FileRequests?: { __typename?: 'FileRequestEntity', id: string, title?: string | null } | null } | null, Payments?: Array<{ __typename?: 'PaymentsEntity', completedAt?: any | null, deletedAt?: any | null }> | null }> };


export const GetTasksByTaxYearInterviewIdDocument = gql`
    query GetTasksByTaxYearInterviewId($taxYearInterviewId: String!) {
  tasksByTaxYearInterviewId(taxYearInterviewId: $taxYearInterviewId) {
    id
    taxYearId
    taskType
    completedAt
    deletedAt
    createAt
    updateAt
    RequestedFiles {
      id
      taskId
      fileName
      notes
      completedAt
    }
    FileApproval {
      id
      taskId
      completedAt
      FileRequests {
        id
        title
      }
    }
    Payments {
      completedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useGetTasksByTaxYearInterviewIdQuery__
 *
 * To run a query within a React component, call `useGetTasksByTaxYearInterviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksByTaxYearInterviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksByTaxYearInterviewIdQuery({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetTasksByTaxYearInterviewIdQuery(baseOptions: Apollo.QueryHookOptions<GetTasksByTaxYearInterviewIdQuery, GetTasksByTaxYearInterviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksByTaxYearInterviewIdQuery, GetTasksByTaxYearInterviewIdQueryVariables>(GetTasksByTaxYearInterviewIdDocument, options);
      }
export function useGetTasksByTaxYearInterviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksByTaxYearInterviewIdQuery, GetTasksByTaxYearInterviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksByTaxYearInterviewIdQuery, GetTasksByTaxYearInterviewIdQueryVariables>(GetTasksByTaxYearInterviewIdDocument, options);
        }
export type GetTasksByTaxYearInterviewIdQueryHookResult = ReturnType<typeof useGetTasksByTaxYearInterviewIdQuery>;
export type GetTasksByTaxYearInterviewIdLazyQueryHookResult = ReturnType<typeof useGetTasksByTaxYearInterviewIdLazyQuery>;
export type GetTasksByTaxYearInterviewIdQueryResult = Apollo.QueryResult<GetTasksByTaxYearInterviewIdQuery, GetTasksByTaxYearInterviewIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetTasksByTaxYearInterviewId: 'GetTasksByTaxYearInterviewId'
  }
}