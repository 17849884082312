import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VideoCameraOutlined, AudioOutlined } from '@ant-design/icons';
import styles from './style.module.scss';

const MediaPlayer = ({
  videoTrack,
  audioTrack,
  className,
  classContainerStatus,
  placeholder,
  name,
}) => {
  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      if (videoTrack) videoTrack.play(container.current);
    }
    return () => {
      if (videoTrack) videoTrack.stop();
    };
  }, [container, videoTrack]);

  useEffect(() => {
    if (audioTrack) audioTrack.play();
    return () => {
      if (audioTrack) audioTrack.stop();
    };
  }, [audioTrack]);

  return (
    <div ref={container} className={className}>
      {!videoTrack && placeholder()}
      <div className={classContainerStatus}>
        <p>{name}</p>
        { !audioTrack && <AudioOutlined className={styles.iconStatusSmall} />}
        { !videoTrack && <VideoCameraOutlined className={styles.iconStatusSmall} />}
      </div>
    </div>
  );
};

MediaPlayer.defaultProps = {
  videoTrack: null,
  audioTrack: null,
  className: '',
  placeholder: () => {},
  name: '',
};

MediaPlayer.propTypes = {
  videoTrack: PropTypes.shape(),
  audioTrack: PropTypes.shape(),
  className: PropTypes.string,
  classContainerStatus: PropTypes.string.isRequired,
  placeholder: PropTypes.func,
  name: PropTypes.string,
};

export default MediaPlayer;
