import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserForInviteLinkWithResetPasswordIdQueryVariables = Types.Exact<{
  passwordResetId: Types.Scalars['String'];
}>;


export type GetUserForInviteLinkWithResetPasswordIdQuery = { __typename?: 'Query', UserForInviteLinkWithResetPasswordId: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } | null, Entity?: { __typename?: 'Entity', UserFirmAccount?: Array<{ __typename?: 'UserFirmAccountEntity', FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null, CustomLayout?: { __typename?: 'CustomLayoutEntity', id: string, headerBackgroundColor?: string | null, headerFontColor?: string | null, logo?: string | null, pixelCode?: string | null } | null } | null }> | null } | null } };

export type GetClientOnboardingDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientOnboardingDataQuery = { __typename?: 'Query', ClientOnboardingData: { __typename?: 'OnboardingClientDataEntity', expired: boolean, alreadyActive: boolean, ownerName?: string | null, email?: string | null, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string, birthday?: any | null, Phones?: Array<{ __typename?: 'PhonesEntity', type: Types.PhoneType, value: string }> | null } | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, name?: string | null, CustomLayout?: { __typename?: 'CustomLayoutEntity', id: string, headerBackgroundColor?: string | null, headerFontColor?: string | null, logo?: string | null, pixelCode?: string | null, createAt?: any | null, updateAt?: any | null } | null } | null } };

export type GetUserByInviteLinkQueryVariables = Types.Exact<{
  uniqueLink: Types.Scalars['String'];
  firmAccountId: Types.Scalars['String'];
}>;


export type GetUserByInviteLinkQuery = { __typename?: 'Query', GetUserByInviteLink: { __typename?: 'UserEntity', id: string, uniqueLink?: string | null } };


export const GetUserForInviteLinkWithResetPasswordIdDocument = gql`
    query GetUserForInviteLinkWithResetPasswordId($passwordResetId: String!) {
  UserForInviteLinkWithResetPasswordId(passwordResetId: $passwordResetId) {
    id
    email
    UserInformation {
      firstName
      lastName
    }
    Entity {
      UserFirmAccount {
        FirmAccount {
          id
          name
          CustomLayout {
            id
            headerBackgroundColor
            headerFontColor
            logo
            pixelCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserForInviteLinkWithResetPasswordIdQuery__
 *
 * To run a query within a React component, call `useGetUserForInviteLinkWithResetPasswordIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForInviteLinkWithResetPasswordIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForInviteLinkWithResetPasswordIdQuery({
 *   variables: {
 *      passwordResetId: // value for 'passwordResetId'
 *   },
 * });
 */
export function useGetUserForInviteLinkWithResetPasswordIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserForInviteLinkWithResetPasswordIdQuery, GetUserForInviteLinkWithResetPasswordIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserForInviteLinkWithResetPasswordIdQuery, GetUserForInviteLinkWithResetPasswordIdQueryVariables>(GetUserForInviteLinkWithResetPasswordIdDocument, options);
      }
export function useGetUserForInviteLinkWithResetPasswordIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserForInviteLinkWithResetPasswordIdQuery, GetUserForInviteLinkWithResetPasswordIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserForInviteLinkWithResetPasswordIdQuery, GetUserForInviteLinkWithResetPasswordIdQueryVariables>(GetUserForInviteLinkWithResetPasswordIdDocument, options);
        }
export type GetUserForInviteLinkWithResetPasswordIdQueryHookResult = ReturnType<typeof useGetUserForInviteLinkWithResetPasswordIdQuery>;
export type GetUserForInviteLinkWithResetPasswordIdLazyQueryHookResult = ReturnType<typeof useGetUserForInviteLinkWithResetPasswordIdLazyQuery>;
export type GetUserForInviteLinkWithResetPasswordIdQueryResult = Apollo.QueryResult<GetUserForInviteLinkWithResetPasswordIdQuery, GetUserForInviteLinkWithResetPasswordIdQueryVariables>;
export const GetClientOnboardingDataDocument = gql`
    query GetClientOnboardingData {
  ClientOnboardingData {
    expired
    alreadyActive
    ownerName
    email
    languageId
    UserInformation {
      id
      firstName
      lastName
      birthday
      Phones {
        type
        value
      }
    }
    FirmAccount {
      id
      name
      CustomLayout {
        id
        headerBackgroundColor
        headerFontColor
        logo
        pixelCode
        createAt
        updateAt
      }
    }
  }
}
    `;

/**
 * __useGetClientOnboardingDataQuery__
 *
 * To run a query within a React component, call `useGetClientOnboardingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientOnboardingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientOnboardingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientOnboardingDataQuery(baseOptions?: Apollo.QueryHookOptions<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>(GetClientOnboardingDataDocument, options);
      }
export function useGetClientOnboardingDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>(GetClientOnboardingDataDocument, options);
        }
export type GetClientOnboardingDataQueryHookResult = ReturnType<typeof useGetClientOnboardingDataQuery>;
export type GetClientOnboardingDataLazyQueryHookResult = ReturnType<typeof useGetClientOnboardingDataLazyQuery>;
export type GetClientOnboardingDataQueryResult = Apollo.QueryResult<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>;
export const GetUserByInviteLinkDocument = gql`
    query GetUserByInviteLink($uniqueLink: String!, $firmAccountId: String!) {
  GetUserByInviteLink(uniqueLink: $uniqueLink, firmAccountId: $firmAccountId) {
    id
    uniqueLink
  }
}
    `;

/**
 * __useGetUserByInviteLinkQuery__
 *
 * To run a query within a React component, call `useGetUserByInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByInviteLinkQuery({
 *   variables: {
 *      uniqueLink: // value for 'uniqueLink'
 *      firmAccountId: // value for 'firmAccountId'
 *   },
 * });
 */
export function useGetUserByInviteLinkQuery(baseOptions: Apollo.QueryHookOptions<GetUserByInviteLinkQuery, GetUserByInviteLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByInviteLinkQuery, GetUserByInviteLinkQueryVariables>(GetUserByInviteLinkDocument, options);
      }
export function useGetUserByInviteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByInviteLinkQuery, GetUserByInviteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByInviteLinkQuery, GetUserByInviteLinkQueryVariables>(GetUserByInviteLinkDocument, options);
        }
export type GetUserByInviteLinkQueryHookResult = ReturnType<typeof useGetUserByInviteLinkQuery>;
export type GetUserByInviteLinkLazyQueryHookResult = ReturnType<typeof useGetUserByInviteLinkLazyQuery>;
export type GetUserByInviteLinkQueryResult = Apollo.QueryResult<GetUserByInviteLinkQuery, GetUserByInviteLinkQueryVariables>;
export const namedOperations = {
  Query: {
    GetUserForInviteLinkWithResetPasswordId: 'GetUserForInviteLinkWithResetPasswordId',
    GetClientOnboardingData: 'GetClientOnboardingData',
    GetUserByInviteLink: 'GetUserByInviteLink'
  }
}