import moment from 'moment';

const GetAvailableTaxYears = () => {
  const NUMBER_OF_PRIOR_YEARS = 9;
  const maxYear = moment().year();

  // // Months in Moment are zero-indexed
  // const APRIL_MONTH_INDEX = 4;
  // const TAX_DAY_OF_MONTH = 18;

  // // First year available is LAST year, unless it is after November, in which case it is this year
  // const calendarYear = moment().year();
  // const calendarMonth = moment().month() + 1;
  // const calendarDay = moment().date();
  // let maxYear;

  // if (
  //   calendarMonth > APRIL_MONTH_INDEX
  //   || (calendarMonth === APRIL_MONTH_INDEX && calendarDay >= TAX_DAY_OF_MONTH)
  // ) {
  //   maxYear = calendarYear;
  // } else {
  //   maxYear = calendarYear - 1;
  // }

  const yearOptions = [maxYear - 1, maxYear];

  for (let i = 2; i <= NUMBER_OF_PRIOR_YEARS; i += 1) {
    yearOptions.push(maxYear - i);
  }

  return yearOptions;
};

export default GetAvailableTaxYears;
