// import storage from 'redux-persist/lib/storage';
import { SET_LANG, SET_BRANDING } from './profile';
import { clearDocuments } from './documents';
import { resetInvoices } from './invoices';
import { setProjects, setActiveProject } from './projects';

export const GET_SESSION = 'GET_SESSION';
export const SET_SESSION = 'SET_SESSION';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SET_UPCOMING_APPOINTMENTS = 'SET_UPCOMING_APPOINTMENTS';

export const getSession = () => ({
  type: GET_SESSION,
});

export const updateFieldSession = (field, value) => ({
  type: UPDATE_FIELD,
  payload: { [field]: value },
});

export const setSession = (graphSession) => (dispatch) => {
  localStorage.setItem('accessToken', graphSession?.accessToken);
  dispatch({
    type: SET_SESSION,
    payload: { graphSession },
  });
};

export const setLang = (lang) => ({
  type: SET_LANG,
  payload: lang,
});

export const setBranding = (branding) => ({
  type: SET_BRANDING,
  payload: branding,
});

export const clearSession = () => (dispatch) => {
  dispatch({
    type: SET_UPCOMING_APPOINTMENTS,
    payload: [],
  });
  dispatch({
    type: SET_SESSION,
    payload: null,
  });
  dispatch(clearDocuments());
  dispatch(resetInvoices());
  dispatch(setProjects(null));
  dispatch(setActiveProject(null));
  localStorage.removeItem('accessToken');
  dispatch(setLang(null));
  dispatch(setBranding(null));
  dispatch(setSession(null));
};
