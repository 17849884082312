import { color, grey } from './colors';
import { Lato } from './fonts';

export const font = {
  fontFamily: [Lato, 'sans-serif'],
  fontSize: '16px',
  color: grey['700'],
  letterSpacing: '0.6px',
};

export const input = {
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
};

export const inputWithBorder = {
  ...input,
  ...font,
  minHeight: '48px',
  minWidth: '80px',
  width: 'auto',
  borderRadius: '6px',
  border: `1px solid ${grey['500']}`,
};

export const inputMaterial = {
  ...input,
  minWidth: '80px',
  width: 'auto',
  padding: '5px 0',
  fontFamily: 'Lato, sans-serif',
  fontSize: '16px',
  color: grey['700'],
  letterSpacing: '0.6px',
  borderBottom: `1px solid ${grey['500']}`,
};

export const label = {
  fontFamily: [Lato, 'sans-serif'],
  fontWeight: 'bold',
  fontSize: '13px',
  textTransform: 'uppercase',
  color: '#000',
};

export const labelInterview = {
  ...label,
  fontSize: '15px',
  letterSpacing: '0.3px',
  textTransform: 'none',
};

export const error = {
  minHeight: '20px',
  padding: '3px 0',
  fontFamily: [Lato, 'sans-serif'],
  fontSize: '12px',
  color: color.red,
};

export const formSelect = {
  option: (base, state) => ({
    ...base,
    position: 'relative',
    padding: '10px 18px',
    fontFamily: 'Lato, sans-serif',
    letterSpacing: '0.6px',
    maxWidth: '250px',
    fontSize: 14,
    backgroundColor: state.isSelected ? grey['700'] : 'white',
    color: state.isSelected ? 'white' : grey['700'],
    borderBottom: `1px solid ${grey['200']}`,
    '&:hover': {
      fontWeight: state.isSelected ? 'normal' : 'bold',
      backgroundColor: state.isSelected ? grey['700'] : grey['500'],
      color: 'white',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  }),
  control: (base, state) => ({
    ...inputMaterial,
    position: 'relative',
    display: 'table',
    tableLayout: 'fixed',
    minWidth: '80px',
    maxWidth: '250px',
    marginBottom: state.isFocused ? '-1px' : 0,
    padding: '3px 14px 3px 2px',
    width: '100%',
    borderBottom: state.isFocused ? `2px solid ${color.lightBlue}` : `1px solid ${grey['500']}`,
    '&:after': {
      position: 'absolute',
      top: 16,
      right: 8,
      display: 'block',
      content: "''",
      width: 1,
      height: 1,
      borderTop: state.isFocused ? `5px solid ${color.lightBlue}` : `5px solid ${grey['800']}`,
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid transparent',
      transform: state.isFocused ? 'rotate(180deg) translateY(4px)' : 'rotate(0deg)',
    },
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: () => ({
    maxWidth: '250px',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    color: grey['700'],
    letterSpacing: '0.6px',
    padding: 0,
    overflow: 'hidden',
  }),
  placeholder: () => ({
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    color: grey['400'],
    letterSpacing: '0.6px',
  }),
  singleValue: (base, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...base, opacity, transition, ...font, padding: 0,
    };
  },
  menu: (base) => ({
    ...base,
    maxWidth: '250px',
    margin: 0,
    border: `1px solid ${grey['200']}`,
    borderRadius: 'none',
    padding: 0,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.09)',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};
