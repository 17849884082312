import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Form,
  Button,
  Input,
  Checkbox,
  message,
} from 'antd';
import {
  // SubmitButton, Checkbox, Field, Input,
} from 'formik-antd';
import { useTranslation } from 'react-i18next';
import TwoFA from '../../Settings/Components/TwoFA';
import GetAvailableTaxYears from '../../helpers/taxYears';
import PhoneInputField from '../../Controls/PhoneInputField';
import DatePicker from '../../atoms/DatePicker';

import style from './style.module.scss';
import { useGetLanguagesQuery } from '../../../graphql/queries/settings';
import { useCreateClientFromInviteLinkMutation, useUpdateClientFromInviteLinkMutation } from '../../../graphql/mutations/users';
import { useGenerateClientOtpMutation } from '../../../graphql/mutations/settings';
import { useUpdateClientMutation } from '../../../graphql/mutations/clients';
import { PhoneType, TaxYearStatus } from '../../../graphql/index';
import moment from 'moment';
import { useLoginMutation } from '../../../graphql/mutations/login';
import { constructGraphSession } from '../../PrivateRoute';
import { useDispatch } from 'react-redux';
import { setSession } from '../../../redux/actions/session';
import { trackSignIn } from '../../helpers/woopra';
import { useHistory } from 'react-router-dom';
import { useGetInterviewNamesByFirmAccountIdQuery } from '../../../graphql/queries/interviews';
import { usePlanAccess, Features } from "../../atoms/PlanAccessProvider";

const InviteForm = ({
  customTextColor,
  firmAccountId,
  referredBy,
  preparer,
  user,
  uniqueId,
  progressStatuses,
}) => {
  const { i18n, t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { accessObject } = usePlanAccess();

  const [form] = Form.useForm();
  const [passwordsNotMatch, setPasswordsNotMatch] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [enableOTP, setEnableOTP] = useState(false);
  const [otpCode, setOtpCode] = useState(undefined);
  const [secretOTP, setSecretOTP] = useState(undefined);
  const [codeQR, setCodeQR] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const unassignedProgressStatusId = progressStatuses?.find((ps) => ps.underlyingStatus === TaxYearStatus.Unassigned)?.id;
  const inProgressProgressStatusId = progressStatuses?.find((ps) => ps.underlyingStatus === TaxYearStatus.InProgress)?.id;
  const initialMobileNumber = user?.UserInformation?.Phones?.find((x) => x.type === PhoneType.Mobile)?.value;

  // get languages from graphql
  const { data: languagesData, loading: loadingLanguages } = useGetLanguagesQuery();
  const { languages } = languagesData || {};
  // get jobs from graphql
  const { data: interviewsData, loading: loadingInterviews } = useGetInterviewNamesByFirmAccountIdQuery({
    variables: {
      firmAccountId,
    },
    skip: !firmAccountId,
  });
  const jobs = interviewsData?.interviewNamesByFirmAccountId;
  const [createClient] = useCreateClientFromInviteLinkMutation();
  const [updateClient] = useUpdateClientFromInviteLinkMutation();
  const [getOtpKeysMutation, { loading: loadingGetOtp }] = useGenerateClientOtpMutation({
    onError: (err) => message.error(err.message),
  });
  const [updateClientActivationLink] = useUpdateClientMutation({
    onError: (err) => message.error(err.message),
  });
  const [login] = useLoginMutation();

  const checkPasswordsMatch = () => {
    if (
      form.getFieldValue('password') !== form.getFieldValue('rePassword')
      && form.getFieldValue('password')
      // && form.getFieldValue('rePassword')
    ) {
      setPasswordsNotMatch(true);
    } else {
      setPasswordsNotMatch(false);
    }
  };

  const handleChangeLanguage = (value) => {
    const lang = languages.find((l) => String(l.id) === String(value));
    localStorage.setItem('lang', lang.lang.toLowerCase());
    i18n.changeLanguage(lang.lang.toLowerCase());
  };

  useEffect(() => {
    if (languages?.length && form) {
      if (form.setFieldsValue) {
        const defaultLang = localStorage.getItem('lang') || 'en';
        const defaultLangId = languages.find((l) => l.lang.toLowerCase() === defaultLang)?.id;
        form.setFieldsValue({ language: defaultLangId })
      }
    }
  }, [languages, form]);

  const handleOnFinishFailed = () => {
    message.error(translation('InviteForm.fixErrors'));
    // window.scrollTo(0, 0);
  }

  const getOtpKeys = async () => {
    const email = form.getFieldValue('email');
    if (!form.getFieldsError(['email']).some(({ errors }) => errors.length)
      && email?.includes('@')) {
      const res = await getOtpKeysMutation({
        variables: {
          email
        }
      });
      
      if (res.data?.generateClientOTP?.key) {
        setSecretOTP(res.data.generateClientOTP.key);
        setCodeQR(res.data.generateClientOTP.codeQR);
      }
    } else {
      message.error("Please enter a valid email in order to turn on Two-Factor Authentication");
    }
  }

  const submitForm = async (values) => {
    if (values.password === values.rePassword) {
      setLoading(true);

      let success = false;
      // put phones into an array
      const phones = [];
      if (values.landLine) {
        phones.push({ value: values.landLine, type: 'LANDLINE' });
      }
      if (values.mobileNumber && values.mobileNumber !== initialMobileNumber) {
        phones.push({ value: values.mobileNumber, type: 'MOBILE' });
      }
      if (values.workNumber) {
        phones.push({ value: values.workNumber, type: 'WORK' });
      }

      if (user) { // if user we update the user
        try {
          if (uniqueId) {
            const result = await updateClient({
              variables: {
                updateClientFromInviteLinkInput: {
                  UserInformation: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    Phones: phones,
                    birthday: values.birthday?.format("YYYY-MM-DD"),
                  },
                  userId: user.id,
                  passwordResetId: uniqueId,
                  password: values.password,
                  languageId: values.language,
                  enableOTP,
                  secretOTP,
                  codeOTP: otpCode,
                },
              },
            });
            if (result.data?.UpdateClientFromInviteLink) {
              success = true;
            }
          } else {
            const result = await updateClientActivationLink({
              variables: {
                updateClientInput: {
                  userInformation: {
                    id: user.UserInformation.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    Phones: phones,
                    birthday: values.birthday?.format("YYYY-MM-DD"),
                  },
                  password: values.password,
                  languageId: values.language,
                  email: user.email,
                }
              }
            });
            if (result.data?.UpdateClient.success) {
              success = true;
            }
          }
        } catch (error) {
          if (error.message.includes('4002')) {
            message.error(translation('Invite.form.alert.expired'), 5);
          } else if (error.message.includes('1005')) {
            message.error(translation('Invite.form.alert.password'));
          } else {
            message.error(error.toString());
          }
        }
      } else { // if no user we create a new user
        try {
          const result = await createClient({
            variables: {
              createClientFromInviteLinkInput: {
                email: values.email,
                password: values.password,
                taxYear: values.taxYear,
                interviewId: values.taxJob,
                languageId: values.language,
                firmAccountId,
                enableOTP,
                secretOTP,
                codeOTP: otpCode,
                referredByUser: referredBy,
                UserInformation: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  Phones: phones,
                  birthday: values.birthday?.format("YYYY-MM-DD"),
                },
                preparedId: preparer?.id ? preparer.id : null,
                progressStatusId: preparer?.id
                  ? inProgressProgressStatusId
                  : unassignedProgressStatusId,
              },
            },
          });
          if (result.data.CreateClientFromInviteLink) {
            success = true;
          }
        } catch (error) {
          if (error.message.includes('Error code 1002')) {
            message.error(translation('Invite.form.alert.msg1'), 10e3);
            setTimeout(() => {
              window.location.href = '/login';
            }, 10e3);
          } else if (error.message.includes('Error code 1003')) {
            message.error(translation('Invite.form.alert.password'));
          } else if (error.message.includes('Error code 4000')) {
            message.error(translation('Invite.form.alert.msg3'));
          } else {
            message.error(error.toString());
          }
        }
      } // end else
      if (success) {
        message.success(translation('InviteForm.success'));
        setLoading(false);
        try {
          const loginData = {
            email: values.email,
            password: values.password,
          };
          if (values.enableOTP) {
            loginData.code = values.codeOTP;
          }  
  
          const result = await login({
            variables: {
              loginInput: loginData,
            },
          });
    
          const graphSession = constructGraphSession(result.data.Login);
          dispatch(setSession(graphSession));
          trackSignIn();
    
          const fromUrl = history?.location?.state?.from?.pathname;
          history.replace(fromUrl ?? '/');  
        } catch (error) {
          message.error(error.toString());
        }
      }

    } else {
      message.error(translation('InviteForm.passwordsNotMatch'));
    }

    setLoading(false);
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Valid email is required!',
      number: '${label} is not a valid number!',
    },
    string: {
      len: "'${name}' must be exactly ${len} characters",
      min: "'${name}' must be at least ${min} characters",
      max: "'${name}' cannot be longer than ${max} characters",
      range: "${name} must be between ${min} and ${max} characters",
    },
    pattern: {
      mismatch: "The password is too weak",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFormChange = async () => {
    try {
      const disable = (!form.isFieldTouched('email') && !user)
      || (!form.isFieldTouched('firstName') && !user)
      || (!form.isFieldTouched('lastName') && !user)
      || !form.isFieldTouched('password')
      || !form.isFieldTouched('rePassword')
      || !form.isFieldTouched('terms')
      || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
      || !form.getFieldValue('terms') // couldn't get checkbox to work with built in validation
      setDisableSubmitButton(disable);
    } catch (errors) {
      setDisableSubmitButton(true);
    }
  };

  return (
    <Form
      onChange={onFormChange}
      form={form}
      className={style.InviteForm}
      layout="horizontal"
      validateMessages={validateMessages}
      initialValues={{
        email: user ? user.email : '',
        firstName: user?.UserInformation?.firstName ?? '',
        lastName: user?.UserInformation?.lastName ?? '',
        mobileNumber: initialMobileNumber ?? '',
        enableOTP,
        secretOTP,
        codeOTP: otpCode,
        taxYear: GetAvailableTaxYears()[0],
        taxJob: jobs ? jobs[0]?.id : '',
      }}
      onSubmit={submitForm}
      onFinish={submitForm}
      onFinishFailed={handleOnFinishFailed}
    >
      <Form.Item
        name="email"
        labelCol={{ span: 9 }}
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.email')}
          </span>
        )}
        required
        rules={[{ required: true, type: 'email' }]}
      >
        <Input
          name="email"
          placeholder={translation('InviteForm.placeholder.email')}
          disabled={!!user}
        />
      </Form.Item>
      <Form.Item
        name="firstName"
        labelCol={{ span: 9 }}
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.firstName')}
          </span>
        )}
        rules={[{ required: true, message: translation('InviteForm.required') }]}
      >
        <Input name="firstName" />
      </Form.Item>
      <Form.Item
        name="lastName"
        labelCol={{ span: 9 }}
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.lastName')}
          </span>
        )}
        rules={[{ required: true, message: translation('InviteForm.required') }]}
      >
        <Input name="lastName" />
      </Form.Item>

      <Form.Item
        name="birthday"
        labelCol={{ span: 9 }}
        label={translation('InviteForm.birthday')}
        initialValue={user?.UserInformation?.birthday ? moment(user?.UserInformation?.birthday) : undefined}
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder={translation('interview.formfield.datepicker.placeholder')}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        name="landLine"
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.landLine')}
          </span>
        )}
        rules={[{ required: false }]}
        labelCol={{ span: 9 }}
      >
        <PhoneInputField />
      </Form.Item>
      <Form.Item
        name="mobileNumber"
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.mobileNumber')}
          </span>
        )}
        rules={[{ required: true, message: translation('InviteForm.required') }]}
        labelCol={{ span: 9 }}
      >
        <PhoneInputField />
      </Form.Item>
      <Form.Item
        name="workNumber"
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.workNumber')}
          </span>
        )}
        rules={[{ required: false }]}
        labelCol={{ span: 9 }}
      >
        <PhoneInputField />
      </Form.Item>
      <Form.Item
        name="password"
        labelCol={{ span: 9 }}
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.createPassword')}
          </span>
        )}
        required
        rules={[{
          required: true,
          type: 'string',
          min: 8,
          max: 30,
          pattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})',
        }]}
      >
        <Input name="password" type="password" onChange={() => checkPasswordsMatch()} />
      </Form.Item>
      <Form.Item
        name="rePassword"
        labelCol={{ span: 9 }}
        label={(
          <span style={customTextColor}>
            {translation('InviteForm.confirmPassword')}
          </span>
        )}
        required
        rules={[{ required: true, message: translation('InviteForm.required') }]}
        validateStatus={passwordsNotMatch ? 'error' : undefined}
        hasFeedback={passwordsNotMatch}
        help={passwordsNotMatch ? "Passwords must match!" : undefined}
      >
        <Input name="rePassword" type="password" onChange={() => checkPasswordsMatch()} />
      </Form.Item>
      {!user && (
        <>
          <Form.Item
            name="taxYear"
            labelCol={{ span: 9 }}
            className={style.twoRowsRequired}
            label={(
              <span style={customTextColor}>
                {translation('InviteForm.taxYearFor')}
              </span>
            )}
            required
          >
            <Select
              className={style.InviteForm__select}
              name="taxYear"
              onSelect={(value) => form.setFieldsValue('taxYear', value)}
            >
              {GetAvailableTaxYears().map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="taxJob"
            labelCol={{ span: 9 }}
            className={style.twoRowsRequired}
            label={(
              <span style={{ ...customTextColor }}>
                {translation('InviteForm.taxFilingFor')}
              </span>
            )}
            required
          >
            <Select
              className={style.InviteForm__select}
              name="taxJob"
              onSelect={(value) => form.setFieldsValue('taxJob', value)}
              loading={loadingInterviews}
            >
              {jobs
                && jobs.map(({ id, name }) => {
                  // if user doesn't have access to entity interviews, only show an individual interview
                  let availableInterviewId;
                  if (!accessObject?.[Features.entityInterviews]) {
                    availableInterviewId = jobs?.filter((i) => i?.name?.toLowerCase().includes('individual'))[0]?.id || jobs?.[0]?.id;
                  }
                  if (!(availableInterviewId && id !== availableInterviewId)) { // the opposite of disabled
                    return (
                      <Select.Option value={id} key={id}>
                        {name}
                      </Select.Option>
                    );
                  }
                  return null;
                })}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        name="language"
        style={customTextColor}
        labelCol={{ span: 9 }}
        label={`${translation('InviteForm.language')} :`}
      >
        <Select
          className={style.InviteForm__select}
          name="language"
          onChange={handleChangeLanguage}
          onSelect={(value) => form.setFieldsValue('language', value)}
        >
          {!loadingLanguages && languages && languages.map((l) => (
            <Select.Option key={l.id} value={l.id}>
              {translation(`settings.lang.${l.lang.toLowerCase()}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="terms"
        style={{ textAlign: 'center' }}
        required
        valuePropName="checked"
        rules={[
          {
            required: true,
            validator: async (_, checked) => {
                if (!checked) {
                  return Promise.reject(
                    new Error("you must accept the Terms of use."),
                  );
                }
                return Promise.resolve();
            },
          },
        ]}
      >
        <Checkbox name="terms" required onChange={() => form.validateFields()}>
          {translation('InviteForm.termsAndConditions')}
          <a
            href="https://taxaroo.com/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            {translation('InviteForm.termsAndConditionsLink')}
          </a>
        </Checkbox>
      </Form.Item>

      {(!user || (user && uniqueId)) && (
        <TwoFA
          onToggle={(value) => {
            setEnableOTP(value);
            if (value) {
              getOtpKeys();
            }
          }}
          onChangeVerificationCode={(value) => setOtpCode(value)}
          secretOTP={secretOTP}
          codeQR={codeQR}
          loadingQR={loadingGetOtp}
        />
      )}

      <div className={style.InviteForm__submit}>
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={disableSubmitButton}
            >
              {translation('InviteForm.getStarted')}
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

InviteForm.defaultProps = {
  customTextColor: '',
};

InviteForm.propTypes = {
  customTextColor: PropTypes.object,
  // loadingSubmit: PropTypes.bool.isRequired,
};

export default InviteForm;
