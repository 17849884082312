import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Translation = ({ text, variables }) => {
  const { t } = useTranslation();

  if (variables && Object.keys(variables).length) {
    return t(text, variables);
  }

  const textSp = text.split(':');
  if (textSp.length) {
    const textT = textSp.shift();
    const textNT = textSp.join('');
    return `${t(textT, variables)}${textNT ? `:${textNT}` : ''}`;
  }
  return t(text);
};

Translation.propTypes = {
  text: PropTypes.string.isRequired,
  variables: PropTypes.objectOf(PropTypes.any),
};

Translation.defaultProps = {
  variables: {},
};

export default Translation;
