import { Button, Modal } from 'antd';
import SignatureCanvas from 'react-signature-canvas'
import { isMobileView } from '../../../../../../utils/screen';

const screenWidth = window.screen.width;
const canvasWidth = isMobileView() ? screenWidth - 35 : 900;
const canvasHeight = canvasWidth / 4.5;

const SignCanvasModal = ({
  isModalVisible,
  signatureCanvasRef,
  onClear,
  onCancel,
  onSignatureDrawComplete,
}) => (
  <Modal
    title="Create Signature"
    visible={isModalVisible}
    onCancel={onCancel}
    width={1000}
    bodyStyle={{
      backgroundColor: '#FFFFFF',
      textAlign: 'center',
      paddingLeft: isMobileView() ? 10 : 24,
      paddingRigth: isMobileView() ? 10 : 24,
    }}
    footer={[
      <Button
        key="buttonClear"
        type="default"
        onClick={onClear}
      >
        Clear
      </Button>,
      <Button
        key="buttonOk"
        type="primary"
        onClick={onSignatureDrawComplete}
      >
        OK
      </Button>,
    ]}
  >
    <SignatureCanvas
      ref={signatureCanvasRef}
      penColor="black"
      canvasProps={{
        width: canvasWidth,
        height: canvasHeight,
        className: 'sigCanvas',
        style: { backgroundColor: '#f0f2f5' },
      }}
    />
  </Modal>
);

export default SignCanvasModal;
