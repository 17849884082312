import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Row, Col } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import classes from './style.module.scss';
import Translation from '../atoms/Translation';
import UpdateForm from './UpdateForm';
import ShareButton from './ShareButton';
import ReferredCount from './ReferredCount';
import { useGetReferralsByUserIdQuery } from '../../graphql/queries/referrals';
import { useTranslation } from 'react-i18next';

const Referrals = (props) => {
  const { t: translation } = useTranslation();

  const {
    firmName, firmUniqueLink, firmId, preparedByUser, session,
  } = props;
  const { graphSession } = session;
  const {
    userId, firstName, uniqueLink
  } = graphSession;

  const preparerPersonalInvite = preparedByUser?.uniqueLink || preparedByUser?.id;
  const baseUrl = (!preparedByUser)
    ? `${window.location.protocol}//${window.location.host}/invite/${firmUniqueLink || firmId}?referrer=`
    : `${window.location.protocol}//${window.location.host}/invite/${firmUniqueLink || firmId}/${preparerPersonalInvite}?referrer=`;
  const [isUpdatingCode, setIsUpdatingCode] = useState(false);
  const [copyTooltipText, setCopyTooltipText] = useState(<Translation text="referrals.copy" />);
  const referralCode = uniqueLink || userId;

  const copyUrl = () => {
    navigator.clipboard.writeText(`${baseUrl}${referralCode}`);
    setCopyTooltipText(<Translation text="referrals.copied" />);
    setTimeout(() => {
      setCopyTooltipText(<Translation text="referrals.copy" />);
    }, 1500);
  };

  // graphql to get all referred
  const { data, loading, error } = useGetReferralsByUserIdQuery({
    variables: {
      userId,
    }
  });
  const { referralsByUserId: referredUsers } = data || {};

  return (
    <>
      <p className={classes.subText}>
        <Translation text="referrals.cta" />
        <Tooltip
          title={translation('referrals.tooltip')}
        >
          <InfoCircleOutlined
            style={{
              fontSize: 12,
              position: 'relative',
              top: '-1em',
              cursor: 'pointer',
            }}
          />
        </Tooltip>
        :
      </p>
      <div className={classes.row}>
        <p style={{ color: 'black', fontSize: '15px' }}>
          {!isUpdatingCode
            ? (
              <>
                <Tooltip title={copyTooltipText} placement="top" onClick={() => copyUrl()}>
                  <span style={{ cursor: 'pointer' }}>
                    {baseUrl}
                    {uniqueLink || userId}
                  </span>
                </Tooltip>
                <span className={classes.editIcon}>
                  <Tooltip title="Edit" placement="top">
                    <EditOutlined onClick={() => setIsUpdatingCode(true)} />
                  </Tooltip>
                </span>
              </>
            )
            : (
              baseUrl
            )}
        </p>
        {isUpdatingCode
          && (
            <UpdateForm
              setIsUpdatingCode={setIsUpdatingCode}
              referralCode={referralCode}
            />
          )}
      </div>
      {!isUpdatingCode
        && (
          <Row gutter={[10, 10]}>
            <Col>
              <ShareButton url={`${baseUrl}${referralCode}`} firmName={firmName} clientFirstName={firstName}>
                <Translation text="referrals.more" />
              </ShareButton>
            </Col>
            <Col>
              {/* todo: we need a referredBy column for clients or users */}
              <ReferredCount referred={referredUsers} />
            </Col>
          </Row>
        )}
    </>
  );
};

Referrals.propTypes = {
  firmName: PropTypes.string.isRequired,
  firmId: PropTypes.string.isRequired,
  firmUniqueLink: PropTypes.string,
    // preparedByUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Referrals.defaultProps = {
  firmUniqueLink: null,
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(Referrals);
