/* eslint-disable */
import { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { css, StyleSheet } from "aphrodite";
import { message, Spin } from "antd";

import { Icon } from "../Icon/Icon";
import { color, grey } from "../../styles/colors";
import { heading, text } from "../../styles/fonts";
import { closeInvoiceView } from "../../redux/actions/invoices";

import { withTranslation } from 'react-i18next';
import Translation from "../atoms/Translation";
import { useGetClientSecretFromPaymentIntentIdLazyQuery, useGetConnectAccountIdLazyQuery } from "../../graphql/queries/payments";
import CheckoutForm from "./CheckoutForm";
import { getStripeKey } from '../../api/config';
import { isMobileView } from "../../utils/screen";

const InvoiceRow = ({ invoice }) => {
  if (invoice.completedDate) return null;
  return (
    <>
      {invoice.Invoices &&
        invoice.Invoices.map((item, i) => (
          <div key={i} className={css(table.row)}>
            <div className={css(table.col, table.colFirst)}>
              {item?.description}
            </div>
            <div className={css(table.col)}>${item?.priceEach?.toFixed(2)}</div>
            <div className={css(table.col)}>{item?.quantity}</div>
            <div className={css(table.col, table.colLast)}>
              ${(item?.priceEach * item?.quantity)?.toFixed(2)}
            </div>
          </div>
        ))}
    </>
  );
};

const Payment = (props) => {
  const [invoice, setInvoice] = useState(props.invoice || null);
  const [clientSecret, setClientSecret] = useState(null);
  const [connectAccountId, setConnectAccountId] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };

  useEffect(() => {
    const { activeInvoice } = props;
    if (activeInvoice) {
      setInvoice(activeInvoice);
    }
  }, []);

  // grapql
  // fetch client secret
  const [getClientSecret, { loading: loadingGetClientSecret }] = useGetClientSecretFromPaymentIntentIdLazyQuery({
    onCompleted: (data) => {
      setClientSecret(data?.GetClientSecretFromPaymentIntentId);
    },
    onError: (error) => {
      message.error(error.message);
    }
  });
  // fetch the connectAccountId
  const [getConnectAccountId, { loading: loadingGetAccountId }] = useGetConnectAccountIdLazyQuery({
    onCompleted: (data) => {
      setConnectAccountId(data?.GetConnectAccountId);
    },
    onError: (error) => {
      message.error(error.message);
    }
  });

  useEffect(() => {
    // fetch the clientSecret
    if (invoice?.paymentIntentId) {
      getClientSecret({
        variables: {
          paymentIntentId: invoice?.paymentIntentId
        }
      });
      // fetch the connectAccountId
      getConnectAccountId({
        variables: {
          firmAccountId: invoice?.firmAccountId
        },
      });
    }
  }, [invoice]);

  useEffect(() => {
    if (connectAccountId) {
      const stripePromise = loadStripe(getStripeKey(), { stripeAccount: connectAccountId });
      setStripePromise(stripePromise);
    }
  }, [connectAccountId]);

  const onClose = (e) => {
    if (e.target === e.currentTarget) {
      props.dispatch(closeInvoiceView());
    }
  }

  const getTotalAmount = () => {
    if (!invoice) {
      return 0;
    }
    return invoice?.totalDollarAmount;
  };

  if (!invoice) {
    return null;
  }

  return (
    <div className={css(styles.overlay)} onClick={onClose}>
      <div className={css(styles.modal)}>
        <div className={css(styles.leftCol)}>
          <div className={css(styles.titleBlack)}>
            Invoice Info
            <span className={css(styles.iconHolderMobile)}>
              <Icon exit onClick={onClose} />
            </span>
          </div>
          <div className={css(styles.infoBlack)}>
            <Translation text="invoices.payment.info" />:
          </div>
          <div className={css(table.table)}>
            <div className={css(table.header)}>
              <div className={css(table.headerCol, table.headerColFirst)}>
                <Translation text="invoices.payment.description" />
              </div>
              <div className={css(table.headerCol)}>
                <Translation text="invoices.payment.priceEach" />
              </div>
              <div className={css(table.headerCol)}>
                <Translation text="invoices.payment.quantity" />
              </div>
              <div className={css(table.headerCol, table.headerColLast)}>
                <Translation text="invoices.payment.itemTotal" />
              </div>
            </div>
            <InvoiceRow invoice={invoice} />
            <div className={css(table.footer)}>
              <span className={css(table.totalTitle)}>
                <Translation text="invoices.payment.totalCharge" />
              </span>
              <span className={css(table.totalValue)}>
                ${getTotalAmount()?.toFixed(2)}
                <Translation text="invoices.payment.totalCurrency" />
              </span>
            </div>
          </div>
        </div>

        <div className={css(styles.rightCol)}>
          <div className={css(styles.title)}>
            <span className={css(styles.h2)}>
              <Translation text="invoices.payment.payInvoice" />
            </span>
            <span className={css(styles.iconHolder)}>
              <Icon exit onClick={onClose} />
            </span>
          </div>
          {loadingGetClientSecret && (
            <Spin />
          )}
          {clientSecret && stripePromise ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const width = {
  first: "calc(100% - 320px)",
  second: "100px",
  third: "100px",
  last: "120px",
};

const mobileWidth = {
  first: "calc(100% - 260px)",
  second: "92px",
  third: "80px",
  last: "80px",
};

const smallWidth = {
  first: "calc(100% - 200px)",
  second: "60px",
  third: "60px",
  last: "80px",
};

const table = StyleSheet.create({
  table: {
    margin: isMobileView() ? 0 : '0 -20px',
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "44px",
    border: `1px solid ${grey["200"]}`,
    backgroundColor: grey["700"],
    "@media (min-width: 460px)": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
  },
  headerCol: {
    padding: "12px 4px",
    color: color.white,
    ...heading.table,
    fontSize: "9px",
    textAlign: "right",
    width: smallWidth.second,
    "@media (min-width: 460px)": {
      width: mobileWidth.second,
      padding: "12px 10px",
      fontSize: "11px",
    },
    "@media (min-width: 960px)": {
      paddingLeft: "20px",
      width: width.second,
    },
  },
  headerColFirst: {
    paddingLeft: "15px",
    textAlign: "left",
    width: smallWidth.first,
    "@media (min-width: 460px)": {
      width: mobileWidth.first,
    },
    "@media (min-width: 960px)": {
      paddingLeft: "20px",
      width: width.first,
    },
  },
  headerColLast: {
    paddingRight: "15px",
    width: smallWidth.last,
    "@media (min-width: 460px)": {
      width: mobileWidth.last,
    },
    "@media (min-width: 960px)": {
      paddingLeft: "20px",
      width: width.last,
    },
  },
  rowInvoiceName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "40px",
    ...text.regular,
    padding: "10px 15px",
    fontWeight: "bold",
    letterSpacing: "0.4px",
    color: color.blue,
    borderBottom: `1px solid ${grey["400"]}`,
    backgroundColor: grey["100"],
    "@media (min-width: 768px)": {
      padding: "10px 20px",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "60px",
    backgroundColor: color.white,
    borderBottom: `1px solid ${grey["400"]}`,
  },
  rowLast: { borderBottom: "none" },
  col: {
    ...text.regular,
    width: smallWidth.second,
    padding: "10px 4px",
    letterSpacing: "0.4px",
    textAlign: "right",
    color: grey["700"],
    fontSize: "12px",
    "@media (min-width: 460px)": {
      width: mobileWidth.second,
      padding: "10px",
      fontSize: "14px",
    },
    "@media (min-width: 960px)": {
      width: width.second,
    },
  },
  colFirst: {
    width: smallWidth.first,
    paddingLeft: "15px",
    textAlign: "left",
    color: grey["800"],
    "@media (min-width: 460px)": {
      width: mobileWidth.first,
    },
    "@media (min-width: 960px)": {
      paddingLeft: "20px",
      width: width.first,
    },
  },
  colLast: {
    width: smallWidth.last,
    paddingRight: "15px",
    "@media (min-width: 460px)": {
      width: mobileWidth.last,
    },
    "@media (min-width: 960px)": {
      paddingRight: "20px",
      width: width.last,
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "66px",
    padding: "10px 15px",
    backgroundColor: color.white,
    "@media (min-width: 460px)": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    "@media (min-width: 960px)": {
      padding: "10px 20px",
    },
  },
  totalTitle: {
    ...heading.h3,
    fontSize: "12px",
    color: "#1d1d1d",
    "@media (min-width: 460px)": {
      fontSize: "13px",
    },
  },
  totalValue: {
    marginLeft: "30px",
    ...heading.h3,
    fontSize: "14px",
    letterSpacing: "1.3px",
    color: color.green,
    "@media (min-width: 460px)": {
      fontSize: "16px",
    },
  },
});

const styles = StyleSheet.create({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(159, 159, 159, 0.3)",
    overflowY: "auto",
    zIndex: "10",
  },
  modal: {
    position: "absolute",
    top: "40px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "460px",
    marginBottom: "40px",
    backgroundColor: color.white,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    zIndex: "100",
    "@media (min-width: 460px)": {
      width: "calc(100% - 20px)",
      left: "10px",
      marginLeft: 0,
      marginRight: 0,
      borderRadius: "5px",
    },
    "@media (min-width: 640px)": {
      width: "calc(100% - 40px)",
      left: "20px",
    },
    "@media (min-width: 768px)": {
      flexDirection: "row",
      top: "54px",
    },
    "@media (min-width: 1024px)": {
      width: "980px",
      left: "calc(50% - 490px)",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "@media (min-width: 768px)": {
      marginBottom: "40px",
    },
  },
  h2: {
    ...heading.modalHeading,
  },
  iconHolder: {
    position: "relative",
    display: "none",
    "@media (min-width: 768px)": {
      display: "block",
      top: "-15px",
      right: "5px",
    },

    "@media (min-width: 960px)": {
      top: "-30px",
      right: "-30px",
    },
  },
  iconHolderMobile: {
    position: "absolute",
    right: "10px",
    "@media (min-width: 768px)": {
      display: "none",
    },
  },
  leftCol: {
    width: "calc(100% + 6px)",
    marginLeft: "-3px",
    marginRight: "-3px",
    padding: "20px",
    // background: `${grey["700"]} url("/images/bg-payment.jpg") no-repeat`,
    background: grey["200"],
    backgroundSize: "cover",
    "@media (min-width: 460px)": {
      padding: "30px 20px",
    },
    "@media (min-width: 768px)": {
      width: "calc(100% - 320px)",
    },
    "@media (min-width: 960px)": {
      width: "calc(100% - 400px)",
      padding: "60px 40px",
    },
  },
  titleBlack: {
    position: "relative",
    marginBottom: "26px",
    ...heading.modalHeading,
    color: color.black,
  },
  infoBlack: {
    marginBottom: "50px",
    ...text.small,
    fontSize: "11px",
    color: color.black,
    "@media (min-width: 460px)": {
      fontSize: "12px",
    },
  },
  rightCol: {
    width: isMobileView() ? undefined : '100%',
    margin: isMobileView() ? '0 auto 0 0' : '0 auto',
    padding: '20px 15px',
    backgroundSize: 'cover',
  },
});

export default withTranslation()(Payment);