import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPasswordResetIncludeFirmQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetPasswordResetIncludeFirmQuery = { __typename?: 'Query', passwordResetIncludeFirm: { __typename?: 'PasswordResetEntity', id?: string | null, Users?: { __typename?: 'UserEntity', id: string, email?: string | null } | null } };


export const GetPasswordResetIncludeFirmDocument = gql`
    query GetPasswordResetIncludeFirm($id: String!) {
  passwordResetIncludeFirm(id: $id) {
    id
    Users {
      id
      email
    }
  }
}
    `;

/**
 * __useGetPasswordResetIncludeFirmQuery__
 *
 * To run a query within a React component, call `useGetPasswordResetIncludeFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordResetIncludeFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordResetIncludeFirmQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPasswordResetIncludeFirmQuery(baseOptions: Apollo.QueryHookOptions<GetPasswordResetIncludeFirmQuery, GetPasswordResetIncludeFirmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPasswordResetIncludeFirmQuery, GetPasswordResetIncludeFirmQueryVariables>(GetPasswordResetIncludeFirmDocument, options);
      }
export function useGetPasswordResetIncludeFirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPasswordResetIncludeFirmQuery, GetPasswordResetIncludeFirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPasswordResetIncludeFirmQuery, GetPasswordResetIncludeFirmQueryVariables>(GetPasswordResetIncludeFirmDocument, options);
        }
export type GetPasswordResetIncludeFirmQueryHookResult = ReturnType<typeof useGetPasswordResetIncludeFirmQuery>;
export type GetPasswordResetIncludeFirmLazyQueryHookResult = ReturnType<typeof useGetPasswordResetIncludeFirmLazyQuery>;
export type GetPasswordResetIncludeFirmQueryResult = Apollo.QueryResult<GetPasswordResetIncludeFirmQuery, GetPasswordResetIncludeFirmQueryVariables>;
export const namedOperations = {
  Query: {
    GetPasswordResetIncludeFirm: 'GetPasswordResetIncludeFirm'
  }
}