import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { setDefaultLocale } from 'react-datepicker';
import classes from './style.module.scss';
import Translation from '../../../atoms/Translation';

import { setUserLanguage } from '../../../../redux/actions/profile';
import { useGetLanguagesQuery } from '../../../../graphql/queries/settings';
import { useUpdateSelfUserMutation } from '../../../../graphql/mutations/settings';

const Language = (props) => {
  const { dispatch, session } = props;
  const { graphSession } = session;
  const { userId } = graphSession;
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [currentLanguageId, currentLanguageIdSet] = useState(1);
  const [currentLanguage, currentLanguageSet] = useState(1);

  const { t, i18n } = useTranslation();

  const { data: languagesData, loading: loadingLanguages, error: languagesError } = useGetLanguagesQuery();
  const [updateSelfUser] = useUpdateSelfUserMutation()
  const { languages } = languagesData || {};

  const changeLang = async () => {
    if (currentLanguage === localStorage.getItem('lang')) return;
    setMsgSuccess(false);
    setErrors(false);
    setLoading(true);
    const updateLang = await updateSelfUser({
      variables: {
        updateUserInput: {
          languageId: currentLanguageId,
        }
      },
    });
    setLoading(false);
    if (updateLang) {
      dispatch(setUserLanguage(currentLanguage));
      localStorage.setItem('lang', currentLanguage?.toLowerCase());
      i18n.changeLanguage(currentLanguage?.toLowerCase());
      setDefaultLocale(currentLanguage);
      setMsgSuccess(true);

      setTimeout(() => {
        setMsgSuccess(false);
      }, 4000);
    } else {
      setErrors(true);
      setTimeout(() => {
        setErrors(false);
      }, 4000);
    }
  };

  const handleChange = ({ target: { value } }) => {
    const lang = languages.find((l) => String(l.id) === String(value));
    currentLanguageIdSet(lang.id);
    currentLanguageSet(lang.lang);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    changeLang();
  };

  useEffect(() => {
    if (!loadingLanguages && !languagesError) {
      if (languages.length) {
        const defaultLang = localStorage.getItem('lang') || 'en';
        if (defaultLang !== 'en' && defaultLang !== 'es') {
          localStorage.setItem('lang', 'en');
          i18n.changeLanguage('en');
          setDefaultLocale('en');
        }
        const defaultLangId = languages.find((l) => l.lang?.toLowerCase() === defaultLang);
        currentLanguageIdSet(defaultLangId?.id);
        currentLanguageSet(defaultLangId?.lang);
      }
    }
  }, [languages]);

  if (loadingLanguages) return null;
  if (languagesError) return null;
  if (!languagesData) return null;

  return (
    <div className="card p-4 container my-3">
      <h2>
        <Translation text="settings.changeLanguage" />
      </h2>
      <hr style={{ margin: 0 }} />
      <div className={classes.container}>
        <Form
          className="col-8"
          style={{ margin: '1rem 0' }}
          onSubmit={handleSubmit}
        >
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Control
              as="select"
              name="currentLanguage"
              value={currentLanguageId}
              className={errors.currentLanguage ? 'is-invalid' : ''}
              type="password"
              placeholder="Current Password"
              onChange={handleChange}
            >
              {languages.map((l) => (
                <option key={l.id} value={l.id}>
                  {t(`settings.lang.${l.lang?.toLowerCase()}`)}
                </option>
              ))}
            </Form.Control>
            {errors && (
              <div className={classes.invalid}>
                <Translation text="settings.changeLanguage.error" />
              </div>
            )}
          </Form.Group>

          <Button variant="primary" type="submit">
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">
                  <Translation text="settings.loading" />
                  ...
                </span>
              </div>
            ) : (
              <Translation text="settings.changeLanguage" />
            )}
          </Button>

          {msgSuccess && (
            <div data-cy="changesSaved" className={classes.status}>
              <i className="fa fa-check" />
              <Translation text="settings.changeLanguage.success" />
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(Language);
