import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSignedSignatureBySignatureFieldIdQueryVariables = Types.Exact<{
  signatureFieldId: Types.Scalars['String'];
}>;


export type GetSignedSignatureBySignatureFieldIdQuery = { __typename?: 'Query', getSignedSignatureBySignatureFieldId: string };


export const GetSignedSignatureBySignatureFieldIdDocument = gql`
    query GetSignedSignatureBySignatureFieldId($signatureFieldId: String!) {
  getSignedSignatureBySignatureFieldId(signatureFieldId: $signatureFieldId)
}
    `;

/**
 * __useGetSignedSignatureBySignatureFieldIdQuery__
 *
 * To run a query within a React component, call `useGetSignedSignatureBySignatureFieldIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedSignatureBySignatureFieldIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedSignatureBySignatureFieldIdQuery({
 *   variables: {
 *      signatureFieldId: // value for 'signatureFieldId'
 *   },
 * });
 */
export function useGetSignedSignatureBySignatureFieldIdQuery(baseOptions: Apollo.QueryHookOptions<GetSignedSignatureBySignatureFieldIdQuery, GetSignedSignatureBySignatureFieldIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedSignatureBySignatureFieldIdQuery, GetSignedSignatureBySignatureFieldIdQueryVariables>(GetSignedSignatureBySignatureFieldIdDocument, options);
      }
export function useGetSignedSignatureBySignatureFieldIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedSignatureBySignatureFieldIdQuery, GetSignedSignatureBySignatureFieldIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedSignatureBySignatureFieldIdQuery, GetSignedSignatureBySignatureFieldIdQueryVariables>(GetSignedSignatureBySignatureFieldIdDocument, options);
        }
export type GetSignedSignatureBySignatureFieldIdQueryHookResult = ReturnType<typeof useGetSignedSignatureBySignatureFieldIdQuery>;
export type GetSignedSignatureBySignatureFieldIdLazyQueryHookResult = ReturnType<typeof useGetSignedSignatureBySignatureFieldIdLazyQuery>;
export type GetSignedSignatureBySignatureFieldIdQueryResult = Apollo.QueryResult<GetSignedSignatureBySignatureFieldIdQuery, GetSignedSignatureBySignatureFieldIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetSignedSignatureBySignatureFieldId: 'GetSignedSignatureBySignatureFieldId'
  }
}