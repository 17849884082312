export const color = {
  white: '#ffffff',
  lightBlue: '#78bffd',
  mattBlue: '#87bbcf',
  blue: '#3d80f3',
  darkBlue: '#001def',
  orange: '#fdcb5c',
  green: '#81ddb1',
  black: '#000000',
  red: '#ff4316',
  lightRed: '#ff7e00',
};

export const grey = {
  100: '#f9f9f9',
  200: '#f0f0f0',
  300: '#f6f8f9',
  400: '#d8d8d8',
  500: '#c2cdd2',
  600: '#616161',
  700: '#676e71',
  800: '#4c4c4c',
  900: '#9f9f9f',
};
