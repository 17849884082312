import {
  CLOSE_INVOICE_VIEW,
  INVOICES_LIST_ISLOADING,
  RESET_INVOICES,
  SHOW_PAYMENT,
  SHOW_MULTIPLE_PAYMENT,
  SET_INVOICES_LIST,
  VIEW_INVOICE,
} from '../actions/invoices';

const initialState = {
  activeInvoice: null,
  invoices: null,
  show: 'list',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INVOICES_LIST_ISLOADING:
      return {
        ...state,
        invoices: 'loading',
      };
    case SET_INVOICES_LIST:
      return {
        ...state,
        invoices: payload,
      };
    case VIEW_INVOICE:
      return {
        ...state,
        activeInvoice: payload,
        show: 'view',
      };
    case CLOSE_INVOICE_VIEW:
      return {
        ...state,
        activeInvoice: null,
        show: 'list',
      };
    case SHOW_PAYMENT:
      return {
        ...state,
        show: 'payment',
        activeInvoice: payload,
      };
    case SHOW_MULTIPLE_PAYMENT:
      return {
        ...state,
        show: 'payment',
        activeInvoice: null,
      };
    case RESET_INVOICES:
      return initialState;
    default:
      return state;
  }
};
