import { DatePicker as AntdDatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const DEFAULT_FORMAT = 'MM/DD/YYYY';
const SEPARATOR = '/';

const DatePicker = ({
  format,
  ...restProps
}) => {
  const { t: translation } = useTranslation();
  const realFormat = format ?? DEFAULT_FORMAT;

  const handleKeyDown = (event) => {
    if (realFormat !== DEFAULT_FORMAT || event.key < '0' || event.key > '9') {
      return;
    }

    const input = event.target;
    const srcValue = input.value;
    const pos = event.currentTarget.selectionStart ?? 0;
    let newValue = srcValue.substring(0, pos) + event.key + srcValue.substring(pos);
    if (![2, 5].includes(newValue.length)) {
      return;
    }

    newValue += SEPARATOR;
    input.value = newValue;
    event.preventDefault();
  };

  return (
    <AntdDatePicker
      onKeyDown={handleKeyDown}
      picker="date"
      format={realFormat}
      placeholder={translation('datepicker.placeholder')}
      {...restProps}
    />
  );
};

export default DatePicker;
