/* eslint-disable */
import { color, grey } from './colors';
import { Lato } from './fonts';

const p = {
  left: '30px',
  right: '30px',
};

const paddingMobile = {
  left: '15px',
  right: '15px',
};

export const table = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: grey['700'],
    '@media (min-width: 640px)': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
  headerCol: {
    // padding: '16px 6px',
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '9px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: color.white,
    '@media (min-width: 400px)': {
      fontSize: '11px',
    },
    '@media (min-width: 640px)': {
      // padding: '24px 20px 22px',
    },
  },
  headerColFirst: {
    padding: `16px 6px 16px ${paddingMobile.left}`,
    '@media (min-width: 640px)': {
      padding: `24px 10px 22px ${p.left}`,
    },
  },
  headerColLast: {
    paddingRight: paddingMobile.right,
    '@media (min-width: 640px)': {
      paddingRight: p.right,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${grey['400']}`,
    borderRight: `1px solid ${grey['400']}`,
    borderBottom: `1px solid ${grey['400']}`,
  },
  rowLast: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${grey['400']}`,
    borderRight: `1px solid ${grey['400']}`,
    borderBottom: `1px solid ${grey['400']}`,
    '@media (min-width: 640px)': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  cell: {
    padding: '20px 10px',
    fontFamily: [Lato, 'sans-serif'],
    fontSize: '9px',
    lineHeight: '1.4',
    letterSpacing: '0.2px',
    fontWeight: 'normal',
    color: grey['800'],
    wordBreak: 'break-word',
    '@media (min-width: 400px)': {
      fontSize: '11px',
    },
  },
  cellFirst: { paddingLeft: paddingMobile.left, '@media (min-width: 640px)': { paddingLeft: p.left } },
  cellLast: { paddingRight: paddingMobile.right, '@media (min-width: 640px)': { paddingRight: p.right } },
};
