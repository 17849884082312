import React from 'react';

const DownloadCloud = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 1000 1000">
    <g>
      <path fill="#3d80f3" d="M623.1,787.7l23.2,23.2L500,957.2L353.7,810.9l23.2-23.2l106.9,106.9V467h32.6v427.6L623.1,787.7L623.1,787.7z M810.7,303.8c0-2.7,0.4-5.2,0.4-7.7c0-140-113.4-253.3-253.1-253.3c-100.7,0-187.5,59-228.2,144.2c-17.6-8.8-37.5-14-58.6-14c-64.7,0-118.3,47.1-128.7,108.8C65.5,308.2,10,381.2,10,467.2C10,575.3,97.7,663,205.9,663h212.6v-32.8H205.9c-90,0-163.1-73.3-163.1-163.3c0-69.7,44.4-131.7,110.5-154.5l18.4-6.3l3.2-19.1c8.1-47.3,48.6-81.6,96.5-81.6c15.3,0,30.1,3.6,43.8,10.5l29.9,14.9l14.4-30.1C396,124.4,474,75.2,558.2,75.2c121.4,0.4,220.4,99.2,220.4,220.8c0,0.6,0,1.3-0.2,2.1c-0.2,1.5-0.2,3.2-0.2,5l-0.8,33.3l33.3,0.2c80.8,0.2,146.7,66.2,146.7,147.1c0,80.6-65.7,146.5-146.3,146.9H581.6v32.7h229.6C910,662.7,990,582.5,990,483.6C990,384.4,909.8,304,810.7,303.8L810.7,303.8z" />
    </g>
  </svg>
);

export default DownloadCloud;
