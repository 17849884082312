import { useState, useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
// import {
//   auth,
//   startRecording,
//   stopRecording,
//   getRecordingStatus,
// } from '../helpers/recording';
// import API from '../../../../api';
import * as config from '../../../../api/config';
import { message } from 'antd';
import { useAgoraAccessTokenMutation } from '../../../../graphql/mutations/messaging'

const sendMessage = async (channel, userId, status) => null; /* {
  const result = await API.post('pusher/message', {
    channel,
    event: 'videocall',
    message: {
      userId,
      status: status ? 'start' : 'end',
    },
  });
  if (result.status === 200) return result.data.token;
  return null;
}; */

/**
 * Hook Agora For More Details (https://agoraio-community.github.io/AgoraWebSDK-NG/en/)
 *
 * @param {object} client Agora Client
 * @param {object} initialConfig Initial Configuration (video, audio)
 * @param {number} userId User ID
 * @param {object} channelId Channel to connect
 * @returns {object} {leave, join, local, users, joinState, mute, unmute, shareScreen, recording}
 */
export default function useAgora(client, initialConfig, userId, channelId) {
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
  const [localScreenVideoTrack, setLocalScreenVideoTrack] = useState(undefined);
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  // const [resourceId, setResourceId] = useState(null);
  // const [sid, setSid] = useState(null);

  const [getAccessToken] = useAgoraAccessTokenMutation({
    onError: (err) => message.error(err.message),
  });

  const getToken = async (uid, channelName) => {
    const result = await getAccessToken({
      variables: {
        userId: uid,
        channelId: channelName,
      }
    });

    return result.data.AgoraAccessToken;
  };

  /**
   * Create Local Video Stack
   *
   * @param {boolean} isFirst is First creation?
   * @returns {object} CameraStack
   */
  async function createLocalVideoTrack(isFirst) {
    try {
      const cameraTrack = await AgoraRTC.createCameraVideoTrack();
      if (!isFirst) await client.publish(cameraTrack);
      setLocalVideoTrack(cameraTrack);
      return cameraTrack;
    } catch (error) {
      return null;
    }
  }

  /**
   * Delete Local Video Stack
   */
  async function deleteLocalVideoTrack() {
    if (localVideoTrack) {
      try {
        localVideoTrack.stop();
        localVideoTrack.close();
        await client.unpublish(localVideoTrack);
        setLocalVideoTrack(undefined);
      } catch (error) {
        // console.warn(error);
      }
    }
  }

  /**
   * Create Local Audio Stack
   *
   * @param {boolean} isFirst is First creation?
   * @returns {object} AudioStack
   */
  async function createLocalAudioTrack(isFirst) {
    try {
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      setLocalAudioTrack(audioTrack);
      if (!isFirst) await client.publish(audioTrack);
      return audioTrack;
    } catch (error) {
      return null;
    }
  }

  /**
   * Delete Local Audio Stack
   */
  async function deleteLocalAudioTrack() {
    if (localAudioTrack) {
      try {
        localAudioTrack.stop();
        localAudioTrack.close();
        await client.unpublish(localAudioTrack);
        setLocalAudioTrack(undefined);
      } catch (error) {
        // console.warn(error);
      }
    }
  }

  /**
   * Delete Local Screen Stack (shareScreen)
   */
  async function deleteLocalScreenVideoTrack() {
    if (localScreenVideoTrack) {
      try {
        localScreenVideoTrack.stop();
        localScreenVideoTrack.close();
        await client.unpublish(localScreenVideoTrack);
        setLocalScreenVideoTrack(undefined);
      } catch (error) {
        // console.warn(error);
      }
    }
  }

  /**
   * Create Local Tracks (video or audio depending on initialConfig)
   *
   * @returns {Array} [audioTrack, cameraTrack]
   */
  async function createLocalTracks() {
    let audioTrack = null;
    let cameraTrack = null;
    if (initialConfig.video && initialConfig.audio) {
      [
        audioTrack,
        cameraTrack,
      ] = await AgoraRTC.createMicrophoneAndCameraTracks();
      setLocalAudioTrack(audioTrack);
      setLocalVideoTrack(cameraTrack);
    } else {
      if (initialConfig.audio) audioTrack = await createLocalAudioTrack(true);
      if (initialConfig.video) cameraTrack = await createLocalVideoTrack(true);
    }
    return [audioTrack, cameraTrack];
  }

  /**
   * Join to VideoCall
   */
  async function join() {
    if (!client) return;
    const [microphoneTrack, cameraTrack] = await createLocalTracks();
    const toPublish = [];
    if (microphoneTrack) toPublish.push(microphoneTrack);
    if (cameraTrack) toPublish.push(cameraTrack);

    const token = await getToken(userId, channelId);
    await client.join(config.agoraID, channelId, token || null, userId);
    await client.publish(toPublish);

    window.client = client;
    if (cameraTrack) window.videoTrack = cameraTrack;

    setJoinState(true);
  }

  /**
   * Leave to VideoCall
   */
  async function leave() {
    await Promise.all([
      deleteLocalAudioTrack(),
      deleteLocalVideoTrack(),
      deleteLocalScreenVideoTrack(),
    ]);
    setRemoteUsers([]);
    await client.leave();
  }

  /**
   * @param {string} pusherChannel pusher channel to send message (notify share screen)
   * @param {boolean} value True = Share, False = Stop
   */
  async function shareScreen(pusherChannel, value) {
    if (client) {
      if (value) {
        const screen = await AgoraRTC.createScreenVideoTrack({}, 'disable');
        if (localVideoTrack) await client.unpublish(localVideoTrack);
        await Promise.all([
          sendMessage(pusherChannel, userId, value),
          client.publish(screen),
        ]);
        setLocalScreenVideoTrack(screen);
      } else {
        await Promise.all([
          sendMessage(pusherChannel, userId, value),
          deleteLocalScreenVideoTrack(),
        ]);
        if (localVideoTrack) await client.publish(localVideoTrack);
      }
    }
  }

  /**
   * @param {string} disp Mute Audio/Video ["audio", "video"]
   */
  async function mute(disp) {
    if (disp === 'audio') await deleteLocalAudioTrack();
    else if (disp === 'video') await deleteLocalVideoTrack();
  }

  /**
   * @param {string} disp UnMute Audio/Video ["audio", "video"]
   */
  async function unmute(disp) {
    if (disp === 'audio') await createLocalAudioTrack();
    else if (disp === 'video') await createLocalVideoTrack();
  }

  /**
   * Start Recording VideoCall
   */
  // async function start() {
  //   const resource = await auth(userId, channel.name);
  //   const token = await getToken(userId, channel.name);
  //   const newSid = await startRecording(resource, userId, channel, token);
  //   setResourceId(resource);
  //   setSid(newSid);
  // }

  /**
   * Get Status Recording VideoCall
   *
   * @returns {object} Status Recording VideoCall
   */
  // async function getStatus() {
  //   const status = await getRecordingStatus(resourceId, sid);
  //   return status;
  // }

  /**
   * Stop Recording VideoCall
   */
  // async function stop() {
  //   await stopRecording(userId, channel.name, resourceId, sid);
  //   setResourceId(null);
  //   setSid(null);
  // }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserJoined = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserLeft = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);

    // eslint-disable-next-line consistent-return
    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
    };
  }, [client]);

  return {
    local: {
      video: !!localVideoTrack,
      audio: !!localAudioTrack,
      screen: !!localScreenVideoTrack,
    },
    users: [
      ...remoteUsers,
      {
        audioTrack: localAudioTrack,
        videoTrack: localVideoTrack,
        screenTrack: localScreenVideoTrack,
        uid: client.uid,
      },
    ],
    joinState,
    leave,
    join,
    mute,
    unmute,
    shareScreen,
    // recording: {
    //   start,
    //   getStatus,
    //   stop,
    //   sid,
    // },
  };
}
