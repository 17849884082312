/* eslint-disable */
import moment from 'moment';

const getLang = () => localStorage.getItem('lang') || 'en';

const getMomentLocale = (lang, time, utc = false) => {
  const momentLocale = utc ? moment.utc(time) : moment(time);
  if (lang !== 'en') {
    const momentLang = require('moment/locale/es');
    momentLocale.locale(lang, [momentLang]);
  }
  return momentLocale;
};

const getMomentLocaleTime = (lang, time, utc = false) => {
  const momentLocale = getMomentLocale(lang, time, utc);

  let dateStr = momentLocale.format('MMMM DD, YYYY');
  const timeStr = momentLocale.format('h:mm a');

  // reset moment because makes a conflict with react-calendar
  moment.locale('en');

  if (lang === 'es') {
    dateStr = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
  }

  return {
    date: dateStr,
    time: timeStr,
  };
};

export const utcToCustomTime = (time, utc = true) => {
  const lang = getLang();
  const result = getMomentLocaleTime(lang, time, utc);
  if (time && result) {
    return `${result.date} ${result.time}`;
  }
  return '-';
};

export const utcToCustomTimeAndDate = (time, utc = true) => {
  const lang = getLang();
  return getMomentLocaleTime(lang, time, utc);
};
