import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFirmAccountByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFirmAccountByIdQuery = { __typename?: 'Query', FirmAccountById: { __typename?: 'FirmAccountEntity', id: string, name?: string | null } };

export type GetFirmAccountForInviteLinkQueryVariables = Types.Exact<{
  firmId: Types.Scalars['String'];
  preparerId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetFirmAccountForInviteLinkQuery = { __typename?: 'Query', FirmAccountForInviteLink: { __typename?: 'FirmAccountEntity', id: string, name?: string | null, CustomLayout?: { __typename?: 'CustomLayoutEntity', id: string, headerBackgroundColor?: string | null, headerFontColor?: string | null, logo?: string | null, pixelCode?: string | null } | null, ProgressStatuses?: Array<{ __typename?: 'ProgressStatusesEntity', id: string, status: string, underlyingStatus: Types.TaxYearStatus }> | null, UserFirmAccounts?: Array<{ __typename?: 'UserFirmAccountEntity', id: string, Users?: { __typename?: 'UserEntity', id: string, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null }> | null } };


export const GetFirmAccountByIdDocument = gql`
    query GetFirmAccountById($id: String!) {
  FirmAccountById(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetFirmAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetFirmAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFirmAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFirmAccountByIdQuery, GetFirmAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmAccountByIdQuery, GetFirmAccountByIdQueryVariables>(GetFirmAccountByIdDocument, options);
      }
export function useGetFirmAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmAccountByIdQuery, GetFirmAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmAccountByIdQuery, GetFirmAccountByIdQueryVariables>(GetFirmAccountByIdDocument, options);
        }
export type GetFirmAccountByIdQueryHookResult = ReturnType<typeof useGetFirmAccountByIdQuery>;
export type GetFirmAccountByIdLazyQueryHookResult = ReturnType<typeof useGetFirmAccountByIdLazyQuery>;
export type GetFirmAccountByIdQueryResult = Apollo.QueryResult<GetFirmAccountByIdQuery, GetFirmAccountByIdQueryVariables>;
export const GetFirmAccountForInviteLinkDocument = gql`
    query GetFirmAccountForInviteLink($firmId: String!, $preparerId: String) {
  FirmAccountForInviteLink(firmId: $firmId, preparerId: $preparerId) {
    id
    name
    CustomLayout {
      id
      headerBackgroundColor
      headerFontColor
      logo
      pixelCode
    }
    ProgressStatuses {
      id
      status
      underlyingStatus
    }
    UserFirmAccounts {
      id
      Users {
        id
        UserInformation {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetFirmAccountForInviteLinkQuery__
 *
 * To run a query within a React component, call `useGetFirmAccountForInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmAccountForInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmAccountForInviteLinkQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *      preparerId: // value for 'preparerId'
 *   },
 * });
 */
export function useGetFirmAccountForInviteLinkQuery(baseOptions: Apollo.QueryHookOptions<GetFirmAccountForInviteLinkQuery, GetFirmAccountForInviteLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmAccountForInviteLinkQuery, GetFirmAccountForInviteLinkQueryVariables>(GetFirmAccountForInviteLinkDocument, options);
      }
export function useGetFirmAccountForInviteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmAccountForInviteLinkQuery, GetFirmAccountForInviteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmAccountForInviteLinkQuery, GetFirmAccountForInviteLinkQueryVariables>(GetFirmAccountForInviteLinkDocument, options);
        }
export type GetFirmAccountForInviteLinkQueryHookResult = ReturnType<typeof useGetFirmAccountForInviteLinkQuery>;
export type GetFirmAccountForInviteLinkLazyQueryHookResult = ReturnType<typeof useGetFirmAccountForInviteLinkLazyQuery>;
export type GetFirmAccountForInviteLinkQueryResult = Apollo.QueryResult<GetFirmAccountForInviteLinkQuery, GetFirmAccountForInviteLinkQueryVariables>;
export const namedOperations = {
  Query: {
    GetFirmAccountById: 'GetFirmAccountById',
    GetFirmAccountForInviteLink: 'GetFirmAccountForInviteLink'
  }
}