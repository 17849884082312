import { useEffect, useState } from 'react';
import {
  Alert, Spin, Row, message
} from 'antd';
import MetaTags from 'react-meta-tags';
import { useDispatch } from "react-redux";
import {
  useParams, useLocation, Redirect, useHistory
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../components/Layout/Page';
import Header from '../components/Layout/Header';
import Invite from '../components/Pages/Invite';
import { useGetFirmAccountForInviteLinkLazyQuery } from '../graphql/queries/firmAccount';
import {
  useGetUserForInviteLinkWithResetPasswordIdLazyQuery,
  useGetClientOnboardingDataLazyQuery,
  useGetUserByInviteLinkLazyQuery,
} from '../graphql/queries/users';
import { updateFieldSession } from '../redux/actions/session';

const InviteContainer = () => {
  const { firmId, preparerId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const uniqueId = location?.state?.params?.uniqueId; // this is the passwordResetId
  const { t: translation } = useTranslation();

  let { search } = location;
  const accessToken = new URLSearchParams(search).get('access');

  search = search.replace('?', '');
  let referredBy = null;
  try {
    const paramsObj = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
    referredBy = paramsObj.referrer;
  } catch (error) { /* Pass */ }

  const [logo, setLogo] = useState(null);
  const [getOnboardingData, { loading: clientDataLoading, data: clientData }] = useGetClientOnboardingDataLazyQuery();
  const [getUserByUniqueLink, { data: referredByUser }] = useGetUserByInviteLinkLazyQuery();

  // useGraphQL to get firmAccount, preparer
  const [getFirmAccount, { data: firmAccountData, loading: loadingFirmAccount, error: firmAccountError }] = useGetFirmAccountForInviteLinkLazyQuery();
  const [getUser, { data: userData, loading: loadingUser, error: userError }] = useGetUserForInviteLinkWithResetPasswordIdLazyQuery();
  const user = userData?.UserForInviteLinkWithResetPasswordId || clientData?.ClientOnboardingData || null;
  const firm = firmAccountData?.FirmAccountForInviteLink
    || userData?.UserForInviteLinkWithResetPasswordId?.EntitiesOwned[0]?.UserFirmAccount[0]?.FirmAccount
    || user?.FirmAccount || {};
  const error = firmAccountError || userError || null;
  const loading = loadingFirmAccount || loadingUser || false;
  const { CustomLayout } = firm || {};

  useEffect(() => {
    if (accessToken) {
      dispatch(updateFieldSession('accessToken', accessToken));
      history.replace('/invite/activate/pre');
    }
  }, []);

  useEffect(() => {
    if (clientData?.ClientOnboardingData) {
      if (clientData.ClientOnboardingData.alreadyActive) {
        message.error("Looks like your account is already activated. Please login.", 10);
        setTimeout(() => {
          history.replace('/login');
        }, 500);
      } else if (clientData.ClientOnboardingData.expired) {
        message.error("Your activation link has expired! Please contact your tax preparer for a new link.", 10);
      }
    }
  }, [clientData])

  // calls the appropriate GraphQL query
  useEffect(() => {
    if (uniqueId) {
      getUser({
        variables: {
          passwordResetId: uniqueId,
        }
      });
    } else if (firmId === 'activate') {
      getOnboardingData();
    } else if (firmId) {
      getFirmAccount({
        variables: {
          firmId,
          preparerId,
        }
      });
    }
  }, [firmId, preparerId, uniqueId]);

  useEffect(() => {
    if (referredBy && firm?.id) {
      // fetch the userId of the referrer
      getUserByUniqueLink({
        variables: {
          uniqueLink: referredBy,
          firmAccountId: firm.id,
        }
      });
    }
  }, [referredBy, firm?.id]);

  useEffect(() => {
    if (CustomLayout?.logo) {
      setLogo(CustomLayout?.logo);
    }
  }, [CustomLayout?.logo]);

  if (loading || clientDataLoading) {
    return (
      <Row justify="center" align="middle" style={{ height: '90vh' }}>
        <Spin size="large" />
      </Row>
    );
  }

  if (!firmId && !preparerId && !uniqueId && !user) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (firm?.error === 'not found') {
    return <Redirect to={{ pathname: '/login', state: { message: 'Looks like your account is already activated. Please login.' } }} />;
  }

  if (firm) {
    const isPersonalInviteLink = !!preparerId;
    const preparer = (isPersonalInviteLink && firm?.UserFirmAccounts?.length) ? firm?.UserFirmAccounts[0]?.Users : null;
    const preparerName = `${preparer?.UserInformation?.firstName} ${preparer?.UserInformation?.lastName}`;
    const firmName = firm?.name || translation('Invite.title.defaultPreparerName');

    const title = translation('Invite.title', { preparerName: preparer?.UserInformation?.firstName ? preparerName : firmName });
    return (
      <Page customStyles={null}>
        <MetaTags>
          <title>{title}</title>
          <meta name="description" content={title} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={logo} />
          <meta property="og:description" content={title} />
          {/* <meta property="og:url" content="//www.example.com/URL of the article" /> */}
        </MetaTags>
        <Header logo={logo} customLayout={CustomLayout} user={user} />
        {error && (
          <Alert
            description={error.message}
            message="Error"
            style={{ margin: '20px auto', maxWidth: '600px' }}
            type="error"
            showIcon
          />
        )}
        <Invite
          firm={firm}
          customLayout={CustomLayout}
          isPersonalInviteLink={isPersonalInviteLink}
          personalInviteLink={preparerId}
          referredBy={referredByUser?.GetUserByInviteLink?.id || null}
          user={user}
          uniqueId={uniqueId}
        />
      </Page>
    );
  }
  return <></>;
};

export default InviteContainer;
